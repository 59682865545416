import { Orders as OrderTypes } from '~/models/orders.d';
import CarInfo from '~/shared/car-info/car-info.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import Item from '../item/item.component';
import styles from './styled.module.scss';

type IProps = {
    section: OrderTypes.IBaseItemDetailOfInvoiceItem;
    searchQuery?: string;
};

export default function InvoiceSection({ section, searchQuery }: IProps) {
    const items = section?.itemDetails || [];

    return (
        <PanelCard className={styles.section}>
            <div className={styles.header}>
                <CarInfo carInfo={section?.carInfo} hideCountryCode />
            </div>
            {items.map((item) => (
                <Item key={item.itemId} item={item} searchQuery={searchQuery} />
            ))}
        </PanelCard>
    );
}
