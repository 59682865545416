import { useMemo } from 'react';
import { Users as UsersTypes } from '~/models/users';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Skeleton, { getSkeletonRandom } from '~/shared/skeleton';
import styles from './styled.module.scss';

interface IUserListProps {
    users: UsersTypes.IUserInfoCustomerDetails[];
    fetching: boolean;
    impersonate: (customerId: string) => void;
}

export default function CustomersList({ users = [], fetching, impersonate }: IUserListProps) {
    const translate = useTranslations();

    if (fetching) {
        return (
            <ul className={styles.customerList}>
                <SkeletonListItem />
                <SkeletonListItem />
                <SkeletonListItem />
            </ul>
        );
    }

    if (!fetching && !users?.length) {
        return <p className={styles.noResults}>{translate('common.noResults', 'Der er ingen resultater')}</p>;
    }

    return (
        <>
            <ul className={styles.customerList}>
                {users.map((user) => (
                    <li className={styles.customer} key={user?.id}>
                        <div className={styles.customerInfo}>
                            <span className={styles.departmentIdWrapper}>
                                {translate('user.dept', 'Dept')}
                                <span className={styles.departmentId}>{user?.customerDetails?.primaryDepartmentId}</span>
                            </span>
                            <span className={styles.name}>
                                {user?.customer?.name}
                                <br />
                                {user?.customerDetails?.customerId}
                            </span>
                            <span className={styles.address}>
                                {user?.customerDetails?.address} {user?.customerDetails?.zipCodeAndCity}
                            </span>
                            <span className={styles.phoneNumberWrapper}>
                                {translate('common.tel', 'Tlf.')}
                                <span className={styles.phoneNumber}> {user?.customerDetails?.phoneNumber}</span>
                            </span>
                        </div>

                        <Button
                            disabled={!user?.enabled}
                            onClick={() => {
                                impersonate(user?.id as string);
                            }}
                        >
                            {translate('impersonate.chooseCustomerButton', 'Vælg kunde')}
                        </Button>
                    </li>
                ))}
            </ul>
        </>
    );
}

const SkeletonListItem = () => {
    const width = useMemo(() => `${getSkeletonRandom(250)}px`, []);

    return (
        <li className={styles.customer}>
            <Skeleton
                style={{
                    height: '15px',
                    margin: '5px 0',
                    maxWidth: '100%',
                    width,
                }}
            />
        </li>
    );
};
