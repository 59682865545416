import { useForm } from 'react-hook-form';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { useExternalLogin, useUpdateExternalLogin } from '~/libs/queries/users/hooks/use-external-login';
import { Umbraco } from '~/models/umbraco.d';
import { Users } from '~/models/users';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Input from '~/shared/form-elements/input/input.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PageTitle from '~/shared/page-title/page-title.component';
import styles from './styled.module.scss';

export type ExternalLoginKey = Exclude<keyof Users.IExternalLoginsList, 'topMotive'>;
export type ExternalLoginType = Umbraco.WidgetTypes.nFLoginWidget | Umbraco.WidgetTypes.haynesLoginWidget;

export const LOGIN_TYPE_MAP: { [key in ExternalLoginType]: ExternalLoginKey } = {
    nFLoginWidget: 'nfData',
    haynesLoginWidget: 'haynes',
};

export type ExternalLoginFormFields = {
    userName: string;
};

export type ExternalLoginForm = {
    type: ExternalLoginType;
    title: string;
    userNameLabel: string | undefined;
    buttonText: string | undefined;
    successText: string | undefined;
    errorText: string | undefined;
};

export function ExternalLogin({ title, userNameLabel, buttonText, successText, errorText, type }: ExternalLoginForm) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const form = useForm<ExternalLoginFormFields>();

    const { isLoading, isError } = useExternalLogin({
        onSuccess: (data) => {
            const login = data[LOGIN_TYPE_MAP[type]];

            if (login && login.userName && !login.isInherited) {
                form.reset({ userName: login.userName });
            }
        },
    });

    const { mutate: update, isLoading: isLoadingUpdateExternalLogin } = useUpdateExternalLogin({
        onSuccess: () => {
            if (!successText) {
                console.error('Could not show success toast. Success text was undefined.');
                return;
            }
            dispatch(addSuccessToast(successText));
        },
        onError: () => {
            if (!errorText) {
                console.error('Could not show error toast. Error text was undefined.');
                return;
            }
            dispatch(addErrorToast(errorText));
        },
    });

    const handleSubmit = ({ userName }: ExternalLoginFormFields) => {
        if (isLoadingUpdateExternalLogin) {
            return;
        }

        update({ userName, widgetType: type });
    };

    return (
        <>
            <PageTitle>
                <Heading tagName="h1" displayStyle="h3">
                    {title}
                </Heading>
            </PageTitle>

            {isLoading && !isError ? (
                <Loader padding="50px 0" />
            ) : (
                <form className={styles.form} onSubmit={form.handleSubmit(handleSubmit)}>
                    <Input
                        className={styles.input}
                        label={userNameLabel}
                        {...form.register('userName', {
                            disabled: isLoading,
                        })}
                    />
                    <Button
                        disabled={!form.formState.isValid || !form.formState.isDirty}
                        fetching={isLoading}
                        type="submit"
                        className={styles.submitButton}
                    >
                        {buttonText}
                    </Button>
                </form>
            )}

            {isError ? <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox> : null}
        </>
    );
}
