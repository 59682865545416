import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Products as ProductTypes } from '~/models/products.d';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Skeleton from '~/shared/skeleton';
import Text from '~/shared/text/text.component';
import { IModalContentData } from '../modal-content/modal-content.component';
import {
    ICategoryWithParents,
    checkIfAllItemsPresent,
    checkIfSomeItemsPresent,
    getAllCategoryChildren,
    removeFromArray,
} from '../sales-price-markup.helpers';
import rootStyles from '../styled.module.scss';
import CollapseButton from './collapse-button/collapse-button.component';
import styles from './styled.module.scss';

const PriceMarkupSkeleton: FC = () => <Skeleton style={{ width: '100px', height: '15px' }} />;

interface IProps {
    category: ICategoryWithParents;
    isRoot?: boolean;
    prices?: ProductTypes.ICategorySalesPriceMarkup[];
    fetchingPrices?: boolean;
    categoriesListFlat: ICategoryWithParents[];
    selectedCategories: number[];
    setSelectedCategories: (ids: number[]) => void;
    selectedParent?: number;
    setModalContent: React.Dispatch<React.SetStateAction<IModalContentData>>;
}

export default function Category({
    selectedParent,
    category,
    prices,
    fetchingPrices,
    categoriesListFlat,
    selectedCategories,
    setSelectedCategories,
    setModalContent,
}: IProps) {
    const translate = useTranslations();
    const [isOpen, setOpen] = useState(false);
    const [hasSelectedChildren, setHasSelectedChildren] = useState(false);
    const priceMarkup = prices?.find((price) => price.categoryId === category.id);

    const isSelected = selectedCategories.includes(category.id);
    const allCategoryChildren = getAllCategoryChildren(category);

    useEffect(() => {
        const allCategoryChildrenIds: number[] = allCategoryChildren.map((c) => c.id);

        setHasSelectedChildren(!selectedParent && !isSelected && checkIfSomeItemsPresent(selectedCategories, [...allCategoryChildrenIds]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategories, isSelected, selectedParent]);

    useEffect(() => {
        const categoryDirectChildrenIds = category.hasChildren ? category?.children?.map((child) => child.id) : [];

        if (categoryDirectChildrenIds?.length && checkIfAllItemsPresent([...categoryDirectChildrenIds], selectedCategories)) {
            handleAdd();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategories]);

    const handleAdd = () => {
        if (category.hasChildren) {
            const childrenIds = getAllCategoryChildren(category).map((c) => c.id);
            const newItems = removeFromArray(selectedCategories, childrenIds);
            setSelectedCategories([...newItems, category.id]);
        } else {
            setSelectedCategories([...selectedCategories, category.id]);
        }
    };

    const handleRemove = () => {
        setSelectedCategories(selectedCategories?.filter((c) => c !== category.id));
    };

    const handleRemoveFromParent = () => {
        const categoriesToSelect: number[] = [];
        const selectedParentIndex = category?.parents?.indexOf(selectedParent as number);
        const categoryParentsUpToSelected = [...(category.parents as number[])].slice(selectedParentIndex);

        categoryParentsUpToSelected.forEach((catId) => {
            const parent = categoriesListFlat.find((c) => c.id === catId);
            if (!parent) return;
            if (!parent.hasChildren) {
                categoriesToSelect.push(parent.id);
            } else {
                parent?.children?.forEach((child) => {
                    categoriesToSelect.push(child.id);
                });
            }
        });

        const unique = [...new Set([...selectedCategories, ...categoriesToSelect])];
        setSelectedCategories(removeFromArray(unique, [...categoryParentsUpToSelected, category.id]));
    };

    const handleChange = () => {
        if (selectedParent) {
            handleRemoveFromParent();
        } else if (!isSelected) {
            handleAdd();
        } else {
            handleRemove();
        }
    };

    return (
        <li className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <CollapseButton hasChildren={category?.hasChildren} onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
                <div
                    className={clsx(styles.content, rootStyles.grid, {
                        [styles.selectedContent]: hasSelectedChildren || isSelected || selectedParent,
                    })}
                >
                    <Text textStyle="bodySmall" fontWeight="semiBold" className={styles.categoryName}>
                        {category?.name}
                    </Text>
                    <Text textStyle="bodySmall" className={styles.updateTime}>
                        {fetchingPrices ? <PriceMarkupSkeleton /> : priceMarkup?.updatedAtDisplayTime}
                    </Text>
                    <Text textStyle="bodySmall" className={styles.userInfo}>
                        {fetchingPrices ? <PriceMarkupSkeleton /> : priceMarkup?.userName}
                    </Text>
                    <Text textStyle="bodySmall" className={styles.priceInfo}>
                        {fetchingPrices ? <PriceMarkupSkeleton /> : priceMarkup?.priceFactorDescription}
                    </Text>
                    <span className={styles.buttonCell}>
                        <button
                            type="button"
                            className={styles.button}
                            onClick={() =>
                                setModalContent({
                                    items: [category.id],
                                    isRoot: false,
                                })
                            }
                        >
                            <Text fontWeight="semiBold" textStyle="bodySmall">
                                {translate('salesPriceMarkup.setPriceLevel', 'Sæt prisniveau')}
                            </Text>
                        </button>
                    </span>
                    <span className={styles.checkBoxCell}>
                        <Checkbox halfChecked={hasSelectedChildren} checked={isSelected || !!selectedParent} onChange={handleChange} />
                    </span>
                </div>
            </div>
            {isOpen && (
                <div>
                    {category?.children?.length ? (
                        <ul className={styles.children}>
                            {category?.children.map((child) => (
                                <Category
                                    setModalContent={setModalContent}
                                    categoriesListFlat={categoriesListFlat}
                                    selectedParent={isSelected ? category.id : selectedParent}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedCategories={selectedCategories}
                                    fetchingPrices={fetchingPrices}
                                    prices={prices}
                                    key={child?.id}
                                    category={child}
                                />
                            ))}
                        </ul>
                    ) : null}
                </div>
            )}
        </li>
    );
}
