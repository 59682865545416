import { useCheckoutBasket } from '~/libs/queries/basket';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketHeader } from './basket-header';

export function BasketHeaderContainer() {
    const translate = useTranslations();
    const { data: { totalItemsCount } = {} } = useCheckoutBasket();

    return <BasketHeader heading={translate('basket.basket', 'Kurv')} showSavedBasketsLink={Boolean(totalItemsCount)} />;
}
