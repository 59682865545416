import { FormEventHandler } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import styles from '../basket.module.scss';
import { BasketErrorContainer } from '../components/basket-error/basket-error.container';
import { BasketHeaderContainer } from '../components/basket-header/basket-header.container';
import type { ICheckoutValidationProps } from './basket-content.container';
import BasketCheckout from './components/basket-checkout/basket-checkout';
import { BasketFooter } from './components/basket-footer/basket-footer';
import { BasketItemsContainer } from './components/basket-items/basket-items.container';
import { BasketSummaryContainer } from './components/basket-summary/basket-summary.container';

export type BasketProps = {
    onSubmit: FormEventHandler<HTMLFormElement>;
    formProviderProps: UseFormReturn<ICheckoutValidationProps>;
};

export function BasketContent({ formProviderProps, onSubmit }: BasketProps) {
    return (
        <FormProvider {...formProviderProps}>
            <form className={styles.basket} onSubmit={onSubmit}>
                <BasketHeaderContainer />
                <BasketErrorContainer />

                <BasketItemsContainer />
                <BasketSummaryContainer />
                <BasketCheckout />

                <BasketFooter />
            </form>
        </FormProvider>
    );
}
