import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';
import { Products } from '~/models/products';

const truckRequestService = (postData: Products.ICreateTruckSparePartRequest, user: Session | null) => {
    const url = createUrl({
        endpoint: endpoints.products.truckRequest,
        localeOption: LocaleOptions.omit,
    });

    return apiClient.auth(user).postJSON(url, postData);
};

export default truckRequestService;
