import Text from '~/shared/text/text.component';

export type BasketHeaderItemsCountProps = {
    totalItemsLabel: string;
};

export function BasketHeaderItemsCount({ totalItemsLabel }: BasketHeaderItemsCountProps) {
    return (
        <Text overflowEllipsis textStyle="bodySmall" secondary>
            {totalItemsLabel}
        </Text>
    );
}
