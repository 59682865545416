import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { PRINT_BASKET_IF_PICKUP } from '~/constants/local.storage';
import { addErrorToast, addSuccessToast, LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { GA4CompleteCheckout } from '~/libs/ga4';
import { useCheckoutBasket, useCompleteCheckout } from '~/libs/queries/basket';
import { queryKeys } from '~/libs/queries/query-keys';
import useActiveUser from '~/libs/use-active-user';
import useUser, { useIsImpersonated } from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import { Bff } from '~/models/bff.d';
import { printEndpoints } from '~/services/auth-endpoints';
import { formatDeliveryDate, getDeliveryTypeString, getTrackingProducts, trackPurchaseToExtendApi } from '~/services/basket/basket-tracking.service';
import useImpersonateMode from '~/services/user/use-impersonate-mode';
import useCookie from '~/shared/hooks/use-cookie';
import { getLocalStorageItem } from '~/shared/hooks/use-local-storage.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from './use-basket-states';

export function UseBasketSubmission() {
    const { data: { loggedInPageUrl } = {} } = useStaticContent();
    const translate = useTranslations();
    const router = useRouter();
    const queryClient = useQueryClient();
    const dispatch = useLayoutDispatch();
    const { selectedDepartment } = useLayoutState();
    const { cookieValue: gaCookie } = useCookie('_ga');
    const { cookieValue: gaTransactionCookie } = useCookie(process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? '_ga_3T6SGFSKF0' : '_ga_1CRT7HPV0D');

    const { profile, user } = useUser();
    const { activeProfile } = useActiveUser();
    const isImpersonated = useIsImpersonated();
    const { deactivate } = useImpersonateMode();

    const { data: basket, refetch: refetchBasket } = useCheckoutBasket();
    const { mutateAsync: completeCheckoutAsync } = useCompleteCheckout();

    const { isSubmitting, setIsSubmitting, isCheckoutFetching, splitDeliveryOption, setSplitDeliveryOption, selectedDeliveryMethod } =
        useBasketStates();

    const handleSubmitSuccess = async (transactionId: string, manualOrder?: boolean) => {
        // This should never happen because user cant checkout without active basket and user
        if (!profile || !activeProfile || !basket) {
            return;
        }

        // This should never happen because user cant checkout without selectedDeliveryMethod
        if (!selectedDeliveryMethod) {
            return;
        }

        dispatch(addSuccessToast(translate('basket.purchase.success', 'Køb gennemført')));

        // Track purchase
        const staticContent = queryClient.getQueryData<Bff.IStaticContentV2>(queryKeys.bff.staticContent(router.locale, user));
        const currency = profile.currency;

        const sortedGroups = (basket.groups || []).sort((a, b) => {
            return +(a.basketCarInfo === null) - +(b.basketCarInfo === null);
        });
        const trackingProducts = getTrackingProducts(sortedGroups, currency, staticContent);

        const deliveryDateAndTime = basket.deliveryDetails?.deliveryDateAndTime;
        const deliveryDate = formatDeliveryDate(
            typeof deliveryDateAndTime === 'string' ? new Date(deliveryDateAndTime) : (deliveryDateAndTime as Date),
        );
        const deliveryType = getDeliveryTypeString(splitDeliveryOption);
        const total = basket.total as BasketTypes.IPriceModelDto;

        const trackingManualOrder =
            manualOrder ||
            // is there any comment on the order
            (basket.orderDetails?.comment && basket.orderDetails.comment.trim() !== '') ||
            // is there any item with stockStatus other than 0
            sortedGroups.some((group) => group.items.some((item) => item.stockStatus?.statusCode !== 0));

        GA4CompleteCheckout(trackingProducts, {
            employeeID: profile.employeeId?.toString(),
            basketId: basket.id,
            transactionId: transactionId,
            currency: currency,
            value: total?.customerPrice || 0,
            deliveryType,
            deliveryMethod: selectedDeliveryMethod.displayName || '',
            deliveryDate,
            vat: total.vat || 0,
            shippingCost: 0,
            manualOrder: trackingManualOrder,
        });

        if (gaCookie && gaTransactionCookie) {
            await trackPurchaseToExtendApi(
                gaCookie,
                gaTransactionCookie,
                currency,
                total,
                activeProfile,
                deliveryDate,
                selectedDeliveryMethod,
                splitDeliveryOption,
                profile,
                trackingProducts,
                transactionId,
            );
        }

        setSplitDeliveryOption(BasketTypes.DeliveryType.UnSpecified);
    };

    const handleSubmitError = (translationString: string, errorMessages?: string[]) => {
        refetchBasket();

        if (errorMessages?.length) {
            errorMessages.forEach((message) => {
                dispatch(addErrorToast(message));
            });
        } else {
            dispatch(addErrorToast(translate(`basket.purchase.${translationString}`, 'Ordren blev desværre ikke oprettet. Prøv igen.')));
        }
    };

    return async (isManualOrder = false) => {
        // This should never happen because user cant checkout without active basket and user
        if (!activeProfile || !basket || !basket.id) {
            return;
        }

        if (isSubmitting || activeProfile.cantBuyProducts || isCheckoutFetching) {
            return;
        }

        setIsSubmitting(true);

        const { userMustChoseDeliveryType } = basket.deliveryDetails?.validation || {};
        const result = await completeCheckoutAsync({
            basketId: basket.id,
            deliveryType: userMustChoseDeliveryType ? splitDeliveryOption : BasketTypes.DeliveryType.UnSpecified,
            departmentId: selectedDepartment?.id,
            manualOrder: isManualOrder,
        });

        if (!result) {
            handleSubmitError('');
        } else if (result.status === BasketTypes.CompleteOrderValidation.Success && result.transactionId) {
            if (basket?.deliveryDetails?.isPickup && JSON.parse(getLocalStorageItem(PRINT_BASKET_IF_PICKUP))) {
                window.setTimeout(() => {
                    window.open(`${printEndpoints.basket}?id=${basket?.id}`, '_blank');
                }, 500);
            }

            isImpersonated && (await deactivate());

            await handleSubmitSuccess(result.transactionId, isManualOrder);

            await router.push(result.redirectUrl || loggedInPageUrl || '/');
        } else {
            const translationString = `${result.status.charAt(0).toLowerCase()}${result.status.slice(1)}`;
            handleSubmitError(translationString, result.errorMessages);
        }

        dispatch({
            type: LayoutActionTypes.SetExpertMode,
            payload: false,
        });

        dispatch({
            type: LayoutActionTypes.SetSelectedDepartment,
            payload: null,
        });

        setIsSubmitting(false);
    };
}
