import React from 'react';
import Heading from '~/shared/heading/heading.component';

import styles from './topmotive-heading.module.scss';

export type TopmotiveHeadingProps = {
    children: string;
};

export function TopmotiveHeading({ children }: TopmotiveHeadingProps) {
    return (
        <Heading className={styles.topmotiveHeading} tagName="h3" displayStyle="h2">
            {children}
        </Heading>
    );
}
