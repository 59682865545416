import clsx from 'clsx';
import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';
import styles from './copy-to-clipboard.module.scss';
import Text from '~/shared/text/text.component';
import { useLayoutDispatch, addErrorToast, addSuccessToast } from '~/context/layout.context';
import useTranslations from '../hooks/use-translations.hook';
import useUser from '~/libs/use-user';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';

interface IProps extends IWithClassName {
    textToCopy: string | undefined;
    internalOnly?: boolean;
    tooltipText?: string;
}

const CopyToClipboard: FC<IProps> = ({ children, textToCopy, className, internalOnly, tooltipText }) => {
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const copyText = () => {
        navigator.clipboard.writeText(textToCopy as string).then(
            () => {
                /* clipboard successfully set */
                dispatch(
                    addSuccessToast(
                        translate('copyToClipboard.textIsCopied', '"[textToCopy]" er kopieret til udklipsholderen').replace(
                            '[textToCopy]',
                            textToCopy
                        )
                    )
                );
            },
            () => {
                /* clipboard write failed */
                dispatch(
                    addErrorToast(translate('copyToClipboard.couldNotCopy', '"[textToCopy]" kunne ikke kopieres').replace('[textToCopy]', textToCopy))
                );
            }
        );
    };

    if (internalOnly && !isInternalUser) return <>{children}</>;

    return (
        <div className={clsx(className, styles.root)}>
            {children}
            <button type="button" onClick={copyText} className={styles.button}>
                <Tooltip
                    content={
                        <TooltipContentWrapper>
                            <Text overflowEllipsis fontWeight="regular">
                                {tooltipText || translate('copyToClipboard.copy', 'Kopiér')}
                            </Text>
                        </TooltipContentWrapper>
                    }
                >
                    <Svg name="paper-stack" className={styles.icon} />
                </Tooltip>
            </button>
        </div>
    );
};

export default CopyToClipboard;
