import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const updateDeliveryAddress = async (basketId: string, addressId: string | null | undefined, user: Session | null) => {
    const url = createUrl({
        endpoint: endpoints.baskets.setDeliveryAddress,
        localeOption: LocaleOptions.omit,
        query: {
            basketId,
            addressId,
        },
    });

    return apiClient.auth(user).put(url);
};

export default updateDeliveryAddress;
