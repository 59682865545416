import { TopmotiveAddToBasketEvent } from '~/shared/topmotive/types';

const TOPMOTIVE_ORIGIN = process.env.NEXT_PUBLIC_TOPMOTIVE_HOST;

export function isTopmotiveHost(origin: string) {
    return origin === TOPMOTIVE_ORIGIN;
}

export function isTopmotiveAddToBasketEvent(data: any): data is TopmotiveAddToBasketEvent {
    return data.addOePartsToBasket && Array.isArray(data.addOePartsToBasket);
}
