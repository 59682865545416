import React, { FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import Toggle from '~/shared/toggle/toggle.component';
import { Products as ProductTypes } from '~/models/products.d';
import { LocaleOptions, createUrl } from '~/services/service-endpoint';
import { IWithClassName } from '~/models/dev';

const ViewToggle: FC<IWithClassName> = ({ className }) => {
    const { query, replace } = useRouter();

    const isListView = useMemo(() => {
        if (!query.viewType) return true;
        return Number(query.viewType) === ProductTypes.CategoryDefaultViewType.ProductsListView;
    }, [query.viewType]);

    const toggleViewType = async () => {
        const newQuery = { ...query };
        delete newQuery.slug;
        delete newQuery.page;

        const newViewType = isListView ? ProductTypes.CategoryDefaultViewType.TilesListView : ProductTypes.CategoryDefaultViewType.ProductsListView;

        const url = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.path,
            query: {
                ...newQuery,
                viewType: newViewType,
            },
        });

        replace(url, undefined, {
            shallow: true,
        });
    };

    return <Toggle isOn={!isListView} className={className} onClick={toggleViewType} onIcon="list" offIcon="tiles" />;
};

export default ViewToggle;
