import { Basket as BasketTypes } from '~/models/basket.d';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';

export type ProductItemFtzCodeProps = {
    ftzCode: string | undefined;
    allPrices: BasketTypes.IArticleQuantityPriceModelDto[];
    colli: number;
    colliLocked: boolean;
    caption: string;
    captionClassName?: string;
};

export function ProductItemFtzCode({ ftzCode, allPrices, colli, colliLocked, caption, captionClassName }: ProductItemFtzCodeProps) {
    return (
        <ValueWithCaption caption={caption} captionClassName={captionClassName}>
            <FtzPricesTooltip
                isShort
                prices={{
                    actualPrice: {
                        ftzCode: ftzCode,
                    },
                    allPrices: allPrices,
                }}
                colli={colli}
                colliLocked={colliLocked}
            />
        </ValueWithCaption>
    );
}
