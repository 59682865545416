import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { addSuccessToast, LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { GA4BasketDelete, GA4RemoveListFromCart } from '~/libs/ga4';
import { useCheckoutBasket, useDeleteBasket, useDeleteBasketHandlers } from '~/libs/queries/basket';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import { Bff } from '~/models/bff.d';
import { getTrackingProducts } from '~/services/basket/basket-tracking.service';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../use-basket-states';
import { BasketFooterDeleteBasket } from './basket-footer-delete-basket';

export function BasketFooterDeleteBasketContainer() {
    const { data: { loggedInPageUrl } = {} } = useStaticContent();
    const translate = useTranslations();
    const router = useRouter();
    const queryClient = useQueryClient();
    const dispatch = useLayoutDispatch();
    const { profile, user } = useUser();
    const { data: basket } = useCheckoutBasket();
    const { setSplitDeliveryOption, isLoading } = useBasketStates();

    const { mutate: deleteBasket } = useDeleteBasket();
    const {
        handleError: handleDeleteBasketError,
        handleSettled: handleDeleteBasketSettled,
        handleSuccess: handleDeleteBasketSuccess,
    } = useDeleteBasketHandlers();

    const handleDelete = async () => {
        // This should never happen because footer is only rendered with active basket and user
        if (!profile || !basket) {
            return;
        }

        if (window.confirm(translate('basket.deleteConfirmMessage', 'Er du sikker på du vil slette kurven'))) {
            deleteBasket(
                { basketId: basket?.id },
                {
                    onSuccess: async (...args) => {
                        dispatch(addSuccessToast(translate('basket.yourBasketHasBeenDeleted', 'Kurven er slettet')));

                        handleDeleteBasketSuccess(...args);

                        GA4BasketDelete();

                        // Track all removed cart items
                        const staticContent = queryClient.getQueryData<Bff.IStaticContentV2>(queryKeys.bff.staticContent(router.locale, user));
                        const currency = profile.currency;

                        const sortedGroups = (basket.groups || []).sort((a, b) => {
                            return +(a.basketCarInfo === null) - +(b.basketCarInfo === null);
                        });

                        const trackingProducts = getTrackingProducts(sortedGroups, currency, staticContent);

                        GA4RemoveListFromCart(trackingProducts, {
                            basketId: basket.id,
                            value: basket.total?.customerPrice || 0,
                            currency: currency,
                        });

                        await router.push(loggedInPageUrl || '/');
                    },
                    onError: handleDeleteBasketError,
                    onSettled: async (...args) => {
                        setSplitDeliveryOption(BasketTypes.DeliveryType.UnSpecified);

                        await handleDeleteBasketSettled(...args);

                        dispatch({
                            type: LayoutActionTypes.SetSelectedDepartment,
                            payload: null,
                        });

                        dispatch({
                            type: LayoutActionTypes.SetExpertMode,
                            payload: false,
                        });
                    },
                },
            );
        }
    };

    return <BasketFooterDeleteBasket label={translate('basket.deleteBasket', 'deleteBasket')} isLoading={isLoading} handleClick={handleDelete} />;
}
