import clsx from 'clsx';
import { useState } from 'react';
import { Orders as OrderTypes } from '~/models/orders.d';
import CollapseButton from '~/shared/collapse-button/collapse-button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import CreditDetails from './details/details.component';
import Overview from './overview/overview.component';
import styles from './styled.module.scss';

type IProps = {
    credit: OrderTypes.ICreditViewModel;
    creditIdOrSellerOrderNumber: number;
    isSelected: boolean;
    onCreditSelectionChange: (credit: OrderTypes.ICreditViewModel) => any;
    searchQuery: string;
};

export default function Credit({ credit, creditIdOrSellerOrderNumber, isSelected, onCreditSelectionChange, searchQuery }: IProps) {
    const [isOpen, setOpen] = useState<boolean>(false);

    const isDetailsView = isOpen || (credit?.items?.length as number) > 0;

    return (
        <div
            className={clsx(styles.root, {
                [styles.open]: isOpen,
            })}
        >
            <Checkbox className={styles.checkBox} checked={isSelected} onChange={onCreditSelectionChange(credit)} />
            <div
                className={clsx(styles.collapseButtonWrapper, {
                    [styles.open]: isOpen,
                })}
            >
                <CollapseButton onClick={() => setOpen(!isOpen)} isOpen={isOpen} className={styles.collapseButton} />
            </div>
            {!isDetailsView && (
                <Overview credit={credit} creditIdOrSellerOrderNumber={creditIdOrSellerOrderNumber} setOpen={setOpen} searchQuery={searchQuery} />
            )}
            {isDetailsView && (
                <CreditDetails
                    creditId={credit?.id}
                    searchQuery={searchQuery}
                    credit={credit}
                    sellerOrderNumber={credit?.sellerOrderNumber}
                    isOpen={isOpen}
                />
            )}
        </div>
    );
}
