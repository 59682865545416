import { useState } from 'react';
import { Orders as OrderTypes } from '~/models/orders';
import ButtonAnimated, { IButtonAnimatedProps } from '~/shared/buttons/button-animated/button-animated.component';
import Collapse from '~/shared/collapse/collapse.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import OrdersGroup from '../orders-group/orders-group.component';
import styles from './styled.module.scss';

interface IProps {
    timeSlot: OrderTypes.IOrdersOverviewByTime;
}

export default function TimeSlot({ timeSlot }: IProps) {
    const [isOpen, setOpen] = useState(true);

    const translate = useTranslations();

    const handleClick = () => setOpen(!isOpen);
    const iconProps = {
        name: 'badge-confirm',
    };

    const activeText = translate('common.hide', 'skjul');
    const inactiveText = translate('common.show', 'vis');

    const buttonAnimatedProps = {
        className: styles.button,
        onClick: handleClick,
        icon: iconProps,
        active: isOpen,
        text: timeSlot.displayDateTimeTitle,
        activeText,
        inactiveText,
    };

    return (
        <div className={styles.timeSlot}>
            <div className={styles.divider}>
                <ButtonAnimated {...(buttonAnimatedProps as IButtonAnimatedProps)} />
            </div>
            <Collapse isOpen={isOpen} className={styles.content}>
                {timeSlot?.orders?.map((group, index) => (
                    <OrdersGroup group={group} key={`${group?.carInfo?.carId}-${group.webOrderNumber}-${index}`} />
                ))}
            </Collapse>
        </div>
    );
}
