import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Bff } from '~/models/bff';
import { Products as ProductTypes } from '~/models/products';
import { Umbraco } from '~/models/umbraco.d';
import { LocaleOptions, createUrl } from '~/services/service-endpoint';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import { ExhaustProductList, PictogramProductList, UniversalProductList } from '~/shared/product-list/product-list.component';
import styles from './styled.module.scss';

function debounce(callback: () => void, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    function debounced() {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            callback();
        }, delay);
    }

    return debounced;
}

// Old component: product-list-page.component.tsx
export default function W121SparePartProductList({
    type,
    category,
    categoryId,
    categoryDefaultViewType,
    image,
    exhaustSystemId,
    pictogramId,
    pictogramGroupAlias,
    pictogramGroupType,
    carId,
}: Bff.ISparePartProductListWidgetViewModel) {
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const { query, replace } = useRouter();

    useEffect(() => {
        let viewType: ProductTypes.CategoryDefaultViewType;

        const newQuery = { ...query };
        delete newQuery.slug;

        if (query?.viewType !== undefined) {
            viewType = Number(query?.viewType);
        } else {
            viewType = categoryDefaultViewType ?? 0;
        }

        const url = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.omit,
            query: {
                ...newQuery,
                viewType,
            },
        });

        if (viewType && viewType !== Number(query?.viewType)) {
            replace(url, undefined, {
                shallow: true,
            });
        }
    }, [categoryDefaultViewType, query, replace]);

    useEffect(() => {
        const handleScroll = debounce(() => {
            setShowScrollTopButton((prev) => {
                const scrollPos = window?.scrollY > 300;

                if (prev === scrollPos) {
                    return prev;
                }

                return scrollPos;
            });
        }, 250);

        window?.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            window?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const list = useMemo(() => {
        if (type === Umbraco.WidgetTypes.sparePartProductListWidget && exhaustSystemId) {
            return <ExhaustProductList exhaustSystemId={String(exhaustSystemId)} title={category} carId={carId} image={image} />;
        }
        if (type === Umbraco.WidgetTypes.sparePartProductListWidget && categoryId) {
            return <UniversalProductList categoryId={String(categoryId)} carId={carId} title={category} />;
        }
        if (type === Umbraco.WidgetTypes.sparePartProductListWidget) {
            return (
                <PictogramProductList
                    title={category}
                    carId={carId}
                    pictogramId={pictogramId}
                    pictogramGroupAlias={pictogramGroupAlias}
                    pictogramGroupType={pictogramGroupType}
                    categoryId={String(categoryId)}
                />
            );
        }

        return null;
    }, [type, exhaustSystemId, categoryId, carId, category, pictogramId, pictogramGroupAlias, pictogramGroupType, image]);

    return (
        <>
            {list}
            <div className={showScrollTopButton ? styles.scrollToTopBtnActive : styles.scrollToTopBtn}>
                <ButtonCircle
                    iconName="chevron-up"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        })
                    }
                />
            </div>
        </>
    );
}
