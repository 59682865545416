import { IWithClassName } from '~/models/dev.d';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';

export type BasketHeaderPrintBasketProps = IWithClassName & {
    url: string;
    label: string;
};

export function BasketHeaderPrintBasket({ url, label, className }: BasketHeaderPrintBasketProps) {
    return (
        <Button className={className} icon={<Svg name="print" />} buttonSize="small" buttonStyle="clean" href={url} target="_blank">
            {label}
        </Button>
    );
}
