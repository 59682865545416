import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket, useUpdateBasket } from '~/libs/queries/basket';
import { Basket as BasketTypes } from '~/models/basket.d';
import { BasketItemsLineItem } from './basket-items-line-item';

export type BasketItemsLineItemContainerProps = {
    item: BasketTypes.IBasketItemDto;
    carInfo?: BasketTypes.IBasketCarInfoDto;
};

export function BasketItemsLineItemContainer({ item, carInfo }: BasketItemsLineItemContainerProps) {
    const { itemId, price, productLink } = item;
    const { data: basket } = useCheckoutBasket();
    const { mutate: updateBasket } = useUpdateBasket();
    const { selectedDepartment } = useLayoutState();

    // This should never happen because BasketItems is only rendered with active basket
    if (!basket) {
        return null;
    }

    const removeFromBasket = async () => {
        if (!basket) {
            console.error('Could not delete item from basket. Basket was undefined.');

            return;
        }

        if (!itemId) {
            console.error('Could not delete item from basket. Item id was undefined.');

            return;
        }

        updateBasket({
            basketBeforeUpdate: basket,
            departmentId: selectedDepartment?.id,
            skipValidation: false,
            details: {
                basketId: basket.id,
                carId: carInfo?.carId,
                licensePlate: carInfo?.licensePlate,
                count: 0,
                ftzPrice: price?.customerPrice || 0,
                url: productLink?.url ?? '',
                listName: '',
                itemId,
            },
        });
    };

    return <BasketItemsLineItem item={item} carInfo={carInfo} handleRemoveFromBasket={removeFromBasket} />;
}
