import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import EmailSignUpForm from '~/shared/email-sign-up-form/email-sign-up-form.component';
import styles from './styled.module.scss';
import { useStaticContent } from '~/libs/queries/bff';

export default function F020EmailBanner({ primaryText, text }: Bff.IF020EmailBannerViewModel | Bff.IM210EmailSubscriberModule) {
    const { isLoggedIn, isInternalUser } = useUser();
    const { isLoading } = useStaticContent();

    if (isInternalUser || !isLoggedIn || isLoading) {
        return null;
    }

    return (
        <section className={styles.singUpWrapper}>
            <EmailSignUpForm primaryText={primaryText} text={text} />
        </section>
    );
}
