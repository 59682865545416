import { useState } from 'react';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { Users as UsersTypes } from '~/models/users.d';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

interface IProps {
    users: UsersTypes.IExternalSubUserInfo[];
    loadingUsers?: boolean;
    editUser: (user: UsersTypes.IExternalSubUserInfo) => void;
    deleteUser: (userId: number) => Promise<any>;
}

export default function UsersList({ users, loadingUsers, editUser, deleteUser }: IProps) {
    const [deleting, setDeleting] = useState<number | null>(null);

    const dispatch = useLayoutDispatch();
    const translate = useTranslations();

    const handleDelete = async (userId: number) => {
        setDeleting(userId);
        try {
            await deleteUser(userId);
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        } finally {
            setDeleting(null);
        }
    };

    if (loadingUsers) return <Loader padding="100px 20px" />;
    if (!users?.length) return null;

    return (
        <table>
            <thead>
                <tr>
                    <th>{translate('userAdministration.name', 'Navn')}</th>
                    <th>{translate('userAdministration.userName', 'Brugernavn')}</th>
                    <th>{translate('userAdministration.email', 'Email')}</th>
                    <th>{translate('userAdministration.role', 'Rolle')}</th>
                </tr>
            </thead>
            <tbody>
                {users?.map((user) => (
                    <tr key={user.id}>
                        <td>{user?.name}</td>
                        <td>{user?.userName}</td>
                        <td>{user?.email}</td>
                        <td>
                            <div className={styles.buttonsWrapper}>
                                <Text fontWeight="semiBold">{user?.role?.name}</Text>
                                <div className={styles.buttons}>
                                    <Button
                                        fetching={user?.id === deleting}
                                        buttonStyle="clean"
                                        icon={<Svg name="bin" />}
                                        onClick={() => handleDelete(user.id)}
                                    />
                                    <Button
                                        fetching={user?.id === deleting}
                                        buttonStyle="secondaryLight"
                                        icon={<Svg name="edit" />}
                                        onClick={() => editUser(user)}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
