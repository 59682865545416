import { useEffect } from 'react';
import { TopmotiveAddToBasketEvent } from '~/shared/topmotive/types';
import { isTopmotiveAddToBasketEvent, isTopmotiveHost } from '~/shared/topmotive/utils';

export type UseTopmotiveHandlerAddToBasketParams = {
    productId: string;
    productName: string;
    manufacturerName: string;
    manufacturerId: number;
};

export type UseTopmotiveHandlerAddToBasketHandler = (params: UseTopmotiveHandlerAddToBasketParams) => void;
export type UseTopmotiveHandlerProps = {
    onAddToBasket: UseTopmotiveHandlerAddToBasketHandler;
    onAddMultipleError: () => void;
};

export function useTopmotiveHandler({ onAddToBasket, onAddMultipleError }: UseTopmotiveHandlerProps) {
    useEffect(() => {
        const handleMessage = (e: MessageEvent<TopmotiveAddToBasketEvent | unknown>) => {
            if (!isTopmotiveHost(e.origin)) {
                return;
            }

            const { data } = e;

            if (!isTopmotiveAddToBasketEvent(data)) {
                return;
            }

            if (data.addOePartsToBasket.length > 1) {
                // Adding multiple products to the basket from the topmotive frame is currently not supported.
                onAddMultipleError();

                return;
            }

            const [{ oeNumber, articleName, manufacturer, manufacturerId }] = data.addOePartsToBasket;

            onAddToBasket({
                productId: oeNumber,
                productName: articleName,
                manufacturerName: manufacturer,
                manufacturerId,
            });
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
}
