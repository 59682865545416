import { Bff } from '~/models/bff';
import { Umbraco } from '~/models/umbraco.d';
import { ExternalLogin } from './external-login.component';

// Old component: nf.component.tsx
export default function W072NFLogin({ pageTitle, labelForLoginName, labelForSaveButton, errorText, successText }: Bff.INfLoginWidget) {
    return (
        <ExternalLogin
            type={Umbraco.WidgetTypes.nFLoginWidget}
            title={pageTitle}
            userNameLabel={labelForLoginName}
            buttonText={labelForSaveButton}
            errorText={errorText}
            successText={successText}
        />
    );
}
