import { ChangeEvent, useEffect, useState } from 'react';
import { CREDIT_DRAFTS } from '~/constants/local.storage';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { Orders as OrderTypes } from '~/models/orders.d';
import { Products as ProductTypes } from '~/models/products';
import { useCreditDraftRequisitionStorage } from '~/pages/external/credit';
import { printEndpoints } from '~/services/auth-endpoints';
import createCreditMultiple from '~/services/orders/create-credit-multiple.service';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Input from '~/shared/form-elements/input/input.component';
import Heading from '~/shared/heading/heading.component';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import DraftsList from './drafts-list/drafts-list.component';
import styles from './styled.module.scss';

export interface ICreditDraft extends OrderTypes.ICreditItemRequest {
    timeStamp: number;
    image?: Bff.IImage;
    title?: string;
    brand?: ProductTypes.IBrand;
    itemUrl?: string;
    creditLocation?: string;
    invoiceUrl?: string;
    price?: string;
    deposit?: string;
}

export interface IDraftWithErrors extends ICreditDraft {
    responseError: string;
    duplicateWarning?: boolean;
}

type IProps = {
    mutateCredits?: () => void;
};

export default function CreditDrafts({ mutateCredits }: IProps) {
    const { isInternalUser, user } = useUser();
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const [localStorageDrafts, setLocalStorageDrafts] = useLocalStorage<ICreditDraft[]>(CREDIT_DRAFTS, []);
    const [storedRequisitionNumber, setStoredRequisitionNumber] = useCreditDraftRequisitionStorage();

    const [draftsList, setDraftsList] = useState<IDraftWithErrors[]>([]);

    const [genericError, setGenericError] = useState(false);
    const [hasDuplicates, setHasDuplicates] = useState(false);
    const [responseErrors, setResponseErrors] = useState(null);
    const [fetching, setFetching] = useState(false);

    const getItemError = (invoiceId: number, itemId: string) => {
        if (!responseErrors) return;
        const invoiceErrors = responseErrors[invoiceId] || {};
        return invoiceErrors[itemId] || null;
    };

    useEffect(() => {
        if (!localStorageDrafts?.length) {
            return;
        }

        handleSubmit(true);
    }, [localStorageDrafts]);

    useEffect(() => {
        checkForDraftsWithError();
        mapDrafts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorageDrafts, responseErrors]);

    useEffect(() => {
        setHasDuplicates(draftsList.some((item) => item.duplicateWarning));
    }, [draftsList]);

    const checkForDraftsWithError = () => {
        if (!responseErrors) return;

        const draftsWithErrors = localStorageDrafts.filter((draft) => getItemError(draft.invoiceId as number, draft.itemNo as string));

        if (!draftsWithErrors.length) setResponseErrors(null);
    };

    const deleteDraft = (itemID: string, timeStamp: number) => {
        const newDraftsList = localStorageDrafts.filter((draft) => draft?.itemNo !== itemID || draft?.timeStamp !== timeStamp);
        setLocalStorageDrafts(newDraftsList);
    };

    const deleteAllWithErrors = () => {
        const newDraftsList = draftsList
            .filter((draft) => !draft?.responseError)
            .map((draft) => {
                const newDraft = { ...draft };
                delete newDraft?.duplicateWarning;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                delete newDraft?.responseError;
                return newDraft;
            });
        setLocalStorageDrafts(newDraftsList);
    };

    const deleteAllDuplicates = () => {
        const newDraftsList = draftsList
            .filter((draft) => !draft?.duplicateWarning)
            .map((draft) => {
                const newDraft = { ...draft };
                delete newDraft?.duplicateWarning;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                delete newDraft?.responseError;
                return newDraft;
            });
        setLocalStorageDrafts(newDraftsList);
    };

    const handleSubmit = async (isDryRun = false) => {
        const items: OrderTypes.ICreditItemRequest[] = localStorageDrafts.map(
            ({ itemNo, hasDeposit, quantity, depositOnly, invoiceId, licensePlate, carId }) => ({
                quantity,
                itemNo,
                hasDeposit,
                depositOnly,
                licensePlate,
                carId,
                invoiceId,
            }),
        );

        const postData = {
            requisition: storedRequisitionNumber,
            items,
        };

        setFetching(true);
        try {
            const response = await createCreditMultiple(postData, user, isDryRun);

            if (response?.statusName === OrderTypes.CreateCreditStatus.Created) {
                if (isDryRun) {
                    return;
                }

                setLocalStorageDrafts([]);
                mutateCredits && mutateCredits();

                if (isInternalUser) {
                    dispatch(addSuccessToast(translate('overview.creditCreated', 'kreditering oprettet.')));
                } else {
                    window.open(`${printEndpoints.return}?ids=sellerOrderNumber${response?.creditId}`, '_blank');
                }
            } else if (response?.statusName === OrderTypes.CreateCreditStatus.NotCreated) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                setResponseErrors(response?.failedCreditItems);
            }
        } catch (error) {
            setGenericError(true);
        } finally {
            setFetching(false);
        }
    };

    const handleChangeRequisition = (e: ChangeEvent<HTMLInputElement>) => {
        setStoredRequisitionNumber(e.target.value);
    };

    const mapDrafts = () => {
        const uniqueItems: ICreditDraft[] = [];

        const items = localStorageDrafts.map((draft) => {
            const { itemNo, invoiceId, carId, depositOnly } = draft;

            let hasDuplicate = false;

            const isInTheList = uniqueItems.find(
                (ui) => ui?.invoiceId === invoiceId && ui?.itemNo === itemNo && ui?.carId === carId && ui?.depositOnly === depositOnly,
            );

            if (isInTheList) {
                hasDuplicate = true;
            } else {
                uniqueItems.push(draft);
            }

            return {
                ...draft,
                responseError: getItemError(draft.invoiceId as number, draft.itemNo as string),
                duplicateWarning: hasDuplicate,
            };
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setDraftsList(items);
    };

    if (!localStorageDrafts?.length && !storedRequisitionNumber) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <Heading tagName="h3">{translate('overview.savedCredits.title', 'Gemte krediteringer (ikke oprettede)')}</Heading>
            <div className={styles.header}>
                <ValueWithCaption caption={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                    <Input
                        aria-label="Requisition"
                        className={styles.input}
                        disabled={fetching}
                        onChange={handleChangeRequisition}
                        name="requisition"
                        value={storedRequisitionNumber}
                    />
                </ValueWithCaption>

                <div className={styles.buttons}>
                    {hasDuplicates && (
                        <Button className={styles.clearButton} buttonStyle="secondary" onClick={deleteAllDuplicates}>
                            {translate('overview.savedCredits.deleteAllWithErrors', 'Slet alle med fejl')}
                        </Button>
                    )}
                    {responseErrors && (
                        <Button className={styles.clearButton} buttonStyle="secondary" onClick={deleteAllWithErrors}>
                            {translate('overview.savedCredits.deleteAllWithErrors', 'Slet alle med fejl')}
                        </Button>
                    )}
                    <Button
                        onClick={() => handleSubmit(false)}
                        fetching={fetching}
                        disabled={responseErrors || hasDuplicates || draftsList.length === 0}
                    >
                        {translate('overview.savedCredits.createCredit', 'Opret krediteringer')}
                    </Button>
                </div>
            </div>
            {genericError ? <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox> : null}
            {draftsList.length ? (
                <DraftsList drafts={[...draftsList].reverse()} onDelete={deleteDraft} />
            ) : (
                <ErrorBox type="warning">Tilføj produkter for at påbegynde krediteringen.</ErrorBox> // TODO: implement translation
            )}
        </div>
    );
}
