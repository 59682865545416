import { useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket';
import { useLayoutState } from '~/context/layout.context';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

const useDeliveryOptions = () => {
    const { isLoggedIn, user } = useUser();
    const { selectedDepartment } = useLayoutState();
    const { baskets, createUrl } = useEndpoints();

    return useQuery<BasketTypes.IDeliveryOptionsDto, BasketTypes.IErrorDto>({
        queryKey: queryKeys.basket.deliveryOptions(selectedDepartment?.id),
        enabled: isLoggedIn,
        refetchOnWindowFocus: true,
        queryFn: () =>
            apiClient.auth(user).get(
                createUrl({
                    endpoint: baskets.getDeliveryOptions,
                    query: {
                        departmentId: selectedDepartment?.id,
                    },
                }),
            ),
    });
};

export default useDeliveryOptions;
