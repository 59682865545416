import { useLayoutState } from '~/context/layout.context';
import useActiveUser from '~/libs/use-active-user';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { Products as ProductTypes } from '~/models/products';
import useUser from '~/libs/use-user';

export function useOEMatchesProducts({ variantIds = [] }: { variantIds?: number[] }) {
    const { vehicle, expertMode } = useLayoutState();
    const { activeProfile } = useActiveUser();
    const { locale } = useRouter();
    const { user, isLoggedIn } = useUser();

    return useQuery<ProductTypes.IProductOeMatches[], Error>({
        queryKey: queryKeys.products.oeMatchesProducts(locale, variantIds, activeProfile?.id, vehicle, !!expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: endpoints.products.oeMatchesProducts,
                        localeOption: LocaleOptions.path,
                        query: {
                            carModelTypeId: vehicle?.carId,
                            licensePlate: vehicle?.licensePlate?.number,
                            licensePlateCountry: vehicle?.licensePlate?.country,
                            vin: vehicle?.vin,
                            includeExpertProducts: expertMode,
                        },
                    },
                    locale,
                ),
                variantIds,
                { signal },
            );
        },
        enabled: !!(isLoggedIn && variantIds?.length > 0 && (!!vehicle?.vin || (vehicle?.licensePlate && vehicle?.licensePlate?.number))),
    });
}
