import React from 'react';
import cx from 'clsx';

import styles from './module-list.module.scss';

export type ModuleListWidth = 'default' | 'full';
export type ModuleGap = 'default' | 'none';

export type ModuleListProps = {
    children: React.ReactElement | React.ReactElement[];
    width?: ModuleListWidth;
    gap?: ModuleGap;
    className?: string;
};

export function ModuleList({ className, children, width = 'default', gap = 'default' }: ModuleListProps) {
    return (
        <div
            className={cx(styles.moduleList, className, {
                [styles.moduleListDefault]: width === 'default',
                [styles.moduleListFull]: width === 'full',
                [styles.moduleListGap]: gap === 'default',
            })}
        >
            {children}
        </div>
    );
}
