import React, { FC } from 'react';
import StockSummary from '../stock-summary/stock-summary.component';
import { Products } from '~/models/products';
import { useStockStatusDetails } from '~/libs/queries/products/hooks/use-stock-status-details';

interface IProps {
    itemNo: string;
}

const QuickView: FC<IProps> = ({ itemNo }) => {
    const { data: stockDetails, error: isError, isLoading } = useStockStatusDetails(itemNo);

    return <StockSummary isError={isError} isDark stockSummary={stockDetails?.stockSummary as Products.IStockSummary} fetching={isLoading} />;
};

export default QuickView;
