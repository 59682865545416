import React, { FC } from 'react';
import { Products as ProductTypes } from '~/models/products.d';
import styles from '../details.module.scss';
import Text from '~/shared/text/text.component';
import useTranslations from '~/shared/hooks/use-translations.hook';

interface IProps {
    data: ProductTypes.IVendor[];
}

const Vendors: FC<IProps> = ({ data }) => {
    const translate = useTranslations();
    if (!data?.length) return null;

    return (
        <>
            <Text tagName="h4" textStyle="body" className={styles.title}>
                {translate('stockStatus.suppliersInformation', 'Leverandøroplysninger')}
            </Text>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>{translate('stockStatus.creditorInfo', 'Kontaktinformation')}</td>
                        <td>{translate('stockStatus.vendorCode', 'Kode')}</td>
                        <td>{translate('stockStatus.vendorNumber', 'Varenr.')}</td>
                        <td>{translate('stockStatus.level', 'Niveau')}</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => (
                        <tr key={i}>
                            <td>
                                <Text textStyle="monoSmall">
                                    {row.creditorName}
                                    <br />
                                    {translate('stockStatus.telephone', 'tlf:')} <a href={`tel:${row.phoneNumber}`}>{row.phoneNumber}</a>
                                </Text>
                            </td>
                            <td>
                                <Text textStyle="monoSmall">{row.vendorCode}</Text>
                            </td>
                            <td>
                                <Text textStyle="monoSmall">{row.vendorNumber}</Text>
                            </td>
                            <td>
                                <Text textStyle="monoSmall">{row.level}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Vendors;
