import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import styles from './product-attributes.module.scss';
import { IWithClassName } from '~/models/dev.d';
import Skeleton from '~/shared/skeleton';
import { Products as ProductTypes } from '~/models/products';
import Text from '~/shared/text/text.component';

interface IProps extends IWithClassName {
    attributes: ProductTypes.IProductAttribute[];
    loading?: boolean;
    isDark?: boolean;
}

const ProductAttributes: FC<IProps> = ({ attributes = [], className, loading, isDark }) => {
    const distinctAttributes = useMemo(
        () => [...new Map(attributes.filter((item) => item.value).map((item) => [item.key, item])).values()],
        [attributes]
    );

    return (
        <div className={clsx(styles.attributes, className)}>
            {loading ? (
                <>
                    <Skeleton isDark={isDark} style={{ height: '15px', width: '80%', marginBottom: '5px' }} />
                    <Skeleton isDark={isDark} style={{ height: '15px', width: '80%', marginBottom: '5px' }} />
                    <Skeleton isDark={isDark} style={{ height: '15px', width: '80%', marginBottom: '5px' }} />
                </>
            ) : (
                distinctAttributes?.map((attr, i) => (
                    <div className={styles.attribute} key={i}>
                        <Text className={styles.attributeName} textStyle="bodyExtraSmall">
                            {attr.name}
                        </Text>
                        <Text className={styles.attributeValue} title={styles.attributeValue} textStyle="monoSmall" fontWeight="semiBold">
                            {attr.value}
                            {attr.unit && ` ${attr.unit}`}
                        </Text>
                    </div>
                ))
            )}
        </div>
    );
};
export default ProductAttributes;
