import React, { FC } from 'react';
import VehiclesGroup from '../vehicle-group/vehicle-group.component';
import ModelsList from '../vehicle-group/models-list.component';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import { isDefined } from '~/helpers';

interface IColumnProps {
    list: VehicleTypes.ICarSeries[];
}

const Column: FC<IColumnProps> = ({ list }) => (
    <div>
        {list.filter(isDefined).map((group) => {
            // if only one car in the list render without accordion
            const isSingleItem = Array.isArray(group.models) && group.models.length <= 1;

            if (isSingleItem) {
                return <ModelsList key={group.name} models={group.models as VehicleTypes.ICarModel[]} isSingle />;
            }

            return <VehiclesGroup key={group.name} group={group} />;
        })}
    </div>
);

export default Column;
