import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { useLatestCustomers } from '~/libs/queries/users/hooks/use-latest-customers';
import { Bff } from '~/models/bff';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import CustomersList from './customers-list/customers-list.component';
import styles from './styled.module.scss';

//Before: latest-customers.component.tsx
export default function W046InternalLatestCustomers(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    const { data, isLoading, isError, refetch: mutate } = useLatestCustomers();

    const dispatch = useLayoutDispatch();

    const impersonateCustomer = async (customerId: string) => {
        dispatch({
            type: LayoutActionTypes.Impersonate,
            payload: {
                customerId,
                callBack: mutate,
            },
        });
    };

    return (
        <>
            <Heading tagName="h4" className={styles.title}>
                {translate('internalDashboard.latestCustomers', 'Seneste kunder')}
            </Heading>

            {isError ? (
                <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            ) : (
                <CustomersList isLoading={isLoading} impersonate={impersonateCustomer} customers={data?.latestCustomers || []} />
            )}
        </>
    );
}
