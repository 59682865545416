import React, { FC } from 'react';
import clsx from 'clsx';
import { Svg } from '~/shared/svg';
import styles from './feedback.module.scss';
import RawHtml from '~/page-elements/raw-html/raw-html.component';

interface IFeedback {
    type: 'error' | 'success'; // Is also used as classNames
    headline: string;
    text: string;
    children?: React.ReactNode;
}

const Feedback: FC<IFeedback> = ({ type, headline, text, children }) => (
    <div className={clsx(styles.feedback, type)}>
        <div
            className={clsx(styles.type, {
                [styles.error]: type === 'error',
                [styles.success]: type === 'success',
            })}
        >
            <Svg
                thick
                className={styles.icon}
                name={clsx({
                    close: type === 'error',
                    check: type === 'success',
                })}
            />
        </div>

        <p className={styles.headline}>{headline}</p>

        <div className={styles.text}>
            <RawHtml content={text} />
        </div>

        {children}
    </div>
);

export default Feedback;
