import { useCheckoutBasket } from '~/libs/queries/basket';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketHeaderItemsCount } from './basket-header-items-count';

export function BasketHeaderItemsCountContainer() {
    const translate = useTranslations();
    const { data: basket } = useCheckoutBasket();

    const totalItemsCount = basket?.totalItemsCount;
    if (!totalItemsCount) {
        return null;
    }

    return (
        <BasketHeaderItemsCount
            totalItemsLabel={translate('basket.totalItemsCount', '[total] varer').replace('[total]', totalItemsCount.toString() || '0')}
        />
    );
}
