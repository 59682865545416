import { Orders as OrderTypes } from '~/models/orders.d';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IProps = {
    date?: string;
    numberOfProducts?: number;
    total?: OrderTypes.IPriceModel;
    requisition?: string;
    searchQuery?: string;
    caseId?: string;
};

export default function Header({ date, numberOfProducts, total, requisition, searchQuery, caseId }: IProps) {
    const translate = useTranslations();

    return (
        <header className={styles.root}>
            <ValueWithCaption caption={translate('overview.invoiceDate', 'Faktura dato')}>
                <Text textStyle="monoSmall">{date}</Text>
            </ValueWithCaption>

            <ValueWithCaption caption={translate('overview.numberOfProducts', 'antal produkter')}>
                <Text textStyle="monoSmall">{numberOfProducts}</Text>
            </ValueWithCaption>

            <ValueWithCaption caption={translate('overview.caseNo', 'Sags nr.')}>
                <Text textStyle="monoSmall">{caseId}</Text>
            </ValueWithCaption>

            <ValueWithCaption caption={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                <Text textStyle="bodySmall" text={requisition} highlightText={searchQuery} />
            </ValueWithCaption>

            <ValueWithCaption caption={translate('overview.fee', 'Afgifter')}>
                <Text textStyle="monoMedium" className={styles.price}>
                    {total?.feeFormatted}
                </Text>
            </ValueWithCaption>

            <ValueWithCaption caption={translate('overview.totalPrice', 'totalpris (DKK)')}>
                <Text nowrap textStyle="monoMedium" className={styles.price}>
                    {total?.netPriceFormatted}
                </Text>
            </ValueWithCaption>
        </header>
    );
}
