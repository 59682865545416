import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { Products as ProductTypes } from '~/models/products';
import RawHtml from '~/page-elements/raw-html/raw-html.component';
import setSalesPriceMarkup from '~/services/product/set-sales-price-markup.service';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import { ICategoryWithParents } from '../sales-price-markup.helpers';
import CategorySection from './category-section.component';
import styles from './styled.module.scss';

export interface IModalContentData {
    items: number[];
    isRoot: boolean;
}

interface IFormProps {
    priceLevel: string;
}

interface IProps {
    content: IModalContentData;
    categoriesListFlat: ICategoryWithParents[];
    onRemoveItem?: (itemID: number) => void;
    mutate: (data?: any) => any;
    afterSubmit: () => void;
    salesPriceMarkupData: ProductTypes.ICategorySalesPriceMarkup[];
}

const getDefaultPrice = (categories: ICategoryWithParents[]) => {
    if (categories.find((c) => c.salesPriceData?.hasCategoryDifferentPriceLevels)) {
        return '';
    }
    return categories.every((c) => c.salesPriceData?.priceFactor === categories[0].salesPriceData?.priceFactor)
        ? categories[0].salesPriceData?.priceFactor.toString()
        : '';
};

export default function ModalContent({ categoriesListFlat, onRemoveItem, content, mutate, afterSubmit, salesPriceMarkupData }: IProps) {
    const translate = useTranslations();
    const [categoryIds, setCategoryIds] = useState<number[]>(content?.items || []);
    const dispatch = useLayoutDispatch();
    const { user } = useUser();

    const categories = categoryIds
        .map((id) => categoriesListFlat.find((c) => c.id === id))
        .map((c) => ({
            ...c,
            salesPriceData: salesPriceMarkupData.find((p) => p.categoryId === c?.id),
        }));

    const handleRemove = (id: number) => {
        setCategoryIds(categoryIds.filter((c) => c !== id));
        content?.isRoot && onRemoveItem && onRemoveItem(id);
    };

    const handleFormSubmit = async (data: IFormProps) => {
        const postData: ProductTypes.IAddCategoriesSalesPriceMarkupRequest = {
            categories: [
                ...categories.map((c) => ({
                    categoryId: c.id as number,
                    includeDescendants: !!c.hasChildren,
                })),
            ],
            factorPrice: Number(Number(data.priceLevel).toFixed(2)),
        };

        try {
            await setSalesPriceMarkup(postData, user);

            dispatch(
                addSuccessToast(
                    translate(
                        'salesPriceMarkup.successConfirmMessage',
                        'Oprettet med succes. Vær opmærksom på, at der kan gå op til 5 minutter, før prisændringen slår igennem i kataloget',
                    ),
                ),
            );
            window.setTimeout(afterSubmit, 300);
        } finally {
            mutate();
        }
    };

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<IFormProps>({ defaultValues: { priceLevel: getDefaultPrice(categories as ICategoryWithParents[]) } });

    return (
        <div className={styles.wrapper}>
            <div className={styles.categories}>
                <CategorySection isRoot={content?.isRoot} categories={categories as ICategoryWithParents[]} onRemoveItem={handleRemove} />
            </div>
            {errors.priceLevel && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}
            <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                <Input
                    {...register('priceLevel', { required: true })}
                    className={styles.input}
                    type="number"
                    label={translate('salesPriceMarkup.setPriceLevel', 'Sæt prisniveau')}
                    errorMessage={errors.priceLevel && translate('salesPriceMarkup.priceLevelRequired', 'Prisniveau er påkrævet')}
                />
                <Button type="submit" buttonStyle="secondaryLight">
                    {translate('salesPriceMarkup.savePriceLeve', 'Gem prisniveau')}
                </Button>
            </form>

            <RawHtml
                content={translate(
                    'salesPriceMarkup.formDescriptionText',
                    '<p>Vejledning <br />Et prisniveau på 1,00 er standardprisen<br />Et prisniveau på 1,10 lægger 10% oveni vejl. salgspris<br />Et prisniveau på 0,90 trækker 10% fra vejl. salgspris</p>',
                )}
            />
            <Text tagName="p" fontWeight="semiBold" className={styles.subUsersPriceInheritsWarning}>
                {translate(
                    'salesPriceMarkup.subUsersPriceInheritsWarning',
                    'OBS: Når du sætter et nyt prisniveau, vil den nye pris være gældende for samtlige underbrugere i din kæde.',
                )}
            </Text>
        </div>
    );
}
