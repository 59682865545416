import React, { FC } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Text from '~/shared/text/text.component';
import styles from './in-stock-filter.module.scss';
import { Products as ProductTypes } from '~/models/products.d';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { ICreateUrlParamsQuery, LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { IWithClassName } from '~/models/dev';

// Maximum results to support filtering by stock status
const maxSupportedResults = 5000;

interface IInStockFilterProps extends IWithClassName {
    totalResults: number | undefined;
}

const InStockFilter: FC<IInStockFilterProps> = ({ className, totalResults }) => {
    const { createUrl } = useEndpoints();
    const translate = useTranslations();

    const router = useRouter();
    const { query } = router;
    const hasStockStatuses = typeof query?.stockStatuses !== 'undefined';

    const handleChange = () => {
        const newQuery = { ...query };
        delete newQuery.slug;

        if (hasStockStatuses) {
            delete newQuery.stockStatuses;
        } else {
            newQuery.stockStatuses = [
                ProductTypes.StockStatusCode.PrimaryDepartment.toString(),
                ProductTypes.StockStatusCode.NearbyDepartment.toString(),
                ProductTypes.StockStatusCode.CentralDepartment.toString(),
                ProductTypes.StockStatusCode.OutsideCentralDepartment.toString(),
            ];
        }

        const url = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.path,
            query: { ...(newQuery as ICreateUrlParamsQuery) },
        });

        router.replace(url, undefined, { shallow: true });
    };

    if (totalResults === undefined) {
        return null;
    }

    const tooManyResults = totalResults > maxSupportedResults;

    return (
        <Checkbox checked={hasStockStatuses} disabled={tooManyResults} className={clsx(className, styles.checkbox)} onChange={handleChange}>
            <Text textStyle="monoMedium">{translate('product.inStockFilter', 'På lager')}</Text>
            {tooManyResults && <Text textStyle="monoMedium">({translate('product.inStockFilterTooManyResults', 'For mange resultater')})</Text>}
        </Checkbox>
    );
};

export default InStockFilter;
