import React, { FC } from 'react';
import { Products as ProductTypes } from '~/models/products';
import Splash, { splashType } from '~/shared/splash/splash.component';
import styles from './product-tile-splashes.module.scss';

export interface IProductTileSplashesProps {
    isOeMatch: boolean | undefined;
    campaign: ProductTypes.ICampaign | undefined;
}

const ProductTileSplashes: FC<IProductTileSplashesProps> = ({ isOeMatch, campaign }) => {
    return (
        <div className={styles.splashes}>
            {isOeMatch && (
                <div>
                    <Splash type="OE" />
                </div>
            )}
            {campaign && <Splash type={campaign.code as splashType} />}
        </div>
    );
};
export default ProductTileSplashes;
