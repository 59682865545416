import clsx from 'clsx';
import React, { FC } from 'react';
import { IWithClassName } from '~/models/dev';
import { Orders as OrderTypes } from '~/models/orders.d';
import { Svg } from '~/shared/svg';
import styles from './oe-badge.module.scss';

interface IProps extends IWithClassName {
    orderType: OrderTypes.OrderType;
    showIcon?: boolean;
}

const OEBadge: FC<IProps> = ({ orderType, showIcon, className }) => {
    if (orderType !== OrderTypes.OrderType.OE && !showIcon) return null;

    return <Svg className={clsx(className, styles.icon)} name="oe-badge" />;
};

export default OEBadge;
