import { Orders as OrdersTypes } from '~/models/orders.d';
import CarInfo from '~/shared/car-info/car-info.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import OEBadge from '~/shared/oe-badge/oe-badge.component';
import Price from '~/shared/price/price.component';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IProps = {
    invoice: OrdersTypes.IInvoiceViewModel;
    isOpen: boolean;
    setOpen: () => void;
};

export default function Overview({ invoice, isOpen, setOpen }: IProps) {
    const translate = useTranslations();

    return (
        <div>
            <button onClick={setOpen} type="button" className={styles.invoiceInfo}>
                {!isOpen && (
                    <ValueWithCaption caption={translate('overview.invoiceNumber', 'Faktura nr')}>
                        <Text textStyle="monoMedium">{invoice.number}</Text>
                    </ValueWithCaption>
                )}
                <ValueWithCaption caption={translate('overview.invoiceDate', 'Faktura dato')}>
                    <Text textStyle="monoMedium">{invoice.displayDate}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('overview.numberOfProducts', 'Antal produkter')}>
                    <Text textStyle="monoMedium">{invoice.quantity}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('overview.caseNo', 'Sags nr.')}>
                    <Text textStyle="monoMedium">{invoice.caseId}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('overview.fee', 'Afgifter')}>
                    <Text textStyle="monoMedium" className={styles.price}>
                        {invoice?.total?.feeFormatted}
                    </Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('overview.totalPrice', 'totalpris (DKK)')}>
                    <Text nowrap textStyle="monoMedium">
                        <Price>{invoice?.total?.netPriceFormatted}</Price>
                    </Text>
                </ValueWithCaption>
                <OEBadge orderType={invoice?.orderType} />
            </button>
            {invoice?.carsInfo?.length ? (
                <div className={styles.vehicles}>
                    {invoice.carsInfo.map((vehicle, index) => (
                        <CarInfo key={`${vehicle.carId}-${index}`} carInfo={vehicle} hideCountryCode />
                    ))}
                </div>
            ) : null}
        </div>
    );
}
