import { Bff } from '~/models/bff';
import FindCustomer from '~/page-elements/internal-dashboard/find-customer/find-customer.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PageTitle from '~/shared/page-title/page-title.component';
import styles from './styled.module.scss';

//Before: find-customer-page.component.tsx
export default function W061Impersonate(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();

    return (
        <>
            <PageTitle>
                <Heading tagName="h1" displayStyle="h3">
                    {translate('impersonate.impersonate', 'Impersonér')}
                </Heading>
            </PageTitle>
            <div className={styles.searchContainer}>
                <Heading tagName="h2" displayStyle="h4">
                    {translate('impersonate.searchForUser', 'Søg efter den kunde du ønsker at impersonere')}
                </Heading>
                <FindCustomer formClassName={styles.search} />
            </div>
        </>
    );
}
