import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket } from '~/libs/queries/basket';
import useUser from '~/libs/use-user';
import updateDeliveryOption from '~/services/basket/update-delivery-option.service';
import useDeliveryOptions from '~/services/basket/use-delivery-options.service';
import useNextDelivery from '~/services/basket/use-next-delivery.service';
import CollapseButton from '~/shared/collapse-button/collapse-button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import { useBasketStates } from '../../../use-basket-states';
import styles from './basket-checkout.module.scss';
import { BasketCheckoutDelivery } from './components/basket-checkout-delivery/basket-checkout-delivery';
import { BasketCheckoutSplitDeliveryContainer } from './components/basket-checkout-split-delivery/basket-checkout-split-delivery.container';

const BasketCheckout = () => {
    const translate = useTranslations();
    const { selectedDepartment } = useLayoutState();
    const { user } = useUser();
    const { data: basket, refetch: refetchBasket } = useCheckoutBasket();
    const { isCheckoutFetching, setIsCheckoutFetching, setSelectedDeliveryMethod } = useBasketStates();

    const {
        formState: { errors },
    } = useFormContext();

    const { data: deliveryOptionsResponse, isError: isDeliveryOptionsError, isLoading: isLoadingDeliveryOptions } = useDeliveryOptions();

    const { isLoading: isLoadingNextDelivery } = useNextDelivery();

    const [isOpen, setIsOpen] = useState(false);

    const { allDeliveryOptions } = deliveryOptionsResponse || {};

    useEffect(() => {
        if (!basket) {
            return;
        }

        if (basket.deliveryDetails?.deliveryOptionId) {
            return;
        }

        if (!allDeliveryOptions || allDeliveryOptions.length === 0) {
            return;
        }

        const setDeliveryOption = async () => {
            setIsCheckoutFetching(true);

            await updateDeliveryOption(basket.id as string, allDeliveryOptions[0].value as string, selectedDepartment?.id as number, user);

            await refetchBasket();

            setIsCheckoutFetching(false);
        };

        setDeliveryOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket?.id, basket?.deliveryDetails?.deliveryOptionId, allDeliveryOptions, selectedDepartment?.id]);

    useEffect(() => {
        if (allDeliveryOptions && basket) {
            if (!basket.deliveryDetails?.deliveryOptionId) {
                setSelectedDeliveryMethod(allDeliveryOptions[0]);
            } else {
                setSelectedDeliveryMethod(allDeliveryOptions.find((dm) => dm.value === basket?.deliveryDetails?.deliveryOptionId));
            }
        }
    }, [allDeliveryOptions, basket, setSelectedDeliveryMethod]);

    const { userMustChoseDeliveryType } = basket?.deliveryDetails?.validation || {};

    const isCheckoutUpdating = isCheckoutFetching || isLoadingDeliveryOptions || isLoadingNextDelivery;

    const hasCheckoutValidationError = errors.deliveryMethod || errors.splitDeliveryOption || errors.deliveryDateTime || errors.deliveryAddressId;

    return (
        <>
            {hasCheckoutValidationError && (
                <ErrorBox>{translate('basket.splitDeliveryValidationError', 'Venligst udfyld informationer omkring levering')}</ErrorBox>
            )}

            <PanelCard className={styles.basketCheckoutWrapper}>
                {isCheckoutUpdating && (
                    <div className={styles.basketCheckoutLoaderOverlay}>
                        <Loader />
                    </div>
                )}

                <section className={styles.basketCheckoutHeadingSection}>
                    <div className={styles.basketCheckoutTitleWrapper}>
                        <Heading className={styles.basketCheckoutTitle} tagName="h2" displayStyle="h4">
                            {translate('basket.delivery', 'Levering')}
                        </Heading>

                        <CollapseButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
                    </div>

                    <BasketCheckoutDelivery isOpen={isOpen} />

                    {isDeliveryOptionsError && (
                        <div className={styles.basketCheckoutError}>
                            <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
                        </div>
                    )}
                </section>

                {userMustChoseDeliveryType ? <BasketCheckoutSplitDeliveryContainer /> : null}
            </PanelCard>
        </>
    );
};

export default BasketCheckout;
