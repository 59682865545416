import clsx from 'clsx';
import styles from './styled.module.scss';
import { ReactNode } from 'react';

export interface IDotsProps {
    count: number;
    cursor: number;
    className?: ReactNode;
}

export default function Dots({ className, count, cursor }: IDotsProps) {
    const arr = new Array(count);
    const dots = [];

    for (let i = 0; i < arr.length; i += 1) {
        dots.push(
            <div
                key={i}
                className={clsx(styles.dot, {
                    [styles.active]: i === cursor,
                })}
            />,
        );
    }

    return <div className={clsx(className, styles.dots)}>{dots}</div>;
}
