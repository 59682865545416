import React from 'react';
import Skeleton, { getSkeletonRandom } from '~/shared/skeleton/skeleton.component';

const AttributeSkeleton = () => (
    <div style={{ marginBottom: 20, height: 94 }}>
        <Skeleton isDark style={{ height: 28, width: getSkeletonRandom(400), marginBottom: 15 }} />
        <Skeleton isDark style={{ height: 28, width: getSkeletonRandom(400), margin: '5px 5px 25px 0' }} />
    </div>
);

const FilterSkeleton = () => (
    <>
        <AttributeSkeleton />
        <AttributeSkeleton />
        <AttributeSkeleton />
        <AttributeSkeleton />
        <AttributeSkeleton />
    </>
);

export default FilterSkeleton;
