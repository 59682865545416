import useUser from '~/libs/use-user';
import { Orders as OrderTypes } from '~/models/orders';
import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useRouter } from 'next/router';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';

export function usePreviousOrders(ftzCode?: string) {
    const { selectedDepartment } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { orders, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const url = createUrl({
        endpoint: `${orders.invoices}/by-variant`,
        localeOption: LocaleOptions.path,
        query: {
            ftzCode,
            departmentId: selectedDepartment?.id,
        },
    });

    return useQuery<OrderTypes.IVariantInvoicesList, Error>({
        queryKey: queryKeys.users.previousOrders(locale, ftzCode, selectedDepartment?.id),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: isLoggedIn && !!ftzCode,
    });
}
