import { isDefined } from '~/helpers';
import { useVehicleModels } from '~/libs/queries/vehicles/hooks/use-vehicle-models';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import ModelsGrid from '~/page-elements/vehicle-models/models-grid/models-grid.component';
import ExpertToggle from '~/shared/expert-toggle/expert-toggle.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PageTitle from '~/shared/page-title/page-title.component';
import styles from './styled.module.scss';

//Name before: vehicle-models.component.tsx
export default function W150VehicleModelList({ manufacturerId }: Bff.IVehicleListWidgetViewModel) {
    const translate = useTranslations();
    const { isInternalUser } = useUser();
    const { data, isLoading, error } = useVehicleModels(manufacturerId);

    if (!manufacturerId) return null;

    const modelSeries = data?.modelSeries || [];

    const modelsCount = modelSeries
        .filter(isDefined)
        .map(({ models = [] }) => models)
        .reduce((a, b) => a.concat(b), []).length;

    return (
        <>
            <PageTitle className={styles.heading}>
                <div>
                    <Heading tagName="h1" displayStyle="h3" subHeading={`${translate('common.models', 'Modeller')} (${modelsCount})`} />
                </div>
                {isInternalUser && <ExpertToggle className={styles.expertToggle} />}
            </PageTitle>
            <ModelsGrid isLoading={isLoading} isError={error} data={data} />
        </>
    );
}
