import { useFormContext } from 'react-hook-form';
import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket } from '~/libs/queries/basket';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import updateDeliveryOption from '~/services/basket/update-delivery-option.service';
import useDeliveryOptions from '~/services/basket/use-delivery-options.service';
import Select from '~/shared/form-elements/select/select.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../../../use-basket-states';

export function CheckoutDeliveryMethodContainer() {
    const translate = useTranslations();
    const { selectedDepartment } = useLayoutState();
    const { user } = useUser();
    const { data: basket, refetch: refetchBasket } = useCheckoutBasket();
    const { setSplitDeliveryOption, setIsCheckoutFetching, selectedDeliveryMethod, setSelectedDeliveryMethod } = useBasketStates();
    const {
        register,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const { data: { allDeliveryOptions } = {} } = useDeliveryOptions();

    if (!basket) {
        return null;
    }

    const handleDeliveryMethodChange = async (val: string) => {
        setIsCheckoutFetching(true);
        clearErrors();

        const selected = allDeliveryOptions?.find((dm) => dm.value === val);
        setSelectedDeliveryMethod(selected);

        if (selected) {
            await updateDeliveryOption(basket.id, selected.value as string, selectedDepartment?.id as number, user);
        }

        setSplitDeliveryOption(BasketTypes.DeliveryType.UnSpecified);

        const { data: updatedBasket } = await refetchBasket();

        setIsCheckoutFetching(false);
    };

    return (
        <Select
            label={translate('basket.deliveryMethod', 'Leveringsmetode')}
            {...register('deliveryMethod', {
                required: !basket.deliveryDetails?.deliveryOptionId,
            })}
            errorMessage={errors?.deliveryMethod && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
            onChange={(e) => handleDeliveryMethodChange(e.target.value)}
            value={selectedDeliveryMethod?.value}
        >
            <option disabled value="">
                {translate('basket.chooseDeliveryMethod', 'Vælg Leveringsmetode')}
            </option>

            {allDeliveryOptions?.map((dm, i) => (
                <option key={`${dm}-${i}`} value={dm.value}>
                    {dm.displayName}
                </option>
            ))}
        </Select>
    );
}
