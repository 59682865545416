import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { Products } from '~/models/products';
import { Link } from '~/shared/link';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Skeleton, { getSkeletonRandom } from '~/shared/skeleton';
import styles from './links.module.scss';

interface IListProps {
    isLoading: boolean;
    count?: number;
    categories?: Products.ICategory[];
}

const defaultSkeletonCount = 15;

const List: FC<IListProps> = ({ categories, isLoading, count = defaultSkeletonCount }) => {
    const translate = useTranslations();

    if (!categories?.length && !isLoading) {
        return null;
    }

    const skeletonCount = count === 0 ? defaultSkeletonCount : count;

    return (
        <>
            <StockSprite />

            <ul className={styles.links}>
                {isLoading && <SkeletonList count={skeletonCount} />}

                {!isLoading &&
                    categories?.map((link, i) => {
                        const key = `${link.name}-${i}`;

                        if (link.containsProducts) {
                            return (
                                <li key={key}>
                                    <Link className={styles.link} href={link.url} prefetch={false}>
                                        <span className={styles.text}>{link.name}</span>
                                    </Link>
                                </li>
                            );
                        }

                        return (
                            <li key={key}>
                                <span className={clsx(styles.link, styles.item)}>
                                    <span className={styles.text}>{link.name}</span>
                                    <span className={styles.stock}>
                                        <svg className={styles.stockIcon}>
                                            <use href="#sprite-call-us" />
                                        </svg>
                                        {translate('common.contactFtz', 'Kontakt FTZ')}
                                    </span>
                                </span>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

interface IListSkeletonProps {
    count: number;
}

const SkeletonList: FC<IListSkeletonProps> = ({ count }) => (
    <>
        {[...Array(count)].map((item: undefined, index: number) => (
            <SkeletonListItem key={index} />
        ))}
    </>
);

const SkeletonListItem = () => {
    const width = useMemo(() => `${getSkeletonRandom(200)}px`, []);

    return (
        <li>
            <span className={clsx(styles.link, styles.item)}>
                <Skeleton
                    style={{
                        height: '20px',
                        margin: '4px 0',
                        maxWidth: '100%',
                        width,
                    }}
                />
            </span>
        </li>
    );
};

const StockSprite = () => (
    <svg width="0" height="0">
        <symbol viewBox="0 0 24 24" id="sprite-call-us">
            <path
                stroke="currentColor"
                strokeWidth="1.5px"
                fill="none"
                fillRule="evenodd"
                vectorEffect="non-scaling-stroke"
                d="M19.364 12.905v-1.118C19.364 6.998 16.067 4 12 4s-7.364 2.998-7.364 7.787v1.118M17.727 17.762v-4.857h.818c1.356 0 2.455 1.087 2.455 2.428 0 1.342-1.099 2.429-2.455 2.429h0-.818zM6.273 12.905v4.857h-.818C4.099 17.762 3 16.675 3 15.333c0-1.34 1.099-2.428 2.455-2.428h.818zM14.455 21h0c2.485 0 4.5-1.45 4.5-3.238"
            />
        </symbol>
    </svg>
);

export default List;
