import { Basket as BasketTypes } from '~/models/basket.d';
import { CMSLink } from '~/shared/link';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';

export type ProductItemIDProps = {
    itemId: string | undefined;
    link?: BasketTypes.ILinkDto;
    caption: string;
    captionClassName?: string;
};

export function ProductItemId({ itemId, caption, captionClassName, link }: ProductItemIDProps) {
    return (
        <ValueWithCaption caption={caption} captionClassName={captionClassName}>
            <CMSLink link={link}>
                <Text textStyle="monoSmall" fontWeight="regular">
                    {itemId}
                </Text>
            </CMSLink>
        </ValueWithCaption>
    );
}
