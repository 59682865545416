import React from 'react';
import styles from './topmotive-button-group.module.scss';

export type TopmotiveButtonGroupProps = {
    children: React.ReactElement | React.ReactElement[];
};

export function TopmotiveButtonGroup({ children }: TopmotiveButtonGroupProps) {
    return <div className={styles.topmotiveButtonGroup}>{children}</div>;
}
