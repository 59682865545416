import React, { FC } from 'react';
import clsx from 'clsx';
import LicensePlate, { ILicensePlateProps } from '~/shared/license-plate/license-plate.component';
import styles from './car-info.module.scss';
import { IWithClassName } from '~/models/dev';
// import { Basket as BasketTypes } from '~/models/basket.d'
import { Orders as OrderTypes } from '~/models/orders.d';
import { Link } from '~/shared/link/link.component';
import Text from '~/shared/text/text.component';

interface IProps extends IWithClassName, Pick<ILicensePlateProps, 'hideCountryCode'> {
    carInfo: OrderTypes.ICarInfo | undefined;
    href?: string;
    highlightText?: string;
}

const CarInfo: FC<IProps> = ({ carInfo, className, hideCountryCode, highlightText }) => {
    const getVehicleText = () => {
        const manufacturer = carInfo?.manufacturer || '';
        const model = carInfo?.model || '';
        const modelType = carInfo?.modelType ? ` / ${carInfo?.modelType}` : '';

        return `${manufacturer} ${model} ${modelType}`;
    };

    return (
        <Link href={carInfo?.url} className={clsx(styles.carInfo, className)}>
            <LicensePlate
                highlightText={highlightText}
                hideCountryCode={hideCountryCode}
                className={styles.licensePlate}
                licensePlate={carInfo?.licensePlate}
                carId={carInfo?.carId}
            />
            <Text highlightText={highlightText} color="primaryColor" text={getVehicleText()} />
        </Link>
    );
};

export default CarInfo;
