import { Collapse } from '~/shared/collapse';
import CollapseButton from '~/shared/collapse-button/collapse-button.component';
import Heading from '~/shared/heading/heading.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import styles from './basket-summary.module.scss';
import { BasketSummaryInfo } from './components/basket-summary-info/basket-summary-info';
import { BasketSummaryPrice } from './components/basket-summary-price/basket-summary-price';

export type BasketSummaryProps = {
    heading: string;
    isOpen: boolean;
    toggleOpen: () => void;
    price: {
        caption: string;
        displayValue: string;
    };
    priceFee: {
        caption: string;
        displayValue: string;
    };
    priceDeposit: {
        caption: string;
        displayValue: string;
    };
    infoOrderNo: {
        caption: string;
        displayValue: string;
    };
    infoFtzCode: {
        caption: string;
        displayValue: string;
    };
};

export function BasketSummary({ heading, isOpen, toggleOpen, price, priceFee, priceDeposit, infoOrderNo, infoFtzCode }: BasketSummaryProps) {
    return (
        <PanelCard className={styles.basketSummaryWrapper}>
            <div className={styles.basketSummaryHeaderWrapper}>
                <div className={styles.basketSummaryHeaderTitleWrapper}>
                    <Heading className={styles.basketSummaryHeaderTitle} tagName="h2" displayStyle="h4">
                        {heading}
                    </Heading>

                    <CollapseButton isOpen={isOpen} onClick={toggleOpen} />
                </div>

                <BasketSummaryPrice caption={price.caption} price={price.displayValue} priceProps={{ size: 'large' }} />
            </div>

            <Collapse isOpen={isOpen}>
                <div className={styles.basketSummaryCollapsibleWrapper}>
                    <div className={styles.basketSummaryInfoWrapper}>
                        <BasketSummaryInfo caption={infoOrderNo.caption} value={infoOrderNo.displayValue} />

                        <BasketSummaryInfo
                            caption={infoFtzCode.caption}
                            value={infoFtzCode.displayValue}
                            className={styles.basketSummaryInfoFtzCode}
                        />
                    </div>

                    <div className={styles.basketSummaryPriceWrapper}>
                        <BasketSummaryPrice caption={priceFee.caption} price={priceFee.displayValue} priceProps={{ color: 'black' }} />
                        <BasketSummaryPrice caption={priceDeposit.caption} price={priceDeposit.displayValue} priceProps={{ color: 'black' }} />
                    </div>
                </div>
            </Collapse>
        </PanelCard>
    );
}
