import React, { FC } from 'react';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './bundle-card.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Button from '~/shared/buttons/button/button.component';
import { IProductWithVehicleInfo } from '~/shared/product-list/product-list-rows/product-list-rows.component';
import Text from '~/shared/text/text.component';

interface IBundleCardProps {
    cardData?: IProductWithVehicleInfo;
    setModalContent?: (content: JSX.Element) => void;
}

const BundleCard: FC<IBundleCardProps> = ({ cardData }) => {
    const translate = useTranslations();

    return (
        <div className={styles.card}>
            <div className={styles.imageContainer}>
                {cardData?.image && (
                    <CdnImage
                        width="900"
                        height="200"
                        src={cardData?.image.url as string}
                        alt={cardData?.image.altText}
                        layout="responsive"
                        objectFit="contain"
                    />
                )}
                {cardData?.vehicleInfoText && (
                    <Text tagName="p" fontWeight="semiBold">
                        {cardData.vehicleInfoText}
                    </Text>
                )}
            </div>
            <Button href={cardData?.url as string} className={styles.link}>
                {translate('product.selectParts', 'Vælg dele')}
            </Button>
        </div>
    );
};

export default BundleCard;
