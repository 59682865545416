import { type HTMLAttributes } from 'react';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import { useLabelOrderStorage } from '../use-label-order.hook';
import styles from './styled.module.scss';

type LabelOrderButtonProps = HTMLAttributes<HTMLButtonElement> & {
    identifier: string;
};

//Name Before: page-elements/labels/label-order-button/label-order-button.component.tsx
export default function LabelOrderButton({ className, identifier }: LabelOrderButtonProps) {
    const translate = useTranslations();
    const addOrder = useLabelOrderStorage((state) => state.addOrder);
    const dispatch = useLayoutDispatch();

    const addLabelOrder = () => {
        addOrder(identifier);
        dispatch(
            addSuccessToast(
                translate('label.productAddedHeadline', 'Produktet er blevet tilføjet'),
                translate('label.productAddedDescription', 'Find labelbestillingslisten under Tjenester i venstre menu, og godkend din bestilling.'),
            ),
        );
    };

    return (
        <Tooltip
            className={styles.wrapper}
            content={<TooltipContentWrapper>{translate('product.addLabelOrder', 'Tilføj vare nr. til hyldelabelliste')}</TooltipContentWrapper>}
        >
            <Button className={className} onClick={addLabelOrder} icon={<Svg name="label" />} buttonStyle="clean" buttonSize="link" />
        </Tooltip>
    );
}
