import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './filter-group-list.module.scss';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';

export type hiddenCountChangeHandler = (count: number) => void;

export interface IFilterGroupListProps {
    isOpen: boolean;
    onHiddenCountChange?: hiddenCountChangeHandler;
    children: React.ReactNode;
}

const FilterGroupList: React.FC<IFilterGroupListProps> = ({ children, isOpen, onHiddenCountChange }) => {
    const open = 'OPEN';
    const closed = 'CLOSED';

    const isMobile = useMediaQueryDetect(MediaQuery.Mobile);
    const isTablet = useMediaQueryDetect(MediaQuery.Tablet);
    const isDesktop = useMediaQueryDetect(MediaQuery.Desktop);
    const isLargeDesktop = useMediaQueryDetect(MediaQuery.LargeDesktop);
    const isHugeDesktop = useMediaQueryDetect(MediaQuery.HugeDesktop);

    const isHandheld = isMobile || isTablet;

    const animations = {
        [open]: {
            opacity: 1,
            height: 'auto',
        },
        [closed]: {
            opacity: 0,
            height: 0,
        },
    };

    const visibleElements: React.ReactElement[] = [];
    const hiddenElements: React.ReactElement[] = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.Children.forEach(children, (child: React.ReactElement, i) => {
        const current = i + 1;
        const element = React.cloneElement(child, {
            key: child.props.label,
            className: clsx(child.props.className, styles.filterItem),
        });

        if (isHandheld) {
            return hiddenElements.push(element);
        }

        if (isDesktop) {
            if (current > 3) {
                return hiddenElements.push(element);
            }
            return visibleElements.push(element);
        }

        if (isLargeDesktop) {
            if (current > 4) {
                return hiddenElements.push(element);
            }
            return visibleElements.push(element);
        }

        if (isHugeDesktop) {
            if (current > 6) {
                return hiddenElements.push(element);
            }
            return visibleElements.push(element);
        }
    });

    const animationState = isOpen ? open : closed;

    const motionProps = {
        key: 'filters',
        initial: closed,
        exit: closed,
        animate: animationState,
        variants: animations,
    };

    const numOfVisibleElements = visibleElements.length;
    const numOfHiddenElements = hiddenElements.length;

    useEffect(() => {
        if (onHiddenCountChange && typeof onHiddenCountChange === 'function') {
            onHiddenCountChange(numOfHiddenElements);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numOfHiddenElements]);

    const style = {
        '--column-count': numOfVisibleElements,
    } as React.CSSProperties;

    return (
        <div className={styles.filterListContainer}>
            {numOfVisibleElements > 0 && (
                <div className={styles.filterListVisible} style={style}>
                    {visibleElements}
                </div>
            )}
            {numOfHiddenElements > 0 && (
                <AnimatePresence>
                    <motion.div
                        {...motionProps}
                        style={style}
                        className={clsx(styles.filterListHidden, {
                            [styles.isOpen]: isOpen,
                        })}
                    >
                        {hiddenElements}
                    </motion.div>
                </AnimatePresence>
            )}
        </div>
    );
};

// FilterGroupList.displayName = 'FilterGroupList'

export default FilterGroupList;
