import { Bff } from '~/models/bff';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import InvoiceDetails from './invoice/details/details.component';

//Name before: invoice-details-page.component.tsx
function W050InvoiceDetailOverview({ queryId }: Bff.IDetailOverviewWidgetViewModel) {
    if (!queryId) return null;
    return <InvoiceDetails invoiceId={queryId} isOpen />;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default WithOverviewNavigation(W050InvoiceDetailOverview);
