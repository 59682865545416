import React from 'react';
import Skeleton from '~/shared/skeleton/skeleton.component';

const SkeletonLink = ({ maxWidth = 250 }: { maxWidth: number }) => (
    <Skeleton
        style={{
            height: '16px',
            width: '100%',
            maxWidth: `${maxWidth}px`,
            marginBottom: '20px',
            marginLeft: '10px',
        }}
    />
);

const SkeletonFallback = () => (
    <>
        <Skeleton style={{ height: '16px', width: '150px', marginBottom: '20px' }} />
        <SkeletonLink maxWidth={250} />
        <SkeletonLink maxWidth={220} />
    </>
);

export default SkeletonFallback;
