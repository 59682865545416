import { Basket as BasketTypes } from '~/models/basket.d';
import { BasketItemsGroupCarContainer } from './basket-items-group-car/basket-items-group-car.container';
import { BasketItemsGroup } from './basket-items-group/basket-items-group';

type BasketItemsProps = {
    groups: BasketTypes.IBasketGroupDto[];
};

export function BasketItems({ groups }: BasketItemsProps) {
    const renderGroup = (group: BasketTypes.IBasketGroupDto) => {
        if (!group.items?.length) {
            return null;
        }

        if (group.basketCarInfo) {
            return <BasketItemsGroupCarContainer key={group.id} id={group.id} carInfo={group.basketCarInfo} items={group.items} />;
        }

        return <BasketItemsGroup key={group.id} items={group.items} />;
    };

    return <section>{groups.map(renderGroup)}</section>;
}
