import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Heading from '~/shared/heading/heading.component';
import styles from './calculator.module.scss';
import { Products as ProductTypes } from '~/models/products';
import Button from '~/shared/buttons/button/button.component';
import Select from '~/shared/form-elements/select/select.component';
import getTireCalculations from '~/services/product/get-tire-calculations';
import CalculationResult from './calculation-result.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Bff } from '~/models/bff.d';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import useUser from '~/libs/use-user';

interface IProps {
    tireOptions?: Bff.ITireOptions;
    axle?: VehicleTypes.IWheelData;
}

const Calculator: FC<IProps> = ({ tireOptions, axle }) => {
    const { width = [], profile = [], size = [] } = tireOptions || {};

    const translate = useTranslations();
    const { user } = useUser();

    const [calculations, setCalculations] = useState<ProductTypes.ITireCalculation | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<ProductTypes.ITireCalculationRequest>({
        mode: 'onChange',
    });

    const onSubmit = async (postData: ProductTypes.ITireCalculationRequest) => {
        setError(null);
        setCalculations(null);
        setLoading(true);
        try {
            const res = await getTireCalculations(postData, user);
            setCalculations(res);
        } catch (err: any) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Heading tagName="h4">{translate('tireCalculator.currentTires', 'Nuværende dæk')}</Heading>
                <fieldset className={styles.fieldset}>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireWidth', 'Dækbredde')}
                        {...register('tireOneWidth', { required: true, value: axle?.tireWidth })}
                        defaultValue=""
                        errorMessage={errors?.tireOneWidth && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {width.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireProfile', 'Dækprofil')}
                        {...register('tireOneProfile', { required: true, value: axle?.tireProfile })}
                        defaultValue=""
                        errorMessage={errors?.tireOneProfile && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {profile.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireSize', 'Dækstørrelse')}
                        {...register('tireOneRimDiameterInches', { required: true, value: axle?.rimDiameter })}
                        defaultValue=""
                        errorMessage={errors?.tireOneRimDiameterInches && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {size.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                </fieldset>

                <Heading tagName="h4">{translate('tireCalculator.newTires', 'Nyt dæk')}</Heading>
                <fieldset className={styles.fieldset}>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireWidth', 'Dækbredde')}
                        {...register('tireTwoWidth', { required: true })}
                        defaultValue=""
                        errorMessage={errors?.tireTwoWidth && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {width.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireProfile', 'Dækprofil')}
                        {...register('tireTwoProfile', { required: true })}
                        defaultValue=""
                        errorMessage={errors?.tireTwoProfile && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {profile.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                    <Select
                        wrapperClassName={styles.select}
                        label={translate('tireCalculator.tireSize', 'Dækstørrelse')}
                        {...register('tireTwoRimDiameterInches', { required: true })}
                        defaultValue=""
                        errorMessage={errors?.tireTwoRimDiameterInches && translate('common.fieldMustBeFilled', 'Feltet skal udfyldes')}
                    >
                        <option disabled value="">
                            -
                        </option>
                        {size.map((opt, i) => (
                            <option key={`${opt}-${i}`} value={opt}>
                                {opt}
                            </option>
                        ))}
                    </Select>
                </fieldset>
                <div className={styles.buttons}>
                    <Button type="submit" disabled={!isValid}>
                        {calculations
                            ? translate('tireCalculator.makeNewTireCalculation', 'Lav ny dækberegning')
                            : translate('tireCalculator.makeTireCalculation', 'Lav dækberegning')}
                    </Button>
                </div>
            </form>
            <CalculationResult calculations={calculations as ProductTypes.ITireCalculation} error={error as Error} isLoading={isLoading} />
        </div>
    );
};

export default Calculator;
