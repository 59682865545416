import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { favoriteKeys, FavoritePlacement, FAVORITES_STATUS_ADD_URL, FavoriteStatusListResponse } from '../.';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

export type UseAddFavoriteProps = {
    userId?: string;
    pageIndex?: number;
    placement: FavoritePlacement;
    itemNoList: string[];
};

export function useAddFavorite(
    { userId, pageIndex, placement, itemNoList }: UseAddFavoriteProps,
    args: Omit<UseMutationOptions<FavoriteStatusListResponse, Error, string>, 'mutationKey'> = {},
) {
    const queryClient = useQueryClient();

    const { user } = useUser();
    const { locale } = useRouter();

    return useMutation<FavoriteStatusListResponse, Error, string>({
        mutationFn: (itemNo: string) =>
            apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: FAVORITES_STATUS_ADD_URL,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                itemNo,
            ),
        onMutate: async (itemNo) => {
            await queryClient.cancelQueries(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex));

            queryClient.setQueryData<FavoriteStatusListResponse>(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex), (old) => {
                return { ...old, [itemNo]: 1 };
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex));
        },
        ...args,
    });
}
