import React from 'react';
import debounce from 'lodash/debounce';
import { Controller } from 'react-hook-form';
import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket, useUpdateOrderDetails } from '~/libs/queries/basket';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import type { ICheckoutValidationProps } from './../../../../../../basket-content.container';
import styles from './checkout-delivery-comment.module.scss';

export const CHECKOUT_COMMENT_MAX_LENGTH = 60;

export function CheckoutDeliveryCommentContainer() {
    const translate = useTranslations();

    const { selectedDepartment } = useLayoutState();

    const { data: basket } = useCheckoutBasket();
    const { mutate: updateOrderDetails } = useUpdateOrderDetails();

    const handleChange = debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!basket?.id) {
            console.error('Could not update basket comment. Basket was undefined.');
            return;
        }

        updateOrderDetails({
            basketId: basket.id,
            departmentId: selectedDepartment?.id,
            details: {
                ...basket.orderDetails,
                comment: e.target.value,
            },
        });
    }, 500);

    return (
        <Controller<ICheckoutValidationProps>
            render={({ field: { onChange, ...field } }) => (
                <TextArea
                    {...field}
                    maxLength={CHECKOUT_COMMENT_MAX_LENGTH}
                    label={translate('basket.commentMaxCharacters', 'kommentar (max [number] tegn)').replace('[number]', CHECKOUT_COMMENT_MAX_LENGTH)}
                    className={styles.checkoutDeliveryCommentTextArea}
                    onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                    }}
                />
            )}
            name="comment"
        />
    );
}
