import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';
import styles from './styled.module.scss';

interface ILabelsProps extends IWithClassName {
    orderTime: string;
    expectedDeliveryTime: string;
}

export default function Labels({ orderTime, expectedDeliveryTime, className }: ILabelsProps) {
    return (
        <div className={clsx(styles.labels, className)}>
            <span className={styles.label}>
                <Svg name="badge-confirm" className={styles.icon} />
                {orderTime}
            </span>
            <span className={styles.label}>
                <Svg name="semi-truck" className={styles.icon} />
                {expectedDeliveryTime}
            </span>
        </div>
    );
}
