import clsx from 'clsx';
import type { IWithClassName } from '~/models/dev';
import styles from './add-to-basket.module.scss';
import AddToBasketButton, { AddToBasketButtonShowLabelAt } from './components/add-to-basket-button/add-to-basket-button';
import { AddToBasketQuantity } from './components/add-to-basket-quantity/add-to-basket-quantity';
import type { AddToBasketQuantityInputProps } from './components/add-to-basket-quantity/components/add-to-basket-quantity-input/add-to-basket-quantity-input';

export type AddToBasketProps = IWithClassName & {
    noFullWidth?: boolean;
    showQuantityInput: boolean;
    // BasketInputProps
    value?: number;
    alwaysOpen?: boolean;
    onIncrement: () => void;
    onDecrement: () => void;
    onInputChange: (value: AddToBasketQuantityInputProps['value']) => void;
    inputRef: AddToBasketQuantityInputProps['inputRef'];
    // BasketAddButtonProps
    label?: string;
    showLabelAt?: AddToBasketButtonShowLabelAt;
    isLarge?: boolean;
    disabled?: boolean;
    onAddToBasket: () => void;
};

export function AddToBasket({
    className,
    noFullWidth,
    showQuantityInput = false,
    // BasketInputProps
    value,
    alwaysOpen,
    onIncrement,
    onDecrement,
    onInputChange,
    inputRef,
    // BasketAddButtonProps
    label,
    showLabelAt: showLabelAt,
    isLarge,
    disabled,
    onAddToBasket,
}: AddToBasketProps) {
    return (
        <div
            className={clsx(styles.addToBasketWrapper, className, {
                [styles.addToBasketWrapperNoFullWidth]: noFullWidth,
            })}
        >
            <div className={styles.addToBasketContainer}>
                {showQuantityInput ? (
                    <AddToBasketQuantity
                        inputValue={value}
                        isLarge={isLarge}
                        isAlwaysOpen={alwaysOpen}
                        onIncrementClick={onIncrement}
                        onDecrementClick={onDecrement}
                        onInputChange={onInputChange}
                        inputRef={inputRef}
                    />
                ) : (
                    <AddToBasketButton label={label} showLabelAt={showLabelAt} isLarge={isLarge} disabled={disabled} onClick={onAddToBasket} />
                )}
            </div>
        </div>
    );
}
