import { TopmotiveBody, TopmotiveButtonGroup, TopmotiveHeading } from '~/shared/topmotive/components';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';
import React, { useCallback } from 'react';
import { useTopmotiveModalState } from '~/shared/topmotive/hooks';
import Input from '~/shared/form-elements/input/input.component';
import { useForm } from 'react-hook-form';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import { useRouter } from 'next/router';
import styles from './topmotive-request-form.module.scss';
import { Users } from '~/models/users';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import truckRequestService from '~/services/truck-request/truck-request.service';
import { useCarInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';
import ErrorBox from '~/shared/error-box/error-box';
import useUser from '~/libs/use-user';
import { useMutation } from '@tanstack/react-query';
import { Products } from '~/models/products';
import { GA4RequestOEProduct } from '~/libs/ga4/ga4';

export type TopmotiveRequestFormFields = {
    name: string;
    company: string;
    phone: string;
    email: string;
    comment: string;
};

export function useRequestTruckOEProduct() {
    const { user } = useUser();

    return useMutation<unknown, unknown, Products.ICreateTruckSparePartRequest>({
        mutationFn: (variables) => {
            return truckRequestService(variables, user);
        },
    });
}

export type TopmotiveRequestFormProps = {
    profile: Users.IBasicUserInfo;
};

export function TopmotiveRequestForm({ profile }: TopmotiveRequestFormProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { navigate, manufacturerName, productName, productId } = useTopmotiveModalState(({ navigate, details }) => ({
        navigate,
        manufacturerName: details?.manufacturerName,
        productName: details?.productName,
        productId: details?.productId,
    }));

    const {
        register: registerField,
        handleSubmit,
        formState,
        setError,
    } = useForm<TopmotiveRequestFormFields>({
        defaultValues: {
            name: profile.displayName,
            company: profile.customer?.name ?? '',
            phone: profile.customer?.phoneNumber ?? '',
            email: profile.email ?? '',
            comment: translate('topmotive.request.messagePrefill', '{{request_info}}').replace(
                '{{request_info}}',
                `${productName}: ${productId} (${manufacturerName})`,
            ),
        },
        mode: 'onBlur',
    });

    const handleNavigateBack = useCallback(() => {
        navigate('start');
    }, [navigate]);

    const { asPath } = useRouter();
    const { data: carInfo = {} } = useCarInfo();
    const { licensePlate, vin } = carInfo;

    const { mutate, isLoading: isCreatingTruckOERequest } = useRequestTruckOEProduct();

    const doSubmit = useCallback(
        (fields: TopmotiveRequestFormFields) => {
            mutate(
                {
                    itemNo: productId,
                    currentPath: asPath,
                    name: fields.name,
                    email: fields.email,
                    phone: fields.phone,
                    company: fields.company,
                    comment: fields.comment,
                    // vehicle info
                    regNr: licensePlate?.number ?? '',
                    vin: vin ?? '',
                },
                {
                    onSuccess: () => {
                        dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                        GA4RequestOEProduct(productId ?? '', manufacturerName ?? '');
                    },
                    onError: () => {
                        setError('comment', {
                            message: translate('common.somethingWentWrong', 'Der gik noget galt.'),
                        });
                    },
                },
            );
        },
        [asPath, dispatch, licensePlate?.number, manufacturerName, mutate, productId, setError, translate, vin],
    );

    return (
        <form onSubmit={handleSubmit(doSubmit)}>
            <TopmotiveHeading>{translate('topmotive.request.formHeadline', 'Bekræft dine oplysninger')}</TopmotiveHeading>
            <TopmotiveBody>{translate('topmotive.request.formBody', 'Efter forespørgsel kontakter vi dig med pris og leveringstid.')}</TopmotiveBody>
            <div className={styles.topmotiveRequestFormFields}>
                <Input
                    {...registerField('name', {
                        required: {
                            value: true,
                            message: translate('topmotive.request.errors.required', 'Feltet er påkrævet'),
                        },
                    })}
                    wrapperClassName={styles.topmotiveRequestFormField}
                    label={translate('topmotive.request.nameLabel', 'Navn')}
                    errorMessage={formState.errors.name?.message}
                />
                <Input
                    {...registerField('company')}
                    wrapperClassName={styles.topmotiveRequestFormField}
                    label={translate('topmotive.request.companyLabel', 'Firma')}
                />
                <Input
                    {...registerField('phone')}
                    wrapperClassName={styles.topmotiveRequestFormPhoneField}
                    type="tel"
                    label={translate('topmotive.request.phoneLabel', 'Telefon')}
                />
                <Input
                    {...registerField('email')}
                    wrapperClassName={styles.topmotiveRequestFormEmailField}
                    type="email"
                    label={translate('topmotive.request.emailField', 'Email')}
                />
                <div className={styles.topmotiveRequestFormFieldWide}>
                    <TextArea
                        {...registerField('comment')}
                        wrapperClassName={styles.topmotiveRequestFormFieldWide}
                        label={translate('topmotive.request.messageLabel', 'Besked')}
                        required
                    />
                    {formState.errors.comment && <ErrorBox>{formState.errors.comment.message}</ErrorBox>}
                </div>
            </div>
            <TopmotiveButtonGroup>
                <Button
                    type="submit"
                    fetching={isCreatingTruckOERequest}
                    disabled={formState.isSubmitSuccessful && !formState.isDirty}
                    buttonStyle="secondary"
                    icon={<Svg name="check" />}
                >
                    {translate('topmotive.request.submitButton', 'Send forespørgsel')}
                </Button>
                <Button type="button" onClick={handleNavigateBack} buttonStyle="secondaryLight">
                    {translate('topmotive.request.backButton', 'Tilbage')}
                </Button>
            </TopmotiveButtonGroup>
        </form>
    );
}
