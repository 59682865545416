import { Products, Products as ProductTypes } from '~/models/products.d';

export function getVariantsFromID(variantIds: number[], product: ProductTypes.IProduct) {
    return variantIds?.map((variantId) => product.variants?.find((variant) => variant.id === variantId));
}

export function initVariantPicker(product: ProductTypes.IProduct, itemNo: string) {
    /* Finds the active variant of that product */
    const activeVariant = product.variants?.find((variant) => variant.itemNo === itemNo);

    /* Find the first index of the active variant at sets the primary attribute to that */
    const primaryActiveVariantAttribute = activeVariant?.attributes?.[0];

    /* Finds the correct attribute object on the product in order to get possible variants */
    const primaryVariantDefiningAttribute = product?.variantDefiningAttributes?.find(
        (attribute) => attribute.key === primaryActiveVariantAttribute?.key
    );

    /* Array of possible variant IDs*/
    const primaryAttributePossibleVariantsIds = primaryVariantDefiningAttribute?.values?.find(
        (attribute) => attribute.value === primaryActiveVariantAttribute?.value
    )?.variants;

    /* Getting the variants objects from the product by ID */
    const primaryAttributePossibleVariants = getVariantsFromID(primaryAttributePossibleVariantsIds as number[], product);

    return {
        activeVariant,
        primaryActiveVariantAttribute,
        primaryAttributePossibleVariants,
    };
}

export function filterPossibleVariants(filteredVariants: ProductTypes.IVariant[], newPossbileVariants: ProductTypes.IVariant[]) {
    /* From the new array of variants, find the variants from the previous array and keep them */
    const updatedFilteredVariants = newPossbileVariants.map((newPossbileVariant) =>
        filteredVariants.find((filteredVariant) => filteredVariant.id === newPossbileVariant.id)
    );

    /* Filter out undefined objects from the array */
    const filteredUpdatedVariantsArray = updatedFilteredVariants.filter((variant) => variant !== undefined);

    /* If the filtering happend and it resulted in atleast one variant return that array */
    if (Array.isArray(filteredUpdatedVariantsArray) && filteredUpdatedVariantsArray.length !== 0) {
        return filteredUpdatedVariantsArray;
    }

    return newPossbileVariants;
}

export function findBestVariantMatch(
    previousAttribute: ProductTypes.IProductAttribute,
    filteredVariants: ProductTypes.IVariant[],
    fallbackVariant: ProductTypes.IVariant
) {
    /* Finds the best by taking the filtered array and find the previous attribute in that array. */
    const bestVariantsMatch = filteredVariants.find((filteredVariant) =>
        filteredVariant.attributes?.map((filteredAttribute) => filteredAttribute === previousAttribute)
    );

    /* Returns the best variant match. If there is more than one return index 0 */
    if (bestVariantsMatch) {
        if (Array.isArray(bestVariantsMatch) && bestVariantsMatch.length > 1) {
            return bestVariantsMatch[0];
        }
        return bestVariantsMatch;
    }

    /* If there is no match or combination returns fallback variant */
    return fallbackVariant;
}

export function findVariant(
    attribute: { key: string; value: string; variants: number[] },
    product: ProductTypes.IProduct,
    activePrimaryAttribute: Products.IProductAttribute,
    activeFilteredPossibleVariants: ProductTypes.IVariant[]
) {
    /* Gets all the variants objects from the product from the ID's on the active attribute. */
    const primaryAttributePossibleVariants = getVariantsFromID(attribute.variants, product);

    // Fallback Variant to index 0
    const fallbackVariant = primaryAttributePossibleVariants[0];

    /* Find all the variants that existed in the old filtered array as well. */
    const filteredPossibleVariants = filterPossibleVariants(activeFilteredPossibleVariants, primaryAttributePossibleVariants as Products.IVariant[]);

    /* Finds the best match of variant in the filtered array */
    const bestFilteredMatch = findBestVariantMatch(
        activePrimaryAttribute,
        filteredPossibleVariants as Products.IVariant[],
        fallbackVariant as Products.IVariant
    );

    return {
        bestFilteredMatch,
        primaryAttributePossibleVariants,
        filteredPossibleVariants,
    };
}
