import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type ICustomerDetailsProps = {
    customerId: number;
    customerName: string;
    address: string;
    zipCodeAndCity: string;
    departmentId: number;
    cause: string;
    damageDescription: string;
};

export default function CustomerDetails({
    customerId,
    customerName,
    address,
    zipCodeAndCity,
    departmentId,
    cause,
    damageDescription,
}: ICustomerDetailsProps) {
    const translate = useTranslations();

    return (
        <div className={styles.wrapper}>
            <div className={styles.customerInfo}>
                <ValueWithCaption caption={translate('returns.customerId', 'Kunde nr')}>
                    <Text textStyle="monoSmall">{customerId}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.customerName', 'Firmanavn')}>
                    <Text textStyle="bodySmall">{customerName}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.address', 'Adresse')}>
                    <Text textStyle="bodySmall">{address}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.zipCodeAndCity', 'Post nr & By')}>
                    <Text textStyle="bodySmall">{zipCodeAndCity}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.departmentId', 'Afdeling')}>
                    <Text textStyle="bodySmall">{departmentId}</Text>
                </ValueWithCaption>
            </div>
            <div className={styles.description}>
                <ValueWithCaption caption={translate('returns.damageDescription', 'Beskrivelse af skade')}>
                    <Text textStyle="bodySmall">{damageDescription}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.cause', 'Årsag')}>
                    <Text textStyle="bodySmall">{cause}</Text>
                </ValueWithCaption>
            </div>
        </div>
    );
}
