import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Users as UsersTypes } from '~/models/users.d';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

export function useFTZShopRoles() {
    const { isLoggedIn, user } = useUser();
    const { createUrl, users } = useEndpoints();
    const url = createUrl({
        endpoint: `${users.roles}/ftzShop`,
        localeOption: LocaleOptions.path,
    });
    const { locale } = useRouter();

    return useQuery<UsersTypes.IExternalUserRole[], Error>({
        queryKey: queryKeys.bff.ftzShopRoles(locale),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn,
    });
}
