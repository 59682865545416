import { CmsData } from '~/models/cms-data';
import { ICreateUrlParamsQuery, LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { allDepartmentsId } from '~/libs/queries/routing/hooks/use-departments';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

export interface IOrdersWithSubmitStatus extends CmsData.IManualWebOrderItem {
    submitted?: boolean;
}

export function useManualWebOrders(departmentId?: string) {
    const { isLoggedIn, user } = useUser();
    const { orders, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const query: ICreateUrlParamsQuery = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        department: undefined,
    };

    if (departmentId && departmentId !== allDepartmentsId) {
        query.department = departmentId;
    }

    const url = createUrl({
        endpoint: orders.getManualWebOrders,
        localeOption: LocaleOptions.path,
        query: {},
    });

    return useQuery<CmsData.IManualWebOrders, Error>({
        queryKey: queryKeys.users.manualWebOrders(locale, departmentId),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60,
    });
}
