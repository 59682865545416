import React, { FC, useCallback, useState } from 'react';
import { Users as UsersTypes } from '~/models/users.d';
import { Bff } from '~/models/bff';
import Button from '~/shared/buttons/button/button.component';
import SearchInput from '~/shared/form-elements/search-Input/search-Input.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Modal } from '~/features/primitives/modal';
import PageTitle from '~/shared/page-title/page-title.component';
import { Svg } from '~/shared/svg';
import AddUser from './add-user/add-user.component';
import styles from './styled.module.scss';
import UsersList from './users-list/users-list.component';
import { useSubUsers } from '~/libs/queries/users/hooks/use-sub-users';
import { useAddOrUpdateSubUser } from '~/libs/queries/users/hooks/use-update-or-add-sub-user';
import { useDeleteUser } from '~/libs/queries/users/hooks/use-delete-sub-user';

//Before users-administration.component.tsx
export default function W141UsersAdministration(props: Bff.IStandardModuleViewModel) {
    const [modal, setModal] = useState(false);

    const [search, setSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState<UsersTypes.IExternalSubUserInfo | null>(null);
    const translate = useTranslations();
    const { data: usersList, isLoading } = useSubUsers({ userId: selectedUser?.id, query: search });
    const { mutateAsync: createUpdateUser } = useAddOrUpdateSubUser({
        userId: selectedUser?.id,
        query: search,
    });

    const { mutateAsync: deleteUserMutation } = useDeleteUser({ userId: selectedUser?.id, query: search });

    const editUser = (user: UsersTypes.IExternalSubUserInfo) => {
        setSelectedUser(user);
        setModal(true);
    };

    const deleteUser = async (deletedUserId: number) => {
        await deleteUserMutation(deletedUserId);
        setSelectedUser(null);
    };

    const createOrUpdateUser = async (user: UsersTypes.ICreateSubUserInfoRequest) => {
        await createUpdateUser(user);
        setSelectedUser(null);
    };

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setModal(false);
        setSelectedUser(null);
    }, []);

    return (
        <>
            <Modal
                position="right"
                open={modal}
                title={
                    selectedUser
                        ? translate('userAdministration.userDetails', 'Bruger detaljer')
                        : translate('userAdministration.newUser', 'Ny bruger')
                }
                onOpenChange={handleOpenChange}
            >
                <AddUser
                    createUpdateUser={createOrUpdateUser}
                    selectedUser={selectedUser as UsersTypes.IExternalSubUserInfo}
                    closeModal={() => {
                        setModal(false);
                        setSelectedUser(null);
                    }}
                />
            </Modal>
            <PageTitle className={styles.header}>
                <Heading tagName="h3">{translate('userAdministration.userAdministration', 'Brugeradministration')}</Heading>
                <div className={styles.controls}>
                    <SearchInput value={search} setInputValue={setSearch} />
                    <Button buttonStyle="secondary" onClick={() => setModal(true)} icon={<Svg name="plus" thick />}>
                        {translate('userAdministration.newUser', 'Ny bruger')}
                    </Button>
                </div>
            </PageTitle>
            <UsersList users={usersList as UsersTypes.IExternalSubUserInfo[]} loadingUsers={isLoading} editUser={editUser} deleteUser={deleteUser} />
        </>
    );
}
