import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Vehicles } from '~/models/vehicles.d';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';

export function useVehicleModels(manufacturerId: string | undefined) {
    const { expertMode } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { vehicles, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: `${vehicles.getManufacturers}/${manufacturerId}/models`,
        localeOption: LocaleOptions.path,
        query: {
            includeExpertVehicles: !!expertMode,
        },
    });
    return useQuery<Vehicles.ICarManufacturerModelSeriesList, Error>({
        queryKey: queryKeys.vehicle.vehicleModels(locale, manufacturerId, !!expertMode),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn && !!manufacturerId,
    });
}
