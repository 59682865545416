import React, { FC } from 'react';
import GridTable, { Cell, Row } from '~/shared/grid-table/grid-table.component';
import styles from './vehicle-types-table.module.scss';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import { isDefined } from '~/helpers';
import Loader from '~/shared/loader/loader.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ErrorBox from '~/shared/error-box/error-box';
import Text from '~/shared/text/text.component';

interface IVehicleTypesTableProps {
    modelTypes?: VehicleTypes.ICarModelType[];
    isLoading: boolean;
    isError: Error;
}

const VehicleTypesTable: FC<IVehicleTypesTableProps> = ({ modelTypes = [], isLoading, isError }) => {
    const translate = useTranslations();

    if (isLoading) return <Loader padding="50px 0" />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <GridTable className={styles.table}>
            {modelTypes?.filter(isDefined).map((vehicle, i) => (
                <Row key={`${vehicle.name}=${i}`} href={vehicle.url} collapsible>
                    <Cell label={translate('elements.vehicleEngineList.type', 'Type')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.name}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.kw', 'KW')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.kw}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.bhp', 'HK')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.bhp}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.ccm', 'CCM')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.ccm}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.cylinders', 'CYL')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.cylinders}
                        </Text>
                    </Cell>
                    <Cell label={translate('elements.vehicleEngineList.yearFrom', 'Årgang fra')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.yearFrom}
                        </Text>
                    </Cell>
                    <Cell label={translate('elements.vehicleEngineList.yearTo', 'Årgang til')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.yearTo}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.ftzTypeNumber', 'FTZ type nr')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.ftzTypeNo}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.engineCode', 'Motorkode')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle?.engineCodes?.join(', ')}
                        </Text>
                    </Cell>
                    <Cell hideOnMobile label={translate('elements.vehicleEngineList.carsInDk', 'Biler i DK (vejl)')}>
                        <Text textStyle="bodySmall" className={vehicle?.expertModeEnabled ? styles.expert : undefined}>
                            {vehicle.carsInDenmark}
                        </Text>
                    </Cell>
                </Row>
            ))}
        </GridTable>
    );
};

export default VehicleTypesTable;
