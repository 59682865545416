import { CmsData } from '~/models/cms-data';
import { Products } from '~/models/products';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IProductInfoProps = {
    item: CmsData.IReturnDetailsProductInfo;
};

export default function ProductInfo({ item }: IProductInfoProps) {
    const translate = useTranslations();
    return (
        <div className={styles.wrapper}>
            <ProductImageTitleBrand image={item?.image} name={item?.title} brand={item?.brand as Products.IBrand} />
            <ValueWithCaption caption={translate('returns.itemId', 'Vare nr')}>
                <Link href={item?.url}>
                    <Text textStyle="monoSmall">{item?.itemId}</Text>
                </Link>
            </ValueWithCaption>
        </div>
    );
}
