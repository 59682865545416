import Script from 'next/script';
import React from 'react';
import { Bff } from '~/models/bff';
import RawHtml from '~/page-elements/raw-html/raw-html.component';

//Before: ticket-butler-script-widget.tsx
function ScriptContent({ id, content, campaignSpot, type }: Bff.IM200TicketButlerScriptModule) {
    return (
        <div>
            {content ? <RawHtml content={content} campaignSpot={campaignSpot} campaignId={id} campaignType={type} /> : null}
            <Script
                type="text/javascript"
                src="https://unpkg.com/@ticketbutler/event-embedder@latest/dist/index.js"
                strategy="afterInteractive"
                onLoad={() => {
                    window.document.dispatchEvent(
                        new Event('DOMContentLoaded', {
                            bubbles: true,
                            cancelable: true,
                        }),
                    );
                }}
            />
        </div>
    );
}

const M200TicketButlerScript = React.memo(ScriptContent);

export default M200TicketButlerScript;
