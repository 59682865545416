import { NextPageWithLayout } from '~/page-types/types';
import { ModuleList, ModuleRow } from '~/features/primitives/modules';
import { DynamicWidget } from '~/widgets/dynamic-widget';
import { Widget } from '~/libs/queries/bff/hooks/use-cms-page';

const modules: Widget[] = [
    {
        type: 'M040HeadlineModule',
        id: '6f030f0c-55a2-4491-9414-6d09b215f7ac',
        spacingBottom: 'small',
        headline: 'Noget gik galt på vores side',
        horizontalAlignment: 'left',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        headingId: '500',
        tag: '',
        style: '',
        discriminator: 'StandardModuleViewModel',
    },
    {
        type: 'M100RichTextModule',
        id: '4f165d53-dc22-4c4d-8fb0-79b7102ae43f',
        spacingBottom: 'default',
        richText:
            '<p>Vi beklager ulejligheden.</p><p>Vi arbejder på højtryk for at finde, og rette, fejlen så du hurtigt kan komme videre</p><p>Indtil da, kontakt vores supportteam eller hold dig opdateret via driftsbanneret i toppen af siden.</p>',
        discriminator: 'StandardModuleViewModel',
    },
    {
        type: 'M132TwoColumnRow',
        id: 'b99a68a8-ac68-42c4-bbb7-2a091430cd84',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        spacingBottom: null,
        numberAreaColumns: 12,
        areas: [
            {
                rowSpan: 1,
                columnSpan: 12,
                elements: [
                    {
                        type: 'M102IconTitleAndRichTextModule',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        headline: 'Kundeservice',
                        spacingBottom: 'small',
                        richText:
                            '<p>Du kan komme i kontakt med vores supportteam på</p>\n<p><a href="mailto:shop@ftz.dk">shop@ftz.dk</a></p>\n<p><a href="tel:+4565654030">+45 65 65 40 30</a></p>\n<p> </p>',
                        icon: 'chat',
                        id: '738ddde4-11b6-4af5-bf23-638cb37c4b9b',
                    },
                ],
                type: 'Left',
            },
        ],
        discriminator: 'StandardModuleViewModel',
    },
];

// We rely on the CMS to provide us with content for the 500 page. This means that content should be available at build time
const ErrorPage: NextPageWithLayout = () => (
    <ModuleList gap="default">
        {modules.map((item) => {
            return (
                <ModuleRow key={item.id} spacing={item.spacingBottom}>
                    <DynamicWidget widget={item} />
                </ModuleRow>
            );
        })}
    </ModuleList>
);

export default ErrorPage;
