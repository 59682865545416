import Price, { IProps as PriceProps } from '~/shared/price/price.component';
import styles from './basket-summary-price.module.scss';

export type BasketSummaryPriceProps = {
    caption: string;
    price: string;
    priceProps: PriceProps;
};

export function BasketSummaryPrice({ caption, price, priceProps }: BasketSummaryPriceProps) {
    return (
        <div className={styles.basketSummaryPriceWrapper}>
            <span className={styles.basketSummaryPriceCaption}>{caption}</span>
            <Price {...priceProps}>{price}</Price>
        </div>
    );
}
