import React, { FC, useState, useRef, useEffect } from 'react';
import Button, { IButtonProps } from '../button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';

interface IButtonCancelableProps extends IButtonProps {
    callBack: () => void;
    timeout?: number; // milliseconds
    cancelText?: string;
}

const ButtonCancelable: FC<IButtonCancelableProps> = (props) => {
    const translate = useTranslations();
    const { timeout = 5000, callBack, children, cancelText = translate('common.undo', 'fortryd'), ...rest } = props;

    const buttonEl = useRef<HTMLButtonElement>(null);
    const [isPendingCallback, setPendingCallback] = useState(false);
    const timer = useRef(null);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    useEffect(() => () => window.clearTimeout(timer.current), []);

    const handleClick = () => {
        buttonEl?.current?.blur();

        if (isPendingCallback) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.clearTimeout(timer.current);
            setPendingCallback(false);
        } else {
            setPendingCallback(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            timer.current = window.setTimeout(() => {
                callBack();
                setPendingCallback(false);
            }, timeout);
        }
    };

    return (
        <Button
            icon={isPendingCallback ? <Svg name="close" /> : null}
            onClick={handleClick}
            ref={buttonEl}
            {...rest}
            buttonStyle={isPendingCallback ? 'secondaryLight' : 'primary'}
        >
            {isPendingCallback ? cancelText : children}
        </Button>
    );
};

export default ButtonCancelable;
