import React, { forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';
import InputValidation from '../common/input-validation/input-validation.component';
import styles from './radio.module.scss';

// import Checkable, {
//   ICheckableProps,
//   ICheckableRef
// } from '../common/checkable/checkable.component'

// type ICheckboxProps = Omit<ICheckableProps, 'type'>

// /**
//  * @author SBF
//  *
//  * @description
//  * A wrapper around <input type="radio" />
//  *
//  * @example
//  * // By itself
//  *
//  * const [value, setValue] = useState(false);
//  * ...
//  * <Radio checked={value === 1} onClick={() => setValue(1)}>Radio 1</Radio>
//  * <Radio checked={value === 2} onClick={() => setValue(2)}>Radio 2</Radio>
//  *
//  * @example
//  * // With react-hook-form
//  *
//  * const { register } = useForm();
//  * ...
//  * <form>
//  *   <Radio name="radio-grp" value="1" ref={register}>Radio 1</Radio>
//  *   <Radio name="radio-grp" value="2" ref={register}>Radio 2</Radio>
//  * </form>
//  */
// const Radio = forwardRef<ICheckableRef, ICheckboxProps>((props, ref) => (
//   <Checkable type="radio" {...props} ref={ref} />
// ))

// export default Radio

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IWithClassName {
    errorMessage?: string;
    isError?: boolean;
}

export type ICheckableRef = HTMLInputElement;

const Radio = forwardRef<ICheckableRef, IProps>(({ children, className, errorMessage, isError, disabled, ...inputProps }, ref) => (
    <>
        <label /* eslint-disable-line  jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
            className={clsx(className, styles.root, disabled && styles.disabled)}
        >
            <input type="radio" className={styles.input} ref={ref} disabled={disabled} {...inputProps} />
            <span
                className={clsx(styles.radio, {
                    [styles.error]: errorMessage || isError,
                    [styles.disabledRadio]: disabled,
                })}
            >
                <span className={styles.icon} />
            </span>

            {children && (
                <Text className={clsx(styles.label, disabled && styles.disabledLabel)} color={isError ? 'errorColor' : undefined}>
                    {children}
                </Text>
            )}
        </label>
        <InputValidation errorMessage={errorMessage} />
    </>
));

export default Radio;
