import clsx from 'clsx';
import { useEffect } from 'react';
import { MANUAL_ORDERS_STATE } from '~/constants/local.storage';
import { IDepartment, allDepartmentsId, useDepartmentOptions, useDepartments } from '~/libs/queries/routing/hooks/use-departments';
import useSortedManualWebOrders from '~/libs/queries/users/hooks/use-manual-web-order-sorted';
import { useManualWebOrders } from '~/libs/queries/users/hooks/use-manual-web-orders';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Select from '~/shared/form-elements/select/select.component';
import Heading from '~/shared/heading/heading.component';
import { useLocalStorageJson } from '~/shared/hooks/use-local-storage.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PageTitle from '~/shared/page-title/page-title.component';
import { Svg } from '~/shared/svg';
import OrdersTable from './orders-table/oders-table.component';
import styles from './styled.module.scss';

//Before manual-orders.component.tsx
export default function W071ManualWebOrders(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        profile: { delivery_department_id: deliveryDepartmentId },
    } = useUser();
    const { data: departments } = useDepartments();
    const departmentOptions = useDepartmentOptions(departments);
    const [selectedDepartment, setSelectedDepartment] = useLocalStorageJson(MANUAL_ORDERS_STATE, {
        id: deliveryDepartmentId || allDepartmentsId,
        userChanged: false,
    });
    const { data: webOrdersRaw, refetch: mutateWebOrders, isLoading, isRefetching, isError } = useManualWebOrders(selectedDepartment.id);
    const { ordersState: webOrders, setWebOrderAsSubmitted } = useSortedManualWebOrders(webOrdersRaw, selectedDepartment.id);
    useEffect(() => {
        if (deliveryDepartmentId && !selectedDepartment.userChanged) {
            setSelectedDepartment({
                id: deliveryDepartmentId,
                userChanged: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryDepartmentId]);

    const handleChangeDepartment = (departmentName: string) => {
        const selected = departmentOptions?.find((d: IDepartment) => d.displayName === departmentName);

        setSelectedDepartment({
            id: selected?.id,
            userChanged: true,
        });
        mutateWebOrders();
    };

    if (isError) {
        return (
            <div className={styles.manualOrders}>
                <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            </div>
        );
    }

    return (
        <>
            <div className={styles.manualOrders}>
                <PageTitle className={styles.header}>
                    <Heading tagName="h1" displayStyle="h3" className={styles.title}>
                        {/* TODO: This title should come from widget cmsdata or data */}
                        {translate('myFtz.manualOrders.manualWebOrders', 'Manuelle webordrer')}

                        <small
                            className={clsx(styles.titleSmall, {
                                [styles.titleSmallVisible]: !isLoading,
                            })}
                        >
                            <span className={styles.titleNumberSpace}>{webOrders?.length}</span>
                            {translate('myFtz.manualOrders.results', 'resultater')}
                        </small>
                    </Heading>

                    <Button
                        className={styles.refreshButton}
                        fetching={isRefetching}
                        onClick={() => {
                            if (!isRefetching) {
                                mutateWebOrders();
                            }
                        }}
                        icon={<Svg name="refresh" />}
                        buttonStyle="secondaryLight"
                    >
                        {translate('myFtz.manualOrders.updateList', 'Opdatér liste')}
                    </Button>

                    {departments?.length ? (
                        <Select
                            wrapperClassName={styles.departmentsDropdown}
                            value={departments?.length ? departments?.find((dpt) => dpt.id === selectedDepartment.id)?.displayName : ''}
                            onChange={(e) => handleChangeDepartment(e.target.value)}
                        >
                            <option disabled>{translate('myFtz.manualOrders.selectDepartment', 'Vælg afdeling')}</option>
                            {departments.map((d) => (
                                <option key={d.id}>{d.displayName}</option>
                            ))}
                        </Select>
                    ) : null}
                </PageTitle>

                <OrdersTable orders={webOrders} setOrderSubmitted={setWebOrderAsSubmitted} />
            </div>

            {isLoading && <Loader padding="50px 0" />}
        </>
    );
}
