import clsx from 'clsx';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './add-to-basket-button.module.scss';

export type AddToBasketButtonShowLabelAt = 'Always' | 'MD' | 'LG' | 'XL' | 'XXL' | 'Never';

export type AddToBasketButtonProps = {
    label?: string;
    showLabelAt?: AddToBasketButtonShowLabelAt;
    isLarge?: boolean;
    disabled?: boolean;
    onClick: () => void;
};

export function AddToBasketButton({ label, showLabelAt = 'Always', isLarge, disabled, onClick }: AddToBasketButtonProps) {
    return (
        <button
            aria-label="Add to Basket"
            type="button"
            disabled={disabled}
            className={clsx(styles.addToBasketButton, {
                [styles.addToBasketButtonLarge]: isLarge,
                [styles.addToBasketButtonSquare]: isLarge && !label,
            })}
            onClick={onClick}
        >
            <Svg name="checkout" className={styles.addToBasketButtonIcon} />
            {label && showLabelAt !== 'Never' ? <Text className={styles[`addToBasketButtonShowLabelAt${showLabelAt}`]}>{label}</Text> : null}
        </button>
    );
}

export default AddToBasketButton;
