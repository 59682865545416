import { useState } from 'react';
import { addErrorToast, addWarnToast, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import submitManualOrder from '~/services/orders/submit-manual-order.service';
import ButtonCancelable from '~/shared/buttons/button-cancelable/button-cancelable.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';

interface IManualOrderRequestButtonProps {
    webOrderId: string;
    submitted: boolean;
    setOrderSubmitted: (webOrderId: string) => void;
}

export default function ManualOrderRequestButton({ webOrderId, submitted, setOrderSubmitted }: IManualOrderRequestButtonProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const [fetching, setFetching] = useState(false);

    const { user } = useUser();

    const handleSubmit = async () => {
        setFetching(true);

        try {
            const json = await submitManualOrder([webOrderId], user);

            if (json.isSuccess) {
                setOrderSubmitted(webOrderId);
            } else {
                dispatch(addWarnToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
            }
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        }

        setFetching(false);
    };

    return (
        <ButtonCancelable disabled={submitted} className={styles.button} fetching={fetching} callBack={handleSubmit}>
            {submitted ? translate('myFtz.manualOrders.transferred', 'Overført') : translate('myFtz.manualOrders.transferOrder', 'Overfør ordre')}
        </ButtonCancelable>
    );
}
