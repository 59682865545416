import { Basket as BasketTypes } from '~/models/basket.d';
import { IWithClassName } from '~/models/dev.d';
import CarInfo from '~/shared/car-info/car-info.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import { Svg } from '~/shared/svg';
import { BasketItemsLineItemContainer } from '../basket-items-line-item/basket-items-line-item.container';
import styles from './basket-items-group-car.module.scss';

export type BasketItemsGroupWithCarInfoProps = IWithClassName & {
    id: string;
    items: BasketTypes.IBasketItemDto[];
    carInfo: BasketTypes.IBasketCarInfoDto;
    handleDeleteClick: () => Promise<void>;
    isLoading: boolean;
};

export function BasketItemsGroupCar({ items, carInfo, handleDeleteClick, isLoading }: BasketItemsGroupWithCarInfoProps) {
    const translate = useTranslations();

    return (
        <section className={styles.basketItemsGroupCar}>
            <div className={styles.basketItemsGroupCarHeader}>
                <CarInfo carInfo={carInfo} />

                <button type="button" className={styles.basketItemsGroupCarDeleteButton} disabled={isLoading} onClick={handleDeleteClick}>
                    <Loader
                        className={styles.basketItemsGroupCarDeleteButtonLoader}
                        isVisible={isLoading}
                        size="30px"
                        padding="0"
                        borderWidth="2px"
                        delay={0.2}
                    />

                    {translate('basket.deleteVehicle', 'Slet bil')}
                    <Svg name="bin" thick className={styles.basketItemsGroupCarDeleteButtonIcon} />
                </button>
            </div>

            {items.map((item) => (
                <BasketItemsLineItemContainer key={item.itemId} item={item} carInfo={carInfo} />
            ))}
        </section>
    );
}
