import { Bff } from '~/models/bff';
import { ProductListWrapper, SearchProductList } from '~/shared/product-list/product-list.component';

//Before it was part of: product-list-page.component.tsx
export default function W112SearchResultsProductList(props: Bff.IStandardModuleViewModel) {
    return (
        <ProductListWrapper categoryDefaultViewType={0}>
            <SearchProductList />
        </ProductListWrapper>
    );
}
