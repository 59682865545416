import React from 'react';
import clsx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import { Products as ProductTypes } from '~/models/products';

import styles from './product-tile-image.module.scss';
import { Svg } from '~/shared/svg';
import { Link } from '~/shared/link';

interface IProductTileImage {
    image: ProductTypes.IImage | undefined;
    url: string | undefined | false;
    leftImage?: boolean;
    onClick: () => void;
}

const ProductTileImage: React.FC<IProductTileImage> = ({ image, url, leftImage, onClick }) => (
    <Link
        aria-label={`Link to ${image?.altText}`}
        className={clsx(leftImage ? styles.imageLeft : styles.imageCenter)}
        href={url as string}
        onClick={onClick}
    >
        {image ? (
            <CdnImage src={image?.url as string} alt={`Image of ${image?.altText}`} layout="fill" objectFit="contain" sizes="240px" quality={60} />
        ) : (
            <Svg name="placeholder-image" className={styles.placeholder} />
        )}
    </Link>
);

export default ProductTileImage;
