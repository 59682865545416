import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import styles from './stock-status.module.scss';
import { IWithClassName } from '~/models/dev';
import { Products as ProductTypes } from '~/models/products.d';
import Details from './details/details.component';
import { Modal } from '~/features/primitives/modal';
import QuickView from './quick-view/quick-view.component';
import Skeleton from '~/shared/skeleton';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';
import useUser from '~/libs/use-user';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';

interface IStockStatusProps extends IWithClassName {
    itemNo?: string;
    subGroup?: string;
    onClick?: (itemNo: string) => void;
    stockStatus?: ProductTypes.IStockStatus;
    useExternalData?: boolean;
    isLoadingExternal?: boolean;
    isErrorExternal?: boolean;
    hideText?: boolean;
}

const StockStatus: FC<IStockStatusProps> = ({
    itemNo,
    subGroup,
    hideText,
    className,
    onClick,
    stockStatus,
    useExternalData = false,
    isLoadingExternal,
    isErrorExternal,
}) => {
    const { data, isLoading: isLoadingInternal, isError: isErrorInternal } = useStockStatus([itemNo as string]);
    const { isInternalUser } = useUser();

    const stockData: ProductTypes.IStockStatus | undefined = useExternalData ? stockStatus : data && data[itemNo as string];
    const isLoading = useExternalData ? isLoadingExternal : isLoadingInternal;
    const isError = useExternalData ? isErrorExternal : isErrorInternal;
    const isErrorBoolean = Boolean(isError);

    return (
        <StockIndicator
            className={className}
            itemNo={itemNo}
            isError={isErrorBoolean}
            stockStatus={stockData}
            onClick={onClick}
            isLoading={isLoading}
            isInternalUser={isInternalUser}
            subGroup={subGroup}
            hideText={hideText}
        />
    );
};

export type StockIndicatorProps = {
    className?: string;
    itemNo?: string;
    subGroup?: string;
    onClick?: (itemNo: string) => void;
    stockStatus?: ProductTypes.IStockStatus;
    isLoading?: boolean;
    isError?: boolean;
    hideText?: boolean;
    isInternalUser?: boolean;
};

export const StockIndicator = React.forwardRef<HTMLSpanElement, StockIndicatorProps>(
    ({ itemNo, subGroup, hideText, className, onClick, stockStatus, isLoading, isError, isInternalUser }, ref) => {
        const [modal, openModal] = useState(false);
        const translate = useTranslations();

        // TODO: ProductTypes.StockStatusCode.ExternalStock still missing
        const callUs = !stockStatus || stockStatus?.statusCode === ProductTypes.StockStatusCode.NotInStock;

        const handleOpenChange = useCallback((open) => {
            if (open) {
                return;
            }

            openModal(false);
        }, []);

        return (
            <span ref={ref}>
                <Modal
                    position="right"
                    title={translate('stockStatus.stockStatus', 'Lagerstatus')}
                    meta={
                        <>
                            <Text textStyle="monoMedium">{itemNo}</Text>
                            {isInternalUser && subGroup && (
                                <Text textStyle="bodySmall">
                                    {translate('stockStatus.subgroup', 'Undergruppe')}:{' '}
                                    <Text textStyle="monoMedium" tagName="span">
                                        {subGroup}
                                    </Text>
                                </Text>
                            )}
                        </>
                    }
                    open={modal}
                    onOpenChange={handleOpenChange}
                >
                    <Details itemNo={itemNo as string} />
                </Modal>

                {isLoading ? (
                    <Skeleton style={{ height: 15, width: 90 }} />
                ) : (
                    <Tooltip
                        delayShow={300}
                        content={
                            <TooltipContentWrapper>
                                <QuickView itemNo={itemNo as string} />
                            </TooltipContentWrapper>
                        }
                    >
                        <button
                            type="button"
                            onClick={() => {
                                openModal(true);
                                onClick && onClick(itemNo as string);
                            }}
                            disabled={isError || isLoading}
                            className={clsx(styles.button, styles[`status-${stockStatus?.statusCode}`], className)}
                        >
                            {callUs && <Svg name="customer-service" thick className={styles.buttonIcon} />}
                            {!hideText && (
                                <span className={styles.text}>{stockStatus?.statusTranslation || translate('common.callUs', 'Kontakt FTZ')}</span>
                            )}
                        </button>
                    </Tooltip>
                )}
            </span>
        );
    },
);

export default StockStatus;
