import React, { FC } from 'react';
import { useCurrentBasket } from '~/libs/queries/basket';
import styles from './product-card-tile.module.scss';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { StockIndicator } from '~/shared/stock-status/stock-status.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import { Products as ProductTypes } from '~/models/products.d';
import ProductTileImage from './product-tile-image/product-tile-image.component';
import { Link } from '~/shared/link';
import Text from '~/shared/text/text.component';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import TireProperties from './tire-properties/tire-properties.component';
import { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import PriceTooltip from '~/shared/price-tooltip/price-tooltip.component';
import ProductTileSplashes from '~/page-elements/product-card/product-card-tile/product-tile-splashes/product-tile-splashes.component';
import { getListName, useBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
import useUser from '~/libs/use-user';
import { GA4SelectItem } from '~/libs/ga4';

export interface IProps {
    item: ProductTypes.IProductTile;
    isOeMatch?: boolean;
    price?: ProductTypes.IVariantPrices;
    isLoadingPrice?: boolean;
    stock?: ProductTypes.IStockStatus;
    isLoadingStock?: boolean;
    isErrorStock?: boolean;
}

const ProductCardTile: FC<IProps> = ({ item, isOeMatch, price, isLoadingPrice, stock, isLoadingStock, isErrorStock }) => {
    const translate = useTranslations();

    const additionalProperties = item.additionalProperties as ProductTypes.ITireTileProperties;
    const hasAdditionalProperties = additionalProperties?.extraLoad || additionalProperties?.runFlat || additionalProperties?.hasOE;
    const isDummy = item.productType === ProductTypes.ProductType.DummyProduct;
    const isTire = item.productTileType === ProductTypes.ProductTileType.Tire;
    const { data: breadcrumb } = useBreadcrumb();
    const { profile, isInternalUser } = useUser();
    const { data: basket } = useCurrentBasket();

    const handleClick = () => {
        const listName = getListName(breadcrumb, item.title);
        GA4SelectItem(item, {
            currency: profile?.currency as string,
            price: price?.actualPrice?.customerPrice as number,
            quantity: 1,
            basketId: basket?.id as string,
            listName,
        });
    };

    return (
        <ProductCardTileContainer>
            <ProductTileSplashes isOeMatch={isOeMatch} campaign={item.campaign} />
            <ProductTileImage leftImage={hasAdditionalProperties} image={item?.images?.[0]} url={!isDummy && item.url} onClick={handleClick} />
            <ProductCardTileContent>
                <ProductCardTileContentTop>
                    <BrandLogo brand={item.brand} width={100} height={20} />
                    {!isDummy && (
                        <ProductCardTileStock
                            tile={item}
                            stock={stock}
                            loading={isLoadingStock}
                            error={isErrorStock}
                            isInternalUser={isInternalUser}
                        />
                    )}
                </ProductCardTileContentTop>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link href={!isDummy && item.url} className={styles.link} onClick={handleClick}>
                    <Text tagName="h3" textStyle="body" fontWeight="semiBold">
                        {item.title}
                    </Text>
                    <Text textStyle="monoSmall" fontWeight="regular">
                        {item.itemNo}
                    </Text>
                </Link>
                <FtzPricesTooltip
                    unit={item.unit}
                    className={styles.ftzPrice}
                    isLoading={isLoadingPrice}
                    prices={price as IColliPrice}
                    colli={item.colli}
                    colliLocked={item.colliLocked}
                />
                {isTire && <TireProperties properties={additionalProperties} />}
            </ProductCardTileContent>
            <ProductCardTileFooter>
                <ValueWithCaption
                    caption={
                        item.unit
                            ? translate('product.pricePerUnit', 'Pris pr. [unit]').replace('[unit]', item.unit)
                            : translate('product.pricePerPiece', 'Pris stk.')
                    }
                >
                    <PriceTooltip
                        isLoading={isLoadingPrice}
                        displayPrice={price?.actualPrice?.displayPrice as string}
                        fee={price?.actualPrice?.displayFee}
                        deposit={price?.actualPrice?.displayDeposit}
                    />
                </ValueWithCaption>
                <AddToBasketContainer
                    disabled={isDummy}
                    smallUnder="Always"
                    label={translate('product.addToBasket', 'Læg i kurv')}
                    syncWithBasket
                    url={item.url as string}
                    productDetails={{
                        colli: item.colli,
                        colliLocked: item.colliLocked,
                        itemNo: item.itemNo as string,
                        customerPrice: price?.actualPrice?.customerPrice,
                    }}
                />
            </ProductCardTileFooter>
        </ProductCardTileContainer>
    );
};

export type ProductCardTileContainerProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardTileContainer({ children }: ProductCardTileContainerProps) {
    return <div className={styles.card}>{children}</div>;
}

export type ProductCardTileContentProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardTileContent({ children }: ProductCardTileContentProps) {
    return <div className={styles.content}>{children}</div>;
}

export type ProductCardTileFooterProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardTileFooter({ children }: ProductCardTileFooterProps) {
    return <div className={styles.footer}>{children}</div>;
}

export type ProductCardTileContentTopProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function ProductCardTileContentTop({ children }: ProductCardTileContentTopProps) {
    return <div className={styles.brandStockContainer}>{children}</div>;
}

export type ProductCardTileStockProps = {
    tile: ProductTypes.IProductTile;
    stock?: ProductTypes.IStockStatus;
    error?: boolean;
    loading?: boolean;
    isInternalUser?: boolean;
};

export function ProductCardTileStock({ tile, stock, error, loading, isInternalUser }: ProductCardTileStockProps) {
    return (
        <div className={styles.stockStatus}>
            <StockIndicator stockStatus={stock} isError={error} isLoading={loading} itemNo={tile?.itemNo} isInternalUser={isInternalUser} />
        </div>
    );
}

export default ProductCardTile;
