import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';

export type BasketSubmitProps = {
    label: string;
    isDisabled: boolean;
    isLoading: boolean;
};

export function BasketSubmit({ label, isDisabled, isLoading }: BasketSubmitProps) {
    return (
        <Button disabled={isDisabled} fetching={isLoading} type="submit" icon={<Svg name="check" thick />}>
            {label}
        </Button>
    );
}
