import { useMediaQuery } from 'usehooks-ts';
import { MediaQuery } from '~/shared/media-query-detect';
import { useMemo } from 'react';

export const slidesPerView = {
    [MediaQuery.Mobile]: 1,
    [MediaQuery.Tablet]: 2,
    [MediaQuery.Desktop]: 3,
    [MediaQuery.LargeDesktop]: 4,
};

export const useContentCarouselItems = (count: number) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const isTablet = useMediaQuery(MediaQuery.Tablet);
    const isDesktop = useMediaQuery(MediaQuery.Desktop);
    const isLargeDesktop = useMediaQuery(MediaQuery.LargeDesktop);

    return useMemo(() => {
        let numSlides = slidesPerView[MediaQuery.LargeDesktop];
        if (isMobile) {
            numSlides = slidesPerView[MediaQuery.Mobile];
        } else if (isTablet) {
            numSlides = slidesPerView[MediaQuery.Tablet];
        } else if (isDesktop) {
            numSlides = slidesPerView[MediaQuery.Desktop];
        } else if (isLargeDesktop) {
            numSlides = slidesPerView[MediaQuery.LargeDesktop];
        }
        return count > numSlides;
    }, [isMobile, isTablet, isDesktop, isLargeDesktop, count]);
};
