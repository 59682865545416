import { Products as ProductTypes } from '~/models/products.d';

export interface ICategoryWithPriceInfo extends ICategoryWithParents {
    priceFactor: number;
    hasCategoryDifferentPriceLevels: boolean;
}

export interface ICategoryWithParents extends ProductTypes.ICategory {
    parentId?: number;
    parents?: number[];
    children?: ICategoryWithParents[];
    salesPriceData?: ProductTypes.ICategorySalesPriceMarkup;
}

export const mapCategoriesWithParents = (
    categories: ICategoryWithParents[],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    parentId: number = null,
    parents: number[] = [],
): ICategoryWithParents[] =>
    categories.map((category) => ({
        ...category,
        parentId,
        parents,
        children: mapCategoriesWithParents(category.children as ICategoryWithParents[], category.id, [...parents, category.id]),
    }));

export const getAllCategoryChildren = (category: ICategoryWithParents) => {
    const items: ICategoryWithParents[] = [];

    const pushToChildren = (children: ICategoryWithParents[]) => {
        children?.forEach((child) => {
            items.push(child);
            if (child?.children?.length) {
                pushToChildren(child.children);
            }
        });
    };
    pushToChildren(category?.children as ICategoryWithParents[]);

    return items;
};

export const checkIfAllItemsPresent = (arr: number[], itemsToCheck: number[]) => arr.every((i) => itemsToCheck.includes(i));

export const checkIfSomeItemsPresent = (arr: number[], itemsToCheck: number[]) => arr.some((i) => itemsToCheck.includes(i));

export const removeFromArray = (array: number[], itemsToRemove: number[]) => array.filter((item) => !itemsToRemove.includes(item));
