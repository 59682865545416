import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

export type UseDeleteSubUserProps = {
    userId?: number;
    query?: string;
};

export function useDeleteUser({ userId, query }: UseDeleteSubUserProps) {
    const { locale } = useRouter();
    const { user } = useUser();
    const queryClient = useQueryClient();
    const { createUrl, users } = useEndpoints();

    return useMutation({
        mutationFn: async (deletedUserId: number) => {
            await apiClient.auth(user).delete(
                createUrl({
                    endpoint: `${users.externalSubUsers}/${deletedUserId}`,
                    localeOption: LocaleOptions.path,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            );
        },
        onMutate: () => queryClient.cancelQueries(queryKeys.users.subUsers(locale, userId, query)),
        onSettled: () => queryClient.invalidateQueries(queryKeys.users.subUsers(locale, userId, query)),
    });
}
