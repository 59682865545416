import React, { FC, useState } from 'react';
import styles from './vehicle-group.module.scss';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import ModelsList from './models-list.component';
import Accordion from '~/shared/accordion/accordion.component';

interface IVehiclesGroupProps {
    group: VehicleTypes.ICarSeries;
}

const VehiclesGroup: FC<IVehiclesGroupProps> = ({ group }) => {
    const [isOpen, setOpen] = useState(false);
    const { name, models = [] } = group;

    const hasExpertVehicles = models.some((model) => model.expertModeEnabled === true);

    return (
        <Accordion
            buttonClassName={hasExpertVehicles ? styles.expertTitle : styles.title}
            isOpen={isOpen}
            onClick={() => setOpen(!isOpen)}
            header={`${name} (${models.length})`}
        >
            <ModelsList models={models} />
        </Accordion>
    );
};

export default VehiclesGroup;
