import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { addErrorToast, addSuccessToast, LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { GA4BasketSave } from '~/libs/ga4';
import { basketKeys, useCheckoutBasket, useCreateBasket, useDeleteBasketHandlers, useSaveBasket, useSaveBasketHandlers } from '~/libs/queries/basket';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';
import useActiveUser from '~/libs/use-active-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../use-basket-states';
import { BasketFooterSaveBasket } from './basket-footer-save-basket';

export function BasketFooterSaveBasketContainer() {
    const translate = useTranslations();
    const router = useRouter();
    const dispatch = useLayoutDispatch();

    const { activeProfile } = useActiveUser();

    const { data: basket } = useCheckoutBasket();
    const { data: { basket: basketFeatures } = {} } = useFeatures();
    const { mutate: saveBasket, isLoading: isSavingBasket } = useSaveBasket();

    const { shouldHideFeature } = useFeatureToggle();
    const { setSplitDeliveryOption } = useBasketStates();
    const { selectedDepartment } = useLayoutState();
    const {
        handleError: handleSaveBasketError,
        handleSettled: handleSaveBasketSettled,
        handleSuccess: handleSaveBasketSuccess,
    } = useSaveBasketHandlers();

    const queryClient = useQueryClient();

    // This should never happen because footer is only rendered with active basket and user
    if (!basket) {
        return null;
    }

    const handleSaveBasket = async () => {
        if (!basket?.id || !basket?.totalItemsCount) {
            return;
        }

        saveBasket(
            {
                basketId: basket?.id,
                departmentId: selectedDepartment?.id,
                status: 'manual',
            },
            {
                onSuccess: (...args) => {
                    dispatch(addSuccessToast(translate('basket.saveBasketSuccess', 'Kurven blev gemt')));

                    handleSaveBasketSuccess(...args);

                    GA4BasketSave();
                },
                onError: handleSaveBasketError,
                onSettled: async (response, ...args) => {
                    setSplitDeliveryOption(BasketTypes.DeliveryType.UnSpecified);

                    await handleSaveBasketSettled(response, ...args);

                    dispatch({
                        type: LayoutActionTypes.SetSelectedDepartment,
                        payload: null,
                    });

                    await queryClient.invalidateQueries(basketKeys.savedBasketsTotal(router.locale, activeProfile?.id, selectedDepartment?.id));

                    if (typeof response?.redirectUrl === 'string') {
                        await router.push(response.redirectUrl);
                    }
                },
            },
        );
    };

    const isDisabled = !basket.totalItemsCount || shouldHideFeature(basketFeatures?.savedBaskets);

    const { clientDisplayName, hasPostSaveRedirect } = basket.thirdPartyDetails ?? {};

    const label =
        hasPostSaveRedirect && clientDisplayName
            ? translate('basket.saveBasketAndGoBack', 'Gem kurven og gå tilbage til [client]').replace('[client]', clientDisplayName)
            : translate('basket.saveBasket', 'Gem kurven');

    return <BasketFooterSaveBasket label={label} isDisabled={isDisabled} isLoading={isSavingBasket} handleClick={handleSaveBasket} />;
}
