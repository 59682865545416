import React, { FC } from 'react';
import Feedback from '~/shared/feedback/feedback.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { createStatus } from '../create-credit.d';
import Button from '~/shared/buttons/button/button.component';
import { printEndpoints } from '~/services/auth-endpoints';
import { Orders as OrderTypes } from '~/models/orders.d';
import { Svg } from '~/shared/svg';
import styles from './credit-feedback.module.scss';

interface IProps {
    messages: string[];
    status: createStatus;
    setStatus: (value: React.SetStateAction<createStatus>) => void;
    creditId: number;
    onClose: () => void;
    isOECredit?: boolean;
    creditDraftDetails?: OrderTypes.ICreditDraftDetails;
    isExternalCredit?: boolean;
}

const SubmitFeedback: FC<IProps> = ({ messages, status, isOECredit, creditId, creditDraftDetails, isExternalCredit, onClose, setStatus }) => {
    const translate = useTranslations();

    const messageList = messages.map((message) => `- ${message}`).reduce((prev, curr) => `${prev}\n ${curr}`, '');

    const errorStr = `
  ${messageList}

  `;

    const messageStr = translate(
        'overview.creditFeedback.errorText',
        "Din kreditering blev desværre ikke oprettet. Det skyldes: [reasons] FTZ's kundeservice er klar ved telefonen, hvis du har spørgsmål"
    ).replace('[reasons]', errorStr);

    if (status === createStatus.created) {
        return (
            <Feedback
                type="success"
                headline={translate('overview.creditFeedback.successHeadline', 'Din kreditering blev oprettet')}
                text={
                    isOECredit
                        ? ''
                        : translate(
                              'overview.creditFeedback.successText',
                              'Du kan nu finde din kreditering på oversigten Krediteringer. Her kan du også følge status for dine krediteringer samt printe dem'
                          )
                }
            >
                {creditId && (
                    <Button
                        buttonStyle="secondary"
                        className={styles.feedbackButton}
                        icon={<Svg name="print" />}
                        onClick={() => window.open(`${printEndpoints.return}?ids=sellerOrderNumber${creditId}`, '_blank')}
                    >
                        {translate('overview.creditFeedback.successPrintButton', 'Print kreditering')}
                    </Button>
                )}

                {isExternalCredit ? (
                    <Button href={creditDraftDetails?.successRedirectUrl} className={styles.feedbackButton}>
                        {translate('createCredit.successRedirectLink', 'Tilbage til [clientDisplayName]').replace(
                            '[clientDisplayName]',
                            creditDraftDetails?.clientDisplayName
                        )}
                    </Button>
                ) : (
                    <Button className={styles.feedbackButton} onClick={() => onClose()}>
                        {translate('overview.creditFeedback.backButton', 'Tilbage')}
                    </Button>
                )}
            </Feedback>
        );
    }

    if (status === createStatus.notCreated) {
        return (
            <Feedback type="error" headline={translate('overview.creditFeedback.errorHeadline', 'Din kreditering blev afvist')} text={messageStr}>
                <Button className={styles.feedbackButton} onClick={() => setStatus(createStatus.initial)}>
                    {translate('overview.creditFeedback.backButton', 'Tilbage')}
                </Button>
            </Feedback>
        );
    }

    return null;
};

export default SubmitFeedback;
