import React from 'react';
import clsx from 'clsx';

import styles from './dots.module.scss';

export interface IDotsProps {
    count: number;
    cursor: number;
}

const Dots: React.FC<IDotsProps> = ({ count, cursor }) => {
    const arr = new Array(count);
    const dots = [];

    for (let i = 0; i < arr.length; i += 1) {
        dots.push(
            <div
                key={i}
                className={clsx(styles.dot, {
                    [styles.active]: i === cursor,
                })}
            />
        );
    }

    return <div className={styles.dots}>{dots}</div>;
};

export default Dots;
