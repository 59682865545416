import { useUniversalPartCategoriesById } from '~/libs/queries/products/hooks/use-universal-part-categories-by-id';
import { Bff } from '~/models/bff';
import { Products } from '~/models/products';
import Links from '~/widgets/W120SparePartCategoryList/chunks/links';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PageTitle from '~/shared/page-title/page-title.component';
import Skeleton from '~/shared/skeleton';

// Name before: universal-category-list.component.tsx
export default function W130UniversalPartCategoryList({ categoryId, title, count }: Bff.IUniversalPartCategoryListWidgetViewModel) {
    const translate = useTranslations();
    const { data: categoryData, isLoading, isError } = useUniversalPartCategoriesById({ categoryId: categoryId as number });

    return (
        <>
            <PageTitle>
                {isLoading ? <Skeleton style={{ height: '26px', margin: '4px 0', width: '25%' }} /> : <Heading tagName="h3">{title}</Heading>}
            </PageTitle>

            <Links isLoading={isLoading} count={count} categories={categoryData?.children as Products.ICategory[]} />

            {isError && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt. Prøv igen senere')}</ErrorBox>}
        </>
    );
}
