import useNextDelivery from '~/services/basket/use-next-delivery.service';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketHeaderNextDelivery } from './basket-header-next-delivery';

export function BasketHeaderNextDeliveryContainer() {
    const translate = useTranslations();
    const { data, isLoading } = useNextDelivery();
    const nextDeliveryDate = data?.nextDeliveryLastCallTime && new Date(data?.nextDeliveryLastCallTime);

    return (
        <BasketHeaderNextDelivery
            isFetching={isLoading}
            counterTo={nextDeliveryDate}
            label={translate('basket.nextDeliveryIn', 'Næste leveringstur om:')}
        />
    );
}
