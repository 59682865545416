import React, { FC } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';
import styles from './accordion.module.scss';
import { Collapse } from '~/shared/collapse';

interface IProps extends IWithClassName {
    header: string | JSX.Element;
    isOpen?: boolean;
    onClick?: () => void;
    buttonClassName?: string;
    contentClassName?: string;
}

const Accordion: FC<IProps> = ({ onClick, isOpen, children, header, buttonClassName, contentClassName }) => (
    <div className={styles.root}>
        <button className={clsx(styles.button, buttonClassName)} type="button" onClick={onClick}>
            {header}
            <Svg
                name="plus"
                thick
                className={clsx({
                    [styles.activeIcon]: isOpen,
                    [styles.icon]: !isOpen,
                })}
            />
        </button>
        <Collapse isOpen={isOpen}>
            <div className={clsx(styles.content, contentClassName)}>{children}</div>
        </Collapse>
    </div>
);

export default Accordion;
