import { useRouter } from 'next/router';
import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useCurrentBasket } from '~/libs/queries/basket';
import ErrorBox from '~/shared/error-box/error-box';
import SearchInput from '~/shared/form-elements/search-Input/search-Input.component';
import useDebounce from '~/shared/hooks/debounce.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import CustomersList from './customers-list/customers-list.component';
import { Users } from '~/models/users';
import { useFindUserByQuery } from '~/libs/queries/users/hooks/use-find-user-by-customer-id';

interface IProps {
    formClassName?: string;
}

const FindCustomer: FC<IProps> = ({ formClassName }) => {
    const translate = useTranslations();
    const { query } = useRouter();
    const [inputValue, setInputValue] = useState('');
    const debouncedQuery = useDebounce(inputValue, 250);
    const { data, isLoading, isError } = useFindUserByQuery(debouncedQuery, 100);
    const { data: basket, refetch: refetchBasket } = useCurrentBasket();
    const dispatch = useLayoutDispatch();
    const { isImpersonated } = useLayoutState();
    const mounted = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const querySearch: string = query?.customerQuery as string;

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }

        if (!isImpersonated) {
            inputRef?.current?.focus();
        }
    }, [isImpersonated]);

    useEffect(() => {
        if (querySearch) {
            setInputValue(querySearch);
        }
    }, [querySearch]);

    const impersonateCustomer = async (customerId: string) => {
        dispatch({
            type: LayoutActionTypes.Impersonate,
            payload: { customerId },
        });
        setInputValue('');
    };

    const activateUserFromQuery = async () => {
        if (!basket?.id) {
            await refetchBasket();
            impersonateCustomer(data?.[0]?.id as string);
        } else {
            impersonateCustomer(data?.[0]?.id as string);
        }
    };

    useEffect(() => {
        if (querySearch && !isLoading && data?.length === 1) {
            activateUserFromQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, query, isLoading]);

    return (
        <>
            <form
                className={formClassName}
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    const customerToImpersonate = data?.find((x) => x?.id?.includes(inputValue));

                    if (customerToImpersonate) {
                        // Activate impersonate by picking first item in list
                        impersonateCustomer(customerToImpersonate.id as string);
                    }
                }}
            >
                <SearchInput ref={inputRef} aria-label="Search input" value={inputValue} setInputValue={setInputValue} autoFocus />
            </form>

            {isError && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}

            {debouncedQuery && !isError && (
                <CustomersList users={data as Users.IUserInfoCustomerDetails[]} fetching={!!isLoading} impersonate={impersonateCustomer} />
            )}
        </>
    );
};

export default FindCustomer;
