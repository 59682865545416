import { useEffect } from 'react';

export type UseScrollLockProps = {
    locked: boolean;
};

export function useScrollLock({ locked }: UseScrollLockProps) {
    useEffect(() => {
        if (locked) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [locked]);
}
