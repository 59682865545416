import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const updateDeliveryOption = async (basketId: string, deliveryOptionId: string | null, departmentId: number, user: Session | null) => {
    const url = createUrl({
        endpoint: endpoints.baskets.setDeliveryOption,
        localeOption: LocaleOptions.omit,
        query: {
            basketId,
            deliveryOptionId,
            departmentId,
        },
    });

    return apiClient.auth(user).put(url);
};

export default updateDeliveryOption;
