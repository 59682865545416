import { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { Basket as BasketTypes } from '~/models/basket.d';
import Button from '~/shared/buttons/button/button.component';
import DropdownContent, { DropdownItem } from '~/shared/dropdown-content/dropdown-content.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import BasketItem from './basket-item/basket-item.component';
import styles from './styled.module.scss';

interface IProps {
    basketsData: BasketTypes.ILatestInternalSavedBasketsOverview;
    virtualCustomerId: string;
    isLoading?: boolean;
}

export default function CustomerBaskets({ basketsData, isLoading, virtualCustomerId }: IProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const [controlledVisible, setControlledVisible] = useState(false);

    const handleActivateBasket = (basketId: string) => {
        setControlledVisible(false);
        dispatch({
            type: LayoutActionTypes.Impersonate,
            payload: {
                customerId: virtualCustomerId,
                basketId,
            },
        });
    };

    useEffect(() => {
        const handleKeyDown = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                setControlledVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
        trigger: 'click',
        placement: 'left-start',
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
    });

    const triggerDisabled = !basketsData?.total;

    return (
        <>
            {visible && (
                <DropdownContent
                    onClose={() => setControlledVisible(false)}
                    ref={setTooltipRef}
                    title={translate('basket.savedBaskets', 'Gemte kurve')}
                    {...getTooltipProps()}
                >
                    {basketsData?.savedBaskets?.map((basket) => (
                        <DropdownItem key={basket.id} onClick={() => handleActivateBasket(basket?.id as string)}>
                            <BasketItem basket={basket} key={basket?.id} />
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
            <Button
                onClick={(e) => e.stopPropagation()}
                className={triggerDisabled ? styles.triggerButtonDisabled : styles.triggerButton}
                ref={setTriggerRef}
                icon={<Svg name={triggerDisabled ? 'check' : 'checkout'} />}
                buttonSize="small"
                disabled={triggerDisabled}
                fetching={isLoading}
            >
                {basketsData?.total || '0'}
            </Button>
        </>
    );
}
