import clsx from 'clsx';
import { ReactNode } from 'react';
import { Svg } from '~/shared/svg';
import Button from './button/button.widget';
import styles from './styled.module.scss';

type SwiperButtonsProps = {
    prevRef: React.RefObject<HTMLButtonElement>;
    nextRef: React.RefObject<HTMLButtonElement>;
    className?: ReactNode;
};

export default function SwiperCarouselButtons({ prevRef, nextRef, className }: SwiperButtonsProps) {
    return (
        <div className={clsx(className, styles)}>
            <Button ref={prevRef} className={styles.backButton}>
                <Svg name="chevron-left" />
            </Button>

            <Button ref={nextRef} className={styles.nextButton}>
                <Svg name="chevron-right" />
            </Button>
        </div>
    );
}
