import React, { FC } from 'react';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './additional-vehicle-info.module.scss';
import { useVariantVehicleInfo } from '~/libs/queries/vehicles/hooks/use-variant-vehicle-info';

interface IAdditionalVehicleInfoProps {
    variantsIDs: string[];
    carId: string;
    shouldFetch: boolean;
}

const AdditionalVehicleInfo: FC<IAdditionalVehicleInfoProps> = ({ variantsIDs, carId, shouldFetch = false }) => {
    const translate = useTranslations();
    const { data: variantVehicleInfo, isError } = useVariantVehicleInfo({ ftzCodes: variantsIDs, carId, shouldFetch });

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return <p className={styles.text}>{variantVehicleInfo?.join(', ')}</p>;
};

export default AdditionalVehicleInfo;
