import { useCheckoutBasket } from '~/libs/queries/basket';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketError } from './basket-error';

export function BasketErrorContainer() {
    const translate = useTranslations();
    const { isError } = useCheckoutBasket();

    if (!isError) {
        return null;
    }

    return <BasketError errorMessage={translate('common.somethingWentWrong', 'Der gik noget galt.')} />;
}
