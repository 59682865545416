import React, { FC } from 'react';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import { splitArr } from '~/helpers';
import Column from '../column/column.component';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import styles from './models-grid.module.scss';
import Loader from '~/shared/loader/loader.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ErrorBox from '~/shared/error-box/error-box';

interface IModelsGridProps {
    isLoading: boolean;
    isError?: Error | null;
    data?: VehicleTypes.ICarManufacturerModelSeriesList;
}

const ModelsGrid: FC<IModelsGridProps> = ({ isLoading, isError, data }) => {
    const translate = useTranslations();
    const isTablet = useMediaQueryDetect(MediaQuery.MD);

    if (isLoading) return <Loader padding="50px 0" />;
    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    const modelSeries = data?.modelSeries || [];

    const columns = isTablet ? splitArr(modelSeries, 2) : splitArr(modelSeries, 3);

    return (
        <div className={styles.grid}>
            {columns.map((col, i) => (
                <Column list={col} key={i} />
            ))}
        </div>
    );
};

export default ModelsGrid;
