import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Umbraco } from '~/models/umbraco.d';
import { Users as UserTypes } from '~/models/users.d';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export function useExternalLogin(options?: UseQueryOptions<UserTypes.IExternalLoginsList, Error>) {
    const { isLoggedIn } = useUser();
    const { locale } = useRouter();
    const { users, createUrl } = useEndpoints();
    const { user } = useUser();

    const externalLoginUrl = createUrl({
        localeOption: LocaleOptions.omit,
        endpoint: users.externalLogins,
    });

    return useQuery<UserTypes.IExternalLoginsList, Error>({
        queryKey: queryKeys.users.externalLogin(locale),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).get(externalLoginUrl, { signal });
        },
        enabled: isLoggedIn,
        ...options,
    });
}

type UseUpdateExternalLoginProps = {
    onSuccess: () => void;
    onError: () => void;
};

interface ExternalLoginMutationParams {
    userName: string;
    widgetType?: Umbraco.WidgetTypes;
}

export function useUpdateExternalLogin({ onSuccess, onError }: UseUpdateExternalLoginProps) {
    const { locale } = useRouter();
    const { user } = useUser();
    const queryClient = useQueryClient();
    const getPath = (widgetType?: Umbraco.WidgetTypes) => {
        switch (widgetType) {
            case Umbraco.WidgetTypes.nFLoginWidget:
                return users.nfData;
            case Umbraco.WidgetTypes.haynesLoginWidget:
                return users.haynes;
            default:
                return '';
        }
    };
    const { users, createUrl } = useEndpoints();

    return useMutation({
        mutationKey: queryKeys.users.externalLogin(locale),
        mutationFn: ({ userName, widgetType }: ExternalLoginMutationParams) => {
            return apiClient.auth(user).putJSON(
                createUrl({
                    localeOption: LocaleOptions.omit,
                    endpoint: getPath(widgetType),
                }),
                { userName },
            );
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: queryKeys.users.externalLogin(locale) });
            const previousDetails = queryClient.getQueryData(queryKeys.users.externalLogin(locale));
            return { previousDetails };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(queryKeys.users.externalLogin(locale), context?.previousDetails);
            onError();
        },
        onSuccess: () => {
            onSuccess();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKeys.users.externalLogin(locale));
        },
    });
}
