import { useCallback, useState } from 'react';
import { Bff } from '~/models/bff';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import CreateDepositCredit from '~/shared/create-credit/create-deposit-credit/create-deposit-credit.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { Modal } from '~/features/primitives/modal';

// Old component: deposit-button.component.tsx
export default function W045InternalReturnDeposit(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setIsOpen(false);
    }, []);

    return (
        <div className={styles.container}>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleOpenChange}
                title={translate('createCredit.returnDeposit', 'Returner depositum')}
            >
                <CreateDepositCredit onClose={() => setIsOpen(false)} />
            </Modal>

            <ButtonCircle iconName="refresh" onClick={() => setIsOpen(true)} text={translate('createCredit.returnDeposit', 'Returner depositum')} />
        </div>
    );
}
