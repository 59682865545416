import { useRecommendedProducts } from '~/libs/queries/bff';
import { getVariantPriceRequest, useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { Bff } from '~/models/bff';
import { ProductCardSmall } from '~/page-elements/product-card/product-card-small/product-card-small.component';
import Skeleton from '~/shared/skeleton';
import { CampaignSliderSpotProducts, useCampaignSliderSpotClickTracking } from './M033ProductsCarousel';
import styles from './styled.module.scss';

//Name before: campaign-slider-raptor.component.tsx
export default function M034ProductsCarouselRaptor({
    type,
    amount,
    id,
    raptorModule,
    headline,
    campaignSpot,
}: Bff.IM034RaptorCarouselModuleViewModel) {
    const {
        data: products = [],
        isLoading,
        isError,
    } = useRecommendedProducts({
        name: raptorModule,
        count: amount,
    });
    const { data: prices } = useProductPrices(getVariantPriceRequest(products));
    const { data: stockStatus } = useStockStatus(products?.map(({ ftzCode }) => ftzCode as string));

    const handleClickTracking = useCampaignSliderSpotClickTracking({
        listName: headline,
        prices,
        promotionId: raptorModule,
        componentId: id,
        componentName: type,
        campaignSpot,
    });

    const determinedAmount = products?.length ?? amount;

    return (
        <CampaignSliderSpotProducts count={determinedAmount} headline={headline} loading={isLoading} error={isError}>
            {isLoading
                ? Array.from(Array(determinedAmount)).map((_item, i) => (
                      <div key={i} className={styles.item}>
                          <div className={styles.innerItem}>
                              <Skeleton key={i} style={{ height: '400px', margin: '15px 0' }} />
                          </div>
                      </div>
                  ))
                : products?.map((item) => {
                      const { id: itemId, title, ftzCode, brand, colli, colliLocked, image, unit, url = '#' } = item;

                      return (
                          <div key={itemId} className={styles.item}>
                              <div className={styles.innerItem}>
                                  <ProductCardSmall
                                      key={itemId}
                                      item={item}
                                      url={url}
                                      ftzCode={ftzCode}
                                      brand={brand}
                                      colli={colli}
                                      colliLocked={colliLocked}
                                      image={image}
                                      stockStatus={ftzCode ? stockStatus?.[ftzCode] : undefined}
                                      price={ftzCode ? prices?.[ftzCode] : undefined}
                                      listName={headline}
                                      unit={unit}
                                      title={title}
                                      onClick={() => handleClickTracking(item)}
                                      promotionId={raptorModule}
                                      componentId={id}
                                      componentName={type}
                                      campaignSpot={campaignSpot}
                                  />
                              </div>
                          </div>
                      );
                  })}
        </CampaignSliderSpotProducts>
    );
}
