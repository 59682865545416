import React, { FC } from 'react';
import clsx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './product-Image-title.module.scss';
import { Bff } from '~/models/bff';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { Products as ProductTypes } from '~/models/products.d';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';

interface IProps extends IWithClassName {
    image: Bff.IImage | undefined;
    name: string | undefined;
    brand: ProductTypes.IBrand | undefined;
    highlightText?: string;
}

const ProductImageTitleBrand: FC<IProps> = ({ image, brand, name, className, highlightText }) => (
    <div className={clsx(styles.container, className)}>
        <div className={styles.productImageWrapper}>
            <div className={styles.productImage}>
                {image?.url && <CdnImage layout="fill" objectFit="contain" src={image?.url} alt={image?.altText} />}
            </div>
        </div>
        <div className={styles.titleContainer}>
            <BrandLogo brand={brand} />
            <Text textStyle="body" fontWeight="regular" className={styles.title} text={name} highlightText={highlightText} />
        </div>
    </div>
);

export default ProductImageTitleBrand;
