import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';
import styles from './colli-prices.module.scss';

export interface IColliPriceItem {
    ftzCode?: string | undefined;
}

export interface IAllColliPrices {
    quantity: number;
    variant?: IColliPriceItem;
}

export interface IColliPrice {
    actualPrice: IColliPriceItem;
    allPrices: IAllColliPrices[];
}

interface IColliPricesProps {
    prices: IAllColliPrices[];
    unit?: string;
}

const ColliPrices: FC<IColliPricesProps> = ({ prices, unit }) => {
    const translate = useTranslations();
    const displayUnit = unit || translate('product.piece', 'stk');

    return (
        <ul className={styles.priceList}>
            {prices.map((price, i) => (
                <Price key={i} color="grey" upperCase>
                    {price.quantity} {displayUnit} / {price?.variant?.ftzCode}
                </Price>
            ))}
        </ul>
    );
};

export default ColliPrices;
