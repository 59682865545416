import { Collapse } from '~/shared/collapse';
import styles from './basket-checkout-delivery.module.scss';
import { CheckoutDeliveryAddressContainer } from './components/checkout-delivery-address/checkout-delivery-address.container';
import { CheckoutDeliveryCommentContainer } from './components/checkout-delivery-comment/checkout-delivery-comment.container';
import { CheckoutDeliveryMethodContainer } from './components/checkout-delivery-method/checkout-delivery-method.container';

export type BasketCheckoutDeliveryMethodProps = {
    isOpen: boolean;
};

export function BasketCheckoutDelivery({ isOpen }: BasketCheckoutDeliveryMethodProps) {
    return (
        <div className={styles.basketCheckoutDeliveryWrapper}>
            <CheckoutDeliveryMethodContainer />

            <CheckoutDeliveryAddressContainer />

            <div className={styles.basketCheckoutCollapseWrapper}>
                <Collapse isOpen={isOpen}>
                    <section className={styles.basketCheckoutCommentWrapper}>
                        <CheckoutDeliveryCommentContainer />
                    </section>
                </Collapse>
            </div>
        </div>
    );
}
