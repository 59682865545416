import clsx from 'clsx';
import { Basket as BasketTypes } from '~/models/basket.d';
import { AddToBasketCheckoutContainer } from '~/shared/add-to-basket/add-to-basket-checkout.container';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import { Svg } from '~/shared/svg';
import styles from './basket-items-line-item.module.scss';
import { ProductItemFtzCode } from './components/product-item-ftz-code/product-item-ftz-code';
import { ProductItemId } from './components/product-item-id/product-item-id';
import { ProductItemPrice } from './components/product-item-price/product-item-price';
import { ProductItemStockStatus } from './components/product-item-stock-status/product-item-stock-status';

export type BasketItemsLineItemProps = {
    item: BasketTypes.IBasketItemDto;
    carInfo?: BasketTypes.IBasketCarInfoDto;
    handleRemoveFromBasket: () => void;
};

export function BasketItemsLineItem({ item, carInfo, handleRemoveFromBasket }: BasketItemsLineItemProps) {
    const translate = useTranslations();

    const { image, brand, name, itemId, price, colli, colliLocked, count, productLink, totalPrice, stockStatus, allUnitPrices, unit } = item;

    return (
        <div className={clsx(styles.basketItemsLineItemWrapper, { [styles.basketItemsLineItemWrapperWithCarInfo]: !!carInfo })}>
            <div className={styles.basketItemsLineItemNameCell}>
                <ProductImageTitleBrand image={image} name={name} brand={brand} />
            </div>

            <div className={styles.basketItemsLineItemIdCell}>
                <ProductItemId
                    itemId={itemId}
                    link={productLink}
                    caption={translate('product.itemNo', 'Vare nr.')}
                    captionClassName={styles.basketItemsLineItemCellCaption}
                />
            </div>

            <div className={styles.basketItemsLineItemFtzCodeCell}>
                <ProductItemFtzCode
                    ftzCode={price?.ftzCode}
                    allPrices={allUnitPrices || []}
                    colli={colli}
                    colliLocked={colliLocked}
                    caption={translate('product.ftzNo', 'FTZ Nr.')}
                    captionClassName={styles.basketItemsLineItemCellCaption}
                />
            </div>

            <div className={styles.basketItemsLineItemStockStatusCell}>
                <ProductItemStockStatus
                    itemId={itemId}
                    stockStatus={stockStatus}
                    caption={translate('product.stockStatus', 'Lagerstatus')}
                    captionClassName={styles.basketItemsLineItemCellCaption}
                />
            </div>

            <div className={styles.basketItemsLineItemAddToBasketCell}>
                <AddToBasketCheckoutContainer
                    isAlwaysOpen
                    value={count}
                    syncWithBasket={false}
                    url={productLink?.url ?? ''}
                    productDetails={{
                        itemNo: itemId || '',
                        customerPrice: price?.customerPrice,
                        colli: colli,
                        colliLocked: colliLocked,
                        carId: carInfo?.carId,
                        licensePlate: carInfo?.licensePlate,
                    }}
                />
            </div>

            <div className={styles.basketItemsLineItemDeleteCell}>
                <Button onClick={handleRemoveFromBasket} buttonSize="small" buttonStyle="clean" aria-label="Delete" icon={<Svg name="bin" thick />} />
            </div>

            <div className={styles.basketItemsLineItemPriceCell}>
                <ProductItemPrice
                    displayPrice={totalPrice?.displayPrice}
                    unitPrice={
                        price?.displayPrice !== undefined
                            ? {
                                  displayPrice: price?.displayPrice,
                                  label: unit || translate('product.piece', 'Stk.'),
                              }
                            : undefined
                    }
                />
            </div>
        </div>
    );
}
