import { useStaticContent } from '~/libs/queries/bff';
import { useSavedBasketsTotal } from '~/libs/queries/basket/hooks/use-saved-basket-total';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketHeaderSavedBasketsLink } from './basket-header-saved-baskets-link';

export function BasketHeaderSavedBasketsLinkContainer() {
    const { data: { urlMappings } = {} } = useStaticContent();
    const translate = useTranslations();
    const { data, isLoading } = useSavedBasketsTotal();

    if (isLoading) {
        return null;
    }

    return (
        <BasketHeaderSavedBasketsLink
            url={urlMappings?.savedBaskets}
            label={translate('basket.seeMyTotalSavedBaskets', 'Se mine [total] gemte kurve').replace('[total]', data?.total?.toString() || '0')}
        />
    );
}
