import React, { useState, FC, Fragment, useCallback, useMemo, useRef, useEffect } from 'react';
import styles from './highlighted-filters.module.scss';
import FilterGroupList from './filter-group-list/filter-group-list.component';
import Button from '~/shared/buttons/button/button.component';
import { Products, Products as ProductTypes } from '~/models/products';
import { useFilterActions, useFilterValues } from '../facets-filter/filter.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { MultiSelect } from '~/shared/multi-select';
import { Combobox } from '@headlessui/react';
import Text from '~/shared/text/text.component';
import cx from 'clsx';
import IFacetValue = Products.IFacetValue;

export type HighlightedFilterProps = { filter: ProductTypes.IFacet; focus?: boolean };

export function HighlightedFilter({ filter, focus = false }: HighlightedFilterProps) {
    const { name: label } = filter;
    const { options, selected } = useFilterValues(filter);
    const { setQueryParams } = useFilterActions();

    const [term, setTerm] = useState('');
    const filtered = useMemo(() => {
        return !term.length ? options : options.filter((item) => item.name?.toLowerCase().includes(term.toLowerCase()));
    }, [options, term]);

    const placeholder = selected.length ? selected.map((item) => item.value).join(', ') : 'Vælg';
    const handleChange = useCallback(
        (updatedActiveFacets: ProductTypes.IFacetValue[]) => {
            if (!filter?.key) {
                return;
            }

            setQueryParams(filter.key, updatedActiveFacets);
        },
        [filter, setQueryParams],
    );

    const inputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key !== 'Tab' || !filter.key || !filtered[0] || !term.length) {
                return;
            }

            const match = filtered.find((item) => item.count > 0);

            if (!match) {
                return;
            }

            setTerm('');
            setQueryParams(filter.key, [...selected, match]);
        },
        [filter.key, filtered, selected, setQueryParams, term.length],
    );

    useEffect(() => {
        if (!inputRef.current || !focus) {
            return;
        }

        inputRef.current.focus();
    }, []);

    useEffect(() => {
        const { current: input } = inputRef;

        if (!input) {
            return;
        }

        input.addEventListener('keydown', handleKeyDown);

        return () => {
            input.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <MultiSelect<IFacetValue>
            inputRef={inputRef}
            onChange={handleChange}
            value={selected}
            label={label}
            placeholder={placeholder}
            onTermChange={setTerm}
            displayValue={(value) => value?.name ?? value.value ?? ''}
            onClickOutside={() => setTerm('')}
        >
            {filtered.map((item) => (
                <Combobox.Option key={item.value} value={item} as={Fragment} disabled={item.count === 0 && !item.selected}>
                    {({ selected, active }) => (
                        <li
                            className={cx(styles.multiSelectItem, {
                                [styles.selected]: selected,
                                [styles.active]: active,
                            })}
                        >
                            {item.name}

                            <Text itemType="span" textStyle="monoSmall" className={styles.count}>
                                ({item.count})
                            </Text>
                        </li>
                    )}
                </Combobox.Option>
            ))}
        </MultiSelect>
    );
}

type HighlightedFiltersProps = {
    facets: ProductTypes.IFacet[];
};

const HighlightedFilters: FC<HighlightedFiltersProps> = ({ facets = [] }) => {
    const translate = useTranslations();
    const { clearQueryParams } = useFilterActions();

    const [hiddenCount, setHiddenCount] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOpen = () => setIsOpen((prevIsOpen) => !prevIsOpen);

    if (!facets.length) return null;

    return (
        <div className={styles.container}>
            <FilterGroupList isOpen={isOpen} onHiddenCountChange={setHiddenCount}>
                {facets?.map((filter, i) => <HighlightedFilter key={filter.key} filter={filter} focus={i === 0} />)}
            </FilterGroupList>
            <div className={styles.buttons}>
                {hiddenCount && hiddenCount > 0 ? (
                    <Button onClick={handleClickOpen} buttonStyle="secondaryLight">
                        {isOpen ? translate('filter.lessFilters', 'Færre filtre') : translate('filter.moreFilters', 'Flere filtre')}
                    </Button>
                ) : null}
                <Button buttonStyle="clean" onClick={clearQueryParams} disabled={false}>
                    {translate('filter.clearHighlightedFilters', 'Ryd filtre')}
                </Button>
            </div>
        </div>
    );
};

export default HighlightedFilters;
