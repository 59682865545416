import { useEffect, useState } from 'react';
import { isBrowser, isTest } from '~/helpers';
import { MediaQuery } from './media-query-detect.types';

export function useMediaQueryDetect(mediaQuery: MediaQuery): boolean {
    const [isMatch, setIsMatch] = useState(false);

    function onMatchMedia(e: MediaQueryListEvent): void {
        setIsMatch(e.matches);
    }

    useEffect(() => {
        if (!isBrowser || isTest) {
            return;
        }

        const mql = window.matchMedia(mediaQuery);
        mql.addListener(onMatchMedia);

        setIsMatch(mql.matches);

        return () => {
            mql.removeListener(onMatchMedia);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isMatch;
}
