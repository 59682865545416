import { endpoints, createUrl, LocaleOptions } from '../service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

export const CarChassisRequest = (formData: FormData, locale: string, user?: Session | null) => {
    const url = createUrl(
        {
            endpoint: endpoints.vehicles.chassisRequest,
            localeOption: LocaleOptions.path,
        },
        locale,
    );

    return apiClient.auth(user).post(url, {
        body: formData,
    });
};
