import { useStaticContent } from '~/libs/queries/bff';
import { Umbraco } from '~/models/umbraco.d';
import SecondaryNavigation from './secondary-navigation/secondary-navigation.component';

export default function OverviewNavigation() {
    const { data: staticContent } = useStaticContent();
    const links = staticContent?.myFtz?.filter((link: any) => !link.widgetTypes.includes(Umbraco.WidgetTypes.manualWebordersOverviewWidget));

    return <SecondaryNavigation hideOnMobile links={links} addCreditButton />;
}
