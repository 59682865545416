import React, { useCallback, useState } from 'react';
import { Products as ProductTypes } from '~/models/products.d';
import Category from '../category/category.component';
import styles from './styled.module.scss';
import rootStyles from '../styled.module.scss';
import { Modal } from '~/features/primitives/modal';
import ModalContent, { IModalContentData } from '../modal-content/modal-content.component';

import { getAllCategoryChildren, ICategoryWithParents, mapCategoriesWithParents } from '../sales-price-markup.helpers';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Heading from '~/shared/heading/heading.component';
import PageTitle from '~/shared/page-title/page-title.component';
import { useSalesPriceMarkup } from '~/libs/queries/products/hooks/use-sales-price-markup';

interface IProps {
    categoriesList: ProductTypes.ICategory[];
}

export default function Categories({ categoriesList }: IProps) {
    const translate = useTranslations();
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const { data: salesPriceMarkupData, isLoading, refetch } = useSalesPriceMarkup();
    const [modalContent, setModalContent] = useState<IModalContentData | null>(null);

    const selectAllChecked = categoriesList.length === selectedCategories.length;

    const categoriesListFlat: ICategoryWithParents[] = [];

    categoriesList.forEach((cat) => {
        categoriesListFlat.push(cat, ...getAllCategoryChildren(cat));
    });

    const categoriesWithParents = mapCategoriesWithParents(categoriesList);

    const handleRemove = (id: number) => {
        setSelectedCategories(selectedCategories.filter((c) => c !== id));
    };

    const handleCheckBoxChange = () => {
        if (selectAllChecked) {
            setSelectedCategories([]);
        } else {
            setSelectedCategories(categoriesList.map((c) => c.id));
        }
    };

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setModalContent(null);
    }, []);

    return (
        <div className={styles.root}>
            <Modal
                position="right"
                open={!!modalContent}
                onOpenChange={handleOpenChange}
                title={translate('salesPriceMarkup.setPriceLevel', 'Sæt prisniveau')}
            >
                <ModalContent
                    afterSubmit={() => {
                        setModalContent(null);
                        setSelectedCategories([]);
                    }}
                    mutate={refetch}
                    content={modalContent as IModalContentData}
                    onRemoveItem={handleRemove}
                    categoriesListFlat={categoriesListFlat}
                    salesPriceMarkupData={salesPriceMarkupData?.categoriesSalesPriceMarkup || []}
                />
            </Modal>

            <PageTitle className={styles.header}>
                <Heading tagName="h3">{translate('salesPriceMarkup.pageTitle', 'Priskalkulering')}</Heading>
                <div className={styles.headerButtons}>
                    {selectedCategories.length ? (
                        <Button
                            buttonStyle="clean"
                            onClick={() =>
                                setModalContent({
                                    items: selectedCategories,
                                    isRoot: true,
                                })
                            }
                        >
                            {translate('salesPriceMarkup.setPriceLevelForSelectedCount', 'Sæt prisniveau for valgte ([count])').replace(
                                '[count]',
                                selectedCategories.length,
                            )}
                        </Button>
                    ) : null}
                    <Checkbox className={styles.checkbox} checked={selectAllChecked} onChange={handleCheckBoxChange}>
                        {translate('common.selectAll', 'Vælg alle')}
                    </Checkbox>
                </div>
            </PageTitle>
            <div className={styles.tableHeader}>
                <div className={rootStyles.grid}>
                    <div className={styles.tableHeaderItem}>
                        <span className="caption">{translate('salesPriceMarkup.categoryName', 'Produktkategori')}</span>
                    </div>
                    <div className={styles.tableHeaderItem}>
                        <span className="caption">{translate('salesPriceMarkup.registeredDate', 'Redigeret d')}</span>
                    </div>
                    <div className={styles.tableHeaderItem}>
                        <span className="caption">{translate('salesPriceMarkup.byUser', 'Af bruger')}</span>
                    </div>
                    <div className={styles.tableHeaderItem}>
                        <span className="caption">{translate('salesPriceMarkup.currentPrice', 'Nuværende pris')}</span>
                    </div>
                    <div className={styles.tableHeaderItem} />
                    <div className={styles.tableHeaderItem} />
                </div>
            </div>
            <ul className={styles.categoriesList}>
                {categoriesWithParents?.map((category) => (
                    <Category
                        setModalContent={setModalContent as React.Dispatch<React.SetStateAction<IModalContentData>>}
                        categoriesListFlat={categoriesListFlat}
                        setSelectedCategories={setSelectedCategories}
                        selectedCategories={selectedCategories}
                        fetchingPrices={isLoading}
                        prices={salesPriceMarkupData?.categoriesSalesPriceMarkup}
                        isRoot
                        key={category?.id}
                        category={category}
                    />
                ))}
            </ul>
        </div>
    );
}
