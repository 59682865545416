import { create } from 'zustand';

export type TopmotiveModalStep = 'start' | 'form';
export type TopmotiveModalDetails = {
    productId: string;
    productName: string;
    manufacturerName: string;
    manufacturerId: number;
};

// If the modal open state is `false`, then it is closed.
export type TopmotiveModalOpenState = 'request' | 'product' | false;

export type TopmotiveModalState = {
    open: TopmotiveModalOpenState;
    step: TopmotiveModalStep;
    details?: TopmotiveModalDetails;
};

export type TopmotiveModalActions = {
    setOpen: (open: TopmotiveModalOpenState) => void;
    setDetails: (details: TopmotiveModalDetails) => void;
    navigate: (step: TopmotiveModalStep) => void;
    reset: () => void;
};

export type TopmotiveModalStore = TopmotiveModalState & TopmotiveModalActions;

const initialState: TopmotiveModalState = {
    open: false,
    step: 'start',
    // When adding a product to the basket from the plugin, we store the id and the name of the product.
    // The reason for this is that we can use the values in the OE request form,
    // in case of a not found on product lookup.
    details: undefined,
};

export const useTopmotiveModalState = create<TopmotiveModalStore>((setState) => ({
    ...initialState,
    setOpen: (open) =>
        setState((prevState) => ({
            ...prevState,
            open,
        })),
    setDetails: (details) =>
        setState((prevState) => ({
            ...prevState,
            details,
        })),
    navigate: (step) => setState((prevState) => ({ ...prevState, step })),
    reset: () => setState(initialState),
}));
