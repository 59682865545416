import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const getTireCalculations = (postData: ProductTypes.ITireCalculationRequest, user: Session | null): Promise<ProductTypes.ITireCalculation> => {
    const url = createUrl({
        endpoint: endpoints.products.tireCalculation,
        localeOption: LocaleOptions.path,
    });

    return apiClient.auth(user).post(url, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
    });
};

export default getTireCalculations;
