import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { CdnImage } from '~/shared/image/cdn-image';
import Button from '~/shared/buttons/button/button.component';
import styles from './styled.module.scss';
import Heading from '~/shared/heading/heading.component';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';
import { GA4CampaignClicked } from '~/libs/ga4';
import { ImageProps } from 'next/image';

const TextMedia = ({
    callToAction,
    ctaBackgroundColor,
    ctaTextColor,
    backgroundColor,
    image,
    imageFitType = 'cover',
    imageHeightType,
    richText,
    textColor,
    headline,
    richTextAlignment,
    campaignSpot,
    id,
    type,
}: Bff.IM101RichTextAndMediaModule) => {
    const { profile } = useUser();
    const link = callToAction;
    const linkUrl = link?.url;
    const buttonLinkUrl = linkUrl?.replace?.('##customer_id##', profile?.customer_id as string);
    const sizes = ['(max-width: 420px) 420px', '(min-width: 420px AND max-width: 768px) 768px', '(min-width: 768px) 1024px'].join(', ');

    const onClick = useCallback((link: Bff.ILinkViewModel, campaignId?: string, campaignSpot?: string) => {
        GA4CampaignClicked({
            to: link?.url || '#',
            campaignId,
            campaignSpot,
            campaignType: type,
        });
    }, []);

    return (
        <section
            className={clsx(styles.section, styles.fullWidth, {
                [styles.small]: imageHeightType === 'Small',
                [styles.dynamic]: imageHeightType === 'Dynamic',
                [styles.flip]: richTextAlignment === 'Left',
            })}
        >
            <div className={styles.media}>
                {image?.src ? (
                    <CdnImage
                        sizes={sizes}
                        src={image.src}
                        alt={image.alt}
                        layout="fill"
                        objectFit={imageFitType as ImageProps['objectFit']}
                        quality={65}
                    />
                ) : null}
            </div>

            <div
                className={styles.text}
                style={{
                    backgroundColor,
                    color: textColor,
                }}
            >
                <Heading tagName="h2" displayStyle="h1" className={styles.title}>
                    {headline}
                </Heading>

                {richText && (
                    <div className={styles.body}>
                        <RawHtmlComponent content={richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />
                    </div>
                )}

                {link && (
                    <Button
                        className={styles.link}
                        style={{
                            backgroundColor: `${ctaBackgroundColor}`,
                            color: `${ctaTextColor}`,
                        }}
                        href={buttonLinkUrl}
                        target={link.target}
                        onClick={() => onClick(link, id, campaignSpot)}
                    >
                        {link.title}
                    </Button>
                )}
            </div>
        </section>
    );
};

export default TextMedia;
