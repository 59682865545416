import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useCallback, useState } from 'react';
import { EasingFramer, TimingFramer } from '~/constants/animation';
import { IWithClassName } from '~/models/dev';
import { Orders as OrderTypes } from '~/models/orders.d';
import { Products as ProductTypes } from '~/models/products.d';
import CreateCredit, { ICreateCreditProps } from '~/shared/create-credit/create-credit.component';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import { Link } from '~/shared/link';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import { Modal } from '~/features/primitives/modal';
import { Svg } from '~/shared/svg';
import Button from '../buttons/button/button.component';
import useTranslations from '../hooks/use-translations.hook';
import Text from '../text/text.component';
import styles from './previous-orders.module.scss';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';

interface IPreviousOrdersProps extends IWithClassName {
    invoices?: OrderTypes.IVariantInvoiceDetails[];
    productItem?: ProductTypes.IVariant;
}

const PreviousOrders: FC<IPreviousOrdersProps> = ({ className, productItem, invoices = [] }) => {
    const translate = useTranslations();
    const invoicesLength = invoices?.length;
    const [selectedItem, setSelectedItem] = useState<ICreateCreditProps | null>(null);
    const { data: prices } = useProductPrices([
        {
            articleId: productItem?.itemNo,
            quantity: productItem?.colli || 1,
        },
    ]);
    const price = prices ? prices[productItem?.itemNo as string] : undefined;

    const isPortable = useMediaQueryDetect(MediaQuery.LG);
    const containerHeight = isPortable ? 350 : 200;

    const itemsVisible = isPortable ? 4 : 3;

    const isCollapsible = invoicesLength > itemsVisible + 1;
    const [isCollapsed, setCollapsed] = useState(isCollapsible);

    const handleModalOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setSelectedItem(null);
    }, []);

    return (
        <div className={className}>
            <Modal
                position="right"
                open={!!selectedItem}
                onOpenChange={handleModalOpenChange}
                title={translate('overview.createCredit', 'Opret kreditering')}
            >
                {selectedItem?.items ? (
                    <CreateCredit
                        items={selectedItem.items}
                        invoiceDate={selectedItem.invoiceDate}
                        invoiceId={selectedItem.invoiceId}
                        onClose={() => setSelectedItem(null)}
                    />
                ) : null}
            </Modal>
            <motion.div
                initial={{ height: isCollapsed ? containerHeight : 'auto' }}
                animate={{ height: isCollapsed ? containerHeight : 'auto' }}
                transition={{
                    duration: TimingFramer.STANDARD,
                    ease: EasingFramer.BOUNCE_IN,
                }}
                className={clsx(styles.tableWrapper, {
                    [styles.isExpandable]: isCollapsible,
                    [styles.tableWrapperCollapsed]: isCollapsed,
                })}
            >
                <div>
                    {invoices?.map((row, i) => {
                        const alreadyReturned = row.possibleToCreditQuantity === 0 && row.itemQuantity !== 0;
                        return (
                            <div className={styles.orderWrapper} key={`${row.invoiceId}-${i}`}>
                                <Link href={row?.carInfo?.url}>
                                    <LicensePlate licensePlate={row?.carInfo?.licensePlate} />
                                </Link>

                                <div className={styles.returnButtonWrapper}>
                                    <Button
                                        disabled={alreadyReturned}
                                        icon={alreadyReturned ? null : <Svg name="return-alt" />}
                                        buttonStyle="clean"
                                        buttonSize={alreadyReturned ? 'default' : 'small'}
                                        type="button"
                                        onClick={() => {
                                            setSelectedItem({
                                                carInfo: row.carInfo,
                                                invoiceDate: row.invoiceDateFormatted as string,
                                                invoiceId: row.invoiceId,
                                                items: [
                                                    {
                                                        ...row,
                                                        // overwrites the inconsistent types
                                                        quantity: row.itemQuantity,
                                                        creditedQuantity: row.itemCreditedQuantity,

                                                        brand: productItem?.brand,
                                                        description: productItem?.description,
                                                        itemId: productItem?.itemNo,
                                                        image: productItem?.images?.[0] as OrderTypes.IImage | undefined,
                                                        title: productItem?.title,
                                                        colli: productItem?.colli as number,
                                                        colliLocked: !!productItem?.colliLocked,
                                                        grossFormatted: price?.actualPrice?.displayPrice,
                                                        grossUnitFormatted: productItem?.unit,
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        //@ts-ignore
                                                        hasDeposit: price.actualPrice.deposit > 0,
                                                        url: productItem?.url,
                                                        isDepositProduct: false,
                                                    },
                                                ],
                                            });
                                        }}
                                    >
                                        {alreadyReturned ? translate('overview.credited', 'Krediteret') : translate('overview.return', 'Returnér')}
                                    </Button>
                                </div>

                                <div className={styles.caption}>{translate('common.date', 'dato')}</div>
                                <div className={styles.invoiceWrapper}>
                                    <Text textStyle="monoMedium">{row.invoiceDateFormatted}</Text>
                                </div>

                                <div className={styles.caption}>{translate('product.quantity', 'antal')}</div>
                                <div className={styles.quantityWrapper}>
                                    <Text textStyle="monoMedium">{row.itemQuantity}</Text>
                                </div>

                                <div className={styles.caption}>{translate('overview.invoiceNumber', 'Faktura nr.')}</div>
                                <div className={styles.detailedWrapper}>
                                    <Link href={row.invoiceDetailsUrl}>
                                        <Text textStyle="monoMedium">{row.invoiceId}</Text>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </motion.div>

            {isCollapsible && (
                <AnimatePresence initial={false}>
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: TimingFramer.SLOW,
                            ease: EasingFramer.BOUNCE_IN,
                        }}
                        type="button"
                        onClick={() => setCollapsed((prev) => !prev)}
                        className={styles.collapseButton}
                    >
                        {isCollapsed ? `${translate('common.showAll', 'Vis alle')} (${invoicesLength})` : translate('common.showLess', 'Vis mindre')}
                    </motion.button>
                </AnimatePresence>
            )}
        </div>
    );
};

export default PreviousOrders;
