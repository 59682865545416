import { useLayoutState } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { CmsData } from '~/models/cms-data';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';

export type UseOEMatchesVariantsProps = {
    variantIds?: number[];
    shouldFetch?: boolean;
};

export function useOEMatchesVariants({ variantIds = [], shouldFetch = true }: UseOEMatchesVariantsProps) {
    const { isLoggedIn, user } = useUser();
    const { vehicle, expertMode } = useLayoutState();
    const { locale } = useRouter();

    return useQuery<{ [key: string]: string }, CmsData.IError>({
        queryKey: queryKeys.products.oeMatchesVariants(locale, variantIds, vehicle, !!expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: endpoints.products.oeMatchesVariants,
                        localeOption: LocaleOptions.path,
                        query: {
                            carModelTypeId: vehicle?.carId,
                            licensePlate: vehicle?.licensePlate?.number,
                            licensePlateCountry: vehicle?.licensePlate?.country,
                            vin: vehicle?.vin,
                            includeExpertProducts: !!expertMode,
                        },
                    },
                    locale,
                ),
                variantIds,
                { signal },
            );
        },
        enabled: isLoggedIn && shouldFetch && !!vehicle && !!variantIds.length,
    });
}
