import { endpoints, createUrl, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const submitManualOrder = async (webOrdersId: string[], user: Session | null) => {
    // const url = replaceLocale(`${endpoints.orders.getManualWebOrders}/submit`, locale)

    const url = createUrl({
        endpoint: `${endpoints.orders.getManualWebOrders}/submit`,
        localeOption: LocaleOptions.path,
    });

    return apiClient.auth(user).postJSON(url, webOrdersId);
};

export default submitManualOrder;
