import React, { FC } from 'react';
import Skeleton from '../skeleton.component';

interface IProps {
    rows: number;
    colSpan: number;
    height?: string;
    width?: string;
}

const SkeletonTableRows: FC<IProps> = ({ rows, colSpan, width = '100%', height = '15px' }) => (
    <>
        {[...Array(rows).keys()].map((row) => (
            <tr key={row}>
                <td colSpan={colSpan}>
                    <Skeleton style={{ width, height }} />
                </td>
            </tr>
        ))}
    </>
);

export default SkeletonTableRows;
