import debounce from 'lodash/debounce';
import { Controller, useFormContext } from 'react-hook-form';
import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket, useUpdateOrderDetails } from '~/libs/queries/basket';
import useActiveUser from '~/libs/use-active-user';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import type { ICheckoutValidationProps } from './../../../../basket-content.container';
import styles from './basket-footer-comments.module.scss';

export function BasketFooterCommentsContainer() {
    const translate = useTranslations();

    const { data: basket } = useCheckoutBasket();
    const { mutate: updateOrderDetails } = useUpdateOrderDetails();

    const { activeProfile } = useActiveUser();
    const { selectedDepartment } = useLayoutState();

    const { clearErrors } = useFormContext<ICheckoutValidationProps>();

    const handleChangeRequisition = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!basket?.id) {
            console.error('Could not update requisition. Basket was undefined');
            return;
        }

        clearErrors('requisition');

        updateOrderDetails({
            basketId: basket.id,
            departmentId: selectedDepartment?.id,
            details: {
                ...basket.orderDetails,
                requisition: e.target.value,
            },
        });
    }, 500);

    const handleChangeCaseNo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!basket) {
            console.error('Could not update caseNo. Basket was undefined');
            return;
        }

        clearErrors('caseNo');

        updateOrderDetails({
            basketId: basket.id,
            departmentId: selectedDepartment?.id,
            details: {
                ...basket.orderDetails,
                caseNo: e.target.value,
            },
        });
    }, 500);

    // This should never happen because footer is only rendered with active basket and user
    if (!basket || !activeProfile) {
        return null;
    }

    const requiredErrorMessage = translate('common.fieldMustBeFilled', 'Feltet skal udfyldes');

    return (
        <div className={styles.basketFooterCommentsWrapper}>
            <Controller<ICheckoutValidationProps>
                rules={{ required: activeProfile.requisitionRequired }}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <Input
                        {...field}
                        errorMessage={fieldState.error ? requiredErrorMessage : undefined}
                        onChange={(e) => {
                            onChange(e);
                            handleChangeRequisition(e);
                        }}
                        label={translate('basket.requisition', 'Rekvisition')}
                    />
                )}
                name="requisition"
            />

            <Controller<ICheckoutValidationProps>
                rules={{ required: activeProfile.caseNumberRequired }}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <Input
                        {...field}
                        errorMessage={fieldState.error ? requiredErrorMessage : undefined}
                        onChange={(e) => {
                            onChange(e);
                            handleChangeCaseNo(e);
                        }}
                        label={translate('basket.caseNo', 'Sags nr.')}
                    />
                )}
                name="caseNo"
            />
        </div>
    );
}
