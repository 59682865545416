import { Orders as OrdersTypes } from '~/models/orders.d';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import Invoice from '../invoice/invoice.component';

type IInvoiceListProps = {
    invoices: OrdersTypes.IInvoiceViewModel[];
    fetching: boolean;
    searchQuery: string;
    error?: Error;
};

export default function InvoiceList({ invoices = [], fetching, searchQuery, error }: IInvoiceListProps) {
    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!invoices?.length) return <OverviewNoResult />;

    return (
        <div>
            {invoices.map((invoice) => (
                <Invoice key={`${invoice?.id}-${invoice?.number}`} invoice={invoice} searchQuery={searchQuery} />
            ))}
        </div>
    );
}
