import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket, useUpdateDeliveryTime } from '~/libs/queries/basket';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import { IWithClassName } from '~/models/dev.d';
import Input from '~/shared/form-elements/input/input.component';
import Select from '~/shared/form-elements/select/select.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../../../use-basket-states';

export type CheckoutDeliveryTimeSlotDaysRequired = [BasketTypes.ITimeSlotDayDto, ...BasketTypes.ITimeSlotDayDto[]]; // array with min 1 length

export type CheckoutDeliveryTimeProps = IWithClassName & {
    deliveryAddressId: string | undefined;
    timeSlotDays: CheckoutDeliveryTimeSlotDaysRequired;
};

export function CheckoutDeliveryTimeContainer({ deliveryAddressId, timeSlotDays }: CheckoutDeliveryTimeProps) {
    const translate = useTranslations();
    const { selectedDepartment } = useLayoutState();
    const { user } = useUser();
    const { data: basket, refetch: refetchBasket } = useCheckoutBasket();
    const { mutateAsync: mutateDeliveryTimeAsync } = useUpdateDeliveryTime();
    const { setSplitDeliveryOption, setIsCheckoutFetching } = useBasketStates();
    const {
        formState: { errors },
        clearErrors,
        register,
    } = useFormContext();

    const [selectedDay, _setSelectedDay] = useState<BasketTypes.ITimeSlotDayDto | undefined>(undefined);
    const [selectedTime, _setSelectedTime] = useState<BasketTypes.ITimeSlotDto | undefined>(undefined);

    const setSelectedDay = (day: BasketTypes.ITimeSlotDayDto | undefined) => {
        _setSelectedDay(day);

        const selectedDateTime = day?.timeSlots?.find((timeSlot) => timeSlot.available);
        setSelectedTime(selectedDateTime);
    };

    const setSelectedTime = async (time: BasketTypes.ITimeSlotDto | undefined) => {
        if (!basket) {
            return;
        }

        clearErrors('deliveryDateTime');
        clearErrors('splitDeliveryOption');

        setIsCheckoutFetching(true);

        _setSelectedTime(time);

        await mutateDeliveryTimeAsync({
            basketId: basket.id,
            timeSlot: time,
            departmentId: selectedDepartment?.id,
            user,
        });

        await refetchBasket();
        setIsCheckoutFetching(false);
        setSplitDeliveryOption(BasketTypes.DeliveryType.UnSpecified);
    };

    useEffect(() => {
        setSelectedDay(timeSlotDays[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlotDays]);

    // This should not happen. Checkout will not render if no currentBasket
    if (!basket) {
        return null;
    }

    const handleChangeDay = (day: string) => {
        const selected = timeSlotDays.find((timeSlot) => timeSlot.displayDay === day);
        setSelectedDay(selected);
    };

    const handleChangeTime = (time: string) => {
        const selected = selectedDay?.timeSlots?.find((timeSlot) => timeSlot.displayTime === time);
        setSelectedTime(selected);
    };

    const { userMustChoseDeliveryTime } = basket.deliveryDetails?.validation || {};
    const hasDeliveryDateAndTime = basket.deliveryDetails?.deliveryDateAndTime;

    const selectedDayTimeSlotsAvailable = selectedDay?.timeSlots?.filter((timeSlot) => timeSlot.available);

    return (
        <>
            <Select onChange={(e) => handleChangeDay(e.target.value)} value={selectedDay?.displayDay} isValid={!errors?.deliveryDateTime}>
                <option disabled>{translate('common.selectDate', 'Vælg dato')}</option>

                {timeSlotDays.map((day, i) => (
                    <option key={`${day}-${i}-${deliveryAddressId}`}>{day.displayDay}</option>
                ))}
            </Select>

            {selectedDayTimeSlotsAvailable?.length ? (
                <Select
                    {...register('deliveryDateTime', {
                        required: userMustChoseDeliveryTime && !hasDeliveryDateAndTime,
                    })}
                    value={selectedTime?.displayTime}
                    onChange={(e) => {
                        handleChangeTime(e.target.value);
                    }}
                    isValid={!errors?.deliveryDateTime}
                >
                    <option disabled>{translate('common.selectTime', 'Vælg tid')}</option>

                    {selectedDayTimeSlotsAvailable.map((time, i) => (
                        <option key={`${time}-${i}-${deliveryAddressId}`}>{time.displayTime}</option>
                    ))}
                </Select>
            ) : (
                <Input aria-label={translate('common.selectTime', 'Vælg tid')} disabled />
            )}
        </>
    );
}
