import { useLayoutState } from '~/context/layout.context';
import { useDeliveryAddresses } from '~/libs/queries/basket/hooks/use-delivery-addresses';
import { useVehicleDetails } from '~/libs/queries/vehicles/hooks/use-vehicle-details';
import useActiveUser from '~/libs/use-active-user';
import { Bff } from '~/models/bff';
import { Vehicles } from '~/models/vehicles';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PageTitle from '~/shared/page-title/page-title.component';
import OEForm from './oe-form/oe-form.component';

// Old component: oe-spare-parts.component.tsx
export default function W073OESpareParts(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    const { vehicle } = useLayoutState();

    const { data: deliveryAddresses = [], isError: isErrorDeliveryAddresses, isLoading: isLoadingDeliveryAddresses } = useDeliveryAddresses();

    const { activeProfile, isLoading: isLoadingUser, error: userError } = useActiveUser();

    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicle,
        error: vehicleError,
    } = useVehicleDetails(vehicle?.licensePlate as Vehicles.ILicensePlate, vehicle?.carId as string, vehicle?.vin as string);

    const isLoading = isLoadingUser || isLoadingVehicle || isLoadingDeliveryAddresses;
    const error = vehicleError || userError || isErrorDeliveryAddresses;

    const {
        email,
        userName,
        customer: { customerId = null, name: companyName = '', address = '', zipCodeAndCity = '', phoneNumber = '', primaryDepartmentId = null } = {},
    } = activeProfile || {};

    const addresses = [`${address}, ${zipCodeAndCity}`, ...deliveryAddresses?.map((da) => da.displayAddress)];

    return (
        <>
            <PageTitle>
                <Heading tagName="h1" displayStyle="h3">
                    {translate('oeSpareParts.oeRequest', 'OE Forespørgsel')}
                </Heading>
            </PageTitle>
            {isLoading && <Loader padding="50px" />}
            {error && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}
            {!isLoading && !error && (
                <OEForm
                    customerDetails={{
                        email: email as string,
                        customerId: customerId as number,
                        companyName,
                        phoneNumber,
                        primaryDepartmentId: primaryDepartmentId as number,
                        userName: userName as string,
                        addresses: addresses as string[],
                    }}
                    vehicleDetails={vehicleDetails as Vehicles.IVehicleDetails}
                />
            )}
        </>
    );
}
