import clsx from 'clsx';
import React, { FC } from 'react';
import { Svg } from '~/shared/svg';
import styles from './add-favorite.module.scss';
import { IWithClassName } from '~/models/dev';
import Loader from '~/shared/loader/loader.component';
import { Products as ProductTypes } from '~/models/products.d';
import useTranslations from '../hooks/use-translations.hook';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4ModifyWishlist } from '~/libs/ga4';
import useUser from '~/libs/use-user';
import { getListName, useBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
import { FavoritePlacement, useAddFavorite, useFavoriteList, useFavoriteStatusList, useRemoveFavorite } from '~/libs/queries/favorites';
import useActiveUser from '~/libs/use-active-user';

export type FavoriteButtonProps = {
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    handleClick: () => void;
    status: ProductTypes.FavoriteStatus | undefined;
};

export const FavoriteButton = React.forwardRef<HTMLButtonElement, FavoriteButtonProps>(
    ({ status, handleClick, loading, disabled, className }, ref) => (
        <button
            aria-label="Add favorite"
            ref={ref}
            disabled={disabled}
            type="button"
            className={clsx(styles.button, className)}
            onClick={handleClick}
        >
            {loading && <Loader padding="0" size="25px" borderWidth="2px" className={styles.loader} />}
            <Svg
                name="star"
                className={clsx(styles.icon, {
                    [styles.ownFavoriteIcon]: status === 1,
                    [styles.chainFavoriteIcon]: status === 2,
                    [styles.inactive]: loading,
                })}
            />
        </button>
    ),
);

interface IProps extends IWithClassName {
    itemId?: string;
    product?: ProductTypes.IFavoriteProduct | ProductTypes.IVariant;
    price: ProductTypes.IVariantPrice | undefined;
    placement: FavoritePlacement;
    itemNoList: string[];
}

const AddFavorite: FC<IProps> = ({ className, product, price, itemId, placement, itemNoList }) => {
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();

    const { profile } = useUser();
    const { activeProfile } = useActiveUser();

    const { data: breadcrumb } = useBreadcrumb();
    const { data: { favoriteProducts } = {} } = useFavoriteList({ userId: activeProfile?.id, type: 'MyFavorite' });
    const { data: favoriteStatus, isLoading: isLoadingFavoriteStatus } = useFavoriteStatusList({
        itemNoList: itemId ? [itemId] : undefined,
        userId: activeProfile?.id,
        placement,
    });

    const handleError = () => {
        dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
    };

    const { mutate: addFavorite } = useAddFavorite(
        {
            userId: activeProfile?.id,
            placement,
            itemNoList,
        },
        {
            onError: handleError,
        },
    );

    const { mutate: removeFavorite } = useRemoveFavorite(
        {
            userId: activeProfile?.id,
            placement,
            itemNoList,
        },
        {
            onError: handleError,
        },
    );

    const status = itemId && favoriteStatus ? favoriteStatus[itemId] : undefined;

    const handleClick = async () => {
        if (!itemId) {
            return;
        }

        const isMyFavorite = status === 1; // MyFavorite

        if (isMyFavorite) {
            removeFavorite(itemId);
        } else {
            addFavorite(itemId);
        }

        if (product) {
            GA4ModifyWishlist(product, {
                type: isMyFavorite ? 'remove' : 'add',
                currency: profile?.currency ?? '',
                price: price?.customerPrice || 0,
                listName: getListName(breadcrumb, product.title),
                content: favoriteProducts?.map((fav) => fav.itemId).join(),
            });
        }
    };

    return (
        <FavoriteButton
            handleClick={handleClick}
            status={status}
            className={className}
            loading={isLoadingFavoriteStatus}
            disabled={isLoadingFavoriteStatus}
        />
    );
};

export default AddFavorite;
