import { Bff } from '~/models/bff';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import CreditDetails from './credit/details/details.component';

//Name before: credit-details-page.component.tsx
function W030CreditsDetailOverview({ queryId, sellerOrderNumber }: Bff.ICreditsDetailOverviewWidgetViewModel) {
    return <CreditDetails creditId={queryId} isOpen sellerOrderNumber={sellerOrderNumber} />;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default WithOverviewNavigation(W030CreditsDetailOverview);
