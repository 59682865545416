import { Basket as BasketTypes } from '~/models/basket.d';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

interface IProps {
    basket: BasketTypes.ILatestInternalSavedBasket;
}

export default function BasketItem({ basket }: IProps) {
    const maxItemsCount = 4;
    const translate = useTranslations();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const items = [...basket?.items] || [];

    const itemsCount = items.length;

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Svg name="save" className={styles.saveIcon} />
                <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                    {basket?.displayDate}
                </Text>
                <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                    {basket?.quantity} {translate('basket.piece', 'Stk.')}
                </Text>
                <Text textStyle="bodySmall" color="primaryColor" overflowEllipsis fontWeight="semiBold">
                    {basket?.totalPriceFormatted}
                </Text>
            </div>
            <div>
                <div className={itemsCount > maxItemsCount ? styles.itemsWithGradient : undefined}>
                    {items.slice(0, maxItemsCount).map((item) => (
                        <Text
                            key={item.itemId}
                            className={styles.productItem}
                            id={item.itemId}
                            title={item?.name}
                            overflowEllipsis
                            textStyle="bodySmall"
                            color="primaryColor"
                        >
                            {item?.name}
                        </Text>
                    ))}
                </div>
            </div>
        </div>
    );
}
