import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import useUser from '~/libs/use-user';
import { useRouter } from 'next/router';
import { useLayoutState } from '~/context/layout.context';

export function useSimpleVariantsByFtz(ids: string[]) {
    const { products, createUrl } = useEndpoints();
    const { user, isLoggedIn } = useUser();
    const { locale } = useRouter();
    const { expertMode } = useLayoutState();
    const endpoint = createUrl({
        endpoint: products.getVariantsSimpleFtz,
        localeOption: LocaleOptions.path,
    });

    return useQuery<ProductTypes.IVariantLight[], Error>({
        queryKey: queryKeys.users.simpleVariantsByFtz(locale, ids, expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(endpoint, ids, { signal });
        },

        enabled: isLoggedIn && ids.length > 0,
    });
}
