import { Bff } from '~/models/bff';
import { Link } from '~/shared/link';
import styles from './styled.module.scss';
import { useCallback } from 'react';

function getLinkUrl(link?: Bff.ILinkViewModel): string {
    return link?.url + (link?.url ?? '');
}

// Name before: link-container.component.tsx
export default function M160LinkContainer({ headline, links = [], backgroundColor, campaignSpot, type, id }: Bff.IM160LinkContainerModuleViewModel) {
    const bgColor = backgroundColor;

    return (
        <div className={bgColor && styles.withBgColor} style={{ backgroundColor: bgColor ? `#${bgColor}` : undefined }}>
            {headline ? <div className={styles.title}>{headline}</div> : null}

            {links.map((link) => (
                <M160LinkContainerItem key={link.url} link={link} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />
            ))}
        </div>
    );
}

export type M160LinkContainerItem = {
    link: Bff.ILinkViewModel;
    campaignSpot?: string;
    campaignType?: string;
    campaignId?: string;
};

export function M160LinkContainerItem({ link, campaignSpot, campaignId, campaignType }: M160LinkContainerItem) {
    const { url = '#', target, title } = link;

    return (
        <Link className={styles.link} href={url} target={target} campaignSpot={campaignSpot} campaignId={campaignId} campaignType={campaignType}>
            {title ?? url}
        </Link>
    );
}
