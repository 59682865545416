import React, { FC, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Modal, ModalCloseButton } from '~/features/primitives/modal';
import { WELCOME_MODAL_GUIDE } from '~/constants/local.storage';
import { Bff } from '~/models/bff';
import RawHtml from '~/page-elements/raw-html/raw-html.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { useCMSPage } from '~/libs/queries/bff';

export type UseStoredCustomerGuideState = {
    pageId: string;
};

export function useStoredCustomerGuideState({ pageId }: UseStoredCustomerGuideState) {
    // If stored value is true, the customer guide has already been seen, and therefor we should hide the video.
    return useLocalStorage(`${WELCOME_MODAL_GUIDE}-page-${pageId}`, false);
}

//Name before: customer-guide-modal.component
export default function M072VideoPopup({ videoId, headline, richText }: Bff.IM072VideoPopupModuleViewModel) {
    const translate = useTranslations();

    const { data: page } = useCMSPage();

    const [storedCustomerGuideState, setStoredCustomerGuideState] = useStoredCustomerGuideState({ pageId: page?.url ?? '' });
    const [open, setOpen] = useState(!storedCustomerGuideState);

    useEffect(() => {
        setStoredCustomerGuideState(true);
    }, []);

    return (
        <Modal open={open} onOpenChange={setOpen} position="center" size="default" title={headline}>
            <div>
                <iframe title={videoId} className={styles.iframe} src={videoId} />
                <RawHtml content={richText} className={styles.textContent} />
                <div className={styles.buttonWrapper}>
                    <ModalCloseButton buttonStyle="secondary">{translate('customerHelp.getStarted', 'Kom igang')}</ModalCloseButton>
                </div>
            </div>
        </Modal>
    );
}
