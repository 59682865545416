import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { format } from 'url';
import { getRouterQueryParamsWithoutPagination } from '~/helpers/pagination.helper';
import { Products as ProductTypes } from '~/models/products';

export interface IActiveFacet {
    key: string;
    values: string[];
}

// Query string prefix used for all facet keys
const FACET_QUERY_PREFIX = 'f.';

/**
 * Utility for converting a react router query param to an array of options.
 * Useful when a query param has multiple values.
 * @param values
 */
function createValueList(values?: string | string[]) {
    if (values && typeof values === 'string') {
        return [values];
    }

    if (values && Array.isArray(values)) {
        return [...values];
    }

    return [];
}

export function useFilterValues(filter: ProductTypes.IFacet) {
    const router = useRouter();

    return useMemo(() => {
        const valueList = createValueList(router.query[FACET_QUERY_PREFIX + filter.key]);
        const options = filter.values || [];
        const selected: ProductTypes.IFacetValue[] = [];

        for (const option of options) {
            if (typeof option.value !== 'string' || !valueList.includes(option.value)) {
                continue;
            }

            selected.push(option);
        }

        return {
            options,
            selected,
        };
    }, [filter.key, filter.values, router.query]);
}

export function useFilterActions() {
    const router = useRouter();

    function setQueryParams(key: string, values: ProductTypes.IFacetValue[]) {
        const newQuery = { ...router.query };

        newQuery[FACET_QUERY_PREFIX + key] = values.map((item) => item.value as string);

        const url = format({
            pathname: window.location.pathname,
            query: getRouterQueryParamsWithoutPagination(newQuery),
        });

        router.replace(url, undefined, { shallow: true });
    }

    function clearQueryParams() {
        const updatedParams = { ...router.query };

        Object.keys(updatedParams).forEach((key) => {
            if (key.startsWith(FACET_QUERY_PREFIX)) {
                delete updatedParams[key];
            }
        });

        updatedParams['usePrefilling'] = String(false);

        const url = format({
            pathname: window.location.pathname,
            query: getRouterQueryParamsWithoutPagination(updatedParams),
        });

        router.push(url, undefined, { shallow: true });
    }

    function add(key: string, value: string) {
        const current = router.query[FACET_QUERY_PREFIX + key];

        let newQuery;

        if (!current) {
            newQuery = { ...router.query, [FACET_QUERY_PREFIX + key]: value };
        } else if (Array.isArray(current)) {
            newQuery = { ...router.query, [FACET_QUERY_PREFIX + key]: [...current, value] };
        } else {
            newQuery = { ...router.query, [FACET_QUERY_PREFIX + key]: [current, value] };
        }

        const url = format({
            pathname: window.location.pathname,
            query: getRouterQueryParamsWithoutPagination(newQuery),
        });

        router.replace(url, undefined, { shallow: true });
    }

    function remove(key: string, value: string) {
        const current = router.query[FACET_QUERY_PREFIX + key];

        if (!current) {
            return;
        }

        const updatedValues = createValueList(current);
        const existing = updatedValues.findIndex((existingValue) => value === existingValue);

        updatedValues.splice(existing, 1);

        const url = format({
            pathname: window.location.pathname,
            query: getRouterQueryParamsWithoutPagination({ ...router.query, [FACET_QUERY_PREFIX + key]: updatedValues }),
        });

        router.replace(url, undefined, { shallow: true });
    }

    return {
        setQueryParams,
        clearQueryParams,
        add,
        remove,
    };
}

function isFilterQuery([key]: [string, string | string[] | undefined]) {
    return key.startsWith(FACET_QUERY_PREFIX);
}

export type InternalActiveFilter = {
    key: string;
    active: string[];
};

function getActiveObjectForQuery([key, val]: [string, string | string[] | undefined]): InternalActiveFilter {
    return {
        key,
        active: (Array.isArray(val) ? val : [val]) as InternalActiveFilter['active'],
    };
}

export function useActiveFilters() {
    const { query } = useRouter();

    const active = useMemo(() => {
        return Object.entries(query).filter(isFilterQuery).map(getActiveObjectForQuery);
    }, [query]);

    return {
        active,
    };
}

export function useAllFilters() {
    const { query } = useRouter();

    const totalActiveFilters = useMemo(() => {
        return Object.entries(query)
            .filter(([key]) => key.startsWith(FACET_QUERY_PREFIX))
            .map(([, val]) => val).length;
    }, [query]);

    return {
        totalActiveFilters,
    };
}
