import { useQuery } from '@tanstack/react-query';
import { favoriteKeys, FavoritePlacement, FAVORITES_STATUS_LIST_URL, FavoriteStatusListResponse } from '../.';
import { useRouter } from 'next/router';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

export type UseFavoriteStatusList = {
    placement: FavoritePlacement;
    userId?: string;
    itemNoList?: string[];
    pageIndex?: number;
    enabled?: boolean;
};

export function useFavoriteStatusList({
    userId,
    itemNoList = [],
    pageIndex,
    placement,
    enabled = !!userId && !!itemNoList.length,
}: UseFavoriteStatusList) {
    const { user } = useUser();
    const { locale } = useRouter();

    return useQuery<FavoriteStatusListResponse>({
        queryKey: favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex),
        queryFn: () =>
            apiClient.auth(user).postJSON(
                createUrl(
                    {
                        endpoint: FAVORITES_STATUS_LIST_URL,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                itemNoList,
            ),
        enabled,
    });
}
