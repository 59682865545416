import React from 'react';
import { DialogClose } from '@radix-ui/react-dialog';
import Button, { IButtonProps } from '~/shared/buttons/button/button.component';

export const ModalCloseButton = React.forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
    return (
        <DialogClose asChild>
            <Button ref={ref} {...props} />
        </DialogClose>
    );
});
