import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './vehicle-group.module.scss';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import { Link } from '~/shared/link';

interface IModelsListProps {
    models: VehicleTypes.ICarModel[];
    isSingle?: boolean;
}

const ModelsList: FC<IModelsListProps> = ({ models, isSingle }) => (
    <ul className={isSingle ? styles.modelsListSingle : styles.modelsList}>
        {models.map((model: VehicleTypes.ICarModel) => (
            <li className={styles.modelItem} key={model.id}>
                <Link
                    href={model.url}
                    className={clsx(styles.modelLink, {
                        [styles.expertLink]: model.expertModeEnabled,
                    })}
                    prefetch={false}
                >
                    <span className={styles.modelName}>{model.name}</span>
                    <span className={styles.modelYear}>
                        {model.yearFrom}-{model.yearTo}
                    </span>
                </Link>
            </li>
        ))}
    </ul>
);

export default ModelsList;
