import clsx from 'clsx';
import { IWithClassName } from '~/models/dev.d';
import styles from './basket-summary-info.module.scss';

export type BasketSummaryInfoProps = IWithClassName & {
    caption: string;
    value: string;
};

export function BasketSummaryInfo({ caption, value, className }: BasketSummaryInfoProps) {
    const classNames = className ? clsx(styles.basketSummaryInfoWrapper, className) : styles.basketSummaryInfoWrapper;

    return (
        <div className={classNames}>
            <span className={styles.basketSummaryPriceCaption}>{caption}</span>
            <span>{value}</span>
        </div>
    );
}
