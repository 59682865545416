import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { Basket as BasketTypes } from '~/models/basket.d';
import { UseBasketSubmission } from './../use-basket-submission';
import { BasketContent } from './basket-content';
import { useCheckoutBasket } from '~/libs/queries/basket';

export interface ICheckoutValidationProps {
    deliveryMethod: string;
    splitDeliveryOption: BasketTypes.DeliveryType;
    deliveryAddressId: string;
    deliveryDateTime: string;
    requisition: string;
    caseNo: string;
    comment: string;
}

export function BasketContentContainer() {
    const router = useRouter();

    const { data: basket } = useCheckoutBasket();

    const methods = useForm<ICheckoutValidationProps>({
        defaultValues: {
            caseNo: typeof router.query.caseNo === 'string' ? router.query.caseNo : basket?.orderDetails?.caseNo,
            requisition: basket?.orderDetails?.requisition,
            comment: basket?.orderDetails?.comment,
        },
    });

    const handleSubmitForm = UseBasketSubmission();

    return <BasketContent onSubmit={methods.handleSubmit(() => handleSubmitForm())} formProviderProps={methods} />;
}
