import React, { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'clsx';
import { Products as ProductTypes } from '~/models/products.d';
import useTranslations from '../hooks/use-translations.hook';
import styles from './product-list-header.module.scss';
import { Modal } from '~/features/primitives/modal';
import FacetsFilter from './facets-filter/facets-filter.component';
import { getRouterQueryParamsWithoutPagination } from '~/helpers/pagination.helper';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import InStockFilter from './in-stock-filter/in-stock-filter.component';
import Heading, { SubHeading } from '~/shared/heading/heading.component';
import ButtonCircle from '../buttons/button-circle/button-circle.component';
import { SiblingCategories } from '~/shared/sibling-categories';
import { useAllFilters } from '~/shared/product-list-header/facets-filter/filter.hook';
import HighlightedFilters from '~/shared/product-list-header/highlighted-filters/highlighted-filters.component';
import TireCalculator from '~/shared/tire-calculator/tire-calculator.component';
import ViewToggle from '~/shared/product-list-header/view-toggle/view-toggle.component';
import { Bff } from '~/models/bff.d';
import LightBoxImage from './light-box-image/light-box-image.component';
import Text from '../text/text.component';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';

export type ProductListControlsProps = {
    className?: string;
    filters?: ProductTypes.IFacetsResult;
    onClickFilter?: () => void;
};

export function ProductListControls({ className, filters, onClickFilter }: ProductListControlsProps) {
    const translate = useTranslations();
    const { totalActiveFilters } = useAllFilters();

    const { data: features } = useFeatures();
    const { shouldHideFeature } = useFeatureToggle();

    const viewModeControl = useMemo(() => {
        return !shouldHideFeature(features?.productList?.viewModeToggle) ? <ViewToggle className={styles.viewToggle} /> : null;
    }, [features?.productList?.viewModeToggle, shouldHideFeature]);

    return (
        <div className={cx(styles.controls, className)}>
            {filters?.highlightFacets?.showTireCalculation ? <TireCalculator /> : null}
            {filters && onClickFilter && (
                <ButtonCircle
                    buttonStyle="light"
                    iconName="preferences"
                    onClick={onClickFilter}
                    text={translate('filter.caption', 'Filtrér resultater')}
                    badge={totalActiveFilters}
                />
            )}
            <InStockFilter className={styles.inStockFilter} totalResults={filters?.totalResults} />
            {viewModeControl}
        </div>
    );
}

export type ProductListFilterModalProps = {
    filters?: ProductTypes.IFacetsResult;
    isLoading: boolean;
    open: boolean;
    setOpen: (state: boolean) => void;
};

export function ProductListFilterModal({ filters, isLoading, open, setOpen }: ProductListFilterModalProps) {
    const { query: routerQuery, replace } = useRouter();
    const translate = useTranslations();

    const handleCloseFilter = useCallback(() => {
        setOpen(false);

        const routerParams = getRouterQueryParamsWithoutPagination(routerQuery);

        const url = createUrl({
            endpoint: window.location.pathname,
            localeOption: LocaleOptions.path,
            query: {
                ...routerParams,
            },
        });

        replace(url, undefined, { shallow: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerQuery]);

    const handleOpenChange = useCallback(
        (open) => {
            if (open) {
                return;
            }

            handleCloseFilter();
        },
        [handleCloseFilter],
    );

    return (
        <Modal position="right" title={translate('filter.modalTitle', 'Filtrér resultat')} open={open} onOpenChange={handleOpenChange}>
            <FacetsFilter facets={filters?.facets} totalResults={filters?.totalResults} fetching={isLoading} closeFilterModal={handleCloseFilter} />
        </Modal>
    );
}

export function useSortedCategories(categories?: ProductTypes.ICategoryNameWithUrl[]) {
    const { locale } = useRouter();

    if (!categories) {
        return [];
    }

    return categories.sort((a: ProductTypes.ICategoryNameWithUrl, b: ProductTypes.ICategoryNameWithUrl) => {
        return (
            Number(!a.selected) - Number(!b.selected) ||
            Number(a.disabled) - Number(b.disabled) ||
            Number(a.name && b.name && a.name.localeCompare(b.name, locale))
        );
    });
}

export type SearchProductListHeadProps = {
    title?: string;
    filters?: ProductTypes.IFacetsResult;
    isLoadingFilters: boolean;
    sisterCategories?: ProductTypes.ICategoryNameWithUrl[];
    image?: Bff.IImage;
    imageSubText?: string;
    categoryId: string;
};

export function ProductListHead({ filters, isLoadingFilters, sisterCategories, title, image, imageSubText, categoryId }: SearchProductListHeadProps) {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <ProductListFilterModal filters={filters} isLoading={isLoadingFilters} open={isModalOpen} setOpen={setModalOpen} />
            <ProductListHeader
                filters={filters}
                sisterCategories={sisterCategories}
                title={title}
                image={image}
                imageSubText={imageSubText}
                onClickFilter={() => setModalOpen(true)}
                categoryId={categoryId}
            />
        </>
    );
}

export type ProductListHeadProps = {
    filters?: ProductTypes.IFacetsResult;
    isLoadingFilters: boolean;
    sisterCategories?: ProductTypes.ICategoryNameWithUrl[];
};

export function SearchProductListHead({ filters, isLoadingFilters, sisterCategories }: ProductListHeadProps) {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <ProductListFilterModal filters={filters} isLoading={isLoadingFilters} open={isModalOpen} setOpen={setModalOpen} />
            <SearchProductListHeader filters={filters} sisterCategories={sisterCategories} onClickFilter={() => setModalOpen(true)} />
        </>
    );
}

export type ProductListHeaderProps = {
    filters?: ProductTypes.IFacetsResult;
    sisterCategories?: ProductTypes.ICategoryNameWithUrl[];
    title?: string;
    image?: Bff.IImage;
    imageSubText?: string;
    onClickFilter?: () => void;
    categoryId: string;
};

export function ProductListHeader({ filters, sisterCategories = [], title, onClickFilter, image, imageSubText, categoryId }: ProductListHeaderProps) {
    const translate = useTranslations();

    const total = filters?.totalResults ?? 0;
    const countText = total === 1 ? translate('common.result', 'resultat') : translate('common.results', 'resultater');

    const subheader =
        total > 0
            ? {
                  subHeading: `${total} ${countText}`,
              }
            : null;

    return (
        <>
            <div className={styles.headerContainer}>
                {sisterCategories.length > 0 ? (
                    <SiblingCategories categories={sisterCategories} />
                ) : (
                    <Heading className={styles.title} tagName="h1" displayStyle="h3" {...subheader}>
                        {title}
                    </Heading>
                )}
                <ProductListControls filters={filters} onClickFilter={onClickFilter} />

                {image && <LightBoxImage image={image} />}
                {imageSubText && <Text fontWeight="semiBold">{imageSubText}</Text>}
            </div>
            {filters?.highlightFacets?.facets ? (
                <HighlightedFilters
                    facets={filters?.highlightFacets.facets}
                    // We add a key here to make sure that filters are force updated when the category changes.
                    // This is to make sure that focus events are re-triggered
                    key={categoryId}
                />
            ) : null}
        </>
    );
}

export type SearchProductListHeaderProps = {
    filters?: ProductTypes.IFacetsResult;
    sisterCategories?: ProductTypes.ICategoryNameWithUrl[];
    onClickFilter?: () => void;
};

export function SearchProductListHeader({ filters, sisterCategories = [], onClickFilter }: SearchProductListHeaderProps) {
    const { query } = useRouter();
    const translate = useTranslations();
    const total = filters?.totalResults;
    const countText = total === 1 ? translate('common.result', 'resultat') : translate('common.results', 'resultater');

    return (
        <>
            <div className={styles.headerContainer}>
                {sisterCategories.length > 0 ? (
                    <SiblingCategories categories={sisterCategories} />
                ) : (
                    <div>
                        <Heading className={styles.title} tagName="h1" displayStyle="h3" externalSubHeading>
                            {`${translate('filter.shows', 'Viser')} ${total} ${countText} '${query.query}'`}
                        </Heading>
                        <SubHeading className={styles.searchSubHeading}>{`${total} ${countText}`}</SubHeading>
                    </div>
                )}
                <ProductListControls filters={filters} onClickFilter={onClickFilter} />
            </div>
            {filters?.highlightFacets?.facets ? <HighlightedFilters facets={filters?.highlightFacets.facets} /> : null}
        </>
    );
}
