import { useCallback, useMemo, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { GA4CampaignClicked } from '~/libs/ga4';
import { Bff } from '~/models/bff';
import Text from '~/shared/text/text.component';
import Heading from '~/shared/heading/heading.component';
import { CdnImage } from '~/shared/image/cdn-image';
import { Link } from '~/shared/link';
import { MediaQuery } from '~/shared/media-query-detect';
import { Svg } from '~/shared/svg';
import { SwiperCarousel } from '~/shared/swiper-carousel';
import Dots from '~/shared/swiper-carousel/components/dots/dots.widget';
import styles from './styled.module.scss';
import { slidesPerView, useContentCarouselItems } from './utils';

export default function M080ContentCarousel({ items = [], headline, campaignSpot, id, type }: Bff.IM080ContentCarouselViewModel) {
    const [cursor, setCursor] = useState<number>(0);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const hasSlides = useContentCarouselItems(items.length);
    const isCarousel = items?.length > 4;

    const onSlideChange = useCallback(
        (swiper: SwiperCore) => {
            setCursor(swiper.realIndex);
        },
        [setCursor],
    );

    const onClick = useCallback(
        (link?: string, campaignId?: string, campaignSpot?: string) => {
            GA4CampaignClicked({
                to: link ?? '#',
                campaignId,
                campaignSpot,
                campaignType: type,
            });
        },
        [type],
    );

    const carouselOptions = useMemo(
        () => ({
            breakpoints: {
                320: { slidesPerView: slidesPerView[MediaQuery.Mobile] },
                768: { slidesPerView: slidesPerView[MediaQuery.Tablet] },
                992: { slidesPerView: slidesPerView[MediaQuery.Desktop] },
                1200: { slidesPerView: slidesPerView[MediaQuery.LargeDesktop] },
            },
            spaceBetween: 10,
            loop: hasSlides,
        }),
        [hasSlides],
    );

    const itemElements = useMemo(() => {
        if (!items) {
            return [];
        }

        return items.map((item, index) => (
            <div key={index}>
                <Link
                    href={item.callToAction?.url}
                    target={item.callToAction?.target}
                    onClick={() => {
                        onClick(item?.callToAction?.url, id, campaignSpot);
                    }}
                >
                    {item.image?.src ? (
                        <div className={styles.contentCarouselSlideMedia}>
                            <CdnImage layout="fill" objectFit="cover" src={item.image?.src} alt={item.image?.alt} draggable={false} />
                        </div>
                    ) : (
                        <div className={styles.contentCarouselSlideMediaPlaceholder}>
                            <Svg name="placeholder-image" />
                        </div>
                    )}
                </Link>
                <Text textAlign="center" className={styles.contentCarouselSlideText}>
                    {item.headline}
                </Text>
            </div>
        ));
    }, [campaignSpot, id, items, onClick, type]);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'minmax(0, 100%)' }}>
            <div className={styles.contentCarousel}>
                <Heading className={styles.contentCarouselHeading} tagName="h3">
                    {headline}
                </Heading>
                {isCarousel ? (
                    <>
                        <div className={styles.contentCarouselSlider}>
                            <SwiperCarousel
                                onSlideChange={onSlideChange}
                                prevRef={prevRef}
                                nextRef={nextRef}
                                items={itemElements}
                                optionsOverride={carouselOptions}
                            />
                            <button ref={prevRef} className={[styles.contentCarouselButton, styles.contentCarouselButtonBack].join(' ')}>
                                <Svg name="chevron-left" />
                            </button>

                            <button ref={nextRef} className={[styles.contentCarouselButton, styles.contentCarouselButtonNext].join(' ')}>
                                <Svg name="chevron-right" />
                            </button>
                        </div>
                        <Dots count={items.length} cursor={cursor} />
                    </>
                ) : (
                    <div className={styles.contentCarouselFixed}>{itemElements}</div>
                )}
            </div>
        </div>
    );
}
