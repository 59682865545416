import React, { FC, useState } from 'react';
import { useRouter } from 'next/router';
import useDepositProducts from '~/libs/queries/products/hooks/use-deposit-products-service';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Select from '~/shared/form-elements/select/select.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import styles from './create-deposit-credit.module.scss';
import { Products as ProductTypes } from '~/models/products.d';
import { Orders as OrderTypes } from '~/models/orders.d';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import { createStatus } from '../create-credit.d';
import SubmitFeedback from '../submit-feedback/submit-feedback.component';
import createCreditMultiple from '~/services/orders/create-credit-multiple.service';
import { DEPOSIT_ITEMS_COUNT } from '~/constants/deposit-items-count';
import { ICreditDraft } from '~/widgets/overview/credits/credit-drafts/credit-drafts.component';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import { CREDIT_DRAFTS } from '~/constants/local.storage';
import { useStaticContent } from '~/libs/queries/bff';
import useUser from '~/libs/use-user';

interface IProps {
    onClose: () => void;
    depositMatchId?: string;
}

const CreateDepositCredit: FC<IProps> = ({ onClose, depositMatchId }) => {
    const translate = useTranslations();
    const { data: staticContent } = useStaticContent();
    const { data: productsData, isLoading: isLoadingProducts, isError } = useDepositProducts();
    const { user } = useUser();
    const [fetching, setFetching] = useState(false);
    const [selected, setSelected] = useState<string>(depositMatchId ?? '');
    const [quantity, setQuantity] = useState<string>('');
    const [creditId, setCreditId] = useState<null | number>(null);
    const [messages, setMessages] = useState<string[]>([]);
    const [status, setStatus] = useState<createStatus>(createStatus.initial);
    const [drafts, setDrafts] = useLocalStorage<ICreditDraft[]>(CREDIT_DRAFTS);
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const selectedProduct: ProductTypes.IVariantLightPrice = selected ? productsData?.find((item) => item.id.toString() === selected) : null;

    const handleCreateCredit = async () => {
        setStatus(createStatus.initial);
        setFetching(true);
        const postData: OrderTypes.ICreditInvoiceRequest = {
            items: [
                {
                    quantity: Number(quantity),
                    itemNo: selectedProduct?.ftzCode,
                    hasDeposit: true,
                    depositOnly: true,
                },
            ],
        };

        try {
            const response = await createCreditMultiple(postData, user);

            setMessages(response.messages as string[]);

            if (response?.statusName === OrderTypes.CreateCreditStatus.Created) {
                setStatus(createStatus.created);
                setCreditId(response.creditId as number);
            } else if (response?.statusName === OrderTypes.CreateCreditStatus.NotCreated) {
                setStatus(createStatus.notCreated);
            } else {
                setStatus(createStatus.error);
            }
        } catch (error) {
            setStatus(createStatus.error);
        } finally {
            setFetching(false);
        }
    };

    const handleSave = () => {
        setDrafts([
            ...(drafts || []),
            {
                timeStamp: Date.now(),
                quantity: Number(quantity),
                depositOnly: true,
                itemNo: selectedProduct.ftzCode,
                image: selectedProduct?.image,
                title: selectedProduct?.title,
                brand: selectedProduct?.brand,
                hasDeposit: true,
            },
        ]);
        if (staticContent) {
            router.push(staticContent?.urlMappings?.creditsOverviewPage ?? '#');
        }
    };

    if (isLoadingProducts) return <Loader />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    if (status === createStatus.notCreated || status === createStatus.created) {
        return <SubmitFeedback messages={messages} status={status} setStatus={setStatus} creditId={creditId as number} onClose={onClose} />;
    }

    return (
        <div>
            <Select
                disabled={fetching || Boolean(depositMatchId)}
                className={styles.select}
                label={translate('createCredit.selectProductFromTheList', 'Vælg produkt i listen du ønsker at lave depositum på')}
                value={selected}
                onChange={(e) => {
                    setSelected(e.target.value || '');
                    setQuantity('');
                }}
            >
                <option value="">-</option>
                {productsData?.map((item) => (
                    <option value={item?.id} key={item.ftzCode}>
                        {item?.title}
                    </option>
                ))}
            </Select>
            <div className={styles.item}>
                {selectedProduct && (
                    <>
                        <Select
                            disabled={fetching}
                            className={styles.select}
                            label={translate('overview.selectQuantity', 'vælg Antal')}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value || '')}
                        >
                            <option value="">-</option>
                            {Array.from(Array(DEPOSIT_ITEMS_COUNT).keys())
                                .map((i) => i + 1)
                                .map((n) => (
                                    <option key={`${selected}-${n}`}>{n}</option>
                                ))}
                        </Select>
                        <div className={styles.details}>
                            <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')}>
                                <Link href={selectedProduct.url}>
                                    <Text textStyle="monoMedium">{selectedProduct.ftzCode}</Text>
                                </Link>
                            </ValueWithCaption>
                            <Price upperCase>
                                {selectedProduct.displayDeposit} \ {translate('product.piece', 'stk')}
                            </Price>
                        </div>
                    </>
                )}
            </div>
            {status === createStatus.error && (
                <ErrorBox className={styles.errorBox}>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            )}

            {/* the button is disabled but functionality remains hidden */}
            <Button
                className={styles.submitButton}
                onClick={handleCreateCredit}
                // disabled={!selected || !quantity}
                disabled
                fetching={fetching}
            >
                {translate('createCredit.createDeposit', 'Opret depositum')}
            </Button>
            <Button buttonStyle="secondary" onClick={handleSave} disabled={!selected || !quantity}>
                {translate('overview.saveCredit', 'Gem kreditering')}
            </Button>
        </div>
    );
};

export default CreateDepositCredit;
