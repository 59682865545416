import { Orders as OrderTypes } from '~/models/orders';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const createCreditMultiple = async (postData: OrderTypes.ICreditInvoiceRequest, user: Session | null, dryRun = false) => {
    const url = createUrl({
        endpoint: `${endpoints.orders.credits}/credit-multiple`,
        localeOption: LocaleOptions.path,
    });

    const response: OrderTypes.ICreateCreditResponse = await apiClient.auth(user).postJSON(url, { ...postData, isDryRun: dryRun });

    return response;
};

export default createCreditMultiple;
