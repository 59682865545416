import clsx from 'clsx';
import React, { InputHTMLAttributes, forwardRef } from 'react';
import { Svg } from '~/shared/svg';
import { IFormElementCommonProps } from '../common/common';
import InputValidation from '../common/input-validation/input-validation.component';
import Label from '../common/label/label.component';
import styles from './select.module.scss';

interface IProps extends InputHTMLAttributes<HTMLSelectElement>, IFormElementCommonProps {}

const Select = forwardRef<HTMLSelectElement, IProps>((props, ref): JSX.Element => {
    const {
        label,
        disabled,
        hideLabel,
        required,
        name,
        children,
        className,
        wrapperClassName,
        isValid = true,
        errorMessage,
        validMessage,
        ...rest
    } = props;
    const isError = !isValid || errorMessage;

    return (
        <div className={wrapperClassName}>
            {label && (
                <Label name={`Select${name}`} disabled={disabled} isHidden={hideLabel} required={required}>
                    {label}
                </Label>
            )}
            <div className={styles.selectWrapper}>
                <select
                    name={name}
                    id={`Select${name}`}
                    ref={ref}
                    disabled={disabled}
                    className={clsx(className, styles.select, {
                        [styles.invalid]: isError,
                    })}
                    {...rest}
                >
                    {children}
                </select>
                <Svg name="chevron-down" className={disabled ? styles.iconDisabled : styles.icon} />
            </div>
            <InputValidation errorMessage={errorMessage} validMessage={validMessage} />
        </div>
    );
});

export default Select;
