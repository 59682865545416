import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';

export type BasketFooterDeleteBasketProps = {
    label: string;
    isLoading: boolean;
    handleClick: () => void;
};

export function BasketFooterDeleteBasket({ label, isLoading, handleClick }: BasketFooterDeleteBasketProps) {
    return (
        <Button icon={<Svg name="bin" />} buttonStyle="clean" onClick={handleClick} fetching={isLoading}>
            {label}
        </Button>
    );
}
