import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCheckoutBasket } from '~/libs/queries/basket';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import updateDeliveryAddress from '~/services/basket/update-delivery-address.service';
import useDeliveryOptions from '~/services/basket/use-delivery-options.service';
import Input from '~/shared/form-elements/input/input.component';
import Select from '~/shared/form-elements/select/select.component';
import usePreviousDistinct from '~/shared/hooks/use-previous-distinct';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../../../use-basket-states';
import { CheckoutDeliveryTimeContainer, CheckoutDeliveryTimeSlotDaysRequired } from '../checkout-delivery-time/checkout-delivery-time.container';
import styles from './checkout-delivery-address.module.scss';

export function CheckoutDeliveryAddressContainer() {
    const translate = useTranslations();
    const { user } = useUser();
    const { data: basket, refetch: refetchBasket } = useCheckoutBasket();
    const { setIsCheckoutFetching, selectedDeliveryMethod } = useBasketStates();

    const { data: { addresses: deliveryAddresses = [] } = {} } = useDeliveryOptions();
    const prevDeliveryAddresses = usePreviousDistinct(deliveryAddresses);

    const {
        formState: { errors },
        clearErrors,
        register,
    } = useFormContext();

    const allowDeliveryAddress = Boolean(selectedDeliveryMethod?.allowDeliveryAddress);

    const [selectedAddress, setSelectedAddress] = useState<BasketTypes.IAddressDto | undefined>(undefined);

    const handleDeliveryAddressesChange = async (id: string | undefined) => {
        if (!basket?.id) {
            return;
        }

        const selected = deliveryAddresses.find((da) => da.id === id);
        setSelectedAddress(selected);

        clearErrors('deliveryAddressId');

        setIsCheckoutFetching(true);

        await updateDeliveryAddress(basket.id, id, user);
        await refetchBasket();

        setIsCheckoutFetching(false);
    };

    // set first delivery address as selected if any
    useEffect(() => {
        if (!basket) {
            return;
        }

        if (!allowDeliveryAddress || !deliveryAddresses.length) {
            return;
        }

        if (
            selectedAddress?.id !== basket.deliveryDetails?.deliveryAddressId ||
            JSON.stringify(deliveryAddresses) !== JSON.stringify(prevDeliveryAddresses)
        ) {
            if (!basket.deliveryDetails?.deliveryAddressId) {
                handleDeliveryAddressesChange(deliveryAddresses[0].id);
            } else {
                const selected = deliveryAddresses?.find((da) => da.id === basket.deliveryDetails?.deliveryAddressId);

                if (selected) {
                    setSelectedAddress(selected);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket, deliveryAddresses, prevDeliveryAddresses, allowDeliveryAddress]);

    // This should never happen because checkout is only rendered with active basket
    if (!basket) {
        return null;
    }

    const { userMustChoseDeliveryAddress } = basket.deliveryDetails?.validation || {};
    const hasDeliveryAddress = basket.deliveryDetails?.deliveryAddressId;

    const isTimeSlotEnabled = selectedDeliveryMethod?.allowDeliveryTimeSlot && selectedAddress?.deliveryTimeSlots?.length;

    const selectValue = allowDeliveryAddress ? selectedAddress?.id || '' : '';

    return (
        <div className={styles.checkoutDeliveryAddressWrapper}>
            <Select
                className={styles.checkoutDeliveryAddressSelect}
                {...register('deliveryAddressId', {
                    required: userMustChoseDeliveryAddress && !hasDeliveryAddress,
                })}
                isValid={!errors?.deliveryAddressId}
                label={translate('basket.deliveryAddressDefault', 'Leveringsadresse')}
                value={selectValue}
                disabled={deliveryAddresses?.length <= 1 || !allowDeliveryAddress}
                onChange={(e) => handleDeliveryAddressesChange(e.target.value)}
            >
                <option disabled value="">
                    {translate('basket.chooseDeliveryAddress', 'Vælg Leveringsadresse')}
                </option>

                {deliveryAddresses.map((address, i) => (
                    <option key={`${address.id}-${i}`} value={address.id}>
                        {address.displayAddress}
                    </option>
                ))}
            </Select>

            <div className={styles.checkoutDeliveryAddressTimeSlotWrapper}>
                {!isTimeSlotEnabled ? (
                    <>
                        <Input aria-label={translate('common.selectDate', 'Vælg dato')} disabled />
                        <Input aria-label={translate('common.selectTime', 'Vælg tid')} disabled />
                    </>
                ) : (
                    <CheckoutDeliveryTimeContainer
                        deliveryAddressId={selectedAddress.id}
                        timeSlotDays={selectedAddress.deliveryTimeSlots as CheckoutDeliveryTimeSlotDaysRequired} // forced type due to isTimeSlotEnabled check
                    />
                )}
            </div>
        </div>
    );
}
