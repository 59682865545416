import React, { FC, useState, cloneElement, MouseEvent } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import styles from './grid-table.module.scss';
import { Link } from '~/shared/link';
import { Svg } from '~/shared/svg';

interface ICellProps extends IWithClassName {
    label?: string | JSX.Element;
    hideOnMobile?: boolean;
    isOpen?: boolean;
}

export const Cell: FC<ICellProps> = ({ label, children, hideOnMobile, isOpen, className }) => (
    <div
        className={clsx(className, styles.cell, {
            [styles.hideOnMobile]: hideOnMobile && !isOpen,
        })}
    >
        <div className={clsx(styles.cellLabel, 'title')}>{label}</div>
        {children}
    </div>
);

interface IRowProps extends IWithClassName {
    children: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
    href?: string;
    collapsible?: boolean;
    verticalAlign?: boolean;
}

export const Row: FC<IRowProps> = ({ children, className, href, collapsible, verticalAlign }) => {
    const [isOpen, setOpen] = useState(false);

    const clonedChildren = collapsible
        ? (children as Array<any>).map((child, i) =>
              cloneElement(child, {
                  isOpen,
                  key: `${child.props.label}-${i}`,
              })
          )
        : [...(children as Array<any>)];

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        setOpen(!isOpen);
    };

    return (
        <Link
            href={href}
            className={clsx(styles.row, className, {
                [styles.linkRow]: href,
                [styles.collapsibleRow]: collapsible,
                [styles.verticalAlignRow]: verticalAlign,
            })}
        >
            {clonedChildren}
            {collapsible && (
                <button aria-label={isOpen ? 'Collapse' : 'Expand'} className={styles.expandButton} onClick={handleClick} type="button">
                    <Svg
                        name="plus"
                        thick
                        className={clsx(styles.expandButtonIcon, {
                            [styles.expanded]: !isOpen,
                        })}
                    />
                </button>
            )}
        </Link>
    );
};

export enum THeadType {
    Default,
    Clean,
}

interface IGridTableProps extends IWithClassName {
    children: JSX.Element[];
    tHeadType?: THeadType;
}

/**
 * @description
 * Sortable table
 *
 *
 * @example
 * import GridTable, { Cell, Row } from '~/shared/responsive-grid-table/responsive-grid-table.component'
 *

  <GridTable>
    {data.map((row, i) => (
      <Row key={i}>
        <Cell label="licensePLate">
          <LicensePlate number={row?.licensePlate?.number} />
        </Cell>
        <Cell hideOnMobile label="displayDate">{row?.displayDate}</Cell>
        <Cell hideOnMobile label="number">{row?.number}</Cell>
        <Cell label="quantity">{row?.quantity}</Cell>
        <Cell label="totalPrice">{row?.totalPrice}</Cell>
      </Row>
    ))}
  </GridTable>
 *
 */
const GridTable: FC<IGridTableProps> = ({ children = [], className, tHeadType = THeadType.Default }) => {
    const rowEl = children[0];

    const headerRow = {
        className: rowEl?.props.className,
    };

    interface IHeaderCell {
        label: string;
        className: string;
    }

    const headers: IHeaderCell[] =
        rowEl?.props?.children
            ?.filter((child: JSX.Element) => child)
            ?.map((child: JSX.Element) => ({
                label: child?.props?.label,
                className: child?.props?.className,
            })) || [];

    return (
        <div className={clsx(styles.table, className)}>
            <div
                className={clsx(headerRow.className, {
                    [styles.theadDefault]: tHeadType === THeadType.Default,
                    [styles.theadClean]: tHeadType === THeadType.Clean,
                })}
            >
                {headers?.map((header, i) => (
                    <div key={`${header.label}-${i}`} className={clsx(header.className, styles.headerCell)}>
                        {header.label}
                    </div>
                ))}
            </div>
            {children}
        </div>
    );
};

export default GridTable;
