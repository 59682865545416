import { BasketSubmitManualOrderContainer } from '../basket-submit-manual-order/basket-submit-manual-order.container';
import { BasketSubmitContainer } from '../basket-submit/basket-submit.container';
import styles from './basket-footer.module.scss';
import { BasketFooterCommentsContainer } from './components/basket-footer-comments/basket-footer-comments.container';
import { BasketFooterDeleteBasketContainer } from './components/basket-footer-delete-basket/basket-footer-delete-basket.container';
import { BasketFooterPrintOrderContainer } from './components/basket-footer-print-order/basket-footer-print-order.container';
import { BasketFooterSaveBasketContainer } from './components/basket-footer-save-basket/basket-footer-save-basket.container';

export function BasketFooter() {
    return (
        <footer className={styles.basketFooter}>
            <BasketFooterCommentsContainer />

            <div className={styles.basketFooterButtonsWrapper}>
                <BasketFooterDeleteBasketContainer />

                <BasketFooterSaveBasketContainer />

                <BasketSubmitContainer />

                <BasketSubmitManualOrderContainer />
            </div>

            <BasketFooterPrintOrderContainer />
        </footer>
    );
}
