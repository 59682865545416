import React from 'react';
import clsx from 'clsx';
import styles from '~/shared/notification-banner/components/styles.module.scss';

export type NotificationBannerColor = 'dark-green' | 'blue' | 'light-blue';

export type NotificationBannerProps = {
    color: NotificationBannerColor;
    children: React.ReactElement | React.ReactElement[];
};

export function NotificationBanner({ color, children }: NotificationBannerProps) {
    return (
        <div
            className={clsx(styles.root, {
                [styles.statusResolved]: color === 'dark-green',
                [styles.statusWarning]: color === 'blue',
                [styles.informationBanner]: color === 'light-blue',
            })}
        >
            {children}
        </div>
    );
}
