import { useCreditDetails } from '~/libs/queries/basket/hooks/use-credit-details';
import { Orders as OrderTypes } from '~/models/orders.d';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import CreditSection from './credit-section/credit-section.component';
import Header from './header/header.component';

type IProps = {
    creditId?: number;
    credit?: OrderTypes.ICreditViewModel;
    sellerOrderNumber: number | undefined;
    isOpen?: boolean;
    backUrl?: string;
    searchQuery?: string;
};

export default function CreditDetails({ credit, sellerOrderNumber, backUrl, isOpen, searchQuery, creditId }: IProps) {
    const {
        data: creditDetails,
        isError,
        isLoading,
    } = useCreditDetails({
        id: creditId,
        sellerOrderNumber,
        shouldFetch: isOpen,
    });

    const sections = isOpen ? creditDetails?.items : credit?.items;

    const isSellerOrderNumber = creditId === 0;

    const creditIdOrSellerOrderNumber = isSellerOrderNumber ? credit?.sellerOrderNumber : creditId;

    if (isOpen && isError) return <OverviewErrorBox />;
    if (isOpen && isLoading) return <OverviewLoader />;

    return (
        <div>
            <Header
                isSellerOrderNumber={isSellerOrderNumber}
                status={credit?.status ?? creditDetails?.status}
                backUrl={backUrl}
                requisition={credit?.requisition ?? (creditDetails?.requisition as string)}
                pdfUrl={credit?.pdfUrl ?? creditDetails?.pdfUrl}
                date={credit?.displayCreationDateTime ?? creditDetails?.displayDate}
                creditId={creditIdOrSellerOrderNumber}
                numberOfProducts={credit?.numberOfProducts ?? creditDetails?.numberOfProducts}
                total={credit?.total ?? creditDetails?.total}
                searchQuery={searchQuery}
            />

            {sections?.map((section, i) => <CreditSection key={i} section={section} searchQuery={searchQuery} />)}
        </div>
    );
}
