import React, { FC, forwardRef, Ref } from 'react';
import clsx from 'clsx';
import { Portal } from '~/shared/portal';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './dropdown-content.module.scss';
import { IButtonCommonProps } from '~/shared/buttons/buttons.models';
import ButtonWrapper from '../buttons/button-wrapper.component';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const DropdownItem: FC<IButtonCommonProps> = forwardRef(
    ({ children, className, ...restProps }: IButtonCommonProps, ref?: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>): JSX.Element => (
        <ButtonWrapper ref={ref} {...restProps} className={clsx(className, styles.item)}>
            {children}
        </ButtonWrapper>
    )
);

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title?: any;
    onClose: () => void;
}

const DropdownContent = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { title, children, onClose, ...rest } = props;

    return (
        <Portal className={styles.root}>
            <div className={styles.tooltipWrapper} ref={ref} {...rest}>
                <div className={styles.header}>
                    <Text textStyle="bodySmall" fontWeight="semiBold" className={styles.title}>
                        {title}
                    </Text>
                    <Button onClick={onClose} icon={<Svg name="close" />} buttonStyle="clean" buttonSize="small" />
                </div>
                <div className={styles.body}>{children}</div>
            </div>
        </Portal>
    );
});

export default DropdownContent;
