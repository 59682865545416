import React, { useCallback, useMemo, useState } from 'react';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { GA4BasketLoad } from '~/libs/ga4';
import useUser from '~/libs/use-user';
import { Basket as BasketTypes } from '~/models/basket.d';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import GridTable, { Cell, Row } from '~/shared/grid-table/grid-table.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import { Link } from '~/shared/link';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';
import { useDeleteBasketBatch } from '~/libs/queries/basket/hooks/use-delete-basket-batch';
import { useLoadSavedBasket, useLoadSavedBasketHandlers } from '~/libs/queries/basket';

type IBasketsListProps = {
    baskets: BasketTypes.ISavedBasket[];
    fetching: boolean;
    error: Error | undefined;
    reloadBaskets: () => void;
};

export default function BasketsList({ baskets = [], fetching, error, reloadBaskets }: IBasketsListProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const { isInternalUser } = useUser();
    const { mutate: mutateDeleteSavedBaskets } = useDeleteBasketBatch();
    const { mutateAsync: loadBasketAsync } = useLoadSavedBasket();
    const { handleError: handleLoadBasketError, handleSuccess: handleLoadBasketSuccess } = useLoadSavedBasketHandlers();

    const [basketsToRemove, setBasketsToRemove] = useState<Array<string>>([]);

    const isAllSelected = useMemo(() => {
        return baskets.every((b) => basketsToRemove.indexOf(b.id) !== -1);
    }, [baskets, basketsToRemove]);

    const handleReplaceBasket = (basketId?: string, customerId?: string) => async () => {
        dispatch({
            type: LayoutActionTypes.Impersonate,
            payload: { basketId, customerId: customerId },
        });

        GA4BasketLoad();

        if (basketId) {
            await loadBasketAsync(
                { basketId },
                {
                    onSuccess: handleLoadBasketSuccess,
                    onError: handleLoadBasketError,
                },
            );
        }
    };

    const toggleAllSelected = (value: boolean) => {
        if (value) {
            setBasketsToRemove(baskets.map(({ id }) => id));
        } else {
            setBasketsToRemove([]);
        }
    };

    const toggleSelectBasket = (isSelected: boolean, id: string) => {
        const newList = [...basketsToRemove];
        if (isSelected) {
            newList.splice(newList.indexOf(id), 1);
        } else {
            newList.push(id);
        }

        setBasketsToRemove(newList);
    };

    const onDeleteSelected = useCallback(() => {
        mutateDeleteSavedBaskets(
            {
                basketIds: basketsToRemove,
            },
            {
                onSuccess: () => {
                    setBasketsToRemove([]);
                    reloadBaskets();
                },
            },
        );
    }, [basketsToRemove, mutateDeleteSavedBaskets, reloadBaskets]);

    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!baskets?.length) return <OverviewNoResult />;

    return (
        <>
            <div className={styles.controls}>
                <Checkbox checked={isAllSelected} onChange={() => toggleAllSelected(!isAllSelected)} className={styles.selector}>
                    {translate('common.selectAll', 'Vælg alle')}
                </Checkbox>
                <Button buttonStyle="primary" disabled={basketsToRemove.length == 0} icon={<Svg name="bin" />} onClick={onDeleteSelected}>
                    {translate('overview.deleteAll', 'Slet valgte')} ({basketsToRemove?.length || 0})
                </Button>
            </div>
            <GridTable className={styles.root}>
                {baskets.map((row) => (
                    <Row key={row.id} verticalAlign>
                        <Cell label={translate('overview.removeLine', 'Vælg')} className={styles.mobileOnly}>
                            <Checkbox
                                checked={basketsToRemove.indexOf(row.id) !== -1}
                                onChange={() => {
                                    const isSelected = basketsToRemove.indexOf(row.id) !== -1;
                                    toggleSelectBasket(isSelected, row.id);
                                }}
                            ></Checkbox>
                        </Cell>

                        <Cell label={translate('overview.carId', 'bil-id')}>
                            <div className={styles.vehicleInfoWrapper}>
                                <Checkbox
                                    className={styles.desktopOnly}
                                    checked={basketsToRemove.indexOf(row.id) !== -1}
                                    onChange={() => {
                                        const isSelected = basketsToRemove.indexOf(row.id) !== -1;
                                        toggleSelectBasket(isSelected, row.id);
                                    }}
                                ></Checkbox>

                                {row?.carsInfo?.map((vehicle) => (
                                    <Link href={vehicle?.url} key={`${vehicle.carId}-${row.id}`}>
                                        <LicensePlate licensePlate={vehicle.licensePlate} carId={vehicle.carId} />
                                    </Link>
                                ))}
                            </div>
                        </Cell>
                        {isInternalUser && (
                            <Cell label={translate('overview.customerName', 'kunde')}>
                                <Text textStyle="monoSmall">{row.customerName}</Text>
                            </Cell>
                        )}
                        <Cell label={translate('overview.savedD', 'Gemt d.')}>
                            <Text textStyle="monoSmall">{row.displayDate}</Text>
                        </Cell>
                        <Cell label={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                            <Text textStyle="monoSmall">{row.requisition}</Text>
                        </Cell>
                        <Cell label={translate('basket.caseNo', 'Sags nr.')}>
                            <Text textStyle="monoSmall">{row.caseNo}</Text>
                        </Cell>
                        <Cell label={translate('basket.comment', 'Kommentar')}>
                            <Text textStyle="monoSmall">{row.comment}</Text>
                        </Cell>
                        <Cell label={translate('overview.totalPrice', 'totalpris (DKK)')}>
                            <Text textStyle="monoSmall">{row.totalPriceFormatted}</Text>
                        </Cell>
                        <Cell label={translate('overview.unSaveBasket', 'Slet kurv')}>
                            <div className={styles.removeWrapper}>
                                <Svg
                                    name="bin"
                                    className={styles.icon}
                                    onClick={() => {
                                        mutateDeleteSavedBaskets(
                                            {
                                                basketIds: [row.id],
                                            },
                                            {
                                                onSuccess: () => {
                                                    reloadBaskets();
                                                },
                                            },
                                        );
                                    }}
                                ></Svg>
                            </div>
                        </Cell>
                        <Cell>
                            <div className={styles.buttonWrapper}>
                                <Button onClick={handleReplaceBasket(row.id, row.canImpersonate ? row.userId : undefined)}>
                                    {translate('overview.loadBasket', 'Indlæs kurv')}
                                </Button>
                            </div>
                        </Cell>
                    </Row>
                ))}
            </GridTable>
        </>
    );
}
