import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import useVariantBatch from '~/libs/queries/products/hooks/use-variant-batch';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { CdnImage } from '~/shared/image/cdn-image';
import { Link } from '~/shared/link';
import { useLabelOrderStorage } from '../use-label-order.hook';
import styles from './styled.module.scss';

type LabelOrderListProps = HTMLAttributes<HTMLUListElement>;

//Name Before: page-elements/labels/label-order-list/label-order-list.component.tsx
function LabelOrderListContent() {
    const translate = useTranslations();
    const labelOrders = useLabelOrderStorage((state) => state.orders);
    const removeOrder = useLabelOrderStorage((state) => state.removeOrder);
    const { data: variants, isLoading, isError } = useVariantBatch(labelOrders);

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt')}</ErrorBox>;
    }

    if (isLoading) {
        return <div className={clsx(styles.messageContainer, styles.loading)}>{translate('common.loading', 'Indlæser')}</div>;
    }

    if (!variants?.length) {
        return <div className={styles.messageContainer}>{translate('common.noResults', 'Der er ingen resultater')}</div>;
    }

    return (
        <>
            {variants.filter(Boolean)?.map(({ id, title, images, url, brand, ftzCode }) => {
                const [image] = images ?? [];

                // TODO: Remove this when backend updates model to not have ftzCode nullable.
                if (!ftzCode) return null;

                return (
                    <li className={styles.item} key={id}>
                        <div className={styles.itemImage}>
                            {image?.url && <CdnImage width={90} height={90} src={image.url} alt={image.altText} objectFit="contain" />}
                        </div>
                        <div className={styles.itemColumn}>
                            {brand?.image?.url && (
                                <CdnImage width={60} height={30} src={brand.image.url} alt={brand.image.altText} objectFit="contain" />
                            )}
                            <Link href={url}>{title}</Link>
                        </div>
                        <div className={styles.itemColumn}>
                            <span className={styles.productId}>{translate('product.itemNo', 'Vare nr.')}</span>
                            <Link href={url}>{ftzCode}</Link>
                        </div>
                        <ButtonCircle className={styles.button} onClick={() => removeOrder(ftzCode)} iconName="bin" buttonStyle="dark" />
                    </li>
                );
            })}
        </>
    );
}

export default function LabelOrderList(props: LabelOrderListProps) {
    const { className } = props;
    return (
        <ul className={clsx(className, styles.list)}>
            <LabelOrderListContent {...props} />
        </ul>
    );
}
