import { Bff } from '~/models/bff';
import styles from './styled.module.scss';
import ImageSpot from '~/shared/image-spot/image-spot.component';

export default function M191ImageSpots({ columnOne, columnTwo, columnThree, id }: Bff.IM191ImageSpotsModuleViewModel) {
    if (!columnOne || !columnTwo || !columnThree || !id) return null;
    return (
        <div className={styles.columnsWrapper}>
            <div className={styles.column}>
                {columnOne?.map((item) => (
                    <ImageSpot
                        type={item.type}
                        key={item.image?.id}
                        className={columnOne.length === 1 ? styles.square : styles.rectangle}
                        imageSpot={item}
                        campaignId={id}
                    />
                ))}
            </div>
            <div className={styles.column}>
                {columnTwo?.map((item) => (
                    <ImageSpot
                        type={item.type}
                        key={item.image?.id}
                        className={columnTwo.length === 1 ? styles.square : styles.rectangle}
                        imageSpot={item}
                        campaignId={id}
                    />
                ))}
            </div>
            <div className={styles.column}>
                {columnThree?.map((item) => (
                    <ImageSpot
                        type={item.type}
                        key={item.image?.id}
                        className={columnThree.length === 1 ? styles.square : styles.rectangle}
                        imageSpot={item}
                        campaignId={id}
                    />
                ))}
            </div>
        </div>
    );
}
