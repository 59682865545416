import React, { FC, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import AttributeGroup from './attribute-group/attribute-group.component';
import { initialState, variantReducer, VariantActionType } from './variant-picker.reducer';
import { Products as ProductTypes } from '~/models/products.d';
import { initVariantPicker, findVariant } from './variant-picker.helper';
import styles from './variant-picker.module.scss';

interface IVariantPickerProps {
    itemNo: string;
    product: ProductTypes.IProduct;
}

const VariantPicker: FC<IVariantPickerProps> = ({ product, itemNo }: IVariantPickerProps) => {
    const [state, dispatch] = useReducer(variantReducer, initialState);
    const router = useRouter();
    const variants = product?.variantDefiningAttributes;

    const handleSelectAttribute = (attribute: { key: string; value: string; variants: number[] }) => {
        const { bestFilteredMatch, primaryAttributePossibleVariants, filteredPossibleVariants } = findVariant(
            attribute,
            product,
            state.primaryAttribute,
            state.filteredPossibleVariants
        );

        dispatch({
            type: VariantActionType.SetPrimaryAttribute,
            payload: {
                key: attribute.key,
                value: attribute.value,
            },
        });

        dispatch({
            type: VariantActionType.SetActiveVariant,
            payload: {
                activeVariant: bestFilteredMatch,
                primaryAttributePossibleVariants,
                filteredPossibleVariants,
            },
        });

        router.replace(bestFilteredMatch.url, undefined, { shallow: true });
    };

    useEffect(() => {
        if (!state.activeVariant && variants && variants.length) {
            const { primaryActiveVariantAttribute, primaryAttributePossibleVariants, activeVariant } = initVariantPicker(product, itemNo);

            dispatch({
                type: VariantActionType.InitVariantPicker,
                payload: {
                    activeVariant,
                    primaryActiveVariantAttribute,
                    primaryAttributePossibleVariants,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    if (!variants || !variants.length) {
        return null;
    }

    return (
        <div className={styles.variantPicker}>
            {variants
                // Hide if only one variant
                ?.filter((variant) => (variant.values?.length as number) > 1)
                .map((variant, i) => (
                    <AttributeGroup
                        key={`${variant.key}-${i}`}
                        group={variant}
                        activeVariant={state.activeVariant}
                        setSelectedAttribute={handleSelectAttribute}
                        activePossibleVariants={state.primaryAttributePossibleVariants}
                        activePrimaryAttribute={state.primaryAttribute}
                        activeFilteredPossibleVariants={state.filteredPossibleVariants}
                        product={product}
                    />
                ))}
        </div>
    );
};
export default VariantPicker;
