import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styled.module.scss';
import { SwiperCarousel } from '~/shared/swiper-carousel';
import Link from 'next/link';
import { CdnImage } from '~/shared/image/cdn-image';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';
import SwiperCore from 'swiper';
import Dots from '~/page-elements/campaign-slider-spot/dots/dots.component';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import { GA4CampaignClicked } from '~/libs/ga4';
import SwiperClass from 'swiper/types/swiper-class';
import useUser from '~/libs/use-user';
import { replaceUrlTokens } from '~/helpers';
import { Bff } from '~/models/bff';

export interface IHeroSliderSpotProps {
    slides: Bff.IHeroSlideViewModel[];
    campaignId?: string;
    type?: string;
}

const HeroSliderSpot: FC<IHeroSliderSpotProps> = ({ type, slides, campaignId }) => {
    const { profile } = useUser();
    const [cursor, setCursor] = useState<number>(0);
    const count = Math.min(5, slides?.length || 0);
    const showControls = count > 1;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef<SwiperClass | null>(null);
    const isMobile = useMediaQueryDetect(MediaQuery.MD);

    const onClick = (link?: Bff.ILinkViewModel, campaignId?: string, campaignSpot?: string) => {
        GA4CampaignClicked({
            to: link?.url || '#',
            campaignId,
            campaignSpot,
            campaignType: type,
        });
    };

    const onSlideChange = useCallback(
        (swiper: SwiperCore) => {
            setCursor(swiper.realIndex);
        },
        [setCursor],
    );

    useEffect(() => {
        const autoplay = swiperRef?.current?.autoplay;
        if (!autoplay) {
            return;
        }

        if (isMobile && autoplay.running) {
            autoplay.stop();
        } else if (!isMobile && !autoplay.running) {
            autoplay.start();
        }
    }, [swiperRef?.current, isMobile]);

    // keep reference to swiper carousel to be able to stop autoplay of the carousel on mobile
    const onSwiper = (swiper: SwiperClass) => {
        swiperRef.current = swiper;
    };

    const children = useMemo(() => {
        return slides
            .slice(0, 5)
            .map(({ callToAction, ...item }) => {
                if (!profile?.customer_id || !callToAction?.url) {
                    return { ...item, callToAction };
                }

                return {
                    ...item,
                    callToAction: {
                        ...callToAction,
                        url: replaceUrlTokens(callToAction.url, { '##customer_id##': profile.customer_id }),
                    },
                };
            })
            .map(({ id, callToAction, image, campaignSpot }) => (
                <div key={id} className={styles.slide}>
                    <Link href={callToAction?.url || '#'} passHref>
                        <a onClick={() => onClick(callToAction, campaignId, campaignSpot)} target={callToAction?.target}>
                            {image?.src ? <CdnImage src={image?.src} layout="fill" objectFit="cover" /> : null}
                        </a>
                    </Link>
                </div>
            ));
    }, [slides, campaignId, profile?.customer_id]);

    if (count === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <SwiperCarousel
                onSlideChange={onSlideChange}
                optionsOverride={{
                    loop: showControls,
                    slidesPerView: 1,
                    autoplay: !isMobile && {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                }}
                onSwiper={onSwiper}
                prevRef={prevRef}
                nextRef={nextRef}
                items={children}
            />

            {showControls && (
                <div className={styles.controlsWrapper}>
                    <Dots count={count} cursor={cursor} />

                    <div className={styles.controls}>
                        <Button ref={prevRef} className={styles.backButton} buttonStyle="secondary" icon={<Svg name="chevron-left" />} />
                        <Button ref={nextRef} className={styles.nextButton} buttonStyle="secondary" icon={<Svg name="chevron-right" />} />
                    </div>
                </div>
            )}
        </div>
    );
};

const M170HeroSlider = ({ type, slides, id }: Bff.IM170HeroSliderModuleViewModel) => {
    return <HeroSliderSpot type={type} slides={slides} campaignId={id} />;
};

export default M170HeroSlider;
