import React, { useCallback, useState } from 'react';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import CreateDepositCredit from '~/shared/create-credit/create-deposit-credit/create-deposit-credit.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { Modal } from '~/features/primitives/modal';

const Deposits = () => {
    const translate = useTranslations();
    const [isOpen, setOpen] = useState(false);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setOpen(false);
    }, []);

    return (
        <div className={styles.root}>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleOpenChange}
                title={translate('createCredit.returnDeposit', 'Returner depositum')}
            >
                <CreateDepositCredit onClose={() => setOpen(false)} />
            </Modal>

            <ButtonCircle
                iconName="refresh"
                onClick={() => setOpen(true)}
                text={translate('createCredit.depositForSpacialProducts', 'Depositum på batterier, AC flasker osv.')}
            />
        </div>
    );
};

export default Deposits;
