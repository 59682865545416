import clsx from 'clsx';
import { FocusEventHandler } from 'react';
import type { ChangeEventHandler, MutableRefObject, WheelEventHandler } from 'react';
import styles from './add-to-basket-quantity-input.module.scss';

export interface AddToBasketQuantityInputProps {
    value: number | undefined;
    isLarge?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
    onWheel?: WheelEventHandler<HTMLInputElement>;
    setInputHasFocus: (value: boolean) => void;
    inputRef: MutableRefObject<HTMLInputElement>;
}

export function AddToBasketQuantityInput({ value, isLarge, onChange, onBlur, onWheel, setInputHasFocus, inputRef }: AddToBasketQuantityInputProps) {
    return (
        <div
            className={clsx(styles.addToBasketQuantityInputWrapper, {
                [styles.addToBasketQuantityInputWrapperLarge]: isLarge,
            })}
        >
            {value}
            <input
                aria-label="Quantity"
                className={clsx(styles.addToBasketQuantityInput)}
                type="number"
                value={value}
                ref={inputRef}
                onChange={onChange}
                onWheel={onWheel}
                onFocus={() => setInputHasFocus(true)}
                onBlur={onBlur}
            />
        </div>
    );
}
