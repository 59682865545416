/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react';
import useLatestCustomersBaskets from '~/libs/queries/bff/hooks/use-lastest-customers-basket';
import { Basket } from '~/models/basket';
import { Users as UsersTypes } from '~/models/users';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import SkeletonTableRows from '~/shared/skeleton/skeleton-table-rows/skeleton-table-rows.component';
import Text from '~/shared/text/text.component';
import CustomerBaskets from '../customer-baskets/customer-baskets.component';
import styles from './styled.module.scss';

enum tableLenOptions {
    closed = 10,
    open = 30,
}

interface IProps {
    customers: UsersTypes.ILatestCustomer[];
    impersonate: (customerId: string) => void;
    isLoading?: boolean;
}

const CustomersList: FC<IProps> = ({ customers = [], impersonate, isLoading }) => {
    const {
        data: savedBaskets,
        isLoading: isLoadingBaskets,
        // isError: isErrorBaskets
    } = useLatestCustomersBaskets(customers.map((c) => c.customerId));

    const [tableLength, setTableLength] = useState(tableLenOptions.closed);
    const translate = useTranslations();
    const isOpen = tableLength === tableLenOptions.open;

    return (
        <div className={styles.root}>
            <table>
                <thead>
                    <tr>
                        <th>{translate('internalDashboard.departmentId', 'Afd.')}</th>
                        <th>{translate('internalDashboard.customerName', 'Kunde')}</th>
                        <th>{translate('internalDashboard.customerId', 'Kundenr.')}</th>
                        <th>{translate('internalDashboard.address', 'Adresse')}</th>
                        <th>{translate('internalDashboard.telephone', 'Tlf')}</th>
                        <th>{translate('internalDashboard.savedBaskets', 'Gemte kurve')}</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <SkeletonTableRows rows={10} colSpan={6} />
                    ) : (
                        customers.slice(0, tableLength).map((customer, i) => (
                            <tr
                                className={styles.row}
                                key={`${customer.customerId}-${i}`}
                                onClick={() => impersonate(customer.virtualUserId as string)}
                            >
                                <td>
                                    <Text textStyle="monoMedium">{customer.departmentId}</Text>
                                </td>
                                <td>
                                    <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                                        {customer.customerName}
                                    </Text>
                                </td>
                                <td>
                                    <Text textStyle="monoMedium">{customer.customerId}</Text>
                                </td>
                                <td>
                                    <Text textStyle="bodySmall">
                                        {customer.address && <Text textStyle="bodySmall">{customer.address},</Text>}
                                        {customer?.zipCodeAndCity && <Text textStyle="bodySmall">{customer?.zipCodeAndCity}</Text>}
                                    </Text>
                                </td>
                                <td>
                                    <Text textStyle="monoMedium">{customer.telephone}</Text>
                                </td>
                                <td className={styles.buttonCell} onClick={(e) => e.stopPropagation()} onMouseEnter={(e) => e.stopPropagation()}>
                                    <CustomerBaskets
                                        isLoading={isLoadingBaskets}
                                        virtualCustomerId={customer.virtualUserId as string}
                                        basketsData={savedBaskets?.[customer?.customerId] as Basket.ILatestInternalSavedBasketsOverview}
                                    />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            {customers?.length > tableLenOptions.closed && (
                <div className={styles.buttonWrapper}>
                    <Button
                        buttonSize="small"
                        buttonStyle="secondary"
                        onClick={() => setTableLength(isOpen ? tableLenOptions.closed : tableLenOptions.open)}
                    >
                        {isOpen ? translate('common.showLess', 'Vis mindre') : translate('common.showMore', 'Vis mere')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CustomersList;
