import React from 'react';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './overview-error-box.component.module.scss';

interface IOverviewErrorBoxProps {
    children?: string;
}

const OverviewErrorBox: React.FC<IOverviewErrorBoxProps> = ({ children }) => {
    const translate = useTranslations();

    return <ErrorBox className={styles.root}>{children || translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
};

export default OverviewErrorBox;
