import React, { FC } from 'react';
import Button from '~/shared/buttons/button/button.component';
import Text from '~/shared/text/text.component';
import styles from './axle-info.module.scss';

type AxleInfoProps = {
    infoList: Array<{
        axleNumber: number;
        tireWidth: number;
        tireProfile: number;
        rimDiameter: number;
    }>;
    onAxleSelect: (axleNumber: number) => void;
};

const AxleInfoMemo: FC<AxleInfoProps> = ({ infoList, onAxleSelect }) => (
    <section className={styles.wrapper}>
        {infoList.map((info, index) => (
            <article key={`${info.tireWidth}-${info.rimDiameter}-${info.tireProfile}-${index}`} className={styles.axleInfo}>
                <Text>{info.axleNumber === 1 ? 'For' : 'Bag'}</Text>
                <div className={styles.axleInfo}>
                    <Text>{info.tireWidth}</Text>/<Text>{info.tireProfile}</Text>/<Text>{info.rimDiameter}</Text>
                </div>
                <Button buttonStyle="secondary" onClick={() => onAxleSelect(info.axleNumber)}>
                    Vælg
                </Button>
            </article>
        ))}
    </section>
);

export default React.memo(AxleInfoMemo);
