import { Bff } from '~/models/bff';
import Styles from './styled.module.scss';

export default function M071Video({ videoId }: Bff.IM071VideoModuleViewModel) {
    const videoSrc = `https://customer-e8ow56ct53t1th82.cloudflarestream.com/${videoId}/iframe?poster=https%3A%2F%2Fcustomer-e8ow56ct53t1th82.cloudflarestream.com%2F${videoId}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600`;

    return (
        <div className={Styles.videoWrapper}>
            <iframe
                src={videoSrc}
                title={videoId}
                loading="lazy"
                style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
            />
        </div>
    );
}
