import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import styles from './product-description.module.scss';
import { IWithClassName } from '~/models/dev';
import Skeleton from '~/shared/skeleton';
import useTranslations from '~/shared/hooks/use-translations.hook';
import RawHtml from '~/page-elements/raw-html/raw-html.component';
import Text from '~/shared/text/text.component';

import { Collapse } from '~/shared/collapse/collapse.component';
import Button from '../buttons/button/button.component';
import { VehicleOilInfo } from '~/libs/queries/vehicles';
import { Products as ProductTypes } from '~/models/products';
import { ProductVehicleInfo } from '~/shared/product-vehicle-info';
import { Svg } from '../svg';

interface IProps extends IWithClassName {
    description: string | undefined;
    loading?: boolean;
    vehicleInfoText?: ProductTypes.IProductInfoArticle;
    vehicleInfoOilText?: {
        [key: string]: Array<VehicleOilInfo>;
    };
    height?: number;
    backGroundColor?: 'White' | 'Primary';
    importantText?: string;
}

const ProductDescription: FC<IProps> = ({
    description,
    className,
    vehicleInfoText,
    vehicleInfoOilText,
    loading,
    height = 80,
    backGroundColor = 'White',
    importantText,
}) => {
    const translate = useTranslations();

    const [isTooHeigh, setIsTooHeigh] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);
    const textBox = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if ((textBox.current as HTMLElement)?.offsetHeight > height + 20) {
            setIsTooHeigh(true);
            setOpen(false);
        } else {
            setIsTooHeigh(false);
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, vehicleInfoText, vehicleInfoOilText, height]);

    const renderedOilTexts = useMemo(
        () =>
            vehicleInfoOilText
                ? Object.keys(vehicleInfoOilText).map((line) => (
                      <ul className={styles.vehicleInfoOilText} key={line}>
                          <Text tagName="li" fontWeight="semiBold" textStyle="bodySmall">
                              {line}
                          </Text>

                          {vehicleInfoOilText[line].map((oilTextItem) =>
                              Object.keys(oilTextItem)
                                  .filter((k) => (k as keyof typeof oilTextItem) !== 'masterNumberGroupingRuleLabel')
                                  .map((key) => {
                                      const value = oilTextItem[key as keyof typeof oilTextItem];
                                      return value && value.length > 0 ? (
                                          <Text tagName="li" fontWeight="regular" textStyle="bodySmall">
                                              {value}
                                          </Text>
                                      ) : null;
                                  }),
                          )}
                      </ul>
                  ))
                : null,
        [vehicleInfoOilText],
    );
    if (loading) {
        return (
            <div className={className}>
                <div ref={textBox} className={styles.descriptionUnfolded}>
                    <Skeleton style={{ height: '13px', width: '85%', marginBottom: '10px' }} />
                    <Skeleton style={{ height: '13px', width: '75%', marginBottom: '10px' }} />
                    <Skeleton style={{ height: '13px', width: '30%' }} />
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(styles.root, className)}>
            <Collapse
                className={clsx(styles.animation, styles[`background${backGroundColor}`], {
                    // eslint-disable-next-line indent
                    [styles.animationCollapsed]: !isOpen,
                    // eslint-disable-next-line indent
                })}
                isOpen={isOpen}
                variants={{
                    open: { height: 'auto' },
                    collapsed: { height: 100 },
                }}
            >
                <div className={clsx(styles.textBox)} ref={textBox}>
                    {vehicleInfoText?.articles ? <ProductVehicleInfo articles={vehicleInfoText.articles} /> : null}

                    {renderedOilTexts}

                    {description ? <RawHtml content={description} /> : null}
                </div>
            </Collapse>

            {isTooHeigh && (
                <Button
                    buttonStyle="clean"
                    buttonSize="link"
                    className={styles.button}
                    onClick={() => {
                        setOpen(!isOpen);
                    }}
                >
                    {isOpen ? translate('common.readLess', 'Læs mindre') : translate('common.readMore', 'Læs mere')}
                </Button>
            )}
            {importantText ? (
                <div className={styles.warningText}>
                    <span style={{ width: 20 }}>
                        <Svg name={'badge-warning'} className={styles.icon} />
                    </span>

                    <Text tagName="p" textStyle="bodySmall" fontWeight="semiBold">
                        {importantText}
                    </Text>
                </div>
            ) : null}
        </div>
    );
};

export default ProductDescription;
