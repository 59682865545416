import React, { FC } from 'react';
import clsx from 'clsx';
import Price from '~/shared/price/price.component';
import Skeleton from '~/shared/skeleton';
import useTranslations from '../hooks/use-translations.hook';
import styles from './price-tooltip.module.scss';
import { IWithClassName } from '~/models/dev';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';

interface IProps extends IWithClassName {
    displayPrice: string | undefined;
    isLoading?: boolean;
    deposit?: string | number;
    fee?: string | number;
}

const PriceTooltip: FC<IProps> = ({ displayPrice, isLoading, className, deposit, fee }) => {
    const translate = useTranslations();

    if (isLoading) {
        return (
            <div className={className}>
                <Skeleton style={{ height: '20px', width: '90px' }} />
            </div>
        );
    }

    if (!displayPrice) return null;

    return (
        <div className={clsx(styles.root, className)}>
            <Price>{displayPrice || translate('product.callForPrice', 'Ring for pris')}</Price>
            {deposit || fee ? (
                <Tooltip
                    content={
                        <TooltipContentWrapper>
                            {deposit ? (
                                <Price color="grey">
                                    {translate('product.deposit', 'Depositum')} {deposit}
                                </Price>
                            ) : null}
                            {fee ? (
                                <Price color="grey">
                                    {translate('product.fee', 'Afgift')} {fee}
                                </Price>
                            ) : null}
                        </TooltipContentWrapper>
                    }
                />
            ) : null}
        </div>
    );
};

export default PriceTooltip;
