import React from 'react';
import cx from 'clsx';

import styles from './module-row.module.scss';

export type ModuleRowProps = {
    children: React.ReactNode | React.ReactNode[];
    spacing?: string;
    className?: string;
};

export function ModuleRow({ children, spacing = 'default', className }: ModuleRowProps) {
    return (
        <div
            className={cx(styles.moduleRow, className, {
                // TODO: align spacing prop with backend so it is uniform
                [styles.moduleRowSpacingDefault]: spacing === null || spacing === 'default' || spacing === 'none',
                [styles.moduleRowSpacingSmall]: spacing === 'small',
            })}
        >
            {children}
        </div>
    );
}
