import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './styled.module.scss';
import Input from '~/shared/form-elements/input/input.component';
import FileInput from '~/shared/form-elements/file-input/file-input.component';
import Button from '~/shared/buttons/button/button.component';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import useTranslations from '~/shared/hooks/use-translations.hook';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import Text from '~/shared/text/text.component';
import Radio from '~/shared/form-elements/radio/radio.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';
import Label from '~/shared/form-elements/common/label/label.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import { useLayoutDispatch, addErrorToast, addSuccessToast } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import Select from '~/shared/form-elements/select/select.component';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';
import { useRequestOeProduct, UseRequestOeProductParams } from '~/libs/queries/products';

const acceptedFileExtensions = ['.jpg', '.png', '.bmp', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.gif', '.zip', '.rar'];

export type OEFormFields = UseRequestOeProductParams & {
    // Permissions are currently not sent to the backend, and is therefor only a part of the form UI wise.
    terms: boolean;
};

interface IProps {
    vehicleDetails: VehicleTypes.IVehicleDetails;
    customerDetails?: {
        email: string;
        userName: string;
        customerId: number;
        companyName: string;
        phoneNumber: string;
        primaryDepartmentId: number;
        addresses: string[];
    };
}

const OEForm: FC<IProps> = ({ vehicleDetails = {}, customerDetails = {} }) => {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const [files, setFiles] = useState<File[]>([]);
    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();

    const shouldShowIncludeUserInCc = shouldShowFeature(features?.carBanner?.oeSparePartsRequestByEmail);

    const { isInternalUser } = useUser();

    const { mutate: requestOeProductMutation, isLoading: isRequestingOeProduct } = useRequestOeProduct();

    const { email, userName, customerId, companyName, phoneNumber, primaryDepartmentId, addresses } = customerDetails;
    const { licensePlate, engineCode, vin, typeYearFrom, typeYearTo, manufacturerName, modelName, modelTypeName, modelTypeId } = vehicleDetails;

    const {
        register,
        clearErrors,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        control,
        reset,
    } = useForm<OEFormFields>({
        mode: 'onBlur',
        defaultValues: {
            Department: primaryDepartmentId ? String(primaryDepartmentId) : undefined,
            PhoneNumber: phoneNumber,
            ManufacturerName: manufacturerName,
            ModelTypeId: modelTypeId ? String(modelTypeId) : undefined,
            ModelName: modelName,
            ModelTypeName: modelTypeName,
            FirstRegistration: typeYearFrom,
            Contact: userName,
            Email: email,
            CustomerNumber: customerId ? String(customerId) : undefined,
            CompanyName: companyName,
            RegNr: licensePlate?.number,
            Vin: vin,
            EngineCode: engineCode,
            LastInspection: typeYearTo,
        },
    });

    const maxFilesSizeForEmail = files.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);
    const emailSizeWarning = shouldShowIncludeUserInCc && maxFilesSizeForEmail >= 25 * 1024 * 1024; /* 25mb limit*/

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const doSubmit = useCallback(
        (data: OEFormFields) => {
            requestOeProductMutation(
                { ...data, Files: files },
                {
                    onSuccess: (data) => {
                        if (data?.errorMessage) {
                            // The response was 200, but the response included an error message,
                            // so we assume that the request failed.
                            dispatch(addErrorToast(data.errorMessage));
                            return;
                        }

                        dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                        reset();

                        timeoutRef.current = setTimeout(() => {
                            window.close();
                        }, 3000);
                    },
                    onError: () => {
                        dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
                    },
                },
            );
        },
        [dispatch, files, requestOeProductMutation, translate],
    );

    useEffect(() => {
        return () => {
            // We clean up the timeout if the component unmounts. We do this to prevent potential memory leaks
            clearTimeout(timeoutRef.current);
        };
    }, []);

    const handleVinOrRegNoChange = useCallback(() => clearErrors(['Vin', 'RegNr']), []);

    const subjectErrorMessage = translate('oeSpareParts.validation.subject', 'Vælg venligst typen af din forespørgsel');

    const vinFormField = watch('Vin');
    const regNoFormField = watch('RegNr');

    return (
        <form onSubmit={handleSubmit(doSubmit)}>
            <div className={styles.formGrid}>
                <div className={styles.col}>
                    <fieldset className={styles.caseType}>
                        <legend>
                            {translate('oeSpareParts.chooseWhatYouWant', 'Vælg hvad du vil')}
                            <span className={styles.requiredStar}>*</span>
                        </legend>
                        <div className={styles.radio}>
                            <Radio
                                {...register('Subject', { required: subjectErrorMessage })}
                                value={translate('oeSpareParts.oeRequest', 'OE-Forespørgsel')}
                            >
                                {translate('oeSpareParts.oeRequest', 'OE-Forespørgsel')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                {...register('Subject', { required: subjectErrorMessage })}
                                value={translate('oeSpareParts.oeOrder', 'OE-Bestilling')}
                            >
                                {translate('oeSpareParts.oeOrder', 'OE-Bestilling')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio
                                {...register('Subject', { required: subjectErrorMessage })}
                                value={translate('oeSpareParts.oePrices', 'OE-Priser (kræver reservedelsnr.)')}
                            >
                                {translate('oeSpareParts.oePrices', 'OE-Priser (kræver reservedelsnr.)')}
                            </Radio>
                        </div>
                        <div className={styles.radio}>
                            <Radio {...register('Subject', { required: subjectErrorMessage })} value={translate('oeSpareParts.other', 'Andet')}>
                                {translate('oeSpareParts.other', 'Andet')}
                            </Radio>
                        </div>
                        {errors?.Subject && <ValidationErrorMessage>{errors.Subject.message}</ValidationErrorMessage>}
                    </fieldset>
                </div>
                <div className={styles.col}>
                    <fieldset className={styles.caseType}>
                        <legend>{translate('sharedFormTranslations.uploadFiles', 'Upload filer')}</legend>
                        <Text textStyle="bodySmall">
                            {translate(
                                'oeSpareParts.uploadFileLimitations',
                                'Max 3 stk. i format .jpg, .png, .bmp, .pdf, .xls, .xlsx, .doc, .docx eller .gif. Må ikke fylde mere end 25 MB hver.',
                            )}
                        </Text>
                        <FileInput
                            errorMessage={
                                emailSizeWarning
                                    ? translate(
                                          'sharedFormTranslations.emailSizeWaring',
                                          'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                                      )
                                    : null
                            }
                            acceptedFileExtensions={acceptedFileExtensions}
                            accept={acceptedFileExtensions.join(', ')}
                            maxFilesNumber={shouldShowIncludeUserInCc ? undefined : 3}
                            maxFileSize={25 * 1024 * 1024 /* 25mb limit per file*/}
                            onFilesChange={setFiles}
                        />
                    </fieldset>
                </div>
            </div>

            <fieldset className={styles.fieldset}>
                <legend>
                    {isInternalUser
                        ? translate('oeSpareParts.myInfo', 'Mine oplysninger')
                        : translate('oeSpareParts.customerInfo', 'Kundeoplysninger')}
                </legend>
                <div className={styles.formGrid}>
                    <div className={styles.col}>
                        <Input
                            {...register('Contact')}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.contactPerson', 'Kontaktperson')}
                        />
                        <Label required>{translate('customer.email', 'E-mail')}</Label>
                        <Input
                            {...register('Email', {
                                required: true,
                            })}
                            className={styles.emailInput}
                            aria-label="email"
                            type="email"
                            wrapperClassName={styles.textInput}
                            errorMessage={errors?.Email ? errors?.Email?.message : ''}
                        />
                        <Input
                            {...register('CustomerNumber')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('customer.customerId', 'Kunde nr')}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('CompanyName')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('customer.companyName', 'Firmanavn')}
                        />
                        <Select {...register('Address')} label={translate('customer.address', 'Adresse')}>
                            {addresses?.map((addr) => (
                                <option value={addr} key={addr}>
                                    {addr}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('Department')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('customer.department', 'Afdeling')}
                        />
                        <Input {...register('PhoneNumber')} wrapperClassName={styles.textInput} label={translate('customer.phone', 'Telefon')} />
                    </div>
                </div>
            </fieldset>

            <fieldset className={styles.fieldset}>
                <legend>{translate('oeSpareParts.caseInfo', 'Sagsoplysninger')}</legend>
                <div className={styles.formGrid}>
                    <div className={styles.col}>
                        <Label required>{translate('oeSpareParts.vehicleInfo', 'Vehicle Info')}</Label>
                        <fieldset className={styles.requiredCarInfo}>
                            <Controller
                                name="RegNr"
                                control={control}
                                rules={{
                                    required: vinFormField.length === 0,
                                }}
                                render={({ field: { onChange, ...field } }) => {
                                    return (
                                        <Input
                                            {...field}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleVinOrRegNoChange();
                                            }}
                                            wrapperClassName={styles.textInput}
                                            label={translate('vehicle.regNr', 'Reg nr.')}
                                        />
                                    );
                                }}
                            />

                            <Controller
                                name="Vin"
                                control={control}
                                rules={{
                                    required: regNoFormField.length === 0,
                                }}
                                render={({ field: { onChange, ...field } }) => {
                                    return (
                                        <Input
                                            {...field}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleVinOrRegNoChange();
                                            }}
                                            wrapperClassName={styles.textInput}
                                            label={translate('vehicle.chassisNumber', 'Stelnummer')}
                                        />
                                    );
                                }}
                            />
                            {errors?.RegNr ?? errors?.Vin ? (
                                <ValidationErrorMessage>
                                    {translate('sharedFormTranslations.regNumberOrVin', 'Udfyld enten Stelnr. eller Reg. Nr')}
                                </ValidationErrorMessage>
                            ) : null}
                        </fieldset>
                        <Input {...register('EngineCode')} wrapperClassName={styles.textInput} label={translate('vehicle.engineCode', 'Motorkode')} />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('ModelName')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.modelName', 'Model')}
                        />
                        <div className={styles.dateRangeGrid}>
                            <Input
                                {...register('FirstRegistration')}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearFrom', 'Fra årgang')}
                            />
                            <Input
                                {...register('LastInspection')}
                                readOnly
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearTo', 'Til årgang')}
                            />
                        </div>
                        <Input
                            {...register('ModelTypeName')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.type', 'Type')}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            {...register('ModelTypeId')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.modelTypeId', 'FTZ Biltype nr')}
                        />
                        <Input
                            {...register('ManufacturerName')}
                            readOnly
                            wrapperClassName={styles.textInput}
                            label={translate('vehicle.manufacturerName', 'Mærke')}
                        />
                        <Input
                            {...register('ErrorCodes')}
                            wrapperClassName={styles.textInput}
                            label={translate('oeSpareParts.errorCodes', 'Fejlkoder')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
                <div className={styles.formGrid}>
                    <div className={styles.descriptionCol}>
                        <TextArea
                            className={styles.description}
                            label={translate('common.description', 'beskrivelse')}
                            {...register('Description')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset className={styles.fieldset}>
                {shouldShowIncludeUserInCc && (
                    <Checkbox {...register('IncludeUserInCc')}>
                        {translate(
                            'sharedFormTranslations.includeUserInCcCheckBox',
                            'Jeg ønsker selv at modtage en kopi af mailen (Mailen bliver sendt til den mail som du har udfyldt i "E-MAIL" filtet)',
                        )}
                    </Checkbox>
                )}
            </fieldset>
            <Text className={styles.formLegend}>{translate('common.fieldsMustBeFilledOut', 'Felter skal udfyldes')}</Text>
            <div className={styles.checkbox}>
                <Checkbox {...register('terms', { required: true })}>
                    {translate(
                        'oeSpareParts.termsAndConditions',
                        'Jeg accepterer at FTZ må gemme mine oplysninger i 6 måneder og andre vilkår som gør sig gældende for denne formular',
                    )}
                </Checkbox>
            </div>
            {errors?.Subject && <ValidationErrorMessage>{errors.Subject.message}</ValidationErrorMessage>}

            {errors?.Email && <ValidationErrorMessage>{errors.Email?.message}</ValidationErrorMessage>}

            {errors?.RegNr ?? errors?.Vin ? (
                <ValidationErrorMessage>
                    {translate('sharedFormTranslations.regNumberOrVin', 'Udfyld enten Stelnr. eller Reg. Nr')}
                </ValidationErrorMessage>
            ) : null}

            {emailSizeWarning && (
                <ValidationErrorMessage>
                    {translate(
                        'sharedFormTranslations.emailSizeWaring',
                        'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                    )}
                </ValidationErrorMessage>
            )}

            <Button disabled={!isValid} type="submit" className={styles.submitButton} fetching={isRequestingOeProduct}>
                {translate('oeSpareParts.sendInquiry', 'Send forespørgsel')}
            </Button>
        </form>
    );
};

export default OEForm;
