import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Basket as BasketTypes } from '~/models/basket.d';
import { CmsData } from '~/models/cms-data.d';
import useUser from '~/libs/use-user';
import useActiveUser from '~/libs/use-active-user';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { basketKeys } from '~/libs/queries/basket';

export function useSavedBasketsTotal() {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { activeProfile } = useActiveUser();
    const { baskets, createUrl } = useEndpoints();
    const { selectedDepartment } = useLayoutState();

    const endpoint = createUrl({
        endpoint: baskets.getSavedBaskets,
        localeOption: LocaleOptions.query,
        query: { departmentId: selectedDepartment?.id },
    });

    return useQuery<BasketTypes.ISavedBasketOverview, CmsData.IError>({
        queryKey: basketKeys.savedBasketsTotal(locale, activeProfile?.id, selectedDepartment?.id),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn,
    });
}
