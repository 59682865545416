import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CREDIT_DRAFTS } from '~/constants/local.storage';
import { MAIL_FORMAT } from '~/constants/validation';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import { useInvoiceDetails } from '~/libs/queries/users/hooks/use-invoice-details';
import useUser from '~/libs/use-user';
import { Orders as OrderTypes } from '~/models/orders.d';
import { ICreditDraft } from '~/widgets/overview/credits/credit-drafts/credit-drafts.component';
import createCreditMultiple from '~/services/orders/create-credit-multiple.service';
import createCredit from '~/services/orders/create-credit.service';
import createOECredit from '~/services/orders/create-oe-credit.service';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import { useStaticContent } from '~/libs/queries/bff';
import PageTitle from '../page-title/page-title.component';
import Heading from '../heading/heading.component';
import InvoiceDetails from './invoice-details/invoice-details.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Checkbox from '../form-elements/checkbox/checkbox.component';
import Label from '../form-elements/common/label/label.component';
import Input from '../form-elements/input/input.component';
import TextArea from '../form-elements/text-area/text-area.component';
import { ICreateCreditProps, ICreditItem, createStatus } from './create-credit.d';
import styles from './create-credit.module.scss';
import Item from './item/item.component';
import SubmitFeedback from './submit-feedback/submit-feedback.component';

interface IFormData {
    emailInCc: string;
    creditReasonDescription: string;
}

interface IProps extends ICreateCreditProps {
    onClose: (creditId?: number) => void;
    creditDraftDetails?: OrderTypes.ICreditDraftDetails;
    isOECredit?: boolean;
}

const CreateCredit: FC<IProps> = ({ items, invoiceDate, invoiceId, onClose, carInfo, creditDraftDetails, isOECredit }) => {
    const translate = useTranslations();
    const { profile, user } = useUser();
    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();
    const { data: staticContent } = useStaticContent();
    const { refetch } = useInvoiceDetails(invoiceId);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormData>();
    const [fetching, setFetching] = useState(false);
    const [status, setStatus] = useState<createStatus>(createStatus.initial);
    const [creditId, setCreditId] = useState<null | number>(null);
    const [drafts, setDrafts] = useLocalStorage<ICreditDraft[]>(CREDIT_DRAFTS);
    const [stateItems, setStateItems] = useState<ICreditItem[]>([]);
    const [oeRequestCheck, setOeRequestCheck] = useState<boolean>(false);
    const [requisition, setRequisition] = useState('');
    const [messages, setMessages] = useState<string[]>([]);
    const router = useRouter();
    const showSaveButton = shouldShowFeature(features?.credits?.multipleCredits);
    const isExternalCredit = !!creditDraftDetails;

    const getSelectedValue = (quantity: number, colli: number, colliLocked: boolean) => {
        if (quantity === 1) return 1;
        if (colliLocked && colli >= quantity) return quantity;
        return 0;
    };

    useEffect(() => {
        setStateItems(
            items.map((item) => ({
                ...item,
                selectedQuantity: getSelectedValue(
                    // some items might have been returned
                    item?.possibleToCreditQuantity,
                    item?.colli,
                    item?.colliLocked,
                ),
                depositOnly: false,
                quantityWarn: false,
            })),
        );
    }, [items]);

    const handleChangeDeposit = (itemId: string | number, val: boolean) => {
        const newItems = [...stateItems];
        const objIndex = newItems.findIndex((obj) => obj.itemId === itemId);
        newItems[objIndex].depositOnly = val;

        // if is deposit product and user select depositOnly we reset the value
        if (newItems[objIndex].isDepositProduct && val) {
            newItems[objIndex].selectedQuantity = 0;
        } else {
            newItems[objIndex].selectedQuantity = getSelectedValue(
                newItems[objIndex]?.possibleToCreditQuantity,
                newItems[objIndex]?.colli,
                newItems[objIndex]?.colliLocked,
            );
        }
        setStateItems(newItems);
    };

    const handleChangeQuantity = (itemId: string | number, quantity: number) => {
        const newItems = [...stateItems];
        const objIndex = newItems.findIndex((obj) => obj.itemId === itemId);
        newItems[objIndex].selectedQuantity = quantity;
        newItems[objIndex].quantityWarn = false;
        setStateItems(newItems);
    };

    const handleDeleteItem = (itemId: string | number) => {
        const newItems = stateItems.filter((item) => item.itemId !== itemId);
        setStateItems(newItems);
    };

    const validateQuantity = () => {
        let isValid = true;

        const newItems = stateItems.map((item) => {
            if (item.selectedQuantity === 0) {
                isValid = false;
                return { ...item, quantityWarn: true };
            }

            return { ...item, quantityWarn: false };
        });

        setStateItems(newItems);

        return isValid;
    };

    const handleCreateOECredit = async (formData: IFormData) => {
        const postData: OrderTypes.ICreditOeInvoiceRequest = {
            emailInCc: formData.emailInCc,
            creditReasonDescription: formData.creditReasonDescription,
            requisition,
            items: stateItems.map((item) => ({
                itemId: item?.itemId,
                quantity: item?.selectedQuantity,
            })),
        };

        try {
            const response = await createOECredit(invoiceId, postData, user);
            if (response.errorMessage) {
                setMessages([response.errorMessage]);
                setStatus(createStatus.notCreated);
            } else {
                setStatus(createStatus.created);
            }
        } catch (error) {
            setStatus(createStatus.error);
        } finally {
            await refetch();
            setFetching(false);
        }
    };

    const handleCreateCredit = async () => {
        const postData: OrderTypes.ICreditInvoiceRequest = {
            requisition,
            items: stateItems.map((item) => ({
                quantity: item?.selectedQuantity,
                itemNo: item?.itemId,
                hasDeposit: item?.hasDeposit,
                depositOnly: item?.depositOnly,
                licensePlate: carInfo?.licensePlate?.number,
                carId: carInfo?.carId,
                invoiceId: Number(item?.invoiceId || invoiceId),
            })),
        };

        try {
            const response = isExternalCredit ? await createCreditMultiple(postData, user) : await createCredit(invoiceId, postData, user);

            setMessages(response.messages as string[]);
            if (isExternalCredit && response?.status === OrderTypes.CreateCreditStatus.Created) {
                onClose(response?.creditId);
            }

            if (response?.statusName === OrderTypes.CreateCreditStatus.Created) {
                setStatus(createStatus.created);
                setCreditId(response?.creditId as number);
            } else if (response?.statusName === OrderTypes.CreateCreditStatus.NotCreated) {
                setStatus(createStatus.notCreated);
            } else {
                setStatus(createStatus.error);
            }
        } catch (error) {
            setStatus(createStatus.error);
        } finally {
            !isExternalCredit && (await refetch());
            setFetching(false);
        }
    };

    const handleSubmitForm = (formData: IFormData) => {
        // disable submit credit for regular credits with the new requirements
        if (!isExternalCredit && !isOECredit) return;

        const isQuantityValid = validateQuantity();

        if (!isQuantityValid) return;

        setStatus(createStatus.initial);
        setFetching(true);

        if (isOECredit) {
            handleCreateOECredit(formData);
        } else {
            handleCreateCredit();
        }
    };

    const handleSaveDraft = () => {
        const isQuantityValid = validateQuantity();
        if (!isQuantityValid) return;

        const creditItems: ICreditDraft[] = stateItems.map((item) => ({
            timeStamp: Date.now(),
            invoiceId: Number(invoiceId),
            invoiceUrl: router.asPath,
            quantity: item?.selectedQuantity,
            depositOnly: item?.depositOnly,
            licensePlate: item?.carInfo?.licensePlate?.number,
            carId: item?.carInfo?.carId,
            hasErrors: false,
            itemNo: item?.itemId,
            image: item?.image,
            title: item?.title,
            brand: item?.brand,
            itemUrl: item?.url,
            creditLocation: item?.creditLocation,
            hasDeposit: item?.hasDeposit,
            price: item?.unitPrice?.netPriceFormatted,
            deposit: item?.unitPrice?.depositFormatted,
        }));

        setDrafts([...(drafts || []), ...creditItems]);
        if (staticContent) {
            router.push(staticContent?.urlMappings?.creditsOverviewPage ?? '#');
        }
    };

    if (status === createStatus.notCreated || status === createStatus.created) {
        return (
            <SubmitFeedback
                messages={messages}
                status={status}
                setStatus={setStatus}
                isOECredit={isOECredit}
                creditId={creditId as number}
                onClose={onClose}
                creditDraftDetails={creditDraftDetails}
                isExternalCredit={isExternalCredit}
            />
        );
    }

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <PageTitle className={styles.pageTitle}>
                <Heading tagName="h4">{translate('overview.orderDetails', 'Odredetaljer')}</Heading>
            </PageTitle>

            <InvoiceDetails
                fetching={fetching}
                itemsCount={stateItems?.reduce((acc, cur) => acc + cur.quantity, 0)}
                invoiceId={invoiceId}
                invoiceDate={invoiceDate}
                showRequisition={!!(isExternalCredit || isOECredit)}
                requisition={requisition}
                setRequisition={setRequisition}
            />

            <section>
                <PageTitle>
                    <Heading tagName="h4">{translate('overview.itemDetails', 'Varedetaljer')}</Heading>
                </PageTitle>
                {isOECredit && carInfo?.manufacturer && (
                    <ValueWithCaption caption={translate('vehicle.manufacturerName', 'Mærke')}>
                        <Text>{carInfo?.manufacturer}</Text>
                    </ValueWithCaption>
                )}
                {stateItems?.map((item, i) => (
                    <Item
                        fetching={fetching}
                        key={`${item.itemId}-${i}`}
                        item={item}
                        onDeleteItem={handleDeleteItem}
                        onChangeQuantity={handleChangeQuantity}
                        onChangeDeposit={handleChangeDeposit}
                    />
                ))}
            </section>

            {isOECredit && (
                <section>
                    <PageTitle>
                        <Heading tagName="h4">{translate('createCredit.additionalInformation', 'Yderligere Information')}</Heading>
                    </PageTitle>
                    <Label required>{translate('createCredit.replySentTo', 'Svar sendes til')}</Label>
                    <Input
                        type="email"
                        wrapperClassName={styles.input}
                        defaultValue={profile?.email}
                        errorMessage={errors?.emailInCc && errors.emailInCc.message}
                        {...register('emailInCc', {
                            required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                            pattern: {
                                value: MAIL_FORMAT,
                                message: translate('common.invalidEmail', 'Den indtastede email er ikke gyldig'),
                            },
                        })}
                    />

                    <Label required>{translate('createCredit.addReasonForDesiredCredit', 'Tilføj årsag for ønsket kreditering')}</Label>
                    <TextArea
                        errorMessage={errors?.creditReasonDescription && errors.creditReasonDescription.message}
                        wrapperClassName={styles.input}
                        {...register('creditReasonDescription', {
                            required: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
                        })}
                    />

                    <Checkbox checked={oeRequestCheck} onChange={(e) => setOeRequestCheck(e.target.checked)}>
                        {translate(
                            'createCredit.oeCreditCheckBox',
                            'Når der klikkes på ”Forespørg OE kreditering” afsendes forespørgslen nu til FTZ OE for tjek om det er muligt, at returnere. Kontroller at e-mail adresse er korrekt, det er denne som vil være afsender og modtage svaret på forespørgslen.',
                        )}
                    </Checkbox>
                </section>
            )}

            {status === createStatus.error && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}

            <div className={styles.buttons}>
                {showSaveButton && !isOECredit && !isExternalCredit && (
                    <Button disabled={fetching || !stateItems.length} buttonStyle="secondary" onClick={handleSaveDraft}>
                        {translate('overview.addCredit', 'Tilføj til kreditering')}
                    </Button>
                )}
                {(isExternalCredit || isOECredit) && (
                    <Button
                        type="submit"
                        className={styles.submitButton}
                        fetching={fetching}
                        disabled={fetching || !stateItems.length || (isOECredit && !oeRequestCheck)}
                    >
                        {isOECredit
                            ? translate('createCredit.requestOECreditSubmitButton', 'Forespørg OE kreditering')
                            : translate('createCredit.submitButton', 'Indsend kreditering')}
                    </Button>
                )}
            </div>
            {creditDraftDetails?.cancelRedirectUrl && (
                <Button buttonStyle="clean" buttonSize="link" onClick={() => onClose()}>
                    {translate('createCredit.cancelRedirectLink', 'Annuller kreditering og gå tilbage til [clientDisplayName]').replace(
                        '[clientDisplayName]',
                        creditDraftDetails?.clientDisplayName,
                    )}
                </Button>
            )}
        </form>
    );
};

export default CreateCredit;

export * from './create-credit.d';
