import { useState } from 'react';
import { Bff } from '~/models/bff';
import { NotificationBanner, NotificationBannerCloseButton } from '~/shared/notification-banner';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

export default function M150InformationBanner({ text }: Bff.IM150InformationBannerModuleViewModel) {
    const [isClosed, setIsClosed] = useState(false);

    const handleOnClick = () => {
        setIsClosed(true);
    };

    return (
        <>
            {!isClosed ? (
                <div className={styles.infoBannerWrapper}>
                    <NotificationBanner color="light-blue">
                        <Text textAlign="center">{text}</Text>
                        <NotificationBannerCloseButton onClick={handleOnClick} color="black" />
                    </NotificationBanner>
                </div>
            ) : null}
        </>
    );
}
