import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState, type MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { EasingFramer, TimingFramer } from '~/constants/animation';
import { Products as ProductTypes } from '~/models/products.d';
import AddFavorite from '~/shared/add-favorite/add-favorite.component';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import Price from '~/shared/price/price.component';
import StockStatus from '~/shared/stock-status/stock-status.component';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './other-variants.module.scss';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';

export type OtherVariantItemProps = {
    variant: ProductTypes.IVariant;
    isLoadingPrices: boolean;
    prices?: ProductTypes.IVariantPrices;
    onAddToBasket?: () => void;
    isSmall?: boolean;
};

export function OtherVariantItem({ variant, prices, isLoadingPrices, onAddToBasket, isSmall }: OtherVariantItemProps) {
    const router = useRouter();
    const translate = useTranslations();

    const { brand, itemNo, colli, colliLocked, unit, id, url, productGroups } = variant;

    return (
        <div
            key={id}
            className={clsx(styles.variantWrapper, {
                [styles.mobileView]: isSmall,
            })}
        >
            <div className={styles.brandWrapper}>
                <BrandLogo brand={brand as ProductTypes.IBrand} />
            </div>
            <div className={styles.itemNumber}>
                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr.')} captionClassName={styles.caption} />
                <Link
                    href={url}
                    onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                        event.preventDefault();

                        url && router.replace(url, undefined, { shallow: true });
                    }}
                >
                    <Text title={itemNo} textStyle="monoMedium" overflowEllipsis>
                        {itemNo}
                    </Text>
                </Link>
            </div>
            <div className={styles.ftzNumber}>
                <ValueWithCaption caption={translate('product.ftzNo', 'FTZ nummer.')} captionClassName={styles.caption} />
                <FtzPricesTooltip unit={unit} isLoading={isLoadingPrices} prices={prices as IColliPrice} colli={colli} colliLocked={colliLocked} />
            </div>
            <div className={styles.stockInfo}>
                <ValueWithCaption caption={translate('product.stockStatus', 'Lagerstatus')} captionClassName={styles.caption} />
                <StockStatus itemNo={itemNo} subGroup={productGroups?.subGroup} />
            </div>
            <Price weight="regular" size="medium" fetching={isLoadingPrices} className={styles.priceCell}>
                {prices?.actualPrice?.displayPrice || 'N/A'}
            </Price>
            <div className={styles.basketWrapper}>
                <AddFavorite
                    product={variant}
                    price={prices?.actualPrice}
                    itemId={itemNo}
                    placement="detail-variants"
                    itemNoList={[itemNo] as string[]}
                />

                <AddToBasketContainer
                    syncWithBasket
                    className={styles.basketButton}
                    url={variant.url as string}
                    onAddToBasket={onAddToBasket}
                    productDetails={{
                        itemNo: itemNo || '',
                        customerPrice: prices ? prices?.actualPrice?.customerPrice : null,
                        colli: colli,
                        colliLocked: colliLocked,
                    }}
                />
            </div>
        </div>
    );
}

export type OtherVariantProps = {
    otherProductVariants?: ProductTypes.IVariant[];
    onAddToBasket?: () => void;
    isSmall?: boolean;
};

const OtherVariants: FC<OtherVariantProps> = ({ otherProductVariants = [], onAddToBasket, isSmall }) => {
    const translate = useTranslations();
    const isPortable = useMediaQueryDetect(MediaQuery.XL);

    const [isCollapsed, setCollapsed] = useState(false);

    const variantsPost = otherProductVariants?.map((v) => ({
        articleId: v.itemNo?.toString(),
        quantity: v?.colliLocked ? v.colli : 1,
    }));

    const { data: prices, isLoading: isLoadingPrices } = useProductPrices(variantsPost as ProductTypes.IArticle[]);

    const containerHeight = isPortable ? 180 : 450;
    const itemsVisible = isPortable ? 4 : 3;
    const numOfRestVariants = otherProductVariants.length - itemsVisible;

    return (
        <div className={styles.root}>
            <motion.div
                initial={{ height: 0 }}
                animate={{ height: isCollapsed ? containerHeight : 'auto' }}
                transition={{
                    duration: TimingFramer.SLOW,
                    ease: EasingFramer.BOUNCE_IN,
                }}
                className={clsx(styles.tableWrapper, {
                    [styles.tableWrapperCollapsed]: isCollapsed,
                })}
            >
                {otherProductVariants?.map((variant) => (
                    <OtherVariantItem
                        key={variant?.itemNo}
                        variant={variant}
                        isLoadingPrices={isLoadingPrices}
                        prices={variant?.itemNo ? prices?.[variant.itemNo] : undefined}
                        onAddToBasket={onAddToBasket}
                        isSmall={isSmall}
                    />
                ))}
            </motion.div>
            {otherProductVariants.length > itemsVisible ? (
                <AnimatePresence initial={false}>
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: TimingFramer.SLOW,
                            ease: EasingFramer.BOUNCE_IN,
                        }}
                        type="button"
                        onClick={() => setCollapsed((prev) => !prev)}
                        className={styles.collapseButton}
                    >
                        {isCollapsed
                            ? translate('common.restVariants', 'Vis [number] flere').replace('[number]', String(numOfRestVariants))
                            : translate('common.showLess', 'Vis mindre')}
                    </motion.button>
                </AnimatePresence>
            ) : null}
        </div>
    );
};

export default OtherVariants;
