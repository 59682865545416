import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type ICaseDetailsProps = {
    date: string;
    fittingKm: number;
    complaintKm: number;
};

export default function CaseDetails({ date, fittingKm, complaintKm }: ICaseDetailsProps) {
    const translate = useTranslations();
    return (
        <div className={styles.details}>
            <ValueWithCaption caption={translate('returns.fittingDate', 'Montering')}>
                <Text textStyle="monoSmall">{date}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('returns.fittingKm', 'Km. ved montering')}>
                <Text textStyle="monoSmall">{fittingKm}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('returns.complaintKm', 'Km. ved reklamation')}>
                <Text textStyle="monoSmall">{complaintKm}</Text>
            </ValueWithCaption>
        </div>
    );
}
