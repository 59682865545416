import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';
import { Users as UserTypes } from '~/models/users';

export type UseUpdateOrAddSubUserProps = {
    userId?: number;
    query?: string;
};

export function useAddOrUpdateSubUser({ userId, query }: UseUpdateOrAddSubUserProps) {
    const { locale } = useRouter();
    const queryClient = useQueryClient();
    const { user } = useUser();

    return useMutation<UserTypes.IUserCreateOrUpdateResponse, UserTypes.IUserCreateOrUpdateError, UserTypes.ICreateSubUserInfoRequest>({
        mutationFn: async (updatedUserData) => {
            return apiClient.auth(user).request(
                createUrl({
                    endpoint: userId ? `${endpoints.users.externalSubUsers}/${userId}` : endpoints.users.externalSubUsers,
                    localeOption: LocaleOptions.path,
                }),
                userId ? 'PUT' : 'POST',
                {
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUserData),
                },
            );
        },
        onMutate: () => queryClient.cancelQueries(queryKeys.users.subUsers(locale, userId, query)),
        onSettled: () => queryClient.invalidateQueries(queryKeys.users.subUsers(locale, userId, query)),
    });
}
