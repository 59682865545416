import cx from 'clsx';
import React, { useRef } from 'react';
import { GA4SisterCategoryClicked } from '~/libs/ga4';
import { Products as ProductTypes } from '~/models/products';
import { SwiperCarousel } from '~/shared/swiper-carousel';
import { SiblingCategoryItem } from '../../components/sibling-category-item/sibling-category-item';
import styles from './sibling-categories.module.scss';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';

export type SiblingCategoriesProps = {
    className?: string;
    categories: ProductTypes.ICategoryNameWithUrl[];
};

export function SiblingCategories({ className, categories }: SiblingCategoriesProps) {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const onClickSibling = (to: string) => {
        const from = categories.find((c) => c.selected)?.name;
        GA4SisterCategoryClicked({
            from: from as string,
            to,
        });
    };

    return (
        <div className={cx(styles.siblingCategories, className)}>
            <SwiperCarousel
                prevRef={prevRef}
                nextRef={nextRef}
                items={categories.map((item) => (
                    <SiblingCategoryItem key={item.url} item={item} onClick={onClickSibling} />
                ))}
            />
            <div className={styles.siblingCategoriesButtonContainer}>
                <Button ref={prevRef} className={styles.siblingCategoriesButton} buttonStyle="clean" icon={<Svg name="chevron-left" />} />
                <Button ref={nextRef} className={styles.siblingCategoriesButton} buttonStyle="clean" icon={<Svg name="chevron-right" />} />
            </div>
        </div>
    );
}
