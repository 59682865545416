import { CmsData } from '~/models/cms-data';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import GridTable, { Cell, Row } from '~/shared/grid-table/grid-table.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import Text from '~/shared/text/text.component';

type IReturnsListProps = {
    customerComplaints: CmsData.IReturnsList;
    fetching: boolean;
    error: Error | undefined;
};

export default function ReturnsList({ customerComplaints = [], fetching, error }: IReturnsListProps) {
    const translate = useTranslations();

    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!customerComplaints?.length) return <OverviewNoResult />;

    return (
        <GridTable>
            {customerComplaints.map((row, i) => (
                <Row key={`${row.id}-${i}`} href={row.url}>
                    <Cell label={translate('returns.carId', 'Bil-id')}>
                        <LicensePlate hideCountryCode licensePlate={row?.carInfo?.licensePlate} carId={row?.carInfo?.carId} vin="-" />
                    </Cell>
                    <Cell label={translate('returns.caseNo', 'Sags nr.')}>
                        <Text textStyle="monoMedium">{row?.caseNumber}</Text>
                    </Cell>
                    <Cell label={translate('returns.creditNoteNo', 'Kreditnota nr.')}>
                        <Text textStyle="monoMedium">{row?.creditNumber}</Text>
                    </Cell>
                    <Cell label={translate('returns.createdAt', 'Oprettet')}>
                        <Text textStyle="monoMedium">{row?.createdDisplayDate}</Text>
                    </Cell>
                    <Cell label={translate('returns.status', 'Status')}>
                        <Text fontWeight="semiBold" textStyle="monoMedium">
                            {row?.status}
                        </Text>
                    </Cell>
                    <Cell label={translate('returns.itemNo', 'Vare nr.')}>
                        <Text textStyle="monoMedium">{row?.itemId}</Text>
                    </Cell>
                    <Cell label={translate('returns.itemText', 'Varetekst')}>
                        <Text textStyle="bodySmall">{row?.title}</Text>
                    </Cell>
                </Row>
            ))}
        </GridTable>
    );
}
