import React, { CSSProperties, HTMLAttributes, useMemo, useRef } from 'react';
import Link from 'next/link';
import Heading from '~/shared/heading/heading.component';
import { CdnImage } from '~/shared/image/cdn-image';
import { GA4CampaignClicked } from '~/libs/ga4';
import clsx from 'clsx';
import useResponsiveTypography from '~/shared/hooks/use-responsive-typography';
import { replaceUrlTokens } from '~/helpers';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';

import styles from './styled.module.scss';

interface ICampaignImageSpotData extends HTMLAttributes<HTMLDivElement> {
    imageSpot: Bff.IImageSpotViewModel;
    campaignId: string;
    type?: string;
}

const CampaignImageSpot: React.FC<ICampaignImageSpotData> = ({ className, imageSpot, campaignId, type }) => {
    const { profile } = useUser();
    const { image, callToAction, backgroundColor, textColor, primaryText, secondaryText, subText, id } = imageSpot;

    const { getInlineStyles } = useResponsiveTypography();

    const mainStyle = {
        ...(!!textColor && { '--body-color': `${textColor}` }),
        ...(!!backgroundColor && { '--bg-color': `${backgroundColor}` }),
    } as CSSProperties;

    const onClick = (link?: Bff.ILinkViewModel) => {
        GA4CampaignClicked({
            to: link?.url ?? '',
            campaignId,
            campaignSpot: imageSpot.campaignSpot,
            campaignType: type,
        });
    };

    const replacedLink = useMemo(() => {
        if (!callToAction?.url) {
            return;
        }

        if (!profile?.customer_id) {
            return callToAction.url;
        }

        return replaceUrlTokens(callToAction?.url, { '##customer_id##': profile?.customer_id });
    }, [callToAction?.url, profile?.customer_id]);

    return (
        <div className={clsx(className, styles.spot)} style={mainStyle}>
            {callToAction && replacedLink && (
                <Link href={replacedLink} passHref>
                    <a
                        className={clsx(styles.link, styles.fixed)}
                        target={callToAction.target}
                        onClick={() => onClick(callToAction)}
                        aria-label={callToAction.title}
                    ></a>
                </Link>
            )}

            {(primaryText || secondaryText || subText) && (
                <div className={styles.content}>
                    <Heading tagName="h3" displayStyle="h2" className={styles.title} style={getInlineStyles('h2')}>
                        {primaryText}
                    </Heading>
                    <Heading tagName="h4" displayStyle="h3" className={styles.subHeading} style={getInlineStyles('h3')}>
                        {secondaryText}
                    </Heading>
                    {subText ? <RawHtmlComponent className={styles.bodyText} content={subText} /> : null}
                </div>
            )}

            {image?.src && (
                <div className={styles.imageContainer}>
                    <CdnImage src={image.src} width={image.width ?? 500} height={image.height ?? 500} objectFit="cover" layout="fill" />
                </div>
            )}
        </div>
    );
};

export default CampaignImageSpot;
