import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { Svg } from '~/shared/svg';
import styles from './splash.module.scss';
import Text from '~/shared/text/text.component';
import { IWithClassName } from '~/models/dev';
import useTranslations from '../hooks/use-translations.hook';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';

export type splashType = 'OE' | '1' | '2' | '4' | 'S';

interface IProps extends IWithClassName {
    type: splashType;
    small?: boolean;
    text?: string;
}

const Splash: FC<IProps> = ({ type, small, className, text }) => {
    const translate = useTranslations();

    const splashData = useMemo(() => {
        switch (type) {
            case 'OE':
                return {
                    iconName: 'splash-OE',
                    text: text || translate('splashes.oeMatch', 'OE match'),
                };

            case '2':
            case '4':
                return {
                    iconName: 'splash-sale',
                    text: text || translate('splashes.sale', 'Prisbasker'),
                };

            case 'S':
                return {
                    iconName: 'splash-spot',
                    text: text || translate('splashes.spot', 'Spotpris'),
                };

            default:
                return null;
        }
    }, [type, text, translate]);

    if (!splashData) return null;

    const isSale = ['1', '2'].includes(type);

    const wrapperClassName = clsx(className, {
        [styles.rootSmall]: small,
        [styles.rootBig]: !small,
        [styles.splashType4]: isSale,
        [styles[`splashType${type}`]]: !isSale,
    });

    const splashContent = (
        <>
            <Svg name={splashData?.iconName} className={styles.icon} />
            {!small && (
                <Text tagName="span" className={styles.text} textStyle="bodySmall" fontWeight="semiBold">
                    {splashData?.text}
                </Text>
            )}
        </>
    );

    if (small) {
        return (
            <Tooltip className={wrapperClassName} content={<TooltipContentWrapper>{splashData.text}</TooltipContentWrapper>}>
                {splashContent}
            </Tooltip>
        );
    }

    return <div className={wrapperClassName}>{splashContent}</div>;
};

export default Splash;
