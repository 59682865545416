import React, { FC, useState } from 'react';
import clsx from 'clsx';
import styles from './sortable-table.module.scss';
import { ISortableTableHeader, ISortableTableRow, ISortableTableLinkRow, ISortableTableData } from './sortable-table.model';
import { IWithClassName } from '~/models/dev';
import { Link } from '~/shared/link';

interface ISortableTableProps extends IWithClassName {
    tableData: ISortableTableData;
}

interface ITableRowProps {
    row: ISortableTableRow;
    keys: (string | number)[];
}

interface ITableLinkRowProps {
    row: ISortableTableLinkRow;
    keys: (string | number)[];
}

function isLinkRow(row: ISortableTableRow): row is ISortableTableLinkRow {
    return row.url != null;
}

const LinkRow: FC<ITableLinkRowProps> = ({ row, keys }) => (
    <tr className={styles.linkRow}>
        {keys.map((key) => (
            <td key={key}>
                <Link href={row.url} className={styles.link} prefetch={false}>
                    {row[key]}
                </Link>
            </td>
        ))}
    </tr>
);

const StandardRow: FC<ITableRowProps> = ({ row, keys }) => (
    <tr>
        {keys.map((key) => (
            <td key={key}>
                <span className={styles.cellContent}>{row[key]}</span>
            </td>
        ))}
    </tr>
);

const Row: FC<ITableRowProps | ITableLinkRowProps> = ({ row, keys }) => {
    if (isLinkRow(row)) {
        return <LinkRow row={row} keys={keys} />;
    }

    return <StandardRow row={row} keys={keys} />;
};

/**
 * @author KPA
 *
 * @description
 * Sortable table
 *
 * In @tableHeaders you should specify:
 *   @key - it will be used to map properties from @ISortableTableRow
 *   @value - that will be th value for the column
 *   @sortable - defines if the column should be sortable
 *
 *
 * !!! NOTE:
 *  If ISortableTableRow contains property @url it will be used as a link for all elements in the row.
 *  @ISortableTableRow could accept JSX.Element but you shouldn't sort that column
 *
 *
 * @example
 * <SortableTable tableData={sortableTableData} />
 *
 * TODO: Add different table themes
 *
 */
const SortableTable: FC<ISortableTableProps> = ({ tableData, className }): JSX.Element => {
    const [sortKey, setSortKey] = useState<string | number | null>(null);
    const [isASC, setOrderASC] = useState<boolean>(true);

    const { tableHeaders, tableRows } = tableData;

    const headerKeys = tableHeaders.map((th) => th.key);

    const handleChangeSort = (key: string | number) => {
        if (key === sortKey) {
            setOrderASC(!isASC);
        } else {
            setSortKey(key);
            setOrderASC(true);
        }
    };

    const renderTableHeaders = tableHeaders.map((th: ISortableTableHeader) => {
        const btn = (
            <button aria-label="Sort By" className={clsx('cleanButton', styles.sortBtn)} type="button" onClick={() => handleChangeSort(th.key)}>
                {th.value}
                <span className={styles.buttonArrows}>
                    <span className={th.key === sortKey && isASC ? styles.buttonArrowUpActive : styles.buttonArrowUpInactive} />
                    <span className={th.key === sortKey && !isASC ? styles.buttonArrowDownActive : styles.buttonArrowDownInactive} />
                    <span />
                </span>
            </button>
        );

        return (
            <th key={th.key}>
                <span className={styles.cellContent}>{th.sortable ? btn : th.value}</span>
            </th>
        );
    });

    const sortedRowsData =
        sortKey != null
            ? [...tableRows].sort((a, b) => {
                  if (typeof a === 'number') {
                      const A = a[sortKey] as number;
                      const B = b[sortKey] as number;
                      return isASC ? A - B : B - A;
                  }
                  const A = a[sortKey].toString().toUpperCase();
                  const B = b[sortKey].toString().toUpperCase();
                  if (A < B) {
                      return isASC ? -1 : 1;
                  }
                  if (A > B) {
                      return isASC ? 1 : -1;
                  }
                  return 0;
              })
            : tableRows;

    return (
        <table className={clsx(styles.table, className)}>
            <thead className={styles.tableHead}>
                <tr>{renderTableHeaders}</tr>
            </thead>
            <tbody className={styles.tableBody}>
                {sortedRowsData.map((row) => (
                    <Row key={row.id} row={row} keys={headerKeys} />
                ))}
            </tbody>
        </table>
    );
};

export default SortableTable;
