import { useLayoutState } from '~/context/layout.context';
import { useCheckoutBasket, useDeleteBasketCarGroup } from '~/libs/queries/basket';
import { Basket as BasketTypes } from '~/models/basket.d';
import { IWithClassName } from '~/models/dev.d';
import { BasketItemsGroupCar } from './basket-items-group-car';

export type BasketItemsGroupWithCarInfoProps = IWithClassName & {
    id: string;
    items: BasketTypes.IBasketItemDto[];
    carInfo: BasketTypes.IBasketCarInfoDto;
};

export function BasketItemsGroupCarContainer({ id, items, carInfo }: BasketItemsGroupWithCarInfoProps) {
    const { data: basket } = useCheckoutBasket();
    const { mutate: deleteBasketCarGroup, isLoading: isDeletingBasketCarGroup } = useDeleteBasketCarGroup();
    const { selectedDepartment } = useLayoutState();

    const handleDeleteVehicle = async () => {
        if (!basket) {
            console.error('Could not delete basket car group. Basket was undefined.');
            return;
        }

        deleteBasketCarGroup({
            departmentId: selectedDepartment?.id,
            data: {
                basketId: basket.id,
                groupId: id,
            },
        });
    };

    return (
        <BasketItemsGroupCar id={id} items={items} carInfo={carInfo} handleDeleteClick={handleDeleteVehicle} isLoading={isDeletingBasketCarGroup} />
    );
}
