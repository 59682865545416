import Text from '~/shared/text/text.component';

import styles from './topmotive-body.module.scss';

export type TopmotiveBodyProps = {
    children: string;
};

export function TopmotiveBody({ children }: TopmotiveBodyProps) {
    return <Text className={styles.topmotiveBody}>{children}</Text>;
}
