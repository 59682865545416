import React, { FC } from 'react';
import cx from 'clsx';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './thumbnails.module.scss';
import { Bff } from '~/models/bff.d';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';

interface IThumbnails {
    images: Bff.IImage[];
    activeThumbnailIndex: number;
    setActiveThumbnail: ([page, direction]: any) => void;
}

const Thumbnails: FC<IThumbnails> = ({ images, activeThumbnailIndex, setActiveThumbnail }) => {
    const onHandleSlideClick = (index: number) => {
        const direction = activeThumbnailIndex > index ? -1 : 1;
        setActiveThumbnail([index, direction]);
    };

    const isMobile = useMediaQueryDetect(MediaQuery.SM);

    return (
        <div className={styles.thumbnails}>
            {images?.map((image, index) => (
                <button
                    aria-label={`Image of ${image?.altText} button`}
                    type="button"
                    key={`image-${index}`}
                    onClick={() => onHandleSlideClick(index)}
                    className={cx(styles.thumbnail, {
                        [styles.dotThumbnail]: isMobile,
                        [isMobile ? styles.activeMobile : styles.active]: activeThumbnailIndex === index,
                    })}
                >
                    {!isMobile ? <CdnImage src={image?.url as string} alt={image?.altText} height={60} width={60} /> : null}
                    {/* <CdnImage src={image} width={600} height={300} alt="Test" /> */}
                </button>
            ))}
        </div>
    );
};

export default Thumbnails;
