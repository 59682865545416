import Button from '~/shared/buttons/button/button.component';

export type BasketSubmitManualOrderProps = {
    label: string;
    handleSubmit: () => void;
    isDisabled: boolean;
    isLoading: boolean;
};

export function BasketSubmitManualOrder({ label, handleSubmit, isDisabled, isLoading }: BasketSubmitManualOrderProps) {
    return (
        <Button disabled={isDisabled} fetching={isLoading} onClick={handleSubmit} buttonStyle="secondary">
            {label}
        </Button>
    );
}
