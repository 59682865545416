import React, { FC } from 'react';
import { useTireOptions } from '~/libs/queries/bff/hooks/use-tire-options';
import Calculator from './calculator/calculator.component';
import Loader from '~/shared/loader/loader.component';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '../hooks/use-translations.hook';
import { Vehicles as VehicleTypes } from '~/models/vehicles';

type ContentProps = {
    axle?: VehicleTypes.IWheelData;
};

const Content: FC<ContentProps> = ({ axle }) => {
    const { data, isError, isLoading } = useTireOptions();
    const translate = useTranslations();

    if (isLoading) return <Loader />;
    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return <Calculator tireOptions={data} axle={axle} />;
};

export default Content;
