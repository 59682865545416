import { Basket as BasketTypes } from '~/models/basket.d';
import { BasketItemsLineItemContainer } from '../basket-items-line-item/basket-items-line-item.container';
import styles from './basket-items-group.module.scss';

export type BasketItemsGroupProps = {
    items: BasketTypes.IBasketItemDto[];
};

export function BasketItemsGroup({ items }: BasketItemsGroupProps) {
    return (
        <section className={styles.basketItemsGroup}>
            {items.map((item) => (
                <BasketItemsLineItemContainer key={item.itemId} item={item} />
            ))}
        </section>
    );
}
