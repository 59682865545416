import clsx from 'clsx';
import { useState } from 'react';
import { Bff } from '~/models/bff';
import RawHtml from '~/page-elements/raw-html/raw-html.component';
import Accordion from '~/shared/accordion/accordion.component';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';
import { GA4CampaignClicked } from '~/libs/ga4';

export default function M120Accordion({ id, items, headline, campaignSpot, type }: Bff.IM120AccordionModuleViewModel) {
    const [open, setOpen] = useState<number[]>([]);

    const handleItemClick = (index: number, campaignSpot?: string) => {
        if (open.includes(index)) {
            setOpen((prevOpen) => prevOpen.filter((i) => i !== index));
        } else {
            // Only tracking when opening accordion
            // Where to redirect?
            GA4CampaignClicked({
                to: '#',
                campaignSpot,
                campaignId: id,
                campaignType: type,
            });

            setOpen((prevOpen) => [...prevOpen, index]);
        }
    };

    return (
        <div className={styles.accordionWrapper}>
            <div className={styles.root}>
                <Text className={styles.accordionTitle} tagName="h1" fontWeight="semiBold">
                    {headline}
                </Text>
                {items?.map((item, index) => (
                    <Accordion
                        key={index}
                        header={
                            <Text tagName="h2" textStyle="body" fontWeight="semiBold">
                                {item.headline}
                            </Text>
                        }
                        isOpen={open.includes(index)}
                        onClick={() => handleItemClick(index, campaignSpot)}
                    >
                        {item.richText ? (
                            <div className={clsx(styles.content)}>
                                <RawHtml content={item.richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />
                            </div>
                        ) : null}
                    </Accordion>
                ))}
            </div>
        </div>
    );
}
