import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

interface IOrderInfoProps extends IWithClassName {
    caseNo: string;
    requisitionNo: string;
    webOrderNumber: string;
}

export default function OrderInfo({ caseNo, requisitionNo, webOrderNumber, className }: IOrderInfoProps) {
    const translate = useTranslations();

    return (
        <div className={clsx(styles.orderInfo, className)}>
            <ValueWithCaption caption={translate('overview.webOrders', 'webordrer')}>
                <Text textStyle="monoMedium">{webOrderNumber}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('overview.caseNo', 'sags nr.')}>
                <Text textStyle="monoMedium">{caseNo}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                <Text textStyle="monoMedium">{requisitionNo}</Text>
            </ValueWithCaption>
        </div>
    );
}
