import { CmsData } from '~/models/cms-data';
import { useEffect, useState } from 'react';
import { useUpdateManualWebOrderCount } from '~/libs/queries/users/hooks/use-updated-manual-web-orders-count';
import useUser from '~/libs/use-user';
import usePrevious from '~/shared/hooks/use-previous';
import { dequal } from 'dequal';
import { allDepartmentsId } from '~/libs/queries/routing/hooks/use-departments';
import { IOrdersWithSubmitStatus } from '~/libs/queries/users/hooks/use-manual-web-orders';

const useSortedManualWebOrders = (data?: CmsData.IManualWebOrders, departmentId?: string) => {
    const [ordersState, setOrdersState] = useState<IOrdersWithSubmitStatus[]>([]);
    const { mutate: mutateWebOrdersCounter } = useUpdateManualWebOrderCount();
    const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        profile: { delivery_department_id: deliveryDepartmentId },
    } = useUser();

    useEffect(() => {
        const currentDepartmentWebOrders = getWebOrdersFromDepartmentId(departmentId, data?.orders);
        setOrdersState(currentDepartmentWebOrders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);

    const previousData = usePrevious(data);
    useEffect(() => {
        if (!dequal(previousData, data)) {
            const updatedOrders = getUpdatedWebOrders(ordersState, data?.orders);
            setOrdersState(updatedOrders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function setWebOrderAsSubmitted(webOrderId: string) {
        const updatedOrders = getSubmittedWebOrders(ordersState, webOrderId);
        setOrdersState(updatedOrders);

        // Update counter if department match all departments or the current department
        if (departmentId === deliveryDepartmentId || departmentId === allDepartmentsId) {
            const count = updatedOrders.filter((webOrder) => !webOrder.submitted).length;
            mutateWebOrdersCounter(count);
        }
    }

    return {
        data,
        ordersState,
        setWebOrderAsSubmitted,
    };
};
//Helper functions

function getWebOrdersFromDepartmentId(departmentId?: string, webOrders: CmsData.IManualWebOrderItem[] = []) {
    if (departmentId === allDepartmentsId) {
        return webOrders;
    }

    const updatedData: CmsData.IManualWebOrderItem[] = webOrders?.filter((webOrder) => webOrder.departmentId === departmentId);

    return updatedData;
}

function getSubmittedWebOrders(ordersState: IOrdersWithSubmitStatus[], webOrderId: string) {
    const updatedData: IOrdersWithSubmitStatus[] = ordersState?.map((oldWebOrder) => ({
        ...oldWebOrder,
        submitted: oldWebOrder.webOrderId === webOrderId ? true : oldWebOrder.submitted,
    }));

    return updatedData;
}

// Adds the new orders on the bottom of the list without refreshing it
// This is because we need to keep the old submitted orders until refresh
function getUpdatedWebOrders(ordersState: IOrdersWithSubmitStatus[], newOrders: CmsData.IManualWebOrderItem[] = []) {
    // Check if order is missing in the new data disable the line
    const updatedOrders: IOrdersWithSubmitStatus[] = ordersState?.map((oldWebOrder) => ({
        ...oldWebOrder,
        submitted: !newOrders?.some((newOrder) => newOrder.webOrderId === oldWebOrder.webOrderId),
    }));

    newOrders?.forEach((newOrder) => {
        // Checks if new orders add them to the bottom
        if (!updatedOrders.some((updatedOrder) => updatedOrder.webOrderId === newOrder.webOrderId)) {
            updatedOrders.push(newOrder);
        }
    });

    return updatedOrders;
}

export default useSortedManualWebOrders;
