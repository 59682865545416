import React, { FC } from 'react';
import styles from './details.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import DetailsTable from './details-table/details-table.component';
import StockSummary from '../stock-summary/stock-summary.component';
import Text from '~/shared/text/text.component';
import Vendors from './vendors/vendors.component';
import useUser from '~/libs/use-user';
import { Products } from '~/models/products';
import { useStockStatusDetails } from '~/libs/queries/products/hooks/use-stock-status-details';
import { useStockStatusExternal } from '~/libs/queries/products/hooks/use-stock-status-external';

interface IProps {
    itemNo: string;
}

const StockStatusDetails: FC<IProps> = ({ itemNo }) => {
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const { data: stockDetails, error: isErrorDetails, isLoading: isLoadingDetails } = useStockStatusDetails(itemNo);

    const { data: stockDetailsExternal, isLoading: isLoadingExternalInfo, error: isErrorExternalInfo } = useStockStatusExternal(itemNo);

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <StockSummary stockSummary={stockDetails?.stockSummary as Products.IStockSummary} fetching={isLoadingDetails} />
            </div>
            <div>
                <DetailsTable fetching={isLoadingDetails} details={stockDetails?.info} isError={isErrorDetails} />

                <Text tagName="h4" textStyle="body" className={styles.title}>
                    {translate('stockStatus.externalStock', 'Eksternt lager')}
                </Text>
                <DetailsTable isExternal details={stockDetailsExternal} fetching={isLoadingExternalInfo} isError={isErrorExternalInfo} />
            </div>
            <div style={{ order: isInternalUser ? -1 : 0 }}>
                {(stockDetails?.averageCostPrice as number) > 0 && (
                    <div className={styles.section}>
                        <Text tagName="h4" className={styles.sectionTitle}>
                            {translate('stockStatus.averageCostPrice', 'Gns. Kostpris')}
                        </Text>
                        <Text tagName="p" textStyle="bodySmall">
                            {stockDetails?.averageCostPrice}
                        </Text>
                    </div>
                )}
                {stockDetails?.purchaseManager && (
                    <div className={styles.section}>
                        <Text tagName="h4" className={styles.sectionTitle}>
                            {translate('stockStatus.purchaseManager', 'Indkøbsansvarlig')}
                        </Text>
                        <Text tagName="p" textStyle="bodySmall">
                            {stockDetails?.purchaseManager?.name} <br />
                            {stockDetails?.purchaseManager?.team}
                        </Text>
                    </div>
                )}

                {stockDetails?.productManager && (
                    <div className={styles.section}>
                        <Text tagName="h4" className={styles.sectionTitle}>
                            {translate('stockStatus.productManager', 'Produktansvarlig')}
                        </Text>
                        <Text tagName="p" textStyle="bodySmall">
                            {stockDetails.productManager.name} <br />
                            {stockDetails.productManager.team}
                        </Text>
                    </div>
                )}
                <Vendors data={stockDetails?.vendors as Products.IVendor[]} />
            </div>
        </div>
    );
};

export default StockStatusDetails;
