export const Timing = {
    INSTANTLY: 10,
    VERY_QUICK: 50,
    QUICK: 100,
    STANDARD: 200,
    SLOW: 500,
    VERY_SLOW: 1000,
};

export const TimingFramer = {
    INSTANTLY: 0.01,
    VERY_QUICK: 0.05,
    QUICK: 0.1,
    STANDARD: 0.2,
    SLOW: 0.5,
    VERY_SLOW: 0.1,
};

export const EasingFramer = {
    BOUNCE_IN: [0.55, 0, 0, 1],
};
