import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { Orders as OrderTypes } from '~/models/orders.d';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';

interface IProps {
    id?: number;
    sellerOrderNumber?: number | string;
    shouldFetch?: boolean;
}

export function useCreditDetails({ id, sellerOrderNumber, shouldFetch = true }: IProps) {
    const shouldFetchCheck = id || sellerOrderNumber;
    const { isLoggedIn, user } = useUser();
    const { selectedDepartment } = useLayoutState();
    const { orders, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const url = createUrl({
        endpoint: `${orders.credits}/${id}`,
        localeOption: LocaleOptions.path,
        query: {
            sellerOrderNumber: sellerOrderNumber !== 0 ? sellerOrderNumber : undefined,
            departmentId: selectedDepartment?.id,
        },
    });

    return useQuery<OrderTypes.ICreditDetails, Error>({
        queryKey: queryKeys.basket.creditDetails(locale, id, sellerOrderNumber, selectedDepartment?.id),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, {
                signal,
            });
        },
        enabled: !!(isLoggedIn && shouldFetch && shouldFetchCheck),
    });
}
