import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Bff } from '~/models/bff';
import useVantageDashboard from '~/services/content/use-vantage-dashboard';
import { useCurrentBasket } from '~/libs/queries/basket';

// Before: vantage-dashboard/W142VantageDashboard.tsx
export default function W143VantageDashboard(props: Bff.IModuleViewModel) {
    const { data: basket } = useCurrentBasket();
    const router = useRouter();
    const { openVantageDashboard } = useVantageDashboard();

    useEffect(() => {
        if (basket) {
            openVantageDashboard({
                basketId: basket?.id as string,
            });
            router.back();
        }
    }, [basket]);

    return null;
}
