import { useState } from 'react';
import { Orders as OrderTypes } from '~/models/orders';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';
import SkeletonTableRows from '~/shared/skeleton/skeleton-table-rows/skeleton-table-rows.component';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

enum tableLenOptions {
    closed = 10,
    open = 30,
}

interface IPropLatestOrder {
    orders: OrderTypes.ILatestInternalOrder[];
    isLoading?: boolean;
}

const OrderList = ({ orders = [], isLoading }: IPropLatestOrder) => {
    const translate = useTranslations();
    const [tableLength, setTableLength] = useState(tableLenOptions.closed);
    const isOpen = tableLength === tableLenOptions.open;

    return (
        <div className={styles.root}>
            <table>
                <thead>
                    <tr>
                        <th>{translate('internalDashboard.customerName', 'Kunde')}</th>
                        <th>{translate('internalDashboard.orderStatus', 'Bestilt')}</th>
                        <th>{translate('internalDashboard.delivery', 'Levering')}</th>
                        <th>{translate('internalDashboard.quantity', 'Antal')}</th>
                        <th>{translate('internalDashboard.amount', 'Beløb')}</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <SkeletonTableRows rows={10} colSpan={6} />
                    ) : (
                        orders.slice(0, tableLength).map((order, i) => (
                            <tr key={`${order?.customerId}-${order?.orderTime}-${i}`}>
                                <td>
                                    <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                                        {order.customerName}
                                    </Text>
                                </td>
                                <td>
                                    <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                                        {order.orderTimeDisplay}
                                    </Text>
                                </td>
                                <td>
                                    <Text textStyle="bodySmall" color="primaryColor" fontWeight="semiBold">
                                        {order.expectedDeliveryTimeDisplay}
                                    </Text>
                                </td>
                                <td>
                                    <Text textStyle="bodySmall" textAlign="center" color="primaryColor" fontWeight="semiBold">
                                        {order.quantity}
                                    </Text>
                                </td>
                                <td>
                                    <Price textAlign="right">{order.price?.netPriceFormatted}</Price>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            {orders?.length > tableLenOptions.closed && (
                <div className={styles.buttonWrapper}>
                    <Button
                        buttonSize="small"
                        buttonStyle="secondary"
                        onClick={() => setTableLength(isOpen ? tableLenOptions.closed : tableLenOptions.open)}
                    >
                        {isOpen ? translate('common.showLess', 'Vis mindre') : translate('common.showMore', 'Vis mere')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default OrderList;
