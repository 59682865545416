import { memo } from 'react';
import { Bff } from '~/models/bff.d';
import { IWithClassName } from '~/models/dev';
import { CdnImage } from '~/shared/image/cdn-image';

export interface IMediaProps extends IWithClassName {
    image?: Bff.IImage;
}

// Before: media.component.tsx
function W072Media({ image, className }: IMediaProps) {
    if (image) {
        return <CdnImage src={image.url as string} alt={image.altText} className={className} layout="fill" />;
    }
    return <></>;
}

export default memo(W072Media);
