import React, { useCallback, useEffect, useMemo } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import { useCarInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';
import { addWarnToast, useLayoutDispatch } from '~/context/layout.context';
import { useOESearch, usePrefetchOESearch } from '~/libs/queries/products';
import { useTopmotiveHandler, useTopmotiveModalState } from '~/shared/topmotive/hooks';
import { TopmotiveProductModal, TopmotiveRequestModal } from '~/shared/topmotive/components';
import { useVehicleDetails } from '~/libs/queries/vehicles/hooks/use-vehicle-details';

export type TopmotiveFrameProps = {
    src: string;
};

export function TopmotiveFrame({ src }: TopmotiveFrameProps) {
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();

    const { details, open, setOpen, setDetails, reset } = useTopmotiveModalState(({ details, open, setOpen, setDetails, reset }) => ({
        open,
        setOpen,
        setDetails,
        details,
        reset,
    }));

    const { prefetch: prefetchOESearch } = usePrefetchOESearch();
    const { data: vehicleInfo } = useCarInfo();
    const { data: vehicleDetails } = useVehicleDetails(vehicleInfo?.licensePlate, vehicleInfo?.carId, vehicleInfo?.vin);

    useTopmotiveHandler({
        onAddToBasket: async ({ productId: id, productName, manufacturerName, manufacturerId }) => {
            setDetails({
                productName,
                productId: id,
                manufacturerName: manufacturerName,
                manufacturerId,
            });

            // We want to prefetch the product that was added to the basket from the plugin.
            // We do this to be sure that the product is already in cache when the modal opens.
            await prefetchOESearch(id, manufacturerId);
        },
        onAddMultipleError: () => {
            dispatch(
                addWarnToast(
                    translate(
                        'topmotive.addMultipleWarning',
                        'Tilføj kun et produkt til kurv ad gangen. Det er ikke supporteret at tilføje flere på én gang for nuværende',
                    ),
                ),
            );
        },
    });

    useEffect(() => {
        return () => {
            // We want to clean up the state when the component unmounts
            reset();
        };
    }, []);

    const { isFetching } = useOESearch({
        oeReference: details?.productId,
        manufacturerId: details?.manufacturerId,
        options: {
            retry: (failureCount, error) => {
                if (error.status === 404) {
                    // We do not retry our request if the error was not found.
                    // This is because we want to give instant feedback to the user when a product is missing.
                    return false;
                }

                return failureCount < 1;
            },
            keepPreviousData: false,
            enabled: false,
            onSuccess: () => {
                setOpen('product');
            },
            onError: () => {
                setOpen('request');
            },
        },
    });

    const frameUrl = useMemo(() => {
        if (!vehicleDetails) {
            return null;
        }

        const url = new URL(src);

        if (vehicleDetails.vin) {
            url.searchParams.set('vinNumber', vehicleDetails.vin);
        }

        return url.toString();
    }, [src, vehicleDetails]);

    const handleClose = useCallback(() => {
        reset();
    }, [reset]);

    return (
        <>
            <TopmotiveRequestModal />
            <TopmotiveProductModal />

            {isFetching || !frameUrl ? <Loader fixed /> : null}

            {frameUrl ? <iframe style={{ width: '100%', height: '80vh' }} src={frameUrl} /> : null}
        </>
    );
}
