import React from 'react';
import { Bff } from '~/models/bff';
import RawHtml from '~/page-elements/raw-html/raw-html.component';

//Before: /page-element/small-rich-text/small-rich-text.component.tsx
export default function M103SmallRichText({ richText, backgroundColor, campaignSpot, id, type }: Bff.IM103SmallRichTextModule) {
    const inlineStyle = backgroundColor
        ? {
              background: backgroundColor,
              padding: '20px',
          }
        : null;

    if (!richText) {
        return null;
    }

    return (
        <div style={inlineStyle as React.CSSProperties}>
            <RawHtml content={richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />
        </div>
    );
}
