import { useLatestOrders } from '~/libs/queries/basket/hooks/use-latest-orders';
import { Bff } from '~/models/bff';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import OrderList from './orders-list/order-list.component';
import styles from './styled.module.scss';

// Old component: latest-orders.component.tsx
export default function W047InternalLatestOrders(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();

    const { data, isError, isLoading } = useLatestOrders();
    return (
        <>
            <Heading tagName="h4" className={styles.title}>
                {translate('internalDashboard.latestOrders', 'Mine seneste FTZ bestillinger')}
            </Heading>

            {isError ? (
                <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            ) : (
                <OrderList isLoading={isLoading} orders={data?.orders || []} />
            )}
        </>
    );
}
