import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { Products as ProductTypes } from '~/models/products.d';
import Loader from '~/shared/loader/loader.component';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '../../hooks/use-translations.hook';
import styles from './calculator.module.scss';
import Text from '~/shared/text/text.component';
import Heading from '~/shared/heading/heading.component';
import Button from '~/shared/buttons/button/button.component';
import { useRouter } from 'next/router';

const Result: FC<{ calculations?: ProductTypes.ITireCalculation }> = ({ calculations }) => {
    const router = useRouter();
    const translate = useTranslations();
    const {
        percentage,
        percentageString,
        tireOne,
        tireTwo,
        tireComparisonResult,
        speedometer50,
        speedometer80,
        speedometer100,
        speedometer120,
        speedometer150,
        tireListPageUrl,
    } = calculations || {};

    const boxColor = useMemo(() => {
        const formattedPercentage = Number(Math.abs((percentage as number) * 100).toFixed(2));

        if (formattedPercentage < 3) {
            return 'greenBox';
        }

        if (formattedPercentage >= 3 && formattedPercentage < 5) {
            return 'yellowBox';
        }

        return 'redBox';
    }, [percentage]);

    const tiresAreEqual = tireComparisonResult === ProductTypes.TireComparisonResult.Equal;
    const tiresAreSmaller = tireComparisonResult === ProductTypes.TireComparisonResult.Smaller;
    const tiresAreLarger = tireComparisonResult === ProductTypes.TireComparisonResult.Larger;

    const getTireComparisonText = () => {
        if (tiresAreLarger) {
            return translate('tireCalculator.larger', 'større');
        }
        if (tiresAreSmaller) {
            return translate('tireCalculator.smaller', 'mindre');
        }

        return '';
    };

    return (
        <div className={styles.resultsRoot}>
            <div className={clsx(styles.percentageBox, styles[boxColor])}>
                {tiresAreEqual ? (
                    <Heading tagName="h3" className={styles.percentage}>
                        {translate('tireCalculator.tiresAreEqual', 'Dæk er ens')}
                    </Heading>
                ) : (
                    <>
                        <Text>{translate('tireCalculator.theNewTiresAre', 'Det nye dæk er')}</Text>
                        <Heading tagName="h3" className={styles.percentage}>
                            {tiresAreSmaller && '- '}
                            {percentageString} {getTireComparisonText()}
                        </Heading>
                        <Text>{translate('tireCalculator.thanCurrentTires', 'end nuværende dæk')}</Text>
                    </>
                )}
            </div>
            {!tiresAreEqual && (
                <>
                    <section className={styles.resultsSection}>
                        <Heading tagName="h4">{translate('tireCalculator.specifications', 'Specifikationer')}</Heading>
                        <table className="tableClean">
                            <thead>
                                <tr>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <th> </th>
                                    <th>{translate('tireCalculator.current', 'Nuværende')}</th>
                                    <th>{translate('tireCalculator.new', 'Nyt')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{translate('tireCalculator.diameter', 'Diameter')}</td>
                                    <td>
                                        {Math.round(tireOne?.totalHeight as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                    <td>
                                        {Math.round(tireTwo?.totalHeight as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('tireCalculator.circumference', 'Omkreds')}</td>
                                    <td>
                                        {Math.round(tireOne?.diameter as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                    <td>
                                        {Math.round(tireTwo?.diameter as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('tireCalculator.profileHeight', 'profilhøjde')}</td>
                                    <td>
                                        {Math.round(tireOne?.height as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                    <td>
                                        {Math.round(tireTwo?.height as number)} {translate('tireCalculator.cm', 'cm')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>100 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>
                                        {Math.round(tireOne?.rpm as number)} {translate('tireCalculator.rpm', 'RPM')}
                                    </td>
                                    <td>
                                        {Math.round(tireTwo?.rpm as number)} {translate('tireCalculator.rpm', 'RPM')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className={styles.resultsSection}>
                        <Heading tagName="h4">{translate('tireCalculator.speedMeter', 'Speedometer')}</Heading>

                        <table className="tableClean">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>{translate('tireCalculator.current', 'Nuværende')}</th>
                                    <th>{translate('tireCalculator.new', 'Nyt')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>50 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>50</td>
                                    <td>{Math.round(speedometer50 as number)}</td>
                                </tr>
                                <tr>
                                    <td>80 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>80</td>
                                    <td>{Math.round(speedometer80 as number)}</td>
                                </tr>
                                <tr>
                                    <td>100 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>100</td>
                                    <td>{Math.round(speedometer100 as number)}</td>
                                </tr>
                                <tr>
                                    <td>120 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>120</td>
                                    <td>{Math.round(speedometer120 as number)}</td>
                                </tr>
                                <tr>
                                    <td>150 {translate('tireCalculator.kmh', 'km/t')}</td>
                                    <td>150</td>
                                    <td>{Math.round(speedometer150 as number)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </>
            )}
            <Button buttonStyle="secondary" onClick={() => router.push(tireListPageUrl as string)}>
                {translate('common.showAll', 'Vis alle')}
            </Button>{' '}
        </div>
    );
};

interface IProps {
    calculations?: ProductTypes.ITireCalculation;
    error: Error;
    isLoading: boolean;
}

const CalculationResult: FC<IProps> = ({ calculations, isLoading, error }) => {
    const translate = useTranslations();
    if (isLoading) return <Loader />;
    if (error) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }
    if (!calculations) return null;

    return <Result calculations={calculations} />;
};

export default CalculationResult;
