import { useVehicleModelTypes } from '~/libs/queries/vehicles/hooks/use-vehicle-model-types';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import VehicleTypesTable from '~/page-elements/vehicle-types/vehicle-types-table/vehicle-types-table.component';
import ExpertToggle from '~/shared/expert-toggle/expert-toggle.component';
import Heading from '~/shared/heading/heading.component';
import PageTitle from '~/shared/page-title/page-title.component';
import styles from './styled.module.scss';

//Name before: vehicle-types.component.tsx
export default function W160VehicleTypeList({ manufacturerName, modelId, seriesName }: Bff.IVehicleTypeListWidgetViewModel) {
    const { isInternalUser } = useUser();
    const { data, isLoading, error } = useVehicleModelTypes(modelId);

    if (!modelId) return null;

    return (
        <>
            <PageTitle className={styles.heading}>
                <div>
                    <Heading tagName="h1" displayStyle="h3" subHeading={seriesName}>
                        {manufacturerName}
                    </Heading>
                </div>
                {isInternalUser && <ExpertToggle className={styles.expertToggle} />}
            </PageTitle>

            <VehicleTypesTable isLoading={isLoading} isError={error as Error} modelTypes={data?.modelTypes} />
        </>
    );
}
