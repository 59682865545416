import { useState } from 'react';
import { Orders as OrderTypes } from '~/models/orders.d';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import { printEndpoints } from '~/services/auth-endpoints';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Credit from '../credit/credit.component';
import styles from './styled.module.scss';

type ISelected = {
    id: number;
    isSellerOrderNumber: boolean;
};

type IProps = {
    credits?: OrderTypes.ICreditViewModel[];
    fetching: boolean;
    searchQuery: string;
    error: Error;
};

export default function CreditList({ credits = [], fetching, error, searchQuery }: IProps) {
    const translate = useTranslations();

    const [selected, setSelected] = useState<ISelected[]>([]);

    const handleCreditSelectionChange = (credit: OrderTypes.ICreditViewModel) => () => {
        const isSellerOrderNumber = credit.id === 0;

        const id = isSellerOrderNumber ? credit.sellerOrderNumber : credit.id;

        setSelected((prevSelected) =>
            prevSelected.some((item) => item.id === id)
                ? prevSelected.filter((item) => item.id !== id)
                : [{ id, isSellerOrderNumber }, ...prevSelected],
        );
    };

    const handleCreditSelectAllChange = () => {
        setSelected((prevSelected) => {
            if (prevSelected.length === credits.length) {
                return [];
            }

            return credits.map((item) => {
                const isSellerOrderNumber = item.id === 0;

                const id = isSellerOrderNumber ? item.sellerOrderNumber : item.id;

                return { id, isSellerOrderNumber };
            });
        });
    };

    const isPrintSelectedActive = selected.length > 0;

    const handleClickPrintSelectedCredits = () => {
        const selectedIds = selected.map((item) => {
            if (item?.isSellerOrderNumber) {
                return `sellerOrderNumber${item.id}`;
            }
            return item.id;
        });
        if (isPrintSelectedActive) {
            window.open(`${printEndpoints.return}?ids=${selectedIds.join(',')}`, '_blank');
        }
    };

    const openCredits: OrderTypes.ICreditViewModel[] = credits.filter(({ creditStatus }) => creditStatus === OrderTypes.CreditStatus.CreditOpen);
    const isContainingOpenCredits = openCredits.length > 0;

    const handleClickPrintCreatedCredits = () => {
        if (isContainingOpenCredits) {
            const ids = openCredits
                .map((item) => {
                    const isSellerOrderNumber = item.id === 0;
                    if (isSellerOrderNumber) {
                        return `sellerOrderNumber${item.sellerOrderNumber}`;
                    }
                    return item.id;
                })
                .join(',');

            window.open(`${printEndpoints.return}?ids=${ids}`, '_blank');
        }
    };

    const isAllSelected = selected.length === credits.length;

    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!credits?.length) return <OverviewNoResult />;

    return (
        <>
            {credits.length ? (
                <div className={styles.controls}>
                    <Checkbox onChange={handleCreditSelectAllChange} checked={isAllSelected} className={styles.selector}>
                        {translate('common.selectAll', 'Vælg alle')}
                    </Checkbox>
                    <Button
                        disabled={!isContainingOpenCredits}
                        buttonStyle="clean"
                        buttonSize="small"
                        className={styles.controlAlt}
                        icon={<Svg name="print" />}
                        onClick={handleClickPrintCreatedCredits}
                    >
                        {translate('creditOverview.printAllCreated', 'Print alt i status "oprettet"')}
                    </Button>
                    <Button
                        disabled={!isPrintSelectedActive}
                        buttonStyle="clean"
                        buttonSize="small"
                        className={styles.controlAlt}
                        icon={<Svg name="print" />}
                        onClick={handleClickPrintSelectedCredits}
                    >
                        {translate('creditOverview.printSelected', 'Print valgte ([count])').replace('[count]', String(selected.length))}
                    </Button>
                </div>
            ) : null}
            <div>
                {credits.map((credit, i) => {
                    const creditIdOrSellerOrderNumber = credit.id === 0 ? credit.sellerOrderNumber : credit.id;

                    const isSelected = selected.some((item) => item.id === creditIdOrSellerOrderNumber);

                    return (
                        <Credit
                            searchQuery={searchQuery}
                            onCreditSelectionChange={handleCreditSelectionChange}
                            isSelected={isSelected}
                            creditIdOrSellerOrderNumber={creditIdOrSellerOrderNumber}
                            credit={credit}
                            key={`${i}-${creditIdOrSellerOrderNumber}`}
                        />
                    );
                })}
            </div>
        </>
    );
}
