/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useLayoutEffect, useState, FunctionComponent, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { isBrowser } from '~/helpers';

interface IPortalProps {
    className?: string;
    appendTo?: string;
    children: ReactNode;
}

function useForceUpdate() {
    const update = useState(false)[1];
    return () => update((s) => !s);
}

function getPortalRoot(appendTo: string): Element | undefined | null {
    if (isBrowser) {
        return appendTo ? document.querySelector(appendTo) : document.body;
    }

    return undefined;
}

function addPortalElement(): HTMLDivElement | undefined {
    if (isBrowser) {
        return document.createElement('div');
    }

    return undefined;
}

export const Portal: FunctionComponent<IPortalProps> = ({ children, className = '', appendTo = '' }: any) => {
    const portalRoot = useRef<Element | undefined>(getPortalRoot(appendTo));
    const el = useRef<HTMLDivElement | undefined>();

    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
        if (isBrowser) {
            // Set here to avoid warning with portal not being SSR'ed - https://github.com/facebook/react/issues/13097
            el.current = addPortalElement();

            if (!portalRoot.current || !el.current) {
                return;
            }

            className
                .split(' ')
                .filter((c: string) => !!c)
                .map((c: string) => el.current?.classList.add(c));

            portalRoot.current.appendChild(el.current);

            forceUpdate();
        }

        return () => {
            if (portalRoot.current && el.current) {
                portalRoot.current.removeChild(el.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return el.current ? ReactDOM.createPortal(children, el.current) : null;
};
