import React, { Fragment, useRef } from 'react';
import { Svg } from '~/shared/svg';

import styles from './multi-select.module.scss';
import Label from '~/shared/form-elements/common/label/label.component';
import { Combobox } from '@headlessui/react';
import { useOnClickOutside } from 'usehooks-ts';

// TODO: remove and isolate logic handling items (objective: make it re usable)
export type MultiSelectProps<T> = {
    label?: string;
    onChange: (item: T[]) => void;
    value: T[];
    placeholder: string;
    children: React.ReactNode | React.ReactNode[];
    onTermChange: (term: string) => void;
    displayValue: (value: T) => string;
    onClickOutside?: () => void;
};

export function MultiSelect<T = unknown>({
    label,
    onChange,
    value,
    placeholder,
    children,
    onTermChange,
    displayValue,
    inputRef,
    onClickOutside,
}: MultiSelectProps<T> & {
    inputRef: React.Ref<HTMLInputElement>;
}) {
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
        if (!onClickOutside) {
            return;
        }

        onClickOutside();
    });

    return (
        <div ref={containerRef} className={styles.container}>
            {label ? <Label>{label}</Label> : null}
            <Combobox value={value} onChange={onChange} multiple nullable>
                <Combobox.Button as={Fragment}>
                    <div className={styles.field}>
                        <Combobox.Input displayValue={displayValue} as={Fragment} onChange={(e) => onTermChange(e.target.value)}>
                            <input ref={inputRef} className={styles.input} placeholder={placeholder} />
                        </Combobox.Input>
                        <Svg name="chevron-down" className={styles.icon} />
                    </div>
                </Combobox.Button>
                <Combobox.Options as={Fragment}>
                    <ul className={styles.drawer}>{children}</ul>
                </Combobox.Options>
            </Combobox>
        </div>
    );
}
