import { Dispatch, SetStateAction } from 'react';
import { Orders as OrderTypes } from '~/models/orders.d';
import CarInfo from '~/shared/car-info/car-info.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Price from '~/shared/price/price.component';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IProps = {
    credit: OrderTypes.ICreditViewModel;
    creditIdOrSellerOrderNumber: number;
    setOpen: Dispatch<SetStateAction<boolean>>;
    searchQuery: string;
};

export default function Overview({ credit, setOpen, creditIdOrSellerOrderNumber }: IProps) {
    const translate = useTranslations();

    return (
        <div>
            <div className={styles.top}>
                <button className={styles.details} type="button" onClick={() => setOpen(true)}>
                    <ValueWithCaption caption={translate('overview.creditNo', 'Krediterings nr.')}>
                        <Text textStyle="monoMedium">{creditIdOrSellerOrderNumber}</Text>
                    </ValueWithCaption>
                    <ValueWithCaption caption={translate('overview.number', 'antal')}>
                        <Text textStyle="monoMedium">{credit?.quantity}</Text>
                    </ValueWithCaption>
                    <ValueWithCaption caption={translate('overview.created', 'Oprettet')}>
                        <Text textStyle="monoMedium">{credit?.displayCreationDateTime}</Text>
                    </ValueWithCaption>
                    <ValueWithCaption caption={translate('overview.status', 'Status')}>
                        <Text textStyle="monoMedium">{credit?.status}</Text>
                    </ValueWithCaption>
                    <ValueWithCaption caption={translate('overview.netPrice', 'nettopris')}>
                        <Price>{credit?.total?.netPriceFormatted}</Price>
                    </ValueWithCaption>
                </button>
            </div>
            {credit?.carsInfo?.length ? (
                <div className={styles.vehicles}>
                    {credit.carsInfo.map((vehicle, index) => (
                        <CarInfo key={`${vehicle.carId}-${index}`} carInfo={vehicle} hideCountryCode />
                    ))}
                </div>
            ) : null}
        </div>
    );
}
