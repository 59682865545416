import clsx from 'clsx';
import React, { FC, useState } from 'react';
import Text from '~/shared/text/text.component';
import styles from './facet-group.module.scss';
import { Products as ProductTypes } from '~/models/products';
import { useFilterActions, useFilterValues } from '../filter.hook';
import Accordion from '~/shared/accordion/accordion.component';

interface IFacetGroupProps {
    index: number;
    facet: ProductTypes.IFacet;
}

const FacetGroup: FC<IFacetGroupProps> = ({ index, facet }) => {
    const { options, selected } = useFilterValues(facet);
    const { add, remove } = useFilterActions();

    const [isOpen, setIsOpen] = useState(() => {
        if (selected.length) {
            return index < 5 || !!selected.length;
        }
    });

    const handleClick = (key?: string, item?: ProductTypes.IFacetValue) => {
        if (!key || !item?.value) {
            return;
        }

        if (selected.includes(item)) {
            remove(key, item.value);
        } else {
            add(key, item.value);
        }
    };

    return (
        <Accordion
            onClick={() => setIsOpen((prevOpen) => !prevOpen)}
            isOpen={isOpen}
            header={
                <div className={styles.caption}>
                    <Text color="bodyColor" textStyle="bodyLarge">
                        {facet.name}
                    </Text>
                    <Text secondary>{`(${facet.values?.length})`}</Text>
                </div>
            }
        >
            <div className={styles.content}>
                {options.map((item) => (
                    <button
                        key={item.value}
                        type="button"
                        disabled={item.count === 0 && !selected.includes(item)}
                        onClick={() => handleClick(facet.key, item)}
                        className={clsx(styles.facet, {
                            [styles.selected]: selected.includes(item),
                            [styles.disabled]: item.count === 0,
                        })}
                    >
                        {item.name} {facet.unit && <span>{facet.unit}</span>}
                    </button>
                ))}
            </div>
        </Accordion>
    );
};

export default FacetGroup;
