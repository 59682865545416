import { useState } from 'react';
import { useCheckoutBasket } from '~/libs/queries/basket';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketSummary } from './basket-summary';

export function BasketSummaryContainer() {
    const translate = useTranslations();
    const [isOpen, setIsOpen] = useState(false);
    const { data: basket } = useCheckoutBasket();

    const priceProps = {
        caption: translate('basket.priceTotalWithTaxes', 'Pris i alt inkl. afgifter'),
        displayValue: basket?.total?.displayPrice || '',
    };

    const priceFeeProps = {
        caption: translate('basket.ofWhichTaxes', 'Heraf afgifter'),
        displayValue: basket?.total?.displayFee || '',
    };

    const priceDepositProps = {
        caption: translate('basket.deposit', 'Depositum'),
        displayValue: basket?.total?.displayDeposit || '',
    };

    const orderNoProps = {
        caption: translate('basket.webOrderNo', 'Web Ordre nr.'),
        displayValue: basket?.webOrderId || '-',
    };

    const ftzCodeProps = {
        caption: translate('product.ftzNo', 'FTZ Nr'),
        displayValue: basket?.total?.ftzCode || '-',
    };

    return (
        <BasketSummary
            heading={translate('basket.summary', 'Opsummering')}
            isOpen={isOpen}
            toggleOpen={() => setIsOpen(!isOpen)}
            price={priceProps}
            priceFee={priceFeeProps}
            priceDeposit={priceDepositProps}
            infoOrderNo={orderNoProps}
            infoFtzCode={ftzCodeProps}
        />
    );
}
