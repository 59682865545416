import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { CmsData } from '~/models/cms-data.d';
import { Basket as BasketTypes } from '~/models/basket';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

export function useDeliveryAddresses() {
    const { isLoggedIn, user } = useUser();
    const { selectedDepartment } = useLayoutState();

    const { baskets, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: baskets.getDeliveryAddresses,
        localeOption: LocaleOptions.query,
        query: { departmentId: selectedDepartment?.id },
    });

    return useQuery<BasketTypes.IAddressDto[], CmsData.IError>({
        queryKey: queryKeys.basket.deliveryAddresses(selectedDepartment?.id),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn,
    });
}
