import clsx from 'clsx';
import { useState } from 'react';
import { Orders as OrdersTypes } from '~/models/orders.d';
import CollapseButton from '~/shared/collapse-button/collapse-button.component';
import InvoiceDetails from './details/details.component';
import Overview from './overview/overview.component';
import styles from './styled.module.scss';

type IProps = {
    invoice: OrdersTypes.IInvoiceViewModel;
    searchQuery: string;
};

export default function Invoice({ invoice, searchQuery }: IProps) {
    const [isOpen, setOpen] = useState(false);

    const isDetailsView = isOpen || (invoice?.items?.length as number) > 0;

    return (
        <div
            className={clsx(styles.root, {
                [styles.open]: isOpen,
            })}
        >
            <div className={styles.collapseButtonWrapper}>
                <CollapseButton onClick={() => setOpen(!isOpen)} isOpen={isOpen} className={styles.collapseButton} />
            </div>
            {isDetailsView ? (
                <InvoiceDetails searchQuery={searchQuery} invoice={invoice} isOpen={isOpen} invoiceId={invoice?.number} />
            ) : (
                <Overview invoice={invoice} isOpen={isDetailsView} setOpen={() => setOpen(true)} />
            )}
        </div>
    );
}
