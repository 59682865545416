import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';

export type BasketFooterSaveBasketProps = {
    label: string;
    isDisabled: boolean;
    isLoading: boolean;
    handleClick: () => void;
};

export function BasketFooterSaveBasket({ label, isDisabled, isLoading, handleClick }: BasketFooterSaveBasketProps) {
    return (
        <Button buttonStyle="secondaryLight" disabled={isDisabled} fetching={isLoading} onClick={handleClick} icon={<Svg name="save" thick />}>
            {label}
        </Button>
    );
}
