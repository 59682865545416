import clsx from 'clsx';
import { Svg } from '~/shared/svg';
import styles from './add-to-basket-quantity-button.module.scss';

export type AddToBasketQuantityButtonProps = {
    ariaLabel: string;
    iconName: string;
    isLarge?: boolean;
    onClick: () => void;
};

export function AddToBasketQuantityButton({ ariaLabel, iconName, isLarge, onClick }: AddToBasketQuantityButtonProps) {
    return (
        <button
            aria-label={ariaLabel}
            className={clsx(styles.addToBasketQuantityButton, {
                [styles.addToBasketQuantityButtonLarge]: isLarge,
            })}
            onClick={onClick}
            type="button"
        >
            <Svg className={styles.addToBasketQuantityButtonIcon} name={iconName} thick />
        </button>
    );
}
