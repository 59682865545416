import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import { Bff } from '~/models/bff';
import Filters from '~/page-elements/overview/common/filters/filters.component';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import useTranslations from '~/shared/hooks/use-translations.hook';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import Header from '../common/header/header.component';
import ChemicalProductsList from './chemical-products-list-component/chemical-products-list.component';
import styles from './styled.module.scss';

// Name Before: chemical-products.component.tsx
function W020ChemicalProducts({ chemicalRiskAssessmentLink, hasPermissionForChemicalRiskAssessment }: Bff.IChemicalProductsWidgetViewModel) {
    const { orders } = useEndpoints();
    const { data: features } = useFeatures();
    const { shouldHideFeature } = useFeatureToggle();
    const hideChemicalProducts = shouldHideFeature(features?.overview?.chemistryProducts);
    const translate = useTranslations();

    const {
        data: chemicalProducts,
        isLoadingInitialData,
        isLoadingMore,
        total,
        loaded,
        error,
        loadMore,
        searchVal,
        setSearchVal,
    } = useInfiniteLoading({
        pathName: orders.getChemicalProducts,
        localeOption: LocaleOptions.path,
        key: 'chemicalProducts',
    });

    if (hideChemicalProducts) return null;

    return (
        <div>
            <Header className={styles.headerWrapper}>
                <div>
                    {chemicalRiskAssessmentLink && hasPermissionForChemicalRiskAssessment ? (
                        <ButtonCircle
                            iconName="kemi"
                            href={chemicalRiskAssessmentLink?.url}
                            text={translate('chemicalProducts.chemicalRiskEvaluation', 'Kemisk risikovurdering')}
                        />
                    ) : null}
                </div>
                <div className={styles.inputWrapper}>
                    <Filters hideDateFilter query={searchVal} setQuery={setSearchVal} hidePageSizeFilter />
                </div>
            </Header>

            <ChemicalProductsList
                hasPermission={hasPermissionForChemicalRiskAssessment}
                error={error}
                products={chemicalProducts}
                fetching={isLoadingInitialData}
            />

            {chemicalProducts?.length ? <OverviewLoadMore total={total} loaded={loaded} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </div>
    );
}

export default WithOverviewNavigation(W020ChemicalProducts);
