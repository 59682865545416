import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { IWithClassName } from '~/models/dev';
import styles from './counter.module.scss';
import Skeleton from '~/shared/skeleton';
import {useNextDeliveryForBasket} from "~/services/basket/use-next-delivery-for-basket";

interface ITimeLeft {
    hours?: string;
    minutes?: string;
    seconds?: string;
}

const pad = (num: number): string => {
    if (num <= 0) return '00';
    return num <= 9 ? `0${num.toString()}` : num.toString();
};

const getTimeLeft = (from: Date, to: Date): ITimeLeft => {
    if (!to || to.toString() == 'Invalid Date') return {};
    const diff = to.getTime() - from.getTime();

    return {
        hours: pad(Math.floor(diff / (1000 * 60 * 60))),
        minutes: pad(Math.floor((diff / 1000 / 60) % 60)),
        seconds: pad(Math.floor((diff / 1000) % 60)),
    };
};

interface ICounterProps extends IWithClassName {
    from?: Date;
    to?: Date;
    fetching?: boolean;
}

const Counter: React.FC<ICounterProps> = ({ className, from = new Date(), to, fetching }) => {
    const [timeLeft, setTimeLeft] = useState<ITimeLeft>(getTimeLeft(from, to as Date));
    const { hours, minutes, seconds } = timeLeft;

    const { data } = useNextDeliveryForBasket();

    useEffect(() => {
        const nextDeliveryDepartureTime = data?.nextDeliveryDepartureTime;
        if (!to || to.toString() == 'Invalid Date' && nextDeliveryDepartureTime && nextDeliveryDepartureTime instanceof Date)
        {
            const decreaseTimeLeft = () => setTimeLeft(getTimeLeft(new Date(), new Date(nextDeliveryDepartureTime as Date)));
            const interval = setInterval(decreaseTimeLeft, 1000);

            return () => clearInterval(interval);
        }
        const decreaseTimeLeft = () => setTimeLeft(getTimeLeft(new Date(), to));
        const interval = setInterval(decreaseTimeLeft, 1000);

        return () => clearInterval(interval);
    });

    return (
        <div className={clsx(styles.counter, className)}>
            {fetching ? <Skeleton isDark style={{ height: '32px', width: '32px' }} /> : <span className={styles.digit}>{hours || '-'}</span>}
            <span className={styles.divider}>:</span>
            {fetching ? <Skeleton isDark style={{ height: '32px', width: '32px' }} /> : <span className={styles.digit}>{minutes || '-'}</span>}
            <span className={styles.divider}>:</span>
            {fetching ? <Skeleton isDark style={{ height: '32px', width: '32px' }} /> : <span className={styles.digit}>{seconds || '-'}</span>}
        </div>
    );
};

export default Counter;
