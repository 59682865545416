import React, { useCallback } from 'react';
import { Modal } from '~/features/primitives/modal';
import { useTopmotiveModalState } from '~/shared/topmotive/hooks';
import { TopmotiveRequestFormContainer, TopmotiveRequestStart } from '~/shared/topmotive/components';

export function TopmotiveRequestModal() {
    const { step, reset, open } = useTopmotiveModalState(({ step, open, reset }) => ({ step, reset, open }));

    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (!open) {
                reset();
            }
        },
        [reset],
    );

    return (
        <Modal position="right" open={open === 'request'} onOpenChange={handleOnOpenChange}>
            {step === 'start' ? <TopmotiveRequestStart /> : <TopmotiveRequestFormContainer />}
        </Modal>
    );
}
