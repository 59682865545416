import React, { FC } from 'react';
import styles from './stock-summary.module.scss';
import { Products as ProductTypes } from '~/models/products.d';
import Skeleton from '~/shared/skeleton';
import Text from '~/shared/text/text.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import StatusDot from '../status-dot/status-dot.component';

interface IProps {
    stockSummary: ProductTypes.IStockSummary;
    fetching: boolean;
    isError?: Error | null;
    isDark?: boolean;
}

const StockSummary: FC<IProps> = ({ stockSummary, fetching, isDark, isError }) => {
    const translate = useTranslations();
    if (fetching) {
        return (
            <Skeleton
                isDark={isDark}
                style={{
                    float: 'right',
                    width: '200px',
                    height: '19px',
                }}
            />
        );
    }
    if (isError) {
        return (
            <Text fontWeight="regular" color="errorColor">
                {translate('common.somethingWentWrong', 'Der gik noget galt.')}
            </Text>
        );
    }
    if (!stockSummary) return null;

    return (
        <Text textStyle="monoMedium" className={styles.container}>
            <span className={styles.item}>
                <StatusDot statusCode={ProductTypes.StockStatusCode.PrimaryDepartment} />
                {stockSummary.primaryDepartment}
            </span>
            /
            <span className={styles.item}>
                <StatusDot statusCode={ProductTypes.StockStatusCode.NearbyDepartment} />
                {stockSummary.otherDepartments}
            </span>
            /
            <span className={styles.item}>
                <StatusDot statusCode={ProductTypes.StockStatusCode.CentralDepartment} />
                {stockSummary.central}
            </span>
            /
            <span className={styles.item}>
                <StatusDot statusCode={ProductTypes.StockStatusCode.OutsideCentralDepartment} />
                {stockSummary.remoteDepartments}
            </span>
        </Text>
    );
};

export default StockSummary;
