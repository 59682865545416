import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, endpoints, useEndpoints } from '~/services/service-endpoint';

type SignUpParams = {
    email: string;
};

type EmailSignUpResponse = UseMutationOptions<unknown, unknown, SignUpParams>;

export default function useEmailSignUp(options?: EmailSignUpResponse) {
    const { createUrl } = useEndpoints();
    const { user } = useUser();

    return useMutation<unknown, unknown, SignUpParams>({
        mutationFn: ({ email }) => {
            const url = createUrl({
                endpoint: endpoints.users.signUpNewsletter,
                localeOption: LocaleOptions.omit,
                query: { email },
            });
            return apiClient.auth(user).post(url);
        },
        ...options,
    });
}
