import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import styles from './button-animated.module.scss';
import { ISvgIconProps, Svg } from '~/shared/svg';

export enum ButtonAnimatedTypes {
    normal = 'normal',
    thick = 'thick',
}

export interface IButtonAnimatedProps extends IWithClassName {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    icon: ISvgIconProps;
    active: boolean;
    text: string;
    activeText: string;
    inactiveText: string;
    type?: ButtonAnimatedTypes;
}

const ButtonAnimated: React.FC<IButtonAnimatedProps> = ({
    className,
    onClick,
    icon,
    active,
    text,
    activeText,
    inactiveText,
    type = ButtonAnimatedTypes.normal,
}) => (
    <button
        type="button"
        className={clsx(styles.buttonAnimated, className, {
            [styles.normal]: type === ButtonAnimatedTypes.normal,
            [styles.thick]: type === ButtonAnimatedTypes.thick,
        })}
        onClick={onClick}
    >
        <span className={styles.buttonAnimatedText}>
            <Svg {...icon} className={styles.buttonAnimatedIcon} />
            {text}
        </span>
        <span className={styles.buttonAnimatedHidden}>{active ? activeText : inactiveText}</span>
    </button>
);

export default ButtonAnimated;
