import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes, MouseEventHandler } from 'react';
import { IWithClassName } from '~/models/dev';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import InputValidation from '../common/input-validation/input-validation.component';
import styles from './checkbox.module.scss';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onClick'>, IWithClassName {
    halfChecked?: boolean;
    errorMessage?: string;
    isError?: boolean;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    background?: 'default' | 'white';
}

export type ICheckableRef = HTMLInputElement;

const Checkbox = forwardRef<ICheckableRef, IProps>(
    ({ children, className, halfChecked, errorMessage, isError, disabled, onClick, background = 'default', ...inputProps }, ref) => (
        <>
            <label /* eslint-disable-line  jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
                className={clsx(className, styles.root, disabled && styles.disabled)}
                onClick={onClick}
            >
                <input type="checkbox" className={styles.input} ref={ref} disabled={disabled} {...inputProps} />
                <span
                    className={clsx(styles.checkBox, {
                        [styles.halfChecked]: halfChecked,
                        [styles.error]: errorMessage || isError,
                        [styles.disabledCheckbox]: disabled,
                        [styles.white]: background === 'white',
                    })}
                >
                    {halfChecked && <span className={styles.halfCheckedInner} />}
                    <Svg name="check" className={styles.icon} thick />
                </span>
                {children && (
                    <Text tagName="span" className={clsx(styles.label, disabled && styles.disabledLabel)} color={isError ? 'errorColor' : undefined}>
                        {children}
                    </Text>
                )}
            </label>
            <InputValidation errorMessage={errorMessage} />
        </>
    ),
);

export default Checkbox;
