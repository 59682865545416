import { Bff } from '~/models/bff';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';

//Name Before: raw-html.component.tsx
export default function M100RichText({ spacingBottom, richText, campaignSpot, id, type }: Bff.IM100RichTextModuleViewModel) {
    if (!richText) {
        return null;
    }

    return <RawHtmlComponent className={spacingBottom} content={richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />;
}
