import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';

export type StandardCardPane = 'VariantsList' | 'VehicleFits' | 'ProductDocuments' | 'RelatedProducts' | null;
export type StandardCardContext = {
    // Each StandardCard has its own tabs with different information.
    // This property controls which pane that is active
    activePane: StandardCardPane;
    setActivePane: Dispatch<SetStateAction<StandardCardPane>>;
};

const StandardCardContext = createContext<StandardCardContext | undefined>(undefined);

export type StandardCardProviderProps = {
    children: React.ReactNode | React.ReactNode[];
};

// The provider is wrapped around the StandardCard
// component to share state across component tree
export function StandardCardProvider({ children }: StandardCardProviderProps) {
    // TODO: Performance optimization. We should use CSS to control visibility state instead of js
    const isMobile = useMediaQueryDetect(MediaQuery.LG);
    const [activePane, setActivePane] = useState<StandardCardPane>(!isMobile ? 'VariantsList' : null);

    const debouncedIsMobile = useDebounce(isMobile, 300);

    useEffect(() => {
        if (debouncedIsMobile) {
            setActivePane(null);
        } else {
            setActivePane('VariantsList');
        }
    }, [debouncedIsMobile]);

    return (
        <StandardCardContext.Provider
            value={useMemo(
                () => ({
                    activePane,
                    setActivePane,
                }),
                [activePane, setActivePane],
            )}
        >
            {children}
        </StandardCardContext.Provider>
    );
}

export function useStandardCardContext() {
    const context = useContext(StandardCardContext);

    if (!context) {
        throw new Error('useLayoutState must be used within a LayoutProvider');
    }

    return context;
}
