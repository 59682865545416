import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';
import styles from './invoice-details.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Input from '~/shared/form-elements/input/input.component';

interface IProps {
    fetching: boolean;
    itemsCount: number;
    invoiceId: number | string;
    invoiceDate: string;
    showRequisition: boolean;
    requisition: string;
    setRequisition: Dispatch<SetStateAction<string>>;
}

const InvoiceDetails: FC<IProps> = ({ fetching, itemsCount, invoiceId, invoiceDate, showRequisition, requisition, setRequisition }) => {
    const translate = useTranslations();

    return (
        <section className={styles.invoiceDetails}>
            <ValueWithCaption caption={translate('overview.numberOfProducts', 'antal produkter')}>
                <Text textStyle="monoSmall">{itemsCount}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('overview.invoiceNumber', 'Faktura nr.')}>
                <Text textStyle="monoSmall">{invoiceId}</Text>
            </ValueWithCaption>
            {showRequisition && (
                <ValueWithCaption caption={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                    <Input
                        aria-label="Requisition"
                        disabled={fetching}
                        className={styles.requisitionInput}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setRequisition(e.target.value)}
                        name="requisition"
                        value={requisition}
                    />
                </ValueWithCaption>
            )}
            <ValueWithCaption caption={translate('overview.dateOfPurchase', 'Købsdato')}>
                <Text textStyle="monoSmall">{invoiceDate}</Text>
            </ValueWithCaption>
        </section>
    );
};

export default InvoiceDetails;
