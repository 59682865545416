import type { Products as ProductTypes } from '~/models/products';

import React from 'react';
import Text from '~/shared/text/text.component';
import styles from './product-vehicle-info.module.scss';

export type VehicleInfoPart = {
    key: string;
    value: string;
};

export type ProductVehicleInfo = {
    articles: ProductTypes.IProductArticle[];
};

export function ProductVehicleInfo({ articles }: ProductVehicleInfo) {
    return (
        <>
            {articles
                .reduce<VehicleInfoPart[][]>((list, { criteria = [] }) => {
                    const line = criteria.reduce<VehicleInfoPart[]>((attributes, { key, text = [] }) => {
                        return [
                            ...attributes,
                            {
                                key: String(key),
                                value: text.join(', '),
                            },
                        ];
                    }, []);

                    return [...list, line];
                }, [])
                .map((item, i) => {
                    return (
                        <Text key={i} tagName="p" textStyle="bodySmall">
                            {item.map(({ key, value }) => (
                                <span className={styles.productVehicleInfoAttribute} key={key}>
                                    <b key={key}>{key}:</b> {value}
                                </span>
                            ))}
                        </Text>
                    );
                })}
        </>
    );
}
