import React, { FC } from 'react';
import styles from './overview-load-more.module.scss';
import LoadMore, { ILoadMoreProps } from '~/shared/load-more/load-more.component';

const OverviewLoadMore: FC<ILoadMoreProps> = ({ total, loaded, fetching, onClick }) => (
    <div className={styles.loadMore}>
        <LoadMore total={total} loaded={loaded} onClick={onClick} fetching={fetching} />
    </div>
);

export default OverviewLoadMore;
