import { useRouter } from 'next/router';
import { Products as ProductTypes } from '~/models/products.d';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';

export function useRelatedProducts(ids: number[] = []) {
    const { locale } = useRouter();
    const { user, isLoggedIn } = useUser();
    const { expertMode } = useLayoutState();

    const url = createUrl(
        {
            endpoint: endpoints.products.getVariantsSimple,
            localeOption: LocaleOptions.path,
            query: {
                includeExpertProducts: expertMode,
            },
        },
        locale,
    );
    return useQuery<ProductTypes.IVariantLight[], Error>({
        queryKey: queryKeys.products.relatedProducts(locale, ids, expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(url, ids, { signal });
        },

        enabled: isLoggedIn && ids.length > 0,
    });
}
