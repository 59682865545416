import clsx from 'clsx';
import { IOrdersWithSubmitStatus } from '~/libs/queries/users/hooks/use-manual-web-orders';
import useTranslations from '~/shared/hooks/use-translations.hook';
import ManualOrderRequestButton from '../manual-order-request-button/manual-order-request-button.component';
import styles from './styled.module.scss';

interface IOrdersTableProps {
    orders?: IOrdersWithSubmitStatus[];
    setOrderSubmitted: (webOrderId: string) => void;
}

export default function OrdersTable({ orders, setOrderSubmitted }: IOrdersTableProps) {
    const translate = useTranslations();

    if (!orders?.length) {
        return <p className={styles.noResults}>{translate('common.noResults', 'Der er ingen resultater')}</p>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerRow}>
                <div className={styles.cell}>
                    <span className="caption">{translate('myFtz.manualOrders.dpt', 'Afd')}</span>
                </div>
                <div className={styles.cell}>
                    <span className="caption">{translate('myFtz.manualOrders.orderNumber', 'Ordrenummer')}</span>
                </div>
                <div className={styles.cell}>
                    <span className="caption">{translate('myFtz.manualOrders.orderDate', 'Ordredato')}</span>
                </div>
                <div className={styles.cell}>
                    <span className="caption"> {translate('myFtz.manualOrders.customerNumber', 'Kundenummer')}</span>
                </div>
                <div className={styles.cell}>
                    <span className="caption">{translate('myFtz.manualOrders.customerName', 'Kundenavn')}</span>
                </div>
                <div className={styles.cell}>
                    <span className="caption">{translate('myFtz.manualOrders.user', 'Bruger')}</span>
                </div>
                <div className={styles.cell} />
            </div>

            {orders.map((row) => (
                <div
                    key={row.webOrderId}
                    className={clsx(styles.bodyRow, {
                        [styles.bodyRowSubmitted]: row?.submitted,
                    })}
                >
                    <div className={styles.cell}>{row?.departmentId}</div>
                    <div className={clsx(styles.cell, styles.cellPreformatted)}>{row?.webOrderId}</div>
                    <div className={clsx(styles.cell, styles.cellPreformatted)}>{row?.displayDate}</div>
                    <div className={clsx(styles.cell, styles.cellPreformatted)}>{row?.customerId}</div>
                    <div className={styles.cell}>{row?.customerName}</div>
                    <div className={styles.cell}>{row?.userName}</div>
                    <div className={styles.cell}>
                        <ManualOrderRequestButton submitted={!!row?.submitted} setOrderSubmitted={setOrderSubmitted} webOrderId={row?.webOrderId} />
                    </div>
                </div>
            ))}
        </div>
    );
}
