import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';
import { AddToBasketQuantityInput, AddToBasketQuantityInputProps } from './add-to-basket-quantity-input';

export type AddToBasketQuantityInputContainerProps = {
    initialValue: AddToBasketQuantityInputProps['value'];
    isLarge?: boolean;
    onChange: (value: AddToBasketQuantityInputProps['value']) => void;
    setInputHasFocus: (value: boolean) => void;
    inputRef: AddToBasketQuantityInputProps['inputRef'];
};

const getValidInputValue = (value: string | undefined): number | undefined => {
    if (value === '' || value === undefined) {
        return undefined;
    }

    return parseInt(value) ?? 0;
};

export function AddToBasketQuantityInputContainer({
    initialValue,
    isLarge,
    onChange,
    setInputHasFocus,
    inputRef,
}: AddToBasketQuantityInputContainerProps) {
    // Using uncontrolledValue for optimistic update/user feedback
    // Do not await API response before updating the UI, but revert in parent through inputValue prop if fails
    const [uncontrolledValue, setUncontrolledValue] = useState(initialValue);

    // Listen to change of initialValue to initiate focus when addToBasket has been clicked
    useEffect(() => {
        setUncontrolledValue(initialValue ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => {
        const newValue = getValidInputValue(currentTarget.value);

        setUncontrolledValue(newValue);

        if (newValue !== undefined) {
            onChange(newValue);
        }
    };

    const handleInputBlur: FocusEventHandler<HTMLInputElement> = ({ currentTarget }) => {
        onChange(getValidInputValue(currentTarget.value || '0'));
    };

    return (
        <AddToBasketQuantityInput
            value={uncontrolledValue}
            isLarge={isLarge}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onWheel={({ currentTarget }) => currentTarget.blur()}
            inputRef={inputRef}
            setInputHasFocus={setInputHasFocus}
        />
    );
}
