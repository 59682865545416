/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import useUser from '~/libs/use-user';
import { AddToBasketContainer } from '~/shared/add-to-basket/add-to-basket.container';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import CopyToClipboard from '~/shared/copy-to-clipboard/copy-to-clipboard.component';
import CrossNumbers from '~/shared/cross-numbers/cross-numbers.component';
import FtzPricesTooltip from '~/shared/ftz-prices-tooltip/ftz-prices-tooltip.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import PriceTooltip from '~/shared/price-tooltip/price-tooltip.component';
import Splash, { splashType } from '~/shared/splash/splash.component';
import { StockIndicator } from '~/shared/stock-status/stock-status.component';
import { Svg } from '~/shared/svg/svg.component';
import Text from '~/shared/text/text.component';
import styles from '../variants-list.module.scss';
import { ShowRelatedProducts } from './show-related-products/show-related-products.component';

import { GA4ModifyWishlist, GA4SelectItem } from '~/libs/ga4';
import { getListName, useBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
import { Products as ProductTypes } from '~/models/products.d';
import useActiveUser from '~/libs/use-active-user';
import { useAddFavorite, useFavoriteList, useRemoveFavorite } from '~/libs/queries/favorites';
import { FavoriteButton } from '~/shared/add-favorite/add-favorite.component';
import { Bff } from '~/models/bff';
import { ConverterItem } from '~/libs/ga4/converters/convert-generic/convert-generic';
import { GA4 } from '~/libs/ga4/types/ga4';
import LabelOrderButton from '~/widgets/W062LabelOrder/label-order-button/label-order-button.component';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';
import { useCurrentBasket } from '~/libs/queries/basket';

interface IVariantRowProps {
    relatedVariantIds?: number[] | undefined;
    relatedVariantsExtraInfo?: ProductTypes.IRelatedVariant[];
    loadingItems?: number;
    isErrorStockStatus?: Error;

    variant: ProductTypes.IVariant;
    position: number;
    title: string | undefined;
    originalEquipment?: ProductTypes.IProductOeMatches;
    loadingPrices: boolean;
    prices?: ProductTypes.IVariantPrices;
    errorPrices: boolean;
    favoriteStatus: ProductTypes.FavoriteStatus;
    stock: ProductTypes.IStockStatus;
    loadingStock: boolean;
    errorStock: boolean;
    pageIndex?: number;
    itemNoList: string[];
}

const VariantListRow: FC<IVariantRowProps> = ({
    title,
    relatedVariantIds,
    variant,
    relatedVariantsExtraInfo,
    loadingPrices,
    originalEquipment,
    prices,
    favoriteStatus,
    stock,
    loadingStock,
    errorStock,
    loadingItems,
    pageIndex,
    itemNoList,
}) => {
    const { profile, isInternalUser } = useUser();
    const { data: basket } = useCurrentBasket();
    const translate = useTranslations();
    const [showRelatedProducts, setShowRelatedProducts] = useState(false);
    const { data: breadcrumb } = useBreadcrumb();
    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();

    const isAnimationOnAddFeatureEnabled = shouldShowFeature(features?.productList?.useAccessoryProducts as Bff.FeatureToggle);
    const isAnimatingOnAdd = variant.localizedRelatedVariants?.some((item) =>
        [ProductTypes.NecessityLevel.MustBeUsed, ProductTypes.NecessityLevel.RecommendedNotIncluded].includes(item.necessityLevel),
    );

    const { shortDescription, crossNumbers, itemNo } = variant;
    const { [variant?.itemNo as string]: oeReference } = originalEquipment?.variantOeReferenceNoByFtzCode || {};

    const handleClipboardClick = () => {
        const listName = getListName(breadcrumb, title);
        GA4SelectItem(variant, {
            currency: profile?.currency as string,
            price: prices?.actualPrice?.customerPrice as number,
            quantity: 1,
            basketId: basket?.id as string,
            listName,
        });
    };

    const handleRelatedProductsClick = () => setShowRelatedProducts(true);

    return (
        <>
            <VariantListRowContainer styling={variant?.isExpertProduct ? 'expertProduct' : 'default'}>
                <VariantListRowBrand brand={variant?.brand} />
                <VariantListRowItemNo variant={variant} onClick={handleClipboardClick} />
                <VariantListRowDescription>{shortDescription}</VariantListRowDescription>
                <VariantListRowCode variant={variant} price={prices as unknown as IColliPrice} loading={loadingPrices} />
                <VariantListRowSplash variant={variant} isOriginalEquipment={Boolean(oeReference)} />
                <VariantListRowPrice price={prices?.actualPrice} loading={loadingPrices} />
                <VariantListRowStock isInternalUser={isInternalUser} variant={variant} stock={stock} loading={loadingStock} error={errorStock} />

                <VariantListRowActionContainer>
                    <div className={styles.actionIconButtons}>
                        {!!crossNumbers?.length && (
                            <CrossNumbers
                                oeArticleNo={oeReference}
                                values={crossNumbers}
                                icon={<Svg name="crossNumber" />}
                                buttonStyle="clean"
                                buttonSize="link"
                                className={styles.crossNumber}
                                tooltipText={translate('product.showCrossNumbers', 'Se kryds nr')}
                            />
                        )}

                        {itemNo && <LabelOrderButton identifier={itemNo} />}
                    </div>

                    <VariantListRowFavoriteButton
                        status={favoriteStatus}
                        variant={variant}
                        price={prices?.actualPrice?.customerPrice}
                        pageIndex={pageIndex}
                        itemNoList={itemNoList}
                    />
                    <VariantListRowBasketButton
                        variant={variant}
                        price={prices?.actualPrice?.customerPrice}
                        handleClick={handleRelatedProductsClick}
                    />
                </VariantListRowActionContainer>
            </VariantListRowContainer>
            {isAnimationOnAddFeatureEnabled && isAnimatingOnAdd && relatedVariantIds && loadingItems ? (
                <ShowRelatedProducts
                    relatedVariantIds={relatedVariantIds}
                    relatedVariantsExtraInfo={relatedVariantsExtraInfo}
                    loadingItems={loadingItems}
                    showRelatedProducts={showRelatedProducts}
                    setShowRelatedProducts={setShowRelatedProducts}
                />
            ) : null}
        </>
    );
};

export type VariantListRowContainerStyling = 'default' | 'expertProduct';
export type VariantListRowContainerProps = {
    children: React.ReactNode | React.ReactNode[];
    styling?: VariantListRowContainerStyling;
};

export function VariantListRowContainer({ children, styling = 'default' }: VariantListRowContainerProps) {
    return (
        <div
            className={clsx(styles.row, {
                [styles.rowExpert]: styling === 'expertProduct',
            })}
        >
            {children}
        </div>
    );
}

export type VariantListRowBrandProps = {
    brand: ProductTypes.IBrand | undefined;
};

export function VariantListRowBrand({ brand }: VariantListRowBrandProps) {
    return (
        <div className={styles.brandCell}>
            <BrandLogo brand={brand} height={20} width={64} />
        </div>
    );
}

export type VariantListRowItemNoProps = {
    variant: ProductTypes.IVariant;
    onClick: () => void;
};

export function VariantListRowItemNo({ variant: { itemNo, url }, onClick }: VariantListRowItemNoProps) {
    const translate = useTranslations();

    return (
        <div className={styles.itemNoCell}>
            <CopyToClipboard textToCopy={itemNo} tooltipText={translate('product.copyItemNo', 'Kopiér vare nr.')}>
                <Link className={styles.itemNo} href={url} prefetch={false} onClick={onClick}>
                    <Text title={itemNo} textStyle="monoMedium" overflowEllipsis>
                        {itemNo}
                    </Text>
                </Link>
            </CopyToClipboard>
        </div>
    );
}

export type VariantListRowDescriptionProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function VariantListRowDescription({ children }: VariantListRowDescriptionProps) {
    return (
        <div className={styles.detailsCell}>
            <Text textStyle="monoSmall">{children}</Text>
        </div>
    );
}

export type VariantListRowCodeProps = {
    variant: ProductTypes.IVariant;
    price: IColliPrice;
    loading: boolean;
};

export function VariantListRowCode({ variant: { unit, colli, colliLocked }, price, loading }: VariantListRowCodeProps) {
    return (
        <div className={styles.ftzCodeCell}>
            <FtzPricesTooltip unit={unit} isLoading={loading} prices={price} colli={colli} colliLocked={colliLocked} />
        </div>
    );
}

export type VariantListRowSplashProps = {
    variant: ProductTypes.IVariant;
    isOriginalEquipment: boolean;
};

export function VariantListRowSplash({ variant, isOriginalEquipment }: VariantListRowSplashProps) {
    return (
        <div className={styles.splashCell}>
            {isOriginalEquipment && <Splash small type="OE" className={styles.originalPartSplash} />}
            {variant?.campaign && <Splash small type={variant?.campaign.code as splashType} />}
        </div>
    );
}

export type VariantListRowPriceProps = {
    price?: ProductTypes.IVariantPrice;
    loading: boolean;
};

export function VariantListRowPrice({ price, loading }: VariantListRowPriceProps) {
    return (
        <div className={styles.priceCell}>
            <PriceTooltip isLoading={loading} displayPrice={price?.displayPrice} fee={price?.displayFee} deposit={price?.displayDeposit} />
        </div>
    );
}

export type VariantListRowStockProps = {
    variant: ProductTypes.IVariant;
    stock?: ProductTypes.IStockStatus;
    error?: boolean;
    loading?: boolean;
    isInternalUser?: boolean;
};

export function VariantListRowStock({ variant, stock, error, loading, isInternalUser }: VariantListRowStockProps) {
    return (
        <div className={styles.stockStatusCell}>
            <StockIndicator
                subGroup={variant?.productGroups?.subGroup}
                stockStatus={stock}
                isError={error}
                isLoading={loading}
                itemNo={variant?.itemNo}
                isInternalUser={isInternalUser}
            />
        </div>
    );
}

export type VariantListRowActionContainerProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function VariantListRowActionContainer({ children }: VariantListRowActionContainerProps) {
    return <div className={styles.addToBasketCell}>{children}</div>;
}

export type VariantListRowBasketButtonProps = {
    variant: ProductTypes.IVariant;
    handleClick: () => void;
    price: number | null | undefined;
};

export function VariantListRowBasketButton({ variant, handleClick, price = null }: VariantListRowBasketButtonProps) {
    const translate = useTranslations();
    const { colli, colliLocked, itemNo } = variant;

    return (
        <div className={styles.addToBasketWrapper} onClick={handleClick} role="button" tabIndex={-1}>
            <AddToBasketContainer
                smallUnder="XL"
                label={translate('product.buy', 'Køb')}
                syncWithBasket
                url={variant.url as string}
                productDetails={{
                    itemNo: itemNo || '',
                    customerPrice: price,
                    colli: colli,
                    colliLocked: colliLocked,
                }}
            />
        </div>
    );
}

export type VariantListRowFavoriteButtonProps = {
    status?: ProductTypes.FavoriteStatus;
    variant?: ProductTypes.IVariant;
    price?: number;
    pageIndex?: number;
    itemNoList: string[];
};

export function VariantListRowFavoriteButton({ variant, status, price, pageIndex, itemNoList }: VariantListRowFavoriteButtonProps) {
    const { profile } = useUser();
    const { activeProfile } = useActiveUser();
    const { data: breadcrumb } = useBreadcrumb();
    const { data: favorites } = useFavoriteList({ userId: activeProfile?.id, type: 'MyFavorite' });

    const { mutate: addFavorite } = useAddFavorite({
        userId: activeProfile?.id,
        pageIndex,
        placement: 'list',
        itemNoList,
    });

    const { mutate: removeFavorite } = useRemoveFavorite({
        userId: activeProfile?.id,
        pageIndex,
        placement: 'list',
        itemNoList,
    });

    const handleClick = () => {
        let type: GA4.WishListAction = 'add';
        switch (status) {
            case ProductTypes.FavoriteStatus.MyFavorite:
                type = 'remove';
                removeFavorite(variant?.itemNo as string);
                break;
            case ProductTypes.FavoriteStatus.NotFavorite:
                addFavorite(variant?.itemNo as string);
                break;
        }
        GA4ModifyWishlist(variant as ConverterItem, {
            currency: profile?.currency as string,
            price: price || 0,
            listName: getListName(breadcrumb, variant?.title),
            type: type,
            content: favorites?.favoriteProducts?.map((fav) => fav.itemId).join(),
        });
    };

    const isDisabled = typeof status === 'undefined' || status === ProductTypes.FavoriteStatus.ChainFavorite;
    const isLoading = (typeof status === 'undefined' || !variant) && !isDisabled;

    return <FavoriteButton handleClick={handleClick} status={status} loading={isLoading} disabled={isDisabled} />;
}

export default VariantListRow;
