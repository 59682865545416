import React from 'react';
import styles from './date-filter.module.scss';
import DatePicker from '~/shared/form-elements/date-picker/date-picker.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { DateSetter } from '~/shared/hooks/use-infinite-loading';
import { CLIENT_QUERY_OPTIONS } from '~/constants/query';

interface IDateFilterProps {
    fromDate: string;
    toDate: string;
    setDate: DateSetter;
}

const DateFilter: React.FC<IDateFilterProps> = ({ toDate, fromDate, setDate }) => {
    const translate = useTranslations();

    const handleToDateChange = (date: string) => setDate(date, CLIENT_QUERY_OPTIONS.toDate);
    const handleFromDateChange = (date: string) => setDate(date, CLIENT_QUERY_OPTIONS.fromDate);

    return (
        <div className={styles.dateFilter}>
            <DatePicker
                placement="bottom-start"
                className={styles.datePicker}
                label={translate('common.from', 'fra')}
                value={fromDate}
                maxDate={toDate}
                onChange={handleFromDateChange}
            />
            <DatePicker
                placement="bottom-end"
                className={styles.datePicker}
                label={translate('common.to', 'til')}
                value={toDate}
                minDate={fromDate}
                onChange={handleToDateChange}
            />
        </div>
    );
};

export default DateFilter;
