import { CmsData } from '~/models/cms-data';
import CarInfo from '~/shared/car-info/car-info.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import CaseDetails from './case-details/case-details.component';
import CustomerDetails from './customer-details/customer-details.component';
import styles from './styled.module.scss';

type ICaseProps = {
    details: CmsData.IReturnDetails;
};

export default function Case({ details }: ICaseProps) {
    return (
        <PanelCard className={styles.wrapper}>
            <div className={styles.header}>
                <CarInfo carInfo={details?.carInfo} hideCountryCode />
                <CaseDetails date={details?.fittingDisplayDate} fittingKm={details?.fittingKm} complaintKm={details?.complaintKm} />
            </div>

            <CustomerDetails
                customerId={details?.customerId}
                customerName={details?.customerName}
                address={details?.address}
                zipCodeAndCity={details?.zipCodeAndCity}
                departmentId={details?.departmentId}
                cause={details?.cause}
                damageDescription={details?.damageDescription}
            />
        </PanelCard>
    );
}
