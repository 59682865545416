import React, { FC } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import styles from './product-documents.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import { Svg } from '~/shared/svg';
import SkeletonFallback from './skeleton-fallback.component';
import ErrorBox from '~/shared/error-box/error-box';
import { Products as ProductTypes } from '~/models/products.d';
import Heading from '../heading/heading.component';
import { useProductDocuments } from '~/libs/queries/basket/hooks/use-product-documents';

interface IDocumentGroupProps {
    documentsGroup: ProductTypes.IDocumentGroup;
}

const DocumentsGroup: React.FC<IDocumentGroupProps> = ({ documentsGroup }) => {
    const translate = useTranslations();
    if (!documentsGroup?.documents?.length) return null;

    return (
        <>
            <Heading tagName="h4">{documentsGroup.alias}</Heading>
            <ul className={styles.documentsList}>
                {documentsGroup?.documents?.map((document, i) => (
                    <li key={`${document.description}-${i}`}>
                        <Link className={styles.link} href={document.url} target="_blank">
                            {document.description || translate('product.seeDocument', 'Se dokument')}
                            <Svg name="paper-stack" thick className={styles.linkIcon} />
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};

interface IProductDocumentsProps extends IWithClassName {
    variantId?: string;
    className?: string;
}

const ProductDocuments: FC<IProductDocumentsProps> = ({ className, variantId }) => {
    const translate = useTranslations();

    const { data: documents, isError, isLoading } = useProductDocuments(variantId);

    if (isLoading) {
        return (
            <div className={className}>
                <SkeletonFallback />
            </div>
        );
    }

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return <ProductDocumentList className={className} documents={documents} />;
};

export type ProductDocumentListProps = {
    documents: ProductTypes.IDocumentGroup[];
    className?: string;
};

export function ProductDocumentList({ className, documents }: ProductDocumentListProps) {
    return (
        <div className={clsx(className, styles.documents)}>
            {documents.map((group, i) => (
                <div key={`${group.alias}-${i}`}>
                    <DocumentsGroup documentsGroup={group} />
                </div>
            ))}
        </div>
    );
}

export default ProductDocuments;
