import Heading from '~/shared/heading/heading.component';
import Text from '~/shared/text/text.component';
import styles from '../basket.module.scss';
import { BasketErrorContainer } from '../components/basket-error/basket-error.container';
import { BasketHeaderContainer } from '../components/basket-header/basket-header.container';
import { W110SavedBasketsOverview } from '~/widgets/overview';

export type BasketEmptyProps = {
    heading: string;
    showSavedBaskets: boolean;
    savedBasketsHeading?: string;
    savedBasketsSubHeading?: string;
};

export function BasketEmpty({ heading, showSavedBaskets, savedBasketsHeading, savedBasketsSubHeading }: BasketEmptyProps) {
    return (
        <div className={styles.basketEmptyWrapper}>
            <BasketHeaderContainer />
            <BasketErrorContainer />

            <Text tagName="h2" textAlign="center" className={styles.basketEmptyHeadline}>
                {heading}
            </Text>

            {showSavedBaskets ? (
                <>
                    <Heading tagName="h2" displayStyle="h4" subHeading={savedBasketsSubHeading}>
                        {savedBasketsHeading}
                    </Heading>

                    <W110SavedBasketsOverview />
                </>
            ) : null}
        </div>
    );
}
