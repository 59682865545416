import { endpoints, LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

export function useVehiclesFits(variantsIDs: string[]) {
    const { createUrl } = useEndpoints();
    const { expertMode } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const shouldFetch = isLoggedIn && variantsIDs.length > 0;
    const { locale } = useRouter();
    const url = createUrl({
        endpoint: endpoints.vehicles.productsFits,
        localeOption: LocaleOptions.path,
        query: {
            includeExpertProducts: expertMode,
        },
    });

    return useQuery<VehicleTypes.IVehicleProductsFitsDetails, Error>({
        queryKey: queryKeys.vehicle.vehiclesFits(locale, variantsIDs, !!expertMode),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).postJSON(url, variantsIDs, {
                signal,
            });
        },
        enabled: shouldFetch,
    });
}
