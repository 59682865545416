import { FC, forwardRef, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Svg } from '~/shared/svg';
import styles from './toggle.module.scss';
import { IWithClassName } from '~/models/dev';

export enum ToggleType {
    standard = 'standard',
    expertMode = 'expertMode',
}

export interface IHandleProps {
    icon: string;
    text: string;
    active: boolean;
    toggleType: ToggleType;
}

const Handle = forwardRef<HTMLDivElement, IHandleProps>(({ icon, text, active, toggleType }, ref) => (
    <div className={styles.option} ref={ref}>
        <Svg
            name={icon}
            thick={toggleType === ToggleType.expertMode}
            className={clsx(styles.icon, {
                [styles.iconDeactivated]: !active,
            })}
        />

        {text && <div className={styles.text}>{text}</div>}
    </div>
));

export interface IProps extends IWithClassName {
    isOn: boolean;
    onClick?: () => void;
    onIcon: string;
    onText?: string;
    offIcon: string;
    offText?: string;
    toggleType?: ToggleType;
}

const Toggle: FC<IProps> = ({ className, isOn, onClick, onIcon, onText, offIcon, offText, toggleType = ToggleType.standard }) => {
    const [isReady, setReady] = useState(false);
    const [handleOffWidth, setHandleOffWidth] = useState<number | undefined>(0);
    const [handleOnWidth, setHandleOnWidth] = useState<number | undefined>(0);

    const handleOffEl = useRef<HTMLDivElement>(null);
    const handleOnEl = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        setHandleOffWidth(handleOffEl?.current?.offsetWidth);
        setHandleOnWidth(handleOnEl?.current?.offsetWidth);
        setReady(true);
    }, [handleOffEl?.current?.offsetWidth, handleOnEl?.current?.offsetWidth]);

    return (
        <button
            aria-label="Toggle"
            className={clsx(className, styles.switch, {
                [styles.switchExpert]: toggleType === ToggleType.expertMode,
                [styles.switchReady]: isReady,
            })}
            onClick={onClick}
            type="button"
        >
            <div className={styles.inner}>
                <div
                    className={styles.handle}
                    style={{
                        width: isOn ? handleOnWidth : handleOffWidth,
                        left: isOn ? handleOffWidth : 0,
                    }}
                />
                <Handle ref={handleOffEl} toggleType={toggleType} icon={onIcon} text={onText as string} active={!isOn} />
                <Handle ref={handleOnEl} toggleType={toggleType} icon={offIcon} text={offText as string} active={isOn} />
            </div>
        </button>
    );
};

export default Toggle;
