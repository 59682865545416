import { useCallback } from 'react';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { useTopmotiveModalState } from '../../hooks';
import { TopmotiveBody, TopmotiveButtonGroup, TopmotiveHeading, TopmotiveProductDetails } from '..';
import { GA4CopyTruckOENumber } from '~/libs/ga4/ga4';

export function TopmotiveRequestStart() {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { navigate, productId, productName, manufacturerName } = useTopmotiveModalState(({ navigate, details }) => ({
        navigate,
        productId: details?.productId ?? '',
        productName: details?.productName ?? '',
        manufacturerName: details?.manufacturerName ?? '',
    }));

    const handleCopy = useCallback(async () => {
        await navigator.clipboard.writeText(productId);

        dispatch(addSuccessToast(translate('topmotive.request.copySuccess', 'OE-nummer kopieret')));

        GA4CopyTruckOENumber(productId, manufacturerName);
    }, [dispatch, manufacturerName, productId, translate]);

    const handleNavigateForward = useCallback(() => {
        navigate('form');
    }, [navigate]);

    return (
        <>
            <TopmotiveHeading>{translate('topmotive.request.startHeadline', 'Vi har desværre ikke produktet, endnu...')}</TopmotiveHeading>
            <TopmotiveBody>{translate('topmotive.request.startBody', 'Det valgte produkt er desværre ikke på lager.')}</TopmotiveBody>
            <TopmotiveProductDetails name={productName} id={productId} />
            <TopmotiveButtonGroup>
                <Button onClick={handleCopy} icon={<Svg name="paper-stack" />}>
                    {translate('topmotive.request.copyButton', 'Kopiér OE nummer')}
                </Button>
                <Button onClick={handleNavigateForward} icon={<Svg name="request" />} buttonStyle="secondary">
                    {translate('topmotive.request.requestButton', 'Forespørg ved FTZ')}
                </Button>
            </TopmotiveButtonGroup>
        </>
    );
}
