import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { favoriteKeys, FavoritePlacement, FAVORITES_STATUS_REMOVE_URL, FavoriteStatusListResponse } from '../.';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

export type UseAddFavoriteProps = {
    userId?: string;
    pageIndex?: number;
    placement: FavoritePlacement;
    itemNoList: string[];
};

export function useRemoveFavorite(
    { userId, pageIndex, placement, itemNoList }: UseAddFavoriteProps,
    args: Omit<UseMutationOptions<FavoriteStatusListResponse, Error, string>, 'mutationKey'> = {},
) {
    const queryClient = useQueryClient();

    const { user } = useUser();
    const { locale } = useRouter();

    return useMutation<FavoriteStatusListResponse, Error, string>({
        mutationFn: (itemNo: string) => {
            return apiClient.auth(user).delete(
                createUrl(
                    {
                        endpoint: FAVORITES_STATUS_REMOVE_URL,
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
                {
                    body: JSON.stringify(itemNo),
                    headers: { 'Content-Type': 'application/json' },
                },
            );
        },
        onMutate: async (itemNo) => {
            await queryClient.cancelQueries(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex));

            queryClient.setQueryData<FavoriteStatusListResponse>(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex), (old) => {
                return { ...old, [itemNo]: 0 };
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(favoriteKeys.statusList(locale, userId, placement, itemNoList, pageIndex));
        },
        ...args,
    });
}
