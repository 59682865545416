import { Bff } from '~/models/bff';
import Heading from '~/shared/heading/heading.component';

//Name before: section-heading.component.tsx
export default function M040Headline({ headlineId = '', headline, horizontalAlignment, style, tag }: Bff.IM040HeadlineModuleViewModel) {
    const encodedId = encodeURIComponent(headlineId);
    const validTag = getValidHeading(tag?.toLowerCase());
    const validStyle = getValidHeading(style?.toLowerCase());
    const validHorizontalAlignment = getValidAlignment(horizontalAlignment);

    return (
        <Heading tagName={validTag} style={{ textAlign: validHorizontalAlignment }} id={encodedId} displayStyle={validStyle}>
            {headline}
        </Heading>
    );
}

const VALID_HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5'] as const;
const VALID_ALIGNMENTS = ['left', 'right', 'center'] as const;

type ValidHeading = (typeof VALID_HEADINGS)[number];
type ValidAlignment = (typeof VALID_ALIGNMENTS)[number];

function isValidHeading(value: any): value is ValidHeading {
    return VALID_HEADINGS.includes(value);
}

function getValidHeading(value?: string): ValidHeading {
    if (isValidHeading(value)) {
        return value;
    }
    return 'h3';
}

function isValidAlignment(value: any): value is ValidAlignment {
    return VALID_ALIGNMENTS.includes(value);
}

function getValidAlignment(value: string): ValidAlignment {
    if (isValidAlignment(value)) {
        return value;
    }
    return 'left';
}
