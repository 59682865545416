import React, { FC } from 'react';
import Text from '~/shared/text/text.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './no-results.module.scss';

type NoResultsProps = {
    children?: React.ReactNode;
};

const NoResults: FC<NoResultsProps> = ({ children }) => {
    const translate = useTranslations();
    return (
        <Text textStyle="bodySmall" textAlign="center" fontWeight="semiBold" className={styles.noResults}>
            {children || translate('common.noResults', 'Der er ingen resultater')}
        </Text>
    );
};

export default NoResults;
