import Filters from '~/page-elements/overview/common/filters/filters.component';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import Header from '../common/header/header.component';
import ReturnsList from './returns-list/returns-list.component';

//Name before: returns.component.tsx
function W100ReturnsOverview() {
    const { orders } = useEndpoints();

    const { data, error, total, loaded, isLoadingMore, isLoadingInitialData, loadMore, toDate, fromDate, setDate, searchVal, setSearchVal } =
        useInfiniteLoading({
            pathName: `${orders.rma}/overview`,
            key: 'rmaList',
            localeOption: LocaleOptions.path,
        });

    return (
        <>
            <Header>
                <Filters fromDate={fromDate} toDate={toDate} setDate={setDate} query={searchVal} setQuery={setSearchVal} hidePageSizeFilter />
            </Header>

            <ReturnsList error={error} customerComplaints={data} fetching={isLoadingInitialData} />

            {data?.length ? <OverviewLoadMore total={total} loaded={loaded} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </>
    );
}

export default WithOverviewNavigation(W100ReturnsOverview);
