import { Widget } from '~/libs/queries/bff/hooks/use-cms-page';
import { Bff } from '~/models/bff';
import {
    F010USPModule,
    F020EmailBanner,
    M010USPModule,
    M033ProductsCarousel,
    M034ProductsCarouselRaptor,
    M040Headline,
    M070Image,
    M071Video,
    M072VideoPopup,
    M080ContentCarousel,
    M100RichText,
    M101RichTextAndMedia,
    M102IconTitleAndRichText,
    M103SmallRichText,
    M120Accordion,
    M132TwoColumnRow,
    M140DataTable,
    M150InformationBanner,
    M160LinkContainer,
    M161LinkContainerAndRichText,
    M170HeroSlider,
    M180iPaper,
    M190ImageSpotHeroSlider,
    M191ImageSpots,
    M200TicketButlerScript,
} from '~/modules';
import {
    W010CarChassisSupport,
    W041Favorites,
    W05Basket,
    W061Impersonate,
    W062LabelOrder,
    W071ManualWebOrders,
    W072Media,
    W073OESpareParts,
    W081CarReplaceEngine,
    W101SalesPriceMarkup,
    W112SearchResultsProductList,
    W120SparePartCategoryList,
    W121SparePartProductList,
    W122SparePartVariant,
    W130UniversalPartCategoryList,
    W131UniversalPartProductList,
    W132UniversalPartVariant,
    W141UsersAdministration,
    W143VantageDashboard,
    W150VehicleModelList,
    W160VehicleTypeList,
} from '~/widgets';
import { IMediaProps } from '~/widgets/W072Media/W072Media';
import { W070Login } from '~/widgets/content-page';
import { W042HaynesLogin, W072NFLogin } from '~/widgets/external-login';
import {
    W043InternalCustomerSearch,
    W044InternalManualOrders,
    W045InternalReturnDeposit,
    W046InternalLatestCustomers,
    W047InternalLatestOrders,
    W048InternalUserInfo,
} from '~/widgets/internal-dashboard';
import {
    W020ChemicalProducts,
    W030CreditsDetailOverview,
    W040CreditsOverview,
    W050InvoiceDetailOverview,
    W060InvoicesOverview,
    W080OrdersOverview,
    W090ReturnsDetailOverview,
    W100ReturnsOverview,
    W110SavedBasketsOverview,
    W111SavedOrders,
} from '~/widgets/overview';

export type DynamicWidgetProps = {
    widget: Widget;
};

export function DynamicWidget({ widget }: DynamicWidgetProps) {
    switch (widget.type) {
        // TODO: Register new widgets here.
        // example:
        case 'M060UspModule':
            return <M010USPModule {...widget} />;
        case 'F010USPModule':
            return <F010USPModule {...widget} />;
        case 'M210EmailSubscriberModule':
        case 'F020EmailBanner':
            return <F020EmailBanner {...widget} />;
        case 'M033ProductsCarouselModule':
            return <M033ProductsCarousel {...(widget as Bff.IM033ProductsCarouselModuleViewModel)} />;
        case 'M034RaptorCarouselModule':
            return <M034ProductsCarouselRaptor {...(widget as Bff.IM034RaptorCarouselModuleViewModel)} />;
        case 'M040HeadlineModule':
            return <M040Headline {...(widget as Bff.IM040HeadlineModuleViewModel)} />;
        case 'M070ImageModule':
            return <M070Image {...(widget as Bff.IM070ImageModuleViewModel)} />;
        case 'M071VideoModule':
            return <M071Video {...widget} />;
        case 'M072VideoPopupModule':
            return <M072VideoPopup {...(widget as Bff.IM072VideoPopupModuleViewModel)} />;
        case 'M080ContentCarousel':
            return <M080ContentCarousel {...widget} />;
        case 'M100RichTextModule':
            return <M100RichText {...widget} />;
        case 'M101RichTextAndMediaModule':
            return <M101RichTextAndMedia {...widget} />;
        case 'M102IconTitleAndRichTextModule':
            return <M102IconTitleAndRichText {...widget} />;
        case 'M103SmallRichTextModule':
            return <M103SmallRichText {...widget} />;
        case 'M120AccordionModule':
            return <M120Accordion {...widget} />;
        case 'M132TwoColumnRow':
            return <M132TwoColumnRow {...(widget as Bff.IM132TwoColumnRowViewModel)} />;
        case 'M140DataTableModule':
            return <M140DataTable {...widget} />;
        case 'M150InformationBannerModule':
            return <M150InformationBanner {...widget} />;
        case 'M160LinkContainerModule':
            return <M160LinkContainer {...widget} />;
        case 'M161LinkContainerAndRichTextModule':
            return <M161LinkContainerAndRichText {...widget} />;
        case 'M170HeroSliderModule':
            return <M170HeroSlider {...(widget as Bff.IM170HeroSliderModuleViewModel)} />;
        case 'M180iPaperModule':
            return <M180iPaper {...widget} />;
        case 'M190ImageSpotAndHeroModule':
            return <M190ImageSpotHeroSlider {...(widget as Bff.IM190ImageSpotAndHeroModule)} />;
        case 'M191ImageSpotsModule':
            return <M191ImageSpots {...widget} />;
        case 'M200TicketButlerScriptModule':
            return <M200TicketButlerScript {...widget} />;
        case 'basketWidget':
            return <W05Basket {...widget} />;
        case 'carBannerWidget':
            return null;
        case 'chassisSupportRequestWidget':
            return <W010CarChassisSupport {...(widget as Bff.IBaseCarDetailsWidget)} />;
        case 'chemicalProductsWidget':
            return <W020ChemicalProducts {...(widget as Bff.IChemicalProductsWidgetViewModel)} />;
        case 'creditsDetailOverviewWidget':
            return <W030CreditsDetailOverview {...(widget as Bff.ICreditsDetailOverviewWidgetViewModel)} />;
        case 'creditsOverviewWidget':
            return <W040CreditsOverview />;
        case 'favoritesWidget':
            return <W041Favorites {...widget} />;
        case 'haynesLoginWidget':
            return <W042HaynesLogin {...(widget as Bff.INfLoginWidget)} />;
        case 'internalDashboardCustomerSearchWidget':
            return <W043InternalCustomerSearch {...widget} />;
        case 'internalDashboardManualOrdersLinkWidget':
            return <W044InternalManualOrders {...widget} />;
        case 'internalDashboardReturnDepositWidget':
            return <W045InternalReturnDeposit {...widget} />;
        case 'internalDashboardLatestCustomersWidget':
            return <W046InternalLatestCustomers {...widget} />;
        case 'internalDashboardLatestOrdersWidget':
            return <W047InternalLatestOrders {...widget} />;
        case 'internalDashboardUserInfoWidget':
            return <W048InternalUserInfo {...widget} />;
        case 'invoiceDetailOverviewWidget':
            return <W050InvoiceDetailOverview {...(widget as Bff.IDetailOverviewWidgetViewModel)} />;
        case 'invoicesOverviewWidget':
            return <W060InvoicesOverview />;
        case 'impersonateWidget':
            return <W061Impersonate {...widget} />;
        case 'labelOrdreWidget':
            return <W062LabelOrder {...widget} />;
        case 'loginWidget':
            return <W070Login {...widget} />;
        case 'manualWebordersOverviewWidget':
            return <W071ManualWebOrders {...widget} />;
        case 'media':
            return <W072Media {...(widget as IMediaProps)} />;
        case 'nfLoginWidget':
            return <W072NFLogin {...(widget as Bff.INfLoginWidget)} />;
        case 'oeSparePartsRequestWidget':
            return <W073OESpareParts {...widget} />;
        case 'ordersOverviewWidget':
            return <W080OrdersOverview />;
        case 'replacementEngineRequestWidget':
            return <W081CarReplaceEngine {...(widget as Bff.IBaseCarDetailsWidget)} />;
        case 'returnsDetailOverviewWidget':
            return <W090ReturnsDetailOverview {...(widget as Bff.IDetailOverviewWidgetViewModel)} />;
        case 'returnsOverviewWidget':
            return <W100ReturnsOverview />;
        case 'salesPriceMarkupWidget':
            return <W101SalesPriceMarkup {...widget} />;
        case 'savedBasketsOverviewWidget':
            return <W110SavedBasketsOverview />;
        case 'savedOrdersOverviewWidget':
            return <W111SavedOrders />;
        case 'searchResultWidget':
            return <W112SearchResultsProductList {...widget} />;
        case 'sparePartCategoryListWidget':
            return <W120SparePartCategoryList {...(widget as Bff.ICarBannerWidgetViewModel & Bff.ISparePartCategoryListWidgetViewModel)} />;
        case 'sparePartProductListWidget':
            return <W121SparePartProductList {...(widget as Bff.ISparePartProductListWidgetViewModel)} />;
        case 'sparePartVariantWidget':
            return <W122SparePartVariant {...(widget as Bff.ISparePartVariantWidgetViewModel)} />;
        case 'universalPartCategoryListWidget':
            return <W130UniversalPartCategoryList {...(widget as Bff.IUniversalPartCategoryListWidgetViewModel)} />;
        case 'universalPartProductListWidget':
            return <W131UniversalPartProductList {...(widget as Bff.IUniversalPartProductListWidgetViewModel)} />;
        case 'universalPartVariantWidget':
            return <W132UniversalPartVariant {...(widget as Bff.IUniversalPartVariantWidgetViewModel)} />;
        case 'userAdministrationWidget':
            return <W141UsersAdministration {...widget} />;
        case 'vantageDashboardWidget':
            return <W143VantageDashboard {...widget} />;
        case 'vehicleModelListWidget':
            return <W150VehicleModelList {...(widget as Bff.IVehicleListWidgetViewModel)} />;
        case 'vehicleTypeListWidget':
            return <W160VehicleTypeList {...widget} />;
        default:
            return <p>{widget.type} are not implemented</p>;
    }
}
