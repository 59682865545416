export const formatDateISO = (date: string | Date, offset = 0) => {
    const timeStamp = new Date(date).getTime();
    const timeZoneOffset = new Date(date).getTimezoneOffset() * 60000;
    const newDate = new Date(timeStamp - timeZoneOffset + offset * 24 * 60 * 60 * 1000);

    return newDate.toISOString().split('T')[0];
};

export const formatDateString = (date: string | number | readonly string[] | Date) => {
    const d = new Date(date as string);
    const mm = d.getMonth() + 1;
    const dd = d.getDate();
    const yyyy = d.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
};
