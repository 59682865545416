import React, { useMemo } from 'react';
import styles from './tiles.module.scss';
import ProductCardTile from '~/page-elements/product-card/product-card-tile/product-card-tile.component';
import { Products as ProductTypes } from '~/models/products';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useOEMatchesVariants } from '~/libs/queries/products/hooks/use-oe-matches-variants';

export interface ITileListProps {
    items: ProductTypes.IProductTile[];
    setModalContent: (content: JSX.Element) => void;
}

const getProductPricesQuery = (item: ProductTypes.IProductTile) => ({
    articleId: item.itemNo,
    quantity: (item.colliLocked && item.colli) || 1,
});

const ProductListTiles: React.FC<ITileListProps> = ({ items }) => {
    const idList = useMemo(() => items.map((v) => v.id), [items]);

    const { data: oeMatches } = useOEMatchesVariants({
        variantIds: idList,
    });

    const articleList = useMemo<ProductTypes.IArticle[]>(() => {
        return items.map(getProductPricesQuery);
    }, [items]);

    const itemNoList = useMemo(() => {
        return items.map((product) => product.itemNo);
    }, [items]);

    const { data: prices, isLoading: isLoadingPrices } = useProductPrices(articleList, true);
    const { data: stockList, isLoading: isLoadingStockList, isError: isErrorStockList } = useStockStatus(itemNoList as string[]);

    return (
        <>
            {items.map((item) => {
                return (
                    <ProductCardTile
                        key={item.id}
                        item={item}
                        isOeMatch={!!oeMatches?.[item?.itemNo as string]}
                        price={prices?.[item?.itemNo as string]}
                        isLoadingPrice={isLoadingPrices}
                        stock={stockList?.[item?.itemNo as string]}
                        isLoadingStock={isLoadingStockList}
                        isErrorStock={isErrorStockList}
                    />
                );
            })}
        </>
    );
};

export interface ProductListTilePagesProps {
    pages?: ProductTypes.IPagedResultOfProductTile[];
    setModalContent: (content: JSX.Element) => void;
}

export function ProductListTilePages({ pages, ...props }: ProductListTilePagesProps) {
    return (
        <div className={styles.tiles}>
            {pages?.map((page) => (page.items?.length ? <ProductListTiles key={page.items?.[0]?.itemNo} items={page.items} {...props} /> : null))}
        </div>
    );
}
