/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import VariantListRow from './variant-list-row/variant-list-row.component';
import styles from './variants-list.module.scss';
import { Products as ProductTypes } from '~/models/products.d';
import { IStockList } from '~/libs/queries/products/hooks/use-stock-status';
import { IPricesList } from '~/libs/queries/products/hooks/use-product-prices';
import { FavoriteStatusListResponse } from '~/libs/queries/favorites';

export interface IVariantsListProps {
    title: string | undefined;
    variants: ProductTypes.IVariant[] | undefined;
    catalogType: ProductTypes.CatalogType;
    position: number;
    relatedVariantIds?: number[] | undefined;
    relatedVariantsExtraInfo?: ProductTypes.IRelatedVariant[];
    loadingItems?: number;
    productOEMatches?: ProductTypes.IProductOeMatches;
    loadingPrices: boolean;
    prices: IPricesList | undefined;
    errorPrices: boolean;
    stockList?: IStockList;
    loadingStockList: boolean;
    errorStockList: boolean;
    favoriteStatusList?: FavoriteStatusListResponse;
    pageIndex?: number;
    itemNoList: string[];
}

const VariantsList: FC<IVariantsListProps> = ({
    title,
    variants,
    position,
    relatedVariantIds,
    loadingItems,
    productOEMatches,
    relatedVariantsExtraInfo,
    loadingPrices,
    prices,
    stockList,
    loadingStockList,
    errorPrices,
    errorStockList,
    favoriteStatusList,
    pageIndex,
    itemNoList,
}) => {
    const translate = useTranslations();

    if (!variants?.length) {
        return null;
    }

    return (
        <div>
            <div className={styles.header}>
                <div className="caption">{translate('product.brand', 'Mærke')}</div>
                <div className="caption">{translate('product.itemNo', 'Vare nr.')}</div>
                <div className="caption">{translate('product.details', 'Detaljer')}</div>
                <div className={clsx('caption', styles.ftzCodeTitle)}>{translate('product.ftzNo', 'FTZ nummer.')}</div>
                <div className="caption" />
                <div className="caption">{translate('product.pricePerPiece', 'Pris stk.')}</div>
                <div className="caption">{translate('product.stockStatus', 'Lagerstatus')}</div>
                <div />
            </div>
            {variants?.map((variant) => (
                <VariantListRow
                    key={variant.id}
                    relatedVariantIds={relatedVariantIds}
                    relatedVariantsExtraInfo={relatedVariantsExtraInfo}
                    loadingItems={loadingItems}
                    variant={variant}
                    stock={stockList?.[variant?.itemNo as string] as ProductTypes.IStockStatus}
                    loadingStock={loadingStockList}
                    isErrorStockStatus={undefined}
                    loadingPrices={loadingPrices}
                    prices={prices?.[variant?.itemNo as string]}
                    position={position}
                    title={title}
                    favoriteStatus={favoriteStatusList?.[variant?.itemNo as string] as ProductTypes.FavoriteStatus}
                    originalEquipment={productOEMatches}
                    errorStock={errorStockList}
                    errorPrices={errorPrices}
                    pageIndex={pageIndex}
                    itemNoList={itemNoList}
                />
            ))}
        </div>
    );
};

export default VariantsList;
