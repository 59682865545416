import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { createUrl, LocaleOptions } from '~/services/service-endpoint';
import { favoriteKeys, FAVORITES_CHAIN_LIST_URL, FAVORITES_PERSONAL_LIST_URL, FavoriteType } from '../.';

export type UseFavoriteListProps = {
    userId?: string;
    type?: FavoriteType;
    enabled?: boolean;
};

const URL_MAP: { [key in FavoriteType]: string } = {
    ChainFavorite: FAVORITES_CHAIN_LIST_URL,
    MyFavorite: FAVORITES_PERSONAL_LIST_URL,
};

export function useFavoriteList({ userId, type = 'MyFavorite', enabled = !!userId }: UseFavoriteListProps) {
    const { locale } = useRouter();
    const { user } = useUser();

    return useQuery<ProductTypes.IFavoriteProductsList>({
        queryKey: favoriteKeys.favoriteList(locale as string, userId as string, type),
        queryFn: () =>
            apiClient.auth(user).get(
                createUrl(
                    {
                        endpoint: URL_MAP[type],
                        localeOption: LocaleOptions.path,
                    },
                    locale,
                ),
            ),
        enabled,
    });
}
