import { Orders as OrderTypes } from '~/models/orders';
import { createUrl, endpoints, LocaleOptions } from '~/services/service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

const createOECredit = async (invoiceId: string | number, postData: OrderTypes.ICreditOeInvoiceRequest, user: Session | null) => {
    const url = createUrl({
        endpoint: `${endpoints.orders.credits}/${invoiceId}/credit/oe`,
        localeOption: LocaleOptions.path,
    });

    const response: OrderTypes.ICreditOeInvoiceResponse = await apiClient.auth(user).postJSON(url, postData);

    return response;
};

export default createOECredit;
