import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useRouter } from 'next/router';
import useTranslations from '~/shared/hooks/use-translations.hook';

export interface IDepartment {
    id: string;
    displayName: string;
}

export const allDepartmentsId = 'all-departments-id';

export function useDepartments() {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { orders, createUrl } = useEndpoints();

    const endpoint = createUrl({
        endpoint: orders.getDepartments,
        localeOption: LocaleOptions.path,
    });

    return useQuery<IDepartment[], Error>({
        queryKey: queryKeys.routing.departments(locale),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn,
    });
}

export function useDepartmentOptions(data: IDepartment[] = []) {
    const translate = useTranslations();

    const allDepartments: IDepartment = {
        id: allDepartmentsId,
        displayName: translate('myFtz.manualOrders.allDepartments', 'Alle afdelinger'),
    };

    return [allDepartments, ...data];
}
