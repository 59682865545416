import React, { FC } from 'react';
import SearchInput from '~/shared/form-elements/search-Input/search-Input.component';
import { DateSetter } from '~/shared/hooks/use-infinite-loading';
import DateFilter from '../date-filter/date-filter.component';
import styles from './filters.module.scss';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';

interface IProps {
    fromDate?: string;
    toDate?: string;
    setDate?: DateSetter;
    query?: string;
    pageSize?: number;
    setPageSize?: (pageSize: number) => void;
    setQuery?: (val: string) => void;
    hideSearchFilter?: boolean;
    hideDateFilter?: boolean;
    hidePageSizeFilter?: boolean;
}

const Filters: FC<IProps> = ({
    fromDate,
    toDate,
    setDate,
    query,
    setQuery,
    pageSize,
    setPageSize,
    hideSearchFilter = false,
    hideDateFilter = false,
    hidePageSizeFilter = true,
}) => {
    const translate = useTranslations();

    return (
        <div className={styles.filters}>
            {!hideDateFilter ? (
                <DateFilter fromDate={fromDate as string} toDate={toDate as string} setDate={setDate as DateSetter} />
            ) : (
                <div className={styles.filterPlaceholder} />
            )}

            {!hidePageSizeFilter ? (
                <div className={styles.smallDropdown}>
                    <label>
                        <span>{translate('filter.pageSize', 'Sidestørrelse')}</span>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                        {/*@ts-ignore*/}
                        <select onChange={(event) => setPageSize(parseInt(event.currentTarget.value, 10) as number)}>
                            {[30, 60, 90].map((v, i) => (
                                <option value={v} key={i} selected={v === pageSize}>
                                    {v}
                                </option>
                            ))}
                        </select>

                        <Svg name="chevron-down" className={styles.caret} />
                    </label>
                </div>
            ) : (
                <div className={styles.filterPlaceholder} />
            )}

            {!hideSearchFilter ? (
                <SearchInput value={query} setInputValue={setQuery} wrapperClassName={styles.search} autoFocus={true} />
            ) : (
                <div className={styles.filterPlaceholder} />
            )}
        </div>
    );
};

export default Filters;
