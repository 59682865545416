import Counter from '~/shared/counter/counter.component';
import styles from './basket-header-next-delivery.module.scss';

export type BasketHeaderNextDeliveryProps = {
    label: string;
    isFetching: boolean;
    counterTo: Date | undefined;
    counterClassName?: string;
};

export function BasketHeaderNextDelivery({ label, isFetching, counterTo }: BasketHeaderNextDeliveryProps) {
    return (
        <div className={styles.basketHeaderNextDeliveryWrapper}>
            <span className={styles.basketHeaderNextDeliveryLabel}>{label}</span>

            <Counter fetching={isFetching} className={styles.basketHeaderNextDeliveryCounter} to={counterTo} />
        </div>
    );
}
