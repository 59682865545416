import { useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { USER_NAME_FORMAT } from '~/constants/validation';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { useFTZShopPermissions } from '~/libs/queries/auth/hooks/use-ftz-shop-permissions';
import { useFTZShopRoles } from '~/libs/queries/bff/hooks/use-ftz-shop-roles';
import useActiveUser from '~/libs/use-active-user';
import { Users as UsersTypes } from '~/models/users.d';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Input from '~/shared/form-elements/input/input.component';
import Select from '~/shared/form-elements/select/select.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import { Svg } from '~/shared/svg';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import styles from './styled.module.scss';

interface IProps {
    selectedUser: UsersTypes.IExternalSubUserInfo;
    closeModal: () => void;
    createUpdateUser: (postData: UsersTypes.ICreateSubUserInfoRequest, userId: number) => void;
}

export default function AddUser({ selectedUser, createUpdateUser, closeModal }: IProps) {
    const [isFetching, setFetching] = useState(false);
    const [selectedRole, setRole] = useState<string>(selectedUser?.role?.id || '');
    const [userPermissions, setUserPermissions] = useState<string[]>(selectedUser?.permissions || []);

    const { data: permissions, isLoading: isLoadingPermissions } = useFTZShopPermissions();
    const { data: roles, isLoading: isLoadingRoles } = useFTZShopRoles();
    const dispatch = useLayoutDispatch();
    const { activeProfile } = useActiveUser();
    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
        setError: setInputError,
    } = useForm<UsersTypes.ICreateSubUserInfoRequest>({
        reValidateMode: 'onChange',
        mode: 'all',
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessages = Object.values(errors).map((err: FieldError) => err?.message);

    const isLoading = isLoadingRoles || isLoadingPermissions;

    const translate = useTranslations();

    const handleCheck = (checked: boolean, permissionId: string) => {
        if (checked) {
            setUserPermissions([...userPermissions, permissionId]);
        } else {
            setUserPermissions([...userPermissions.filter((item) => item !== permissionId)]);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setUserPermissions(permissions?.map((p) => p.id));
        } else {
            setUserPermissions([]);
        }
    };

    const onSubmit = async (formData: UsersTypes.ICreateSubUserInfoRequest) => {
        if (!selectedRole) {
            setInputError('roleId', {
                type: 'manual',
                message: translate('common.fieldMustBeFilled', 'Feltet skal udfyldes'),
            });
            return;
        }

        setFetching(true);
        try {
            await createUpdateUser(
                {
                    ...formData,
                    roleId: selectedRole,
                    permissions: userPermissions,
                },
                selectedUser?.id,
            );
            closeModal();
            setFetching(false);
        } catch (ex: any) {
            switch (ex.errorCode) {
                case UsersTypes.ErrorCodeDto.UserNameAlreadyUsed:
                case UsersTypes.ErrorCodeDto.InvalidUserName:
                    setInputError('userName', {
                        type: 'manual',
                        message: ex.errorMessage,
                    });
                    break;

                default:
                    dispatch(addErrorToast(ex.errorMessage));
            }

            setFetching(false);
        }
    };

    const requiredText = translate('common.fieldMustBeFilled', 'Feltet skal udfyldes');

    if (isLoading) return <Loader padding="60px" />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <PanelCard className={styles.panelCard}>
                <Input
                    required
                    wrapperClassName={styles.formElement}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    name="name"
                    label={translate('userAdministration.name', 'Navn på bruger')}
                    placeholder={translate('userAdministration.namePlaceholder', 'Skriv f.eks. værkstedsnavn eller fornavn og efternavn')}
                    defaultValue={selectedUser?.name ?? activeProfile?.customer?.name}
                    errorMessage={errors?.name?.message}
                    {...register('name', { required: requiredText })}
                />
                <Input
                    required
                    wrapperClassName={styles.formElement}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    name="userName"
                    label={translate('userAdministration.userName', 'Brugernavn til logind')}
                    placeholder={translate('userAdministration.userNamePlaceholder', 'Vælg et unikt brugernavn til at logge ind med')}
                    defaultValue={selectedUser?.userName}
                    errorMessage={errors?.userName?.message}
                    {...register('userName', {
                        pattern: {
                            value: new RegExp(USER_NAME_FORMAT, 'i'),
                            message: translate(
                                'userAdministration.invalidUserName',
                                "Brugernavn må indeholde bogstaverne A-Z, tallene 0-9 og symbolerne ' . - _ ! # ^ ~",
                            ),
                        },
                    })}
                />
                <Input
                    required={!selectedUser}
                    label={translate('userAdministration.password', 'Kodeord')}
                    placeholder={translate('userAdministration.passwordPlaceholder', 'Vælg et unikt kodeord til at logge ind med')}
                    type="password"
                    wrapperClassName={styles.formElement}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    name="password"
                    errorMessage={errors?.password?.message}
                    {...register('password', { required: !selectedUser ? requiredText : false })}
                />
                <Input
                    wrapperClassName={styles.formElement}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    name="email"
                    label={translate('userAdministration.email', 'Email')}
                    placeholder={translate('userAdministration.emailPlaceholder', 'Skriv email på bruger')}
                    type="email"
                    defaultValue={selectedUser?.email}
                    errorMessage={errors?.email?.message}
                    {...register('email')}
                />
                <Select
                    required
                    errorMessage={errors?.roleId?.message}
                    value={selectedRole}
                    wrapperClassName={styles.formElement}
                    label={translate('userAdministration.role', 'Rolle')}
                    onChange={(e) => {
                        clearErrors('roleId');
                        setRole(e.target.value);
                    }}
                >
                    <option value="" disabled>
                        {translate('userAdministration.rolePlaceholder', 'Vælg fra liste')}
                    </option>
                    {roles?.map((role) => (
                        <option key={role.id} value={role.id}>
                            {role.name}
                        </option>
                    ))}
                </Select>
            </PanelCard>
            <fieldset>
                <Heading tagName="h4" className={styles.sectionTitle}>
                    {translate('userAdministration.permissionsTitle', 'Denne bruger kan se/redigere')}
                </Heading>
                <div className={styles.selectAll}>
                    <Checkbox checked={userPermissions.length === permissions?.length} onChange={(e) => handleSelectAll(e.target.checked)}>
                        {translate('common.selectAll', 'Vælg alle')}
                    </Checkbox>
                </div>
                {permissions?.map((permission, i) => (
                    <div className={styles.formElement} key={`${permission}-${i}`}>
                        <Checkbox
                            checked={userPermissions?.includes(permission?.id as string)}
                            onChange={(e) => handleCheck(e.target.checked, permission?.id as string)}
                        >
                            <div className={styles.checkboxContent}>
                                {permission.name}
                                <Tooltip content={<TooltipContentWrapper>{permission.description}</TooltipContentWrapper>} />
                            </div>
                        </Checkbox>
                    </div>
                ))}
            </fieldset>
            {errorMessages?.length ? (
                <ErrorBox>
                    {errorMessages.map((err, i) => (
                        <div key={`${err}-${i}`}>{err}</div>
                    ))}
                </ErrorBox>
            ) : null}
            <div className={styles.buttons}>
                <Button icon={<Svg name="check" />} type="submit" disabled={isFetching || errorMessages.length > 0} fetching={isFetching}>
                    {selectedUser
                        ? translate('userAdministration.saveChanges', 'Gem ændringer')
                        : translate('userAdministration.createUser', 'Opret bruger')}
                </Button>
            </div>
        </form>
    );
}
