import { Bff } from '~/models/bff';
import { ProductListWrapper, UniversalProductList } from '~/shared/product-list/product-list.component';

// Before it was part of: product-list-page.component.tsx
export default function W131UniversalPartProductList({
    categoryId,
    category,
    categoryDefaultViewType,
}: Bff.IUniversalPartProductListWidgetViewModel) {
    return (
        <ProductListWrapper categoryDefaultViewType={categoryDefaultViewType}>
            <UniversalProductList
                categoryId={String(categoryId)}
                // TODO: Determine usage of carId on universal
                carId={undefined}
                title={category}
            />
        </ProductListWrapper>
    );
}
