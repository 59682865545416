import { Orders as OrdersTypes } from '~/models/orders';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

type IProps = {
    item: OrdersTypes.IOrderProduct;
};

export default function Product({ item }: IProps) {
    const translate = useTranslations();

    if (!item) return null;

    return (
        <li className={styles.product}>
            <Text>{item.name}</Text>
            <Text textStyle="monoSmall">
                {item.count} {translate('product.piece', 'stk')}
            </Text>
            <Link className={styles.link} href={item.url}>
                <Text textStyle="monoSmall">{item.itemId}</Text>
            </Link>
            <Text textStyle="monoSmall">{item.price?.ftzCode || ''}</Text>
            <Price className={styles.displayPrice}>{item.price?.displayPrice || ''}</Price>
        </li>
    );
}
