import { useCallback, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { CloudflareLoader } from './image-loaders';

function CdnImage({ alt, ...restProps }: ImageProps) {
    const [isMissingImage, setMissingImage] = useState(false);

    const handleError = useCallback(() => {
        setMissingImage(true);
    }, []);

    if (isMissingImage) {
        return <Image {...restProps} src="/svg/placeholder-image.svg" />;
    }

    return <Image alt={alt || 'Unknown image'} {...restProps} onError={handleError} loader={CloudflareLoader} />;
}

export { CdnImage };
