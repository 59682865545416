/* eslint-disable max-len */
import React, { FC, memo, useState } from 'react';
import VehicleInfo from '../vehicle-info/vehicle-info.component';
import AdditionalVehicleInfo from '../additional-vehicle-info/additional-vehicle-info.component';
import Text from '~/shared/text/text.component';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { Link } from '~/shared/link';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Accordion from '~/shared/accordion/accordion.component';
import useActiveUser from '~/libs/use-active-user';

interface IVehiclesListProps {
    manufacturer: VehicleTypes.ICarManufacturers;
    variantsIDs: string[];
    productCategory: string;
}

interface IVehicleListHeaderProps {
    carModel: VehicleTypes.ICarModelTypeDetails;
    showDetail: boolean;
}

const VehicleListHeader: FC<IVehicleListHeaderProps> = memo(({ carModel: { carsInDenmark, ftzTypeNo, modelName, name }, showDetail }) => {
    const translate = useTranslations();
    const modelDescription = [
        name,
        showDetail && ftzTypeNo && `${translate('vehicle.ftzTypeNo', 'FTZ Biltype nr.')} ${ftzTypeNo}`,
        showDetail && `${translate('vehicle.carsInDenmark', 'Biler i DK.')} ${carsInDenmark}`,
    ]
        .filter(Boolean)
        .join(' / ');

    return (
        <div>
            <Text fontWeight="semiBold" color="primaryColor">
                {modelName}
            </Text>
            <Text textStyle="bodySmall" color="primaryColor">
                {modelDescription}
            </Text>
        </div>
    );
});

const VehiclesList: FC<IVehiclesListProps> = ({ manufacturer, variantsIDs, productCategory }) => {
    const [selectedCarModelId, setCarModelId] = useState<number | null>(null);
    const translate = useTranslations();
    const { activeProfile } = useActiveUser();

    return (
        <>
            {manufacturer?.carModelTypeDetails?.map((carModel) => {
                const isActive = carModel?.id === selectedCarModelId;
                return (
                    <Accordion
                        onClick={() => setCarModelId(isActive ? null : carModel.id)}
                        isOpen={isActive}
                        header={<VehicleListHeader carModel={carModel} showDetail={!!activeProfile?.employeeId} />}
                        key={carModel.id}
                    >
                        <VehicleInfo vehicle={carModel} />
                        <AdditionalVehicleInfo carId={carModel.id.toString()} variantsIDs={variantsIDs} shouldFetch={isActive} />
                        {carModel?.url ? (
                            <Link href={carModel.url}>
                                {translate('vehicle.showCategoryProductsForVehicle', 'Vis [categoryName] produkter på bil').replace(
                                    '[categoryName]',
                                    productCategory ? `'${productCategory}'` : '',
                                )}
                            </Link>
                        ) : null}
                    </Accordion>
                );
            })}
        </>
    );
};

export default VehiclesList;
