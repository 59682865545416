import { Orders as OrderTypes } from '~/models/orders';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import Price from '~/shared/price/price.component';
import ProductImageTitleBrand from '~/shared/product-image-title-brand/product-image-title-brand.component';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IItemProps = {
    item: OrderTypes.ICreditItem;
    searchQuery?: string;
};

export default function Item({ item, searchQuery }: IItemProps) {
    const translate = useTranslations();
    return (
        <div className={styles.root}>
            <div className={styles.imageTitleCell}>
                <ProductImageTitleBrand highlightText={searchQuery} image={item?.image} name={item?.title} brand={item?.brand} />
            </div>
            <div className={styles.quantityCell}>
                <ValueWithCaption caption={translate('product.quantity', 'antal')} captionClassName={styles.label}>
                    <Text textStyle="monoSmall">
                        {item?.quantity} {translate('product.piece', 'stk')}
                    </Text>
                </ValueWithCaption>
            </div>
            <div className={styles.itemIdCell}>
                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')} captionClassName={styles.label}>
                    <Link href={item?.url} className={styles.itemLink}>
                        <Text highlightText={searchQuery} overflowEllipsis title={item?.itemId} nowrap textStyle="monoSmall">
                            {item?.itemId}
                        </Text>
                        {item?.depositItemId && (
                            <Text overflowEllipsis title={item?.depositItemId} nowrap textStyle="monoSmall">
                                {item?.depositItemId}
                            </Text>
                        )}
                    </Link>
                </ValueWithCaption>
            </div>
            <div className={styles.priceCell}>
                <Price>{item.price?.price ? item?.price?.priceFormatted : item.price?.netPriceFormatted}</Price>
                {item?.hasDeposit && (
                    <Price size="small">
                        {translate('overview.deposit', 'Depositum')} {item?.price?.depositFormatted}
                    </Price>
                )}
                {!!item?.price?.fee && (
                    <Price size="small" color="lightGrey">
                        {translate('overview.fee', 'Afgifter')} {item?.price?.feeFormatted}
                    </Price>
                )}
            </div>

            <div className={styles.location}>
                <ValueWithCaption caption={translate('overview.location', 'Lokation')}>
                    <Text textStyle="monoSmall" overflowEllipsis title={item?.location ?? '-'}>
                        {item?.location ?? '-'}
                    </Text>
                </ValueWithCaption>
            </div>
            <div className={styles.invoiceId}>
                <ValueWithCaption caption={translate('overview.invoiceNumber', 'Faktura nr')}>
                    <Text title={item?.invoiceId?.toString()} textStyle="monoSmall" overflowEllipsis>
                        {item?.invoiceId ?? '-'}
                    </Text>
                    {item?.invoiceRequisition && (
                        <Text textStyle="monoSmall">
                            {translate('overview.requisitionsNo', 'Rekvisitions nr.')} {item.invoiceRequisition}
                        </Text>
                    )}
                </ValueWithCaption>
            </div>
        </div>
    );
}
