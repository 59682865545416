import clsx from 'clsx';
import { useSparePartCategoriesById } from '~/libs/queries/products/hooks/use-spare-part-categories-by-id';
import { useUniversalPartCategoriesById } from '~/libs/queries/products/hooks/use-universal-part-categories-by-id';
import { Bff } from '~/models/bff';
import { Products } from '~/models/products';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import Categories from './categories/categories.component';
import styles from './styled.module.scss';

//Before: sales-price-markup.component.tsx
export default function W101SalesPriceMarkup(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();

    const {
        data: universalCategories,
        isLoading: isLoadingUniversalCategories,
        isError: isErrorUniversalCategories,
    } = useUniversalPartCategoriesById({ categoryId: 0, levels: 10 });

    const {
        data: sparePartCategories,
        isLoading: isLoadingSparePartCategories,
        isError: isErrorSparePartCategories,
    } = useSparePartCategoriesById({ categoryId: 0, levels: 10 });

    const isLoading = isLoadingUniversalCategories || isLoadingSparePartCategories;
    const isError = isErrorUniversalCategories || isErrorSparePartCategories;

    const renderContent = () => {
        if (isLoading) return <Loader className={styles.loader} />;

        if (isError) {
            return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
        }

        return <Categories categoriesList={[universalCategories as Products.ICategory, sparePartCategories as Products.ICategory]} />;
    };

    return <div className={styles.content}>{renderContent()}</div>;
}
