import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { Users as UsersTypes } from '~/models/users';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

export function useFindUserByQuery(query: string, limit = 20, take = 20) {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { users, createUrl } = useEndpoints();
    const endpoint = createUrl({
        endpoint: users.usersByCustomerQuery,
        localeOption: LocaleOptions.omit,
        query: {
            query,
            limit,
            take,
        },
    });

    return useQuery<UsersTypes.IUserInfoCustomerDetails[], Error>({
        queryKey: queryKeys.users.findUserByQuery(locale, query, limit, take),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn && !!query && query.length > 1, // Minimum 2 characters or else service returns too much data and will time out
    });
}
