import Heading from '~/shared/heading/heading.component';
import SortableTable from '~/shared/sortable-table/sortable-table.component';
import { ISortableTableData } from '~/shared/sortable-table/sortable-table.model';
import styles from './styled.module.scss';
import { Bff } from '~/models/bff';

function formatDataRow(row: string[]): { [key: number]: string } {
    if (!row) {
        return {};
    }

    return row.reduce((acc, cell, i) => ({ ...acc, [i]: cell }), {});
}

function formatData(data: Bff.IDataTableViewModel): ISortableTableData {
    const { columns = [], rows = [] } = data ?? {};

    return {
        tableHeaders: columns.map((c, i) => ({
            key: i,
            sortable: true,
            value: c,
        })),
        tableRows: rows.map((r, i) => ({ id: i, ...formatDataRow(r) })),
    };
}

const Title = ({ headline }: { headline: string | undefined }) => {
    return (
        <Heading tagName="h3" displayStyle="h3" className={styles.title}>
            {headline}
        </Heading>
    );
};

//Name before: data-table.component.tsx
export default function M140DataTable({ headline, table }: Bff.IM140DataTableModuleViewModel) {
    if (!table?.columns || !table?.rows) {
        return null;
    }

    return (
        <>
            <Title headline={headline} />
            <SortableTable tableData={formatData(table)} />
        </>
    );
}
