import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products.d';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useUser from "~/libs/use-user";

interface IUseProductsVehicleInfoByIdsProps {
    carModelTypeId?: string;
    productIds?: number[];
    shouldFetch: boolean;
}

export function useProductsVehicleInfoByIds(props: IUseProductsVehicleInfoByIdsProps) {
    const { carModelTypeId = undefined, productIds = [] } = props;
    const postData: ProductTypes.IVariantsInfoTextRequest = { carModelTypeId, productIds };
    const { user } = useUser();

    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const queryClient = useQueryClient();
    const queryKey = queryKeys.vehicle.productsVehicleInfoByIds(locale, !!expertMode);
    const url = createUrl({
        endpoint: products.getProductVehicleInfoByIds,
        localeOption: LocaleOptions.path,
        query: {
            includeExpertProducts: expertMode,
        },
    });

    return useMutation({
        mutationKey: queryKey,
        mutationFn: async () => {
            const response = await apiClient.auth(user).post(url, {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postData),
            });
            return response.json();
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: queryKey });
            const previousData = queryClient.getQueryData<ProductTypes.IProductsInfoTextResponse>(queryKey);
            queryClient.setQueryData<ProductTypes.IProductsInfoTextResponse>(queryKey, previousData);
            return { previousData };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData<ProductTypes.IProductsInfoTextResponse>([queryKey], context?.previousData);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
        },
    });
}
