import clsx from 'clsx';
import { Bff } from '~/models/bff';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './cms-image.module.scss';

export interface ImageProps {
    image: Bff.IImageViewModel;
    darken?: boolean;
    scale?: string;
    horizontalAlignment?: string;
}

export default function CmsImage({ image, darken = false, scale, horizontalAlignment }: ImageProps) {
    if (!image?.src) {
        return null;
    }

    const { src, focalPoint, alt, width, height } = image;
    const { left = 0.5, top = 0.5 } = focalPoint || { left: 0.5, top: 0.5 };
    const objectFitStyle = scale === 'Actual' ? 'contain' : 'cover';
    const layoutStyle = scale === 'Actual' ? 'intrinsic' : 'responsive';

    return (
        <div
            className={clsx({
                [styles.flexContainer]: scale === 'Actual',
                [styles.alignLeft]: horizontalAlignment === 'left',
                [styles.alignCenter]: horizontalAlignment === 'center',
                [styles.alignRight]: horizontalAlignment === 'right',
                [styles.darken]: darken,
            })}
        >
            <CdnImage
                priority
                src={src}
                alt={alt}
                layout={layoutStyle}
                width={width}
                height={height}
                {...(focalPoint && {
                    objectFit: objectFitStyle,
                    objectPosition: `${left * 100}% ${top * 100}`,
                })}
            />
        </div>
    );
}
