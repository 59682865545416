import clsx from 'clsx';
import { useRouter } from 'next/router';
import { FC, memo, useRef, useEffect } from 'react';
import { IWithClassName } from '~/models/dev';
import styles from './raw-html.module.scss';
import { GA4CampaignClicked } from '~/libs/ga4/ga4';

interface IRawHtmlProps extends IWithClassName {
    content: string;
    campaignSpot?: string;
    campaignId?: string;
    campaignType?: string;
}

export function isAnchor(target: any): target is HTMLAnchorElement {
    return target.tagName === 'A';
}

const RawHtml: FC<IRawHtmlProps> = ({ className, content, campaignSpot, campaignId, campaignType }) => {
    const { push } = useRouter();

    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const anchors = Array.from(containerRef.current.getElementsByTagName('a'));

        const handleClick = (e: MouseEvent) => {
            const { currentTarget } = e;

            if (!isAnchor(currentTarget)) {
                return;
            }

            e.preventDefault();

            const url = currentTarget.href;

            if (campaignSpot) {
                GA4CampaignClicked({
                    to: url,
                    campaignId,
                    campaignSpot,
                    campaignType,
                });
            }

            push(url);
        };

        for (const anchor of anchors) {
            anchor.addEventListener('click', handleClick);
        }

        return () => {
            for (const anchor of anchors) {
                anchor.removeEventListener('click', handleClick);
            }
        };
    }, []);

    return <div ref={containerRef} className={clsx(styles.rte, className)} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default memo(RawHtml);
