import { apiClient } from '~/services/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { basketKeys } from '~/libs/queries/basket';
import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';

export function useDeleteBasketBatch() {
    const { locale } = useRouter();
    const queryClient = useQueryClient();
    const { selectedDepartment } = useLayoutState();
    const { baskets, createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: baskets.deleteBatch,
        localeOption: LocaleOptions.path,
        query: { departmentId: selectedDepartment?.id },
    });

    return useMutation({
        mutationKey: basketKeys.deleteSavedBasket(locale, selectedDepartment?.id),
        mutationFn: async ({ basketIds }: { basketIds: string[] }) => {
            await apiClient.auth(user).delete(url, {
                body: JSON.stringify(basketIds),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: basketKeys.deleteSavedBasket(locale, selectedDepartment?.id) });
            const previousDetails = queryClient.getQueryData(basketKeys.deleteSavedBasket(locale, selectedDepartment?.id));
            return { previousDetails };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(basketKeys.deleteSavedBasket(locale, selectedDepartment?.id), context?.previousDetails);
        },
        onSettled: () => {
            queryClient.invalidateQueries(basketKeys.deleteSavedBasket(locale, selectedDepartment?.id));
        },
    });
}
