import RawHtml from '~/page-elements/raw-html/raw-html.component';
import Heading from '~/shared/heading/heading.component';
import { Svg } from '~/shared/svg';
import styles from './styled.module.scss';
import { Bff } from '~/models/bff';

//Name before: title-icon-richtext-component.tsx
export default function M102IconTitleAndRichText({ richText, headline, icon, campaignSpot, id, type }: Bff.IM102IconTitleAndRichTextModuleViewModel) {
    return (
        <div className={styles.section}>
            <header className={styles.header}>
                {icon && <Svg className={styles.icon} name={icon} />}

                {headline && (
                    <Heading tagName="h4" className={styles.title}>
                        {headline}
                    </Heading>
                )}
            </header>

            {richText && <RawHtml content={richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} />}
        </div>
    );
}
