import { PRINT_BASKET_IF_PICKUP } from '~/constants/local.storage';
import { useCheckoutBasket } from '~/libs/queries/basket';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketFooterPrintOrder } from './basket-footer-print-order';

export function BasketFooterPrintOrderContainer() {
    const translate = useTranslations();
    const { data: basket } = useCheckoutBasket();
    const [printBasket, setPrintBasket] = useLocalStorage<boolean>(PRINT_BASKET_IF_PICKUP, false);

    if (!basket?.deliveryDetails?.isPickup) {
        return null;
    }

    const handleClick = () => {
        setPrintBasket(!printBasket);
    };

    return (
        <BasketFooterPrintOrder
            label={translate('basket.printOrderOnBuy', 'Print altid bestilling ved køb')}
            isChecked={printBasket}
            handleChange={handleClick}
        />
    );
}
