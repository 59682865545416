import { Products as ProductTypes } from '~/models/products.d';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export function useSalesPriceMarkup() {
    const { isLoggedIn, user } = useUser();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const endpoint = createUrl({
        localeOption: LocaleOptions.path,
        endpoint: `${products.salesPriceMarkup}/categories`,
    });

    return useQuery<ProductTypes.ICategoriesSalesPriceMarkupList, Error>({
        queryKey: queryKeys.products.salesPriceMarkup(locale),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn,
    });
}
