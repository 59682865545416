import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './license-plate.module.scss';
import { IWithClassName } from '~/models/dev';
import { Vehicles } from '~/models/vehicles';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';

export interface ILicensePlateProps extends IWithClassName {
    licensePlate?: Vehicles.ILicensePlate;
    vin?: number | string;
    carId?: number | string;
    isTruncated?: boolean;
    isFlex?: boolean;
    hideCountryCode?: boolean;
    highlightText?: string;
}

const LicensePlate: FC<ILicensePlateProps> = ({
    licensePlate,
    vin,
    carId,
    className,
    isTruncated,
    isFlex,
    hideCountryCode,
    highlightText,
}): JSX.Element | null => {
    const number = licensePlate?.number || vin || carId;
    if (!number) return null;
    return (
        <div className={clsx(styles.licensePlate, className)}>
            {licensePlate && (
                <span className={styles.country}>
                    {hideCountryCode ? <Svg name="car" className={styles.countryCodePLaceholder} /> : <>{licensePlate?.country || 'DK'}</>}
                </span>
            )}
            <Text
                className={clsx(styles.number, {
                    [styles.isLicensePLate]: licensePlate && !isFlex,
                    [styles.isTruncated]: isTruncated,
                })}
                text={number.toString()}
                highlightText={highlightText}
            />
        </div>
    );
};

export default LicensePlate;
