import { useLayoutState } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { useRouter } from 'next/router';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useQuery } from '@tanstack/react-query';
import { CmsData } from '~/models/cms-data';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

export function useProductDocuments(variantId?: string) {
    const { expertMode } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    const { products, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: products.getVariantDocuments,
        localeOption: LocaleOptions.path,
        query: {
            ftzCode: variantId,
            includeExpertProducts: expertMode,
        },
    });

    return useQuery<CmsData.IProductDocumentsGroup[], Error>({
        queryKey: queryKeys.basket.productDocuments(locale, variantId, expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn && !!variantId,
        refetchOnWindowFocus: false,
        refetchInterval: 0,
    });
}
