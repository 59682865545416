import cx from 'clsx';
import { Bff } from '~/models/bff';
import { DynamicWidget } from '~/widgets/dynamic-widget';
import { ModuleRow } from '~/features/primitives/modules';

import styles from './styled.module.scss';

const DEFAULT_POSITION = 'Left';

export type M132TwoColumnRowColumnProps = {
    area: Bff.IAreaViewModel;
    // Can be one of two: Left or Right
    position?: string;
};

export function M132TwoColumnRowArea({ area, position = DEFAULT_POSITION }: M132TwoColumnRowColumnProps) {
    return (
        <div
            className={cx({
                [styles.right]: position === 'Right',
                [styles.left]: position === 'Left',
            })}
        >
            {area.elements?.map((element) => (
                <ModuleRow key={element.id} spacing={element.spacingBottom}>
                    <DynamicWidget widget={element} />
                </ModuleRow>
            ))}
        </div>
    );
}

export default function M132TwoColumnRow({ areas, spacingBottom }: Bff.IM132TwoColumnRowViewModel) {
    return (
        <ModuleRow spacing={spacingBottom} className={styles.twoColumnRowWrapper}>
            {areas?.map((area, index) => <M132TwoColumnRowArea key={index} area={area} position={area.type} />)}
        </ModuleRow>
    );
}
