import { Bff } from '~/models/bff';
import CmsImage from '~/shared/cms-image/cms-image.component';
import styles from './styled.module.scss';

//Name before: image-page-element.component.tsx
export default function M070Image({ shouldDarken, image, caption, horizontalAlignment, scale }: Bff.IM070ImageModuleViewModel) {
    const darken = Boolean(Number(shouldDarken));

    if (!image) {
        return null;
    }

    return (
        <>
            <CmsImage image={image} darken={darken} scale={scale} horizontalAlignment={horizontalAlignment} />
            {caption && <div className={styles.caption}>{caption}</div>}
        </>
    );
}
