import Price from '~/shared/price/price.component';

export type ProductItemIDProps = {
    displayPrice: string | undefined;
    unitPrice?: {
        displayPrice: string | undefined;
        label: string | undefined;
    };
};

export function ProductItemPrice({ displayPrice, unitPrice }: ProductItemIDProps) {
    return (
        <>
            <Price>{displayPrice}</Price>

            {unitPrice ? (
                <Price color="grey" size="small">
                    {unitPrice.label} {unitPrice.displayPrice}
                </Price>
            ) : null}
        </>
    );
}
