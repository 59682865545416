import React, { FC } from 'react';
import StandardCard from './standard-card/standard-card.component';
import BundleCard from './bundle-card/bundle-card.component';
import { IProductWithVehicleInfo } from '~/shared/product-list/product-list-rows/product-list-rows.component';
import { Products as ProductTypes } from '~/models/products.d';
import { IPricesList } from '~/libs/queries/products/hooks/use-product-prices';
import { IStockList } from '~/libs/queries/products/hooks/use-stock-status';
import { FavoriteStatusListResponse } from '~/libs/queries/favorites';
import { StandardCardProvider } from '~/page-elements/product-card/product-card-row/standard-card/context';
import { useCarInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';

interface IProps {
    cardData: IProductWithVehicleInfo;
    position: number;
    setModalContent: (content: JSX.Element) => void;
    productOEMatches?: ProductTypes.IProductOeMatches[];
    productInfoTexts?: ProductTypes.IProductInfoArticle[];

    loadingPrices: boolean;
    prices?: IPricesList;
    errorPrices: boolean;
    loadingStockList: boolean;
    stockList?: IStockList;
    errorStockList: boolean;
    favoriteStatusList?: FavoriteStatusListResponse;
    pageIndex?: number;
    itemNoList: string[];
}

const ProductCardRow: FC<IProps> = ({
    cardData,
    position,
    setModalContent,
    productOEMatches,
    productInfoTexts,
    prices,
    loadingPrices,
    stockList,
    loadingStockList,
    errorStockList,
    errorPrices,
    favoriteStatusList,
    pageIndex,
    itemNoList,
}) => {
    const oeMatches = productOEMatches?.find((item) => item.productId === cardData.id);
    const infoTexts = productInfoTexts?.find((item) => item.productId === cardData.id);
    const { data: vehicle } = useCarInfo();

    switch (cardData?.productType) {
        case ProductTypes.ProductType.Product:
        case ProductTypes.ProductType.DummyProduct:
            return (
                <StandardCardProvider>
                    <StandardCard
                        loadingPrices={loadingPrices}
                        prices={prices}
                        productOEMatches={oeMatches}
                        infoTexts={infoTexts}
                        cardData={cardData}
                        position={position}
                        setModalContent={setModalContent}
                        stockList={stockList}
                        loadingStockList={loadingStockList}
                        errorStockList={errorStockList}
                        errorPrices={errorPrices}
                        favoriteStatusList={favoriteStatusList}
                        pageIndex={pageIndex}
                        itemNoList={itemNoList}
                        vehicle={vehicle}
                    />
                </StandardCardProvider>
            );

        case ProductTypes.ProductType.BundleExhaustSystem:
            return <BundleCard cardData={cardData} setModalContent={setModalContent} />;

        default:
            return null;
    }
};

export default ProductCardRow;
