import { useDepartmentsByRegions, useDepartmentsByRegionsName } from '~/libs/queries/users/hooks/use-departments-by-regions';
import { useManualWebOrdersCount } from '~/libs/queries/users/hooks/use-manual-web-orders-count';
import { Bff } from '~/models/bff';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import styles from './styled.module.scss';

// Old component: show-manual-orders.component.tsx
export default function W044InternalManualOrders(props: Bff.IStandardModuleViewModel) {
    const translate = useTranslations();
    const buttonText = translate('internalDashboard.manualOrdersButtonText', 'manuelle ordre i');

    const { isLoading: departmentLoading, data: departmentsByRegions } = useDepartmentsByRegions();
    const department = useDepartmentsByRegionsName(departmentsByRegions);

    const { data: orders, isLoading: orderCountLoading } = useManualWebOrdersCount();
    const fetching = departmentLoading || orderCountLoading;
    if (!orders) return null;

    return (
        <Button
            buttonStyle="secondary"
            className={styles.button}
            icon={<Svg name="link-alt" className={styles.icon} />}
            fetching={fetching}
            href={orders?.url}
            target="_blank"
            fullWidth
        >
            {`${orders?.count} ${buttonText} ${department}`}
        </Button>
    );
}
