import React from 'react';
import { Dialog, DialogPortal, DialogOverlay, DialogContent, DialogTitle, DialogClose } from '@radix-ui/react-dialog';
import cx from 'clsx';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import styles from '../modal.module.scss';
import Heading from '~/shared/heading/heading.component';

export type ModalPosition = 'center' | 'right' | 'left';

// Modal sizes are currently only supported for modals with `position: center`
export type ModalSize = 'small' | 'default';

export type ModalProps = {
    // Class applied to the overlay area
    className?: string;
    children?: React.ReactElement | React.ReactElement[] | null;
    title?: string | React.ReactElement;
    // Implements additional markup in the modal header.
    meta?: React.ReactElement;
    position?: ModalPosition;
    size?: ModalSize;
    onOpenChange?: (open: boolean) => void;
    open?: boolean;
    defaultOpen?: boolean;
};

export function Modal({ className, children, title, meta, onOpenChange, open, defaultOpen, position = 'center', size = 'default' }: ModalProps) {
    return (
        <Dialog modal onOpenChange={onOpenChange} open={open} defaultOpen={defaultOpen}>
            <DialogPortal>
                <div
                    className={cx(className, styles.modalOverlayContainer, {
                        [styles.right]: position === 'right',
                        [styles.left]: position === 'left',
                        [styles.center]: position === 'center',
                        [styles.default]: position === 'center' && size === 'default',
                        [styles.small]: position === 'center' && size === 'small',
                    })}
                >
                    <DialogOverlay className={styles.modalOverlay} />
                    <div className={styles.modalContentContainer}>
                        <DialogContent className={styles.modalContent}>
                            <header className={styles.modalHeader}>
                                {title || meta ? (
                                    <div>
                                        {title ? (
                                            <DialogTitle asChild>
                                                <Heading tagName="h3">{title}</Heading>
                                            </DialogTitle>
                                        ) : null}

                                        {meta}
                                    </div>
                                ) : null}
                                <DialogClose asChild>
                                    <ButtonCircle iconName="close" buttonStyle="dark" />
                                </DialogClose>
                            </header>
                            <div className={styles.modalBody}>{children}</div>
                        </DialogContent>
                    </div>
                </div>
            </DialogPortal>
        </Dialog>
    );
}
