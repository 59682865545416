import { useCheckoutBasket } from '~/libs/queries/basket';
import { IWithClassName } from '~/models/dev.d';
import { printEndpoints } from '~/services/auth-endpoints';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketHeaderPrintBasket } from './basket-header-print-basket';

export function BasketHeaderPrintBasketContainer(props: IWithClassName) {
    const translate = useTranslations();
    const { data: basket } = useCheckoutBasket();

    const url = `${printEndpoints.basket}?id=${basket?.id}`;
    const label = translate('basket.printBasket', 'Print kurv');

    return <BasketHeaderPrintBasket url={url} label={label} {...props} />;
}
