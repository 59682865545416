import { useCheckoutBasket, useIsUpdatingBasket } from '~/libs/queries/basket';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';
import useUser from '~/libs/use-user';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../use-basket-states';
import { UseBasketSubmission } from '../../../use-basket-submission';
import { BasketSubmitManualOrder } from './basket-submit-manual-order';

export function BasketSubmitManualOrderContainer() {
    const translate = useTranslations();
    const { isInternalUser } = useUser();
    const { shouldShowFeature } = useFeatureToggle();

    const { data: { totalItemsCount } = {} } = useCheckoutBasket();
    const { data: features } = useFeatures();

    const isUpdatingBasket = useIsUpdatingBasket();
    const handleSubmit = UseBasketSubmission();
    const manualOrderFeatureFlag = shouldShowFeature(features?.orders?.manualOrder);
    const allowManualOrder = manualOrderFeatureFlag && isInternalUser;

    const { isLoading } = useBasketStates();

    if (!allowManualOrder) {
        return null;
    }

    return (
        <BasketSubmitManualOrder
            label={translate('basket.sendForManualButton', 'Manuel behandling')}
            handleSubmit={() => handleSubmit(true)}
            isDisabled={isUpdatingBasket || !totalItemsCount}
            isLoading={isLoading}
        />
    );
}
