import { useCheckoutBasket } from '~/libs/queries/basket';
import { BasketItems } from './basket-items';

export function BasketItemsContainer() {
    const { data: basket } = useCheckoutBasket();

    // This should never happen because BasketItems is only rendered with active basket
    if (!basket) {
        return null;
    }

    const sortedGroups = (basket.groups || []).sort((a, b) => {
        return +(a.basketCarInfo === null) - +(b.basketCarInfo === null);
    });

    return <BasketItems groups={sortedGroups} />;
}
