import React, { FC } from 'react';
import clsx from 'clsx';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import ErrorBox from '~/shared/error-box/error-box';
import { Products as ProductTypes } from '~/models/products.d';
import useUser from '~/libs/use-user';
import styles from '../details.module.scss';
import Text from '~/shared/text/text.component';
import StatusDot from '../../status-dot/status-dot.component';

interface IDetailsTableProps {
    details?: ProductTypes.IStockInfo[];
    fetching?: boolean;
    isError?: Error | null;
    isExternal?: boolean;
}

const DetailsTable: FC<IDetailsTableProps> = ({ details, fetching, isError, isExternal }) => {
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const showLocation = details?.some((d) => d.articleLocations);

    if (fetching) return <Loader padding="100px 20px" />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    const noResultsText = isExternal
        ? translate('stockStatus.noResultsExternal', 'Ingen varer på eksternt lager i øjeblikket. Kontakt FTZ for leveringstid.')
        : translate('stockStatus.noResultsInternal', 'Ingen FTZ afdelinger har varen på lager i øjeblikket. Kontakt FTZ for leveringstid.');

    if (!details?.length) {
        return <Text className={styles.noResults}>{noResultsText}</Text>;
    }

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>{translate('stockStatus.department', 'department')}</th>
                    {showLocation && <th>{translate('stockStatus.location', 'lokation')}</th>}
                    <th>{translate('stockStatus.quantity', 'quantity')}</th>
                </tr>
            </thead>
            <tbody>
                {details?.map((row, i: number) => (
                    <tr key={`${row.department}-${i}`}>
                        <td>
                            <Text textStyle="monoSmall" className={clsx(styles.department)}>
                                <StatusDot statusCode={row?.stockStatusCode as ProductTypes.StockStatusCode} className={styles.statusDot} />
                                {isInternalUser && <span className={styles.departmentId}>{row.departmentId}</span>}
                                {row.department}
                            </Text>
                        </td>
                        {showLocation && (
                            <td>
                                <Text textStyle="monoSmall">{row?.articleLocations?.length ? row.articleLocations.join(', ') : ''}</Text>
                            </td>
                        )}
                        <td>
                            <Text textStyle="monoSmall">{row.quantity}</Text>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DetailsTable;
