import { Orders as OrderTypes } from '~/models/orders';
import styles from './styled.module.scss';
import Text from '~/shared/text/text.component';
import CarInfo from '~/shared/car-info/car-info.component';
import OEBadge from '~/shared/oe-badge/oe-badge.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import Labels from './chunks/labels.component';
import OrderInfo from './chunks/order-info.component';
import Product from './chunks/product.component';

type IProps = {
    group: OrderTypes.IOrderOverviewGroup;
};

export default function OrdersGroup({ group }: IProps) {
    return (
        <PanelCard className={styles.group}>
            <div className={styles.header}>
                <Labels
                    className={styles.labels}
                    expectedDeliveryTime={group.expectedDeliveryTimeDisplay as string}
                    orderTime={group.orderTimeDisplay as string}
                />
                <div className={styles.carInfo}>
                    <CarInfo carInfo={group.carInfo} hideCountryCode />
                    <OEBadge orderType={group.orderType} className={styles.oeBadge} />
                </div>
                <OrderInfo
                    className={styles.orderInfo}
                    caseNo={group.caseNo as string}
                    requisitionNo={group.requisitionNo as string}
                    webOrderNumber={group.webOrderNumber as string}
                />
            </div>
            <div className={styles.content}>
                <ul className={styles.products}>{group.products?.map((item, i) => <Product key={i} item={item} />)}</ul>
                {group?.expectedDeliveryDateTimeDescription && (
                    <Text className={styles.expectedDelivery} textStyle="bodySmall">
                        {group?.expectedDeliveryDateTimeDescription}
                    </Text>
                )}
            </div>
        </PanelCard>
    );
}
