import React, { FC } from 'react';
import clsx from 'clsx';
import { Bff } from '~/models/bff.d';
import { IWithClassName } from '~/models/dev';
import Skeleton from '~/shared/skeleton';
import styles from './images.module.scss';
import { Svg } from '~/shared/svg';
import { Products as ProductTypes } from '~/models/products.d';
import Gallery from '~/shared/gallery/gallery.component';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';

interface IGalleryProps extends IWithClassName {
    loading?: boolean;
    images?: Bff.IImage[];
    brand?: ProductTypes.IBrand;
}

const Images: FC<IGalleryProps> = ({ loading = false, className, images, brand }) => (
    <div className={clsx(className, styles.galleryContainer)}>
        <div className={styles.brand}>
            <BrandLogo brand={brand} height={30} width={120} objectPosition="right center" />
        </div>

        {loading && <Skeleton style={{ height: '80%', width: '100%' }} />}

        {!loading && !images?.length && <Svg name="placeholder-image" className={styles.placeholderImage} />}

        {!loading && images?.length !== 0 && <Gallery slides={images} canOpenInModalView />}
    </div>
);

export default Images;
