import { FC } from 'react';
import OverviewNavigation from './overview-navigation.component';
import styles from './styled.module.scss';

const WithOverviewNavigation =
    <P = any,>(Component: FC<any>) =>
    (props: P) => (
        <>
            <OverviewNavigation />

            <div className={styles.content}>
                <Component {...props} />
            </div>
        </>
    );

export default WithOverviewNavigation;
