import { Products } from '../../../models/products.d';

interface IVariantPickerState {
    primaryAttribute: Products.IProductAttribute | null;
    primaryAttributePossibleVariants: Products.IVariant[] | null;
    previousAttribute: Products.IProductAttribute | null;
    filteredPossibleVariants: Products.IVariant[] | null;
    activeVariant: Products.IVariant | null;
}

export interface IAction {
    type: VariantActionType;
    payload?: any;
}

export enum VariantActionType {
    'SetPrimaryAttribute',
    'SetAppliedAttributes',
    'SetActiveVariant',
    'InitVariantPicker',
}

export const initialState: IVariantPickerState = {
    primaryAttribute: null,
    primaryAttributePossibleVariants: null,
    previousAttribute: null,
    filteredPossibleVariants: null,
    activeVariant: null,
};

export function variantReducer(state: IVariantPickerState, action: IAction) {
    switch (action.type) {
        case VariantActionType.SetPrimaryAttribute: {
            return {
                ...state,
                previousAttribute: state.primaryAttribute,
                primaryAttribute: action.payload,
            };
        }

        case VariantActionType.SetActiveVariant: {
            return {
                ...state,
                activeVariant: action.payload.activeVariant,
                primaryAttributePossibleVariants: action.payload.primaryAttributePossibleVariants,
                filteredPossibleVariants: action.payload.filteredPossibleVariants,
            };
        }

        case VariantActionType.InitVariantPicker: {
            return {
                ...state,
                activeVariant: action.payload.activeVariant,
                primaryAttribute: action.payload.primaryActiveVariantAttribute,
                primaryAttributePossibleVariants: action.payload.primaryAttributePossibleVariants,
                filteredPossibleVariants: action.payload.primaryAttributePossibleVariants,
            };
        }

        default:
            return state;
    }
}
