import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './item.module.scss';
import Radio from '~/shared/form-elements/radio/radio.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';

import Text from '~/shared/text/text.component';
import { Link } from '~/shared/link';
import BrandLogo from '~/shared/brand-logo/brand-logo.component';
import Select from '../../form-elements/select/select.component';
import ValueWithCaption from '../../value-with-caption/value-with-caption.component';
import Price from '../../price/price.component';
import { ICreditItem } from '../create-credit';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg';
import useUser from '~/libs/use-user';
import { DEPOSIT_ITEMS_COUNT } from '~/constants/deposit-items-count';
import Tooltip, { TooltipContentWrapper } from '~/shared/tooltip/tooltip.component';
import { Products } from '~/models/products';

export const DEPOSIT_ONLY_LOCATION = '9DEP';

const getColliValues = (isColliLocked: boolean, quantity = 1, colli = 1) => {
    if (isColliLocked) {
        if (colli >= quantity) return [quantity];
        if (colli === 0) return Array.from(Array(quantity).keys()).map((i) => i + 1);

        return Array.from(Array(quantity / colli).keys())
            .map((i) => i + 1)
            .map((q) => q * colli);
    }

    if (colli === 0) {
        return Array.from(Array(quantity).keys()).map((i) => i + 1);
    }

    return Array.from(Array(quantity).keys()).map((i) => i + 1);
};

interface IProps {
    item: ICreditItem;
    onChangeQuantity: (itemId: string | number, quantity: number) => void;
    onChangeDeposit: (ItemId: string | number, val: boolean) => void;
    onDeleteItem: (ItemId: string | number) => void;
    fetching: boolean;
}

const Item: FC<IProps> = ({ item, onChangeQuantity, onChangeDeposit, fetching, onDeleteItem }) => {
    const translate = useTranslations();
    const { isInternalUser } = useUser();

    const colliValues =
        item?.isDepositProduct && item.depositOnly
            ? getColliValues(false, DEPOSIT_ITEMS_COUNT, 0)
            : getColliValues(
                  item?.colliLocked,
                  // some items might be returned
                  item?.possibleToCreditQuantity,
                  item.colli,
              );

    const creditLocation = item.depositOnly ? DEPOSIT_ONLY_LOCATION : item.creditLocation;

    return (
        <div className={styles.itemWrapper}>
            <div className={styles.item}>
                <div className={styles.itemDescription}>
                    <BrandLogo brand={item?.brand as Products.IBrand} />
                    <Text textStyle="body" fontWeight="regular">
                        {item.title}
                    </Text>
                </div>
                <div className={styles.quantity}>
                    {item?.quantity !== item?.possibleToCreditQuantity && (
                        <Tooltip
                            content={
                                <TooltipContentWrapper>
                                    {translate(
                                        'createCredit.numberOfItemsLeftAfterReturns',
                                        'Antal varer du har tilbage efter tidligere retur [quantity]',
                                    ).replace('[quantity]', item?.possibleToCreditQuantity)}
                                </TooltipContentWrapper>
                            }
                        />
                    )}
                    <span className="title">{translate('overview.number', 'antal')}</span>
                    <Select
                        wrapperClassName={styles.dropdown}
                        isValid={!item.quantityWarn}
                        disabled={fetching}
                        value={item.selectedQuantity}
                        onChange={(e) => onChangeQuantity(item?.itemId as string, Number(e.target.value))}
                    >
                        {(item.selectedQuantity === 0 || (item.depositOnly && item.isDepositProduct)) && <option value="">-</option>}
                        {colliValues.map((v, i) => (
                            <option key={`${v}-${i}`}>{v}</option>
                        ))}
                    </Select>
                </div>
                <ValueWithCaption caption={translate('product.itemNo', 'Vare nr')}>
                    <Link href={item.url}>
                        <Text textStyle="monoMedium">{item.itemId}</Text>
                    </Link>
                </ValueWithCaption>
                {isInternalUser ? (
                    <ValueWithCaption caption={translate('overview.location', 'Lokation')}>
                        <Text textStyle="monoMedium">{creditLocation ?? '-'}</Text>
                    </ValueWithCaption>
                ) : (
                    <div />
                )}
                <div className={styles.price}>
                    {!item?.depositOnly && (
                        <Price textAlign="right">
                            {item?.unitPrice?.netPriceFormatted} / {translate('product.piece', 'stk')}
                        </Price>
                    )}
                    {item?.hasDeposit && (
                        <Price className={styles.depositPrice} textAlign="right" size="medium">
                            {translate('overview.deposit', 'Depositum')} {item?.unitPrice?.depositFormatted} / {translate('product.piece', 'stk')}
                        </Price>
                    )}
                </div>
            </div>
            <div className={styles.deleteButtonWrapper}>
                <div>
                    {item.hasDeposit && (
                        <section className={styles.depositSection}>
                            <div className={styles.radioWrapper}>
                                <Radio checked={!item.depositOnly} onChange={() => onChangeDeposit(item.itemId as string, false)} disabled={fetching}>
                                    {translate('overview.unusedItemsInclDeposit', 'Ubrugte varer (inkl. depositum)')}
                                </Radio>
                            </div>
                            <div className={styles.radioWrapper}>
                                <Radio checked={item.depositOnly} onChange={() => onChangeDeposit(item.itemId as string, true)} disabled={fetching}>
                                    {translate('createCredit.returnOnlyDeposit', 'Returner kun depositum')}
                                </Radio>
                            </div>
                        </section>
                    )}
                </div>
                <Button icon={<Svg name="bin" />} onClick={() => onDeleteItem(item.itemId as string)} buttonStyle="clean" />
            </div>

            {item.quantityWarn && (
                <ValidationErrorMessage className={styles.quantityWarn}>{translate('overview.selectQuantity', 'vælg Antal')}</ValidationErrorMessage>
            )}

            {fetching && (
                //  Prevents click while fetching (submitting)
                <div className={styles.fetchingLayer} />
            )}
        </div>
    );
};

export default Item;
