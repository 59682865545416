import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import { Svg } from '~/shared/svg';
import Label from '../common/label/label.component';
import styles from './search-Input.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    setInputValue?: (val: string) => void;
    wrapperClassName?: string;
    label?: string;
}

const SearchInput = forwardRef<HTMLInputElement, IProps>((props, ref): JSX.Element => {
    const { value, setInputValue, name, className, wrapperClassName, label, ...rest } = props;

    return (
        <div className={wrapperClassName}>
            {label && <Label name={`Input${name}`}>{label}</Label>}
            <div className={styles.inputWrapper}>
                <input
                    ref={ref}
                    id={`Input${name}`}
                    className={clsx(className, styles.input, {})}
                    value={value}
                    onChange={(e: any) => {
                        if (setInputValue) {
                            setInputValue(e.target.value);
                        }
                    }}
                    {...rest}
                />
                {value ? (
                    <ButtonCircle
                        iconName="close"
                        buttonStyle="dark"
                        buttonSize="small"
                        className={styles.clearIcon}
                        onClick={() => setInputValue && setInputValue('')}
                    />
                ) : null}
                <Svg name="search" thick className={styles.searchIcon} />
            </div>
        </div>
    );
});

export default SearchInput;
