import React from 'react';
import clsx from 'clsx';
import styles from './skeleton.module.scss';
import { IWithClassName } from '~/models/dev';
import { isServer } from '~/helpers';

interface ISkeleton extends IWithClassName {
    style?: React.CSSProperties;
    isDark?: boolean;
}

// Usage: const skeletonWidth = useMemo(() => `${getSkeletonRandom(80)}px`, [])
export const getSkeletonRandom = (maxWidth: number) => {
    if (isServer) {
        return maxWidth;
    }

    const minWidth = maxWidth * 0.4;
    const randomValue = Math.random();
    const randomNumber = Math.floor(randomValue * minWidth);

    return randomNumber + (maxWidth - minWidth);
};

// Usage: <Skeleton style={{ height: '30px', margin: '15px 0' }} />
const Skeleton: React.FC<ISkeleton> = ({ children, style, className, isDark = false }) => (
    <span
        className={clsx(className, {
            [styles.skeletonDark]: isDark,
            [styles.skeletonLight]: !isDark,
        })}
        style={style}
    >
        {children}
    </span>
);

export default Skeleton;
