import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { CmsData } from '~/models/cms-data';
import { useRouter } from 'next/router';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';

const useReturnDetails = (rmaId?: number) => {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();

    const { orders, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: `${orders.rma}/id`,
        localeOption: LocaleOptions.path,
        query: {
            id: rmaId,
        },
    });

    return useQuery<CmsData.IReturnDetails, Error>({
        queryKey: queryKeys.bff.returnDetails(locale, rmaId),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: isLoggedIn,
    });
};

export default useReturnDetails;
