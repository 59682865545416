import { Dispatch, SetStateAction } from 'react';
import { Orders as OrderTypes } from '~/models/orders.d';
import CarInfo from '~/shared/car-info/car-info.component';
import PanelCard from '~/shared/panel-card/panel-card.component';
import Item from '../item/item.component';
import styles from './styled.module.scss';

type IProps = {
    section?: OrderTypes.IBaseItemDetailOfInvoiceItem;
    selectedItems: OrderTypes.IInvoiceItem[];
    setSelectedItems: Dispatch<SetStateAction<OrderTypes.IInvoiceItem[]>>;
    handleOpenSingleRequest: (itemNo: string) => void;
    handleSelected: (itemNo: string, add?: boolean) => void;
    searchQuery: string;
};

export default function InvoiceSection({ section, selectedItems, handleOpenSingleRequest, handleSelected, searchQuery }: IProps) {
    const items = section?.itemDetails || [];

    return (
        <PanelCard className={styles.section}>
            <div className={styles.header}>
                <CarInfo carInfo={section?.carInfo} hideCountryCode highlightText={searchQuery} />
            </div>
            {items.map((item) => (
                <Item
                    searchQuery={searchQuery}
                    isAddedForRequest={selectedItems.some((i) => i.itemId === item.itemId)}
                    openRequest={handleOpenSingleRequest}
                    addToSelected={handleSelected as (itemId: string | undefined, add?: boolean | undefined) => void}
                    key={item.itemId}
                    item={item}
                />
            ))}
        </PanelCard>
    );
}
