import { Bff } from '~/models/bff';
import FavoriteProductsList from './favorite-products-list/favorite-products-list.component';
import FavoritesNavigation from './favorites-navigation/favorites-navigation.component';

export type FavoriteType = 'MyFavorite' | 'ChainFavorite';

// Before: favorites.component.tsx
export default function W041Favorites({ favoriteType }: Bff.IFavoritesWidget) {
    // Picks the last breadcrumb as the selected link
    const validFavoriteType = getValidFavoriteTypes(favoriteType);

    return (
        <>
            <FavoritesNavigation />
            <FavoriteProductsList favoriteType={validFavoriteType} />
        </>
    );
}

function getValidFavoriteTypes(value?: string): FavoriteType {
    const allowedFavorites: FavoriteType[] = ['MyFavorite', 'ChainFavorite'];
    if (allowedFavorites.includes(value as FavoriteType)) {
        return value as FavoriteType;
    }
    return 'MyFavorite';
}
