import { useMemo } from 'react';
import { useChemicalLink } from '~/libs/queries/products';
import { CmsData } from '~/models/cms-data';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import Button from '~/shared/buttons/button/button.component';
import GridTable, { Cell, Row } from '~/shared/grid-table/grid-table.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

export type ChemicalProductsListRiskVideoColumnProps = {
    isLoading: boolean;
    row?: CmsData.IProductDocumentsGroup;
};

export function ChemicalProductsListRiskVideoColumn({ isLoading, row }: ChemicalProductsListRiskVideoColumnProps) {
    return (
        <Button
            aria-label="open"
            target="_blank"
            fetching={isLoading}
            disabled={!isLoading && !row?.documents[0]}
            href={row?.documents?.[0]?.url}
            icon={<Svg name="play" />}
            buttonStyle="clean"
        />
    );
}

interface IChemicalProductsListProps {
    products: CmsData.IChemicalProduct[];
    fetching: boolean;
    error?: Error;
    hasPermission?: boolean;
}

export default function ChemicalProductsList({ products = [], fetching, error, hasPermission }: IChemicalProductsListProps) {
    const translate = useTranslations();
    const ftzCodes = useMemo(() => products?.map((v) => v.itemId), [products]);
    const { data, isLoading } = useChemicalLink({ ftzCodes, enabled: hasPermission });

    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!products?.length) return <OverviewNoResult />;

    return (
        <GridTable>
            {products.map((row, i) => (
                <Row key={`${row.id}-${i}`}>
                    <Cell label={translate('chemicalProducts.quantity', 'Antal køb')}>
                        <Text textStyle="monoMedium">{row?.quantity}</Text>
                    </Cell>
                    <Cell label={translate('chemicalProducts.lastBoughtDate', 'Dato køb')}>
                        <Text textStyle="monoMedium">{row?.lastBoughtDisplayDate}</Text>
                    </Cell>
                    {hasPermission ? (
                        <Cell label={translate('chemicalProducts.guide', 'Guide')}>
                            <ChemicalProductsListRiskVideoColumn
                                isLoading={isLoading}
                                row={data?.[row.itemId]?.find((item) => item.alias === 'Risikovurdering')}
                            />
                        </Cell>
                    ) : null}
                    <Cell label={translate('chemicalProducts.itemNo', 'Varenr.')}>
                        <Link href={row.productUrl} target="_blank">
                            <Text textStyle="monoMedium">{row?.itemId}</Text>
                        </Link>
                    </Cell>
                    <Cell label={translate('chemicalProducts.title', 'Varetekst')}>
                        <Text textStyle="bodySmall">{row?.title}</Text>
                    </Cell>
                    <Cell>
                        <div className={styles.buttonWrapper}>
                            <Button aria-label="open" target="_blank" href={row.externalUrl} icon={<Svg name="paper-stack" />} buttonStyle="clean" />
                        </div>
                    </Cell>
                </Row>
            ))}
        </GridTable>
    );
}
