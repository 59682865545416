import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useLayoutState } from '~/context/layout.context';
import { useCurrentBasket, useUpdateBasket } from '~/libs/queries/basket';
import type { Basket as BasketTypes } from '~/models/basket.d';
import type { IWithClassName } from '~/models/dev';
import { AddToBasket } from './add-to-basket';
import type { AddToBasketQuantityInputProps } from './components/add-to-basket-quantity/components/add-to-basket-quantity-input/add-to-basket-quantity-input';

export type AddToBasketProductDetails = {
    itemNo?: string;
    customerPrice: number | null | undefined;
    colli?: number;
    colliLocked?: boolean;
    carId?: string;
    licensePlate?: BasketTypes.ILicensePlateDto;
};

export type AddToBasketPropsDrilled = {
    noFullWidth?: boolean;
    isAlwaysOpen?: boolean;
    label?: string;
    smallUnder?: 'Never' | 'MD' | 'LG' | 'XL' | 'XXL' | 'Always';
    isLarge?: boolean;
    disabled?: boolean;
};

export type AddToBasketTrackingProps = {
    campaignType?: string;
    campaignId?: string;
    campaignSpot?: string;
    raptorSource?: string;
};

export type AddToBasketProps = IWithClassName &
    AddToBasketPropsDrilled & {
        value?: number;
        basketId?: string;
        syncWithBasket?: boolean;
        elementRef?: MutableRefObject<any>;
        listName?: string;
        url: string;
        onAddToBasket?: () => void;
        productDetails: AddToBasketProductDetails;
        tracking?: AddToBasketTrackingProps;
        skipValidation?: boolean; // Validation is skipped unless user is in /basket
    };

const getItemVal = (items: BasketTypes.IBasketItemDto[], id: string) => items?.find((item) => item.itemId === id)?.count;

const closestColliUpVal = (newValue: number, colli: number, inputValue: AddToBasketQuantityInputProps['value']): number => {
    const leftover = newValue % colli;

    if (newValue === inputValue || leftover === 0) {
        return newValue;
    }

    return newValue + (colli - leftover);
};

export function AddToBasketContainer({
    value,
    syncWithBasket,
    url,
    listName,
    onAddToBasket,
    skipValidation,
    productDetails: { itemNo, customerPrice, colli: _colli, colliLocked, carId: carIdProp, licensePlate: licensePlateProp },
    tracking: { campaignSpot, campaignType, campaignId, raptorSource } = {},
    ...drilledProps
}: AddToBasketProps) {
    const { vehicle, selectedDepartment } = useLayoutState();
    const { data: basket } = useCurrentBasket({
        skipValidation,
    });
    const { mutateAsync: updateBasketAsync } = useUpdateBasket();

    const inputRef = useRef<HTMLInputElement>();

    const [itemQuantity, setItemQuantity] = useState(value);
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout>();

    const colli = !colliLocked || !_colli ? 1 : _colli;

    const carId = carIdProp || vehicle?.carId || null;
    const licensePlate = licensePlateProp || vehicle?.licensePlate || null;

    const count = useMemo(() => {
        const getCarGroupItems = () => {
            if (basket && !basket.groups) {
                return [];
            }

            if (licensePlate) {
                return basket?.groups?.find((g) => g.basketCarInfo?.licensePlate?.number === licensePlate?.number)?.items;
            }

            if (carId) {
                return basket?.groups?.filter((g) => g.basketCarInfo?.licensePlate === null)?.find((g) => g.basketCarInfo?.carId === carId)?.items;
            }

            return basket?.groups?.filter((g) => g.basketCarInfo === null)?.find((g) => !g.basketCarInfo)?.items;
        };

        return syncWithBasket && basket ? getItemVal(getCarGroupItems() as BasketTypes.IBasketItemDto[], itemNo as string) : value;
    }, [basket, carId, licensePlate, syncWithBasket, itemNo, value]);

    useEffect(() => {
        setItemQuantity(count);
    }, [count]);

    const handleChange = async (basketValue: AddToBasketProps['value']) => {
        if (basketValue === undefined || basketValue === itemQuantity) {
            return;
        }

        const prevValue = itemQuantity;
        const newValue = colliLocked ? closestColliUpVal(basketValue, colli, itemQuantity) : basketValue;

        setItemQuantity(newValue);

        clearTimeout(debounceTimeout);
        setDebounceTimeout(
            setTimeout(() => {
                if (!basket) {
                    console.error('Could not update item. Basket was undefined.');
                    return;
                }

                if (!itemNo) {
                    console.error('Could not update item. Item id was undefined.');
                    return;
                }

                try {
                    return updateBasketAsync({
                        basketBeforeUpdate: basket,
                        departmentId: selectedDepartment?.id,
                        skipValidation: false,
                        details: {
                            basketId: basket.id,
                            carId: carId || undefined,
                            licensePlate: licensePlate || undefined,
                            count: newValue,
                            ftzPrice: customerPrice || 0,
                            listName,
                            itemId: itemNo,
                            url,
                            campaignSpot,
                            componentId: campaignId,
                            componentName: campaignType,
                            promotionId: raptorSource,
                        },
                    });
                } catch (_) {
                    console.error('Updating item in basket failed. Reverted basket input to previous item quantity.');
                    setItemQuantity(prevValue);
                }
            }, 300),
        );
    };

    const handleAddToBasket = async () => {
        // Need to await the change for the inputRef to be initiated.
        // Otherwise the input element will not be present and no focus can be set
        await handleChange((itemQuantity || 0) + colli);

        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }

        onAddToBasket?.();
    };

    const handleIncrement = () => {
        handleChange((itemQuantity || 0) + colli);
    };

    const handleDecrement = () => {
        if (!itemQuantity) {
            console.error('Could not decrement item. Item was not in basket.');
            return;
        }

        handleChange(Math.max(itemQuantity - colli, 0));
    };

    return (
        <AddToBasket
            className={drilledProps.className}
            noFullWidth={drilledProps.noFullWidth}
            showQuantityInput={drilledProps.isAlwaysOpen || !!itemQuantity}
            // BasketInputProps
            value={itemQuantity}
            alwaysOpen={drilledProps.isAlwaysOpen}
            onIncrement={handleIncrement}
            onDecrement={handleDecrement}
            onInputChange={handleChange}
            inputRef={inputRef as MutableRefObject<HTMLInputElement>}
            // BasketAddButtonProps
            label={drilledProps.label}
            showLabelAt={drilledProps.smallUnder}
            isLarge={drilledProps.isLarge}
            disabled={drilledProps.disabled}
            onAddToBasket={handleAddToBasket}
        />
    );
}
