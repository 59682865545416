import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg/svg.component';
import Text from '~/shared/text/text.component';
import { getAllCategoryChildren, ICategoryWithParents } from '../sales-price-markup.helpers';
import styles from './styled.module.scss';

interface IProps {
    isRoot: boolean;
    categories: ICategoryWithParents[];
    onRemoveItem: (id: number) => void;
}

export default function CategorySection({ isRoot, categories, onRemoveItem }: IProps) {
    const translate = useTranslations();
    if (!categories.length) return null;

    return (
        <ul className={styles.categoriesList}>
            {categories.map((cat) => {
                const allChildrenLength = cat.hasChildren ? getAllCategoryChildren(cat).length : null;
                const maxTextLength = allChildrenLength ? 32 : 55;
                return (
                    <li className={styles.listItem} key={cat.id}>
                        <span className={styles.categoryName}>
                            <Text title={cat.name} fontWeight="semiBold" textStyle="bodySmall" className={styles.categoryNameText}>
                                {(cat?.name?.length as number) > maxTextLength ? `${cat?.name?.substring(0, maxTextLength)}...` : cat.name}
                            </Text>
                            {isRoot && (
                                <button aria-label="Close" className={styles.deleteBtn} type="button" onClick={() => onRemoveItem(cat.id)}>
                                    <Svg name="close" className={styles.closeIcon} />
                                </button>
                            )}
                        </span>
                        {allChildrenLength ? (
                            <Text textStyle="bodySmall" secondary className={styles.childrenCount}>
                                {translate('salesPriceMarkup.inclTotalSubcategories', 'inkl. [total] underkategorier').replace(
                                    '[total]',
                                    allChildrenLength,
                                )}
                            </Text>
                        ) : null}
                    </li>
                );
            })}
        </ul>
    );
}
