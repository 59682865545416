import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import { Placement } from '@popperjs/core';
import { IWithClassName } from '~/models/dev';
import Skeleton from '~/shared/skeleton';

const CustomDatePicker = dynamic(() => import('./custom-date-picker.component'), {
    ssr: false,
    loading: () => <Skeleton style={{ height: '42px', width: '170px' }} />,
});

export interface IDatePickerProps extends IWithClassName {
    value: string;
    label?: string;
    onChange: (val: string) => void;
    disabled?: boolean;
    maxDate?: string;
    minDate?: string;
    placement?: Placement;
}

const DatePicker: FC<IDatePickerProps> = (props) => <CustomDatePicker {...props} />;

export default DatePicker;
