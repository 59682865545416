import clsx from 'clsx';
import { Bff } from '~/models/bff';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';
import M160LinkContainer from '../M160LinkContainer/M160LinkContainer';
import styles from './styled.module.scss';

export default function M161LinkContainerAndRichText({
    id,
    type,
    links,
    richText,
    headline,
    richTextAlignment,
    backgroundColor,
    discriminator,
    campaignSpot,
}: Bff.IM161LinkContainerAndRichTextModule) {
    return (
        <div className={styles.container}>
            <div style={{ order: richTextAlignment === 'Right' ? 1 : 2 }}>
                <M160LinkContainer
                    discriminator={discriminator}
                    headline={headline}
                    links={links}
                    backgroundColor={backgroundColor}
                    campaignSpot={campaignSpot}
                    type={type}
                    id={id}
                />
            </div>
            <div style={{ order: richTextAlignment === 'Right' ? 2 : 1 }}>
                {richText ? <RawHtmlComponent content={richText} campaignSpot={campaignSpot} campaignId={id} campaignType={type} /> : null}
            </div>
        </div>
    );
}
