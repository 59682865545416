import { Products as ProductTypes } from '~/models/products.d';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import useActiveUser from '~/libs/use-active-user';
import { apiClient } from '~/services/api-client';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

interface ISparePartCategoriesByIdProps {
    categoryId: number;
    levels?: number;
}

export function useSparePartCategoriesById({ categoryId, levels = 1 }: ISparePartCategoriesByIdProps) {
    const { isLoggedIn, user } = useUser();
    const { activeProfile } = useActiveUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();

    const endpoint = createUrl({
        localeOption: LocaleOptions.path,
        endpoint: `${products.sparePartCategory}/categories/${categoryId}`,
        query: {
            includeExpertProducts: !!expertMode,
            levels,
        },
    });

    return useQuery<ProductTypes.ICategory, Error>({
        queryKey: queryKeys.products.sparePartCategoriesById(locale, activeProfile?.id, !!expertMode, levels, categoryId),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(endpoint, { signal });
        },
        enabled: isLoggedIn && !!activeProfile,
    });
}
