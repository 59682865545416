import React, { FC, useEffect } from 'react';
import Carousel, { CarouselItem } from '../carousel/carousel.component';
import ErrorBox from '../error-box/error-box';
import ProductCardSmallContainer from '~/page-elements/product-card/product-card-small/product-card-small.component';
import useTranslations from '../hooks/use-translations.hook';
import styles from './related-products-list.module.scss';
import Heading from '~/shared/heading/heading.component';
import { Products } from '~/models/products.d';
import Text from '../text/text.component';
import { GA4ViewListItems } from '~/libs/ga4';
import useUser from '~/libs/use-user';
import { useInView } from 'react-hook-inview';
import { useProductPrices, getVariantPriceRequest } from '~/libs/queries/products/hooks/use-product-prices';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useRelatedProducts } from '~/libs/queries/products/hooks/use-related-products';
import IVariantLight = Products.IVariantLight;

interface RelatedProductsListProps {
    gutter?: number;
    relatedVariantIds?: number[];
    relatedVariantsExtraInfo?: Products.IRelatedVariant[];
    loadingItems?: number;
    showTitle?: boolean;
}

const RelatedProductsList: FC<RelatedProductsListProps> = ({ relatedVariantIds, gutter, loadingItems, showTitle, relatedVariantsExtraInfo }) => {
    const translate = useTranslations();
    const { profile } = useUser();

    const [ref, isVisible] = useInView({
        threshold: 0.1,
        rootMargin: '1000px',
    });
    const { data: relatedProducts, isError, isLoading } = useRelatedProducts(relatedVariantIds);
    const { data: prices } = useProductPrices(getVariantPriceRequest(relatedProducts), isVisible);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { data: stockStatus } = useStockStatus(relatedProducts?.map(({ ftzCode }) => ftzCode) ?? [], isVisible);

    useEffect(() => {
        if (!relatedProducts || !prices) {
            return;
        }

        const variantsWithPrices = relatedProducts.map((v: IVariantLight) => {
            return {
                ...v,
                price: prices?.[v.ftzCode as string]?.actualPrice,
            };
        });

        GA4ViewListItems(variantsWithPrices, { currency: profile?.currency as string, listName: 'accessory_products' });
    }, [relatedProducts, prices]);

    if (!relatedVariantIds?.length) return null;

    if (isError) {
        return (
            <div
                className={styles.error}
                style={{
                    ['--related-products-gutter' as string]: gutter && `${gutter}px`,
                }}
            >
                <ErrorBox>
                    {translate('product.relatedProductsError', 'Vi kan desværre ikke finde nogle tilbehørsprodukter lige nu. Prøv evt. igen senere.')}
                </ErrorBox>
            </div>
        );
    }

    return (
        <div ref={ref}>
            {showTitle && (
                <Heading tagName="h4" className={styles.sectionTitle}>
                    {translate('product.relatedProducts', 'Tilbehørsprodukter')}
                </Heading>
            )}
            {relatedProducts ? (
                <Text tagName="p" textStyle="body" fontWeight="semiBold" textAlign="left" className={styles.numberOfProducts}>
                    {translate('product.relatedProductsAmount', 'Antal produkter')} ({relatedProducts.length})
                </Text>
            ) : null}{' '}
            <Carousel gutter={gutter} arrowsInMiddle>
                {isLoading
                    ? [...Array(loadingItems)]?.map((_, index: number) => (
                          <CarouselItem key={`skeleton-${index}`}>
                              <ProductCardSmallContainer isLoading />
                          </CarouselItem>
                      ))
                    : relatedProducts?.map((product: IVariantLight, index: number) => (
                          <CarouselItem key={`product-${product?.ftzCode}-${index}`}>
                              <ProductCardSmallContainer
                                  product={product}
                                  relatedVariantsNecessityInfo={relatedVariantsExtraInfo}
                                  listName="accessory_products"
                                  price={prices?.[product.ftzCode as string]}
                                  stockStatus={stockStatus?.[product.ftzCode as string]}
                              />
                          </CarouselItem>
                      ))}
            </Carousel>
        </div>
    );
};

export default RelatedProductsList;
