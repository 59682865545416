import { Bff } from '~/models/bff';
import { Orders as OrdersTypes } from '~/models/orders';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import SearchInput from '~/shared/form-elements/search-Input/search-Input.component';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Toggle from '~/shared/toggle/toggle.component';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import OrdersList from '../orders-list/orders-list.component';
import styles from './styled.module.scss';

export enum ordersOverviewSortType {
    orderTime = 'orderTime',
    deliveryTime = 'deliveryTime',
}

//Name before: /page-element/overview/orders.component.tsx
function W080OrdersOverview() {
    const translate = useTranslations();

    const { orders } = useEndpoints();
    const {
        data: ordersData,
        error,
        searchVal,
        setSearchVal,
        isLoadingInitialData,
        isLoadingMore,
        total,
        afterCursor,
        loadMore,
        sortBy,
        setSortByVal,
    } = useInfiniteLoading({
        pathName: `${orders.order}/overview`,
        key: 'ordersByTime',
        localeOption: LocaleOptions.path,
    });

    const handleToggleClick = () => {
        setSortByVal(sortBy !== ordersOverviewSortType.deliveryTime ? ordersOverviewSortType.deliveryTime : ordersOverviewSortType.orderTime);
    };

    return (
        <div className={styles.orders}>
            <div className={styles.header}>
                <div className={styles.totalContainer}>
                    <span className={styles.total}>
                        {total} {translate('overview.webOrders', 'webordrer')}
                    </span>
                </div>

                <div className={styles.filterContainer}>
                    <span className={styles.filterLabel}>{translate('overview.sortAfter', 'sorter efter')}:</span>

                    <Toggle
                        isOn={sortBy === ordersOverviewSortType.deliveryTime}
                        onClick={handleToggleClick}
                        onIcon="badge-confirm"
                        onText={translate('overview.order', 'bestilling')}
                        offIcon="semi-truck"
                        offText={translate('overview.delivery', 'levering')}
                    />
                </div>

                <div className={styles.searchContainer}>
                    <SearchInput wrapperClassName={styles.searchInput} value={searchVal} aria-label="Search" setInputValue={setSearchVal} />
                </div>
            </div>

            <OrdersList error={error} orders={ordersData as OrdersTypes.IOrdersOverviewByTime[]} fetching={isLoadingInitialData} sortBy={sortBy} />

            {ordersData?.length ? <OverviewLoadMore total={total} loaded={afterCursor} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </div>
    );
}

export default WithOverviewNavigation(W080OrdersOverview);
