import { useRouter } from 'next/router';
import { Orders as OrderTypes } from '~/models/orders.d';
import { printEndpoints } from '~/services/auth-endpoints';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import Button from '~/shared/buttons/button/button.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IProps = {
    date?: string;
    pdfUrl?: string;
    creditId?: number;
    numberOfProducts?: number;
    total?: OrderTypes.IPriceModel;
    requisition: string;
    status?: string;
    backUrl?: string;
    isSellerOrderNumber?: boolean;
    searchQuery?: string;
};

export default function Header({
    date,
    creditId,
    numberOfProducts,
    backUrl,
    total,
    pdfUrl,
    status,
    requisition,
    isSellerOrderNumber,
    searchQuery,
}: IProps) {
    const translate = useTranslations();
    const { back, push } = useRouter();

    const handleClickBack = () => {
        if (backUrl) {
            push(backUrl);
        } else {
            back();
        }
    };

    return (
        <header>
            <div className={styles.overview}>
                {backUrl && <ButtonCircle iconName="chevron-left" thickIcon onClick={handleClickBack} buttonSize="small" />}
                <Heading tagName="h4" className={styles.heading}>
                    {translate('overview.creditNo', 'Krediterings nr.')}
                    <Text textStyle="bodyLarge" text={creditId?.toString()} highlightText={searchQuery} />
                </Heading>
                <div className={styles.buttons}>
                    {pdfUrl && (
                        <Button
                            buttonSize="small"
                            href={`/api/${pdfUrl}`}
                            target="_blank"
                            download
                            icon={<Svg name="paper-stack" />}
                            buttonStyle="clean"
                        >
                            {translate('common.openPDF', 'Åben PDF')}
                        </Button>
                    )}

                    <Button
                        icon={<Svg name="print" />}
                        buttonSize="small"
                        buttonStyle="clean"
                        href={`${printEndpoints.return}?ids=${isSellerOrderNumber ? 'sellerOrderNumber' : ''}${creditId}`}
                        target="_blank"
                    >
                        {translate('overview.print', 'print')}
                    </Button>
                </div>
            </div>

            <div className={styles.details}>
                {status && (
                    <ValueWithCaption caption={translate('overview.status', 'Status')}>
                        <Text textStyle="bodySmall">{status}</Text>
                    </ValueWithCaption>
                )}

                <ValueWithCaption caption={translate('overview.creditDate', 'Kredit dato')}>
                    <Text textStyle="monoSmall">{date}</Text>
                </ValueWithCaption>

                <ValueWithCaption caption={translate('overview.numberOfProducts', 'antal produkter')}>
                    <Text textStyle="monoSmall">{numberOfProducts}</Text>
                </ValueWithCaption>

                <ValueWithCaption caption={translate('overview.requisitionsNo', 'Rekvisitions nr.')}>
                    <Text textStyle="bodySmall" highlightText={searchQuery}>
                        {requisition}
                    </Text>
                </ValueWithCaption>

                <ValueWithCaption caption={translate('overview.fee', 'Afgifter')}>
                    <Text textStyle="monoMedium" className={styles.price}>
                        {total?.feeFormatted}
                    </Text>
                </ValueWithCaption>

                <ValueWithCaption caption={translate('overview.totalPrice', 'totalpris (DKK)')}>
                    <Text nowrap textStyle="monoMedium" className={styles.price}>
                        {total?.netPriceFormatted}
                    </Text>
                </ValueWithCaption>
            </div>
        </header>
    );
}
