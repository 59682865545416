import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import useResizeObserver from 'use-resize-observer';
import { Svg } from '~/shared/svg';
import useDebounce from '../hooks/debounce.hook';
import { ICarousel, ICarouselItem } from './carousel.model';
import styles from './carousel.module.scss';

const defaultPadding = 30;
const defaultItemWidth = 275;
const defaultItemHeight = 415;

const Carousel: FC<ICarousel> = ({
    padding = defaultPadding,
    itemWidth = defaultItemWidth,
    itemHeight = defaultItemHeight,
    gutter,
    children,
    arrowsInMiddle = false,
}) => {
    const controls = useAnimation();
    const scrollX = useMotionValue(0);
    const [click, setClick] = useState(true);
    const [stateWidth, setStateWidth] = useState(0);
    const width = useDebounce(stateWidth, 100);
    const { ref } = useResizeObserver({
        onResize: ({ width: rectWidth }) => {
            setStateWidth(rectWidth as number);
        },
    });
    const { left, right, content, drag } = useDrag(itemWidth, padding, width, children);
    const canDrag = drag !== false;

    // Keep scroll position up to date with resize event
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => scrollTo(scrollX.get()), [width]);

    // Button animations
    const prevBg = useTransform(scrollX, (value) => (value >= right ? 'var(--color-black-10)' : null));
    const prevPointer = useTransform(scrollX, (value) => (value >= right ? 'none' : null));
    const nextBg = useTransform(scrollX, (value) => (value <= left ? 'var(--color-black-10)' : null));
    const nextPointer = useTransform(scrollX, (value) => (value <= left ? 'none' : null));

    // Button animations when arrow is in the middle

    const prevPointerMiddle = useTransform(scrollX, (value) => (value >= right ? 'auto' : null));
    const nextPointerMiddle = useTransform(scrollX, (value) => (value <= left ? 'auto' : null));

    function previous() {
        scrollTo(scrollX.get() + itemWidth + padding);
    }

    function next() {
        scrollTo(scrollX.get() - itemWidth - padding);
    }

    function scrollTo(newX: number) {
        let x = newX;

        if (canDrag) {
            if (newX > right) {
                x = right;
            }

            if (newX < left) {
                x = left;
            }
        } else {
            x = right;
        }

        controls.start({
            x,
            transition: {
                type: 'spring',
                stiffness: 250,
                damping: 25,
            },
        });
    }

    return (
        <div
            className={clsx(styles.carousel, { [styles.arrowsMiddle]: arrowsInMiddle })}
            style={{
                ['--carousel-gutter' as string]: gutter && `${gutter}px`,
                ['--carousel-padding' as string]: `${padding}px`,
                ['--carousel-item-width' as string]: `${itemWidth}px`,
                ['--carousel-item-height' as string]: `${itemHeight}px`,
            }}
        >
            <motion.div
                ref={ref}
                className={styles.wrapper}
                style={{ cursor: canDrag ? 'grab' : undefined }}
                whileTap={{ cursor: canDrag ? 'grabbing' : undefined }}
            >
                <motion.div
                    drag={drag}
                    dragElastic={0.05}
                    animate={controls}
                    className={styles.items}
                    dragConstraints={{ left, right }}
                    onDragStart={() => setClick(false)}
                    onDragEnd={() => setTimeout(() => setClick(true), 1)}
                    style={{
                        width: content,
                        x: scrollX,
                    }}
                >
                    {React.Children.map(children, (child, index) =>
                        React.cloneElement(child as JSX.Element, {
                            index,
                            click,
                        })
                    )}
                </motion.div>
            </motion.div>

            <motion.button
                type="button"
                onClick={() => previous()}
                style={
                    arrowsInMiddle
                        ? {
                              backgroundColor: prevBg,
                              pointerEvents: prevPointerMiddle,
                          }
                        : {
                              backgroundColor: prevBg,
                              pointerEvents: prevPointer,
                          }
                }
                className={clsx(styles.arrow, styles.left, {
                    [styles.visible]: canDrag,
                    [styles.arrowsMiddle]: arrowsInMiddle,
                })}
            >
                <Svg name="chevron-left" thick className={styles.arrowIcon} />
            </motion.button>

            <motion.button
                type="button"
                onClick={() => next()}
                style={
                    arrowsInMiddle
                        ? {
                              backgroundColor: nextBg,
                              pointerEvents: nextPointerMiddle,
                          }
                        : {
                              backgroundColor: nextBg,
                              pointerEvents: nextPointer,
                          }
                }
                className={clsx(styles.arrow, styles.right, {
                    [styles.visible]: canDrag,
                    [styles.arrowsMiddle]: arrowsInMiddle,
                })}
            >
                <Svg name="chevron-right" thick className={styles.arrowIcon} />
            </motion.button>
        </div>
    );
};

const CarouselItem: FC<ICarouselItem> = ({ children, index, click }) => (
    <div className={styles.item} style={{ ['--carousel-item-index' as string]: index }}>
        {React.cloneElement(children as JSX.Element, { click })}
    </div>
);

const useDrag = (itemWidth: number, padding: number, width: number, children: React.ReactNode) => {
    const numberOfItems = React.Children.count(children);
    const totalWidth = numberOfItems * itemWidth;
    const totalPadding = (numberOfItems - 1) * padding;

    const content = totalWidth + totalPadding;
    const left = -content + width;
    const right = 0;
    const drag: boolean | 'x' = content > width ? 'x' : false;

    return {
        left,
        right,
        content,
        drag,
    };
};

export { CarouselItem };
export default Carousel;
