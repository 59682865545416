import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { CREDIT_DRAFTS } from '~/constants/local.storage';
import { ICreditDraft } from '~/widgets/overview/credits/credit-drafts/credit-drafts.component';
import Loader from '~/shared/loader/loader.component';

export const CREDIT_DRAFT_REQUISITION_KEY = 'ftz:credit-drafts-requisition';

export function useCreditDraftRequisitionStorage() {
    const serializer = useCallback((value: string) => {
        // The passed value was a string, and therefor safe to insert.
        return value;
    }, []);

    const deserializer = useCallback((value: string): string => {
        // We can return the stored value as is, since it will
        // always be a string when coming from local storage.
        return value;
    }, []);

    return useLocalStorage<string>(CREDIT_DRAFT_REQUISITION_KEY, '', {
        serializer,
        deserializer,
    });
}

export type ExternalCreditPageContentProps = {
    requisition: string;
    redirectTo: string;
};

export function ExternalCreditPageContent({ requisition, redirectTo }: ExternalCreditPageContentProps) {
    const router = useRouter();
    const [, setCreditDraftRequisition] = useCreditDraftRequisitionStorage();
    const [, setLocalStorageDrafts] = useLocalStorage<ICreditDraft[]>(CREDIT_DRAFTS, []);

    useEffect(() => {
        setCreditDraftRequisition(requisition);
        setLocalStorageDrafts([]);

        router.replace(redirectTo);
    });

    return <Loader fixed />;
}

export default function ExternalCreditPage() {
    const { isReady: isRouterReady, query, replace } = useRouter();
    const { requisitionNumber, redirectTo } = query;

    if (!isRouterReady) {
        return null;
    }

    // if no redirectTo parameter is defined, we redirect to the frontpage
    const determinedRedirectToUrl = typeof redirectTo === 'string' ? redirectTo : '/';

    if (typeof requisitionNumber !== 'string') {
        // the requisition number was not sent along, so we do the redirect only
        replace(determinedRedirectToUrl);

        return <Loader fixed />;
    }

    return <ExternalCreditPageContent requisition={requisitionNumber} redirectTo={determinedRedirectToUrl} />;
}
