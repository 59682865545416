import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { Products as ProductTypes } from '~/models/products.d';

export function useStockStatusDetails(variantId: string) {
    const { selectedDepartment } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const key = createUrl({
        endpoint: products.getStockStatusDetails,
        localeOption: LocaleOptions.path,
        query: {
            ftzCode: variantId,
            departmentId: selectedDepartment?.id,
        },
    });
    const { isLoggedIn, user } = useUser();

    return useQuery<ProductTypes.IVariantStockInfo, Error>({
        queryKey: queryKeys.products.stockStatusDetails(selectedDepartment?.id, variantId),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(key, { signal });
        },
        enabled: isLoggedIn,
        refetchInterval: 1000 * 30,
        retryDelay: 1000 * 60 * 5,
    });
}
