import { FC } from 'react';
import useReturnDetails from '~/libs/queries/bff/hooks/use-return-details';
import { Bff } from '~/models/bff';
import { CmsData } from '~/models/cms-data.d';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import Case from './case/case.component';
import Header from './header/header.component';
import ProductInfo from './product-info/product-info.component';

// Name before: return-details.component.tsx
const W090ReturnsDetailOverview = ({ queryId }: Bff.IDetailOverviewWidgetViewModel) => {
    const { data, isLoading, isError } = useReturnDetails(queryId);

    if (isLoading) return <OverviewLoader />;
    if (isError) return <OverviewErrorBox />;
    return (
        <div>
            <Header id={data?.id} date={data?.createdDisplayDate} status={data?.status} />
            <Case details={data as CmsData.IReturnDetails} />
            <ProductInfo item={data?.productInfo as CmsData.IReturnDetailsProductInfo} />
        </div>
    );
};

export default WithOverviewNavigation(W090ReturnsDetailOverview);
