import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import Button from '~/shared/buttons/button/button.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import styles from './styled.module.scss';

// Before: user-info.component.tsx
export default function W048InternalUserInfo({ homePage }: Bff.IInternalDashboardUserInfoWidgetViewModel) {
    const translate = useTranslations();
    const { profile } = useUser();

    return (
        <div className={styles.root}>
            <Heading tagName="h2" displayStyle="h2">
                {translate('internalDashboard.greetUser', 'Hej [userName]').replace('[userName]', profile?.name)}
            </Heading>

            <Button className={styles.button} href={homePage} target="_blank" buttonStyle="clean" icon={<Svg name="link-alt" />}>
                {translate('internalDashboard.customerFrontPageLink', 'Vis kundeforside')}
            </Button>
        </div>
    );
}
