import { useEffect, useState } from 'react';

function useRevalidation(shouldFetch: boolean | undefined = true) {
    const [fetching, setFetching] = useState(shouldFetch);
    const [revalidate, setRevalidate] = useState(shouldFetch);

    useEffect(() => {
        if (shouldFetch) {
            setRevalidate(true);
            setFetching(true);
        } else {
            setRevalidate(false);
        }
    }, [shouldFetch]);

    return {
        fetching,
        revalidate,
    };
}

export default useRevalidation;
