import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import { Orders as OrdersType } from '~/models/orders.d';
import useUser from '~/libs/use-user';

export function useUpdateManualWebOrderCount() {
    const { selectedDepartment } = useLayoutState();
    const { orders, createUrl } = useEndpoints();
    const { locale } = useRouter();
    const { user } = useUser();

    const url = createUrl({
        endpoint: orders.getManualWebOrdersCount,
        localeOption: LocaleOptions.path,
        query: { departmentId: selectedDepartment?.id },
    });

    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id),
        mutationFn: async (count: number) => {
            await apiClient.auth(user).post(url, { body: JSON.stringify(count) });
        },
        onMutate: async (count: number) => {
            await queryClient.cancelQueries({ queryKey: queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id) });
            const previousCount = queryClient.getQueryData(queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id));
            queryClient.setQueryData(queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id), count);
            return { previousCount, count };
        },
        onError: (err, count: number, context) => {
            queryClient.setQueryData<OrdersType.IManualOrdersCountDto>(
                [queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id)],
                context,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.users.manualWebOrderCount(locale, selectedDepartment?.id) });
        },
    });
}
