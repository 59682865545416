import React, { FC, useMemo, useState } from 'react';
import { IWithClassName } from '~/models/dev';
import { Products as ProductTypes } from '~/models/products';
import Button, { IButtonProps } from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';
import styles from './cross-numbers.module.scss';
import Splash from '~/shared/splash/splash.component';
import { Modal } from '~/features/primitives/modal';

interface ICrossNumber {
    value?: ProductTypes.ICrossNumber;
    oeMatch?: string;
}

const CrossNumberRow: FC<ICrossNumber> = ({ value, oeMatch }: ICrossNumber) => {
    if (!value?.manufacturer && !value?.oeReferenceNo) {
        return null;
    }

    return (
        <tr>
            <td className={styles.manufacturer}>
                <span>{value.manufacturer}</span>
                {value.oeReferenceNo === oeMatch && <Splash small type="OE" className={styles.originalPartSplash} />}
            </td>
            <td className={styles.tecDocNo}>{value.oeReferenceNo}</td>
        </tr>
    );
};

export interface ICrossNumbersProps extends IWithClassName {
    values?: ProductTypes.ICrossNumber[];
    oeArticleNo?: string;
    children?: React.ReactNode;
    buttonStyle?: IButtonProps['buttonStyle'];
    buttonSize?: IButtonProps['buttonSize'];
    icon?: JSX.Element;
    tooltipText?: string;
    visible?: boolean;
}

const CrossNumbers: FC<ICrossNumbersProps> = ({ className, values, oeArticleNo, children, icon, buttonStyle, buttonSize, tooltipText, visible }) => {
    const translate = useTranslations();
    const [isModalOpen, setModalOpen] = useState(false);

    const handleClick = () => {
        setModalOpen(true);
    };

    const modalMetaHeader = useMemo(() => {
        return <Text textStyle="bodySmall">{`${values?.length ?? 0} ${translate('common.results', 'resultater')}`}</Text>;
    }, [translate, values?.length]);

    return (
        <>
            <Modal
                position="right"
                open={isModalOpen}
                title={translate('product.crossNumber', 'Kryds nr.')}
                meta={modalMetaHeader}
                onOpenChange={setModalOpen}
            >
                <table>
                    <thead>
                        <tr>
                            <th>
                                <span className="title">{translate('product.manufacturer', 'Fabrikant')}</span>
                            </th>
                            <th>
                                <span className="title">{translate('product.crossNumber', 'Kryds nr.')}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {values
                            ?.sort((a, b) => {
                                const aOe = a.oeReferenceNo === oeArticleNo;
                                const bOe = b.oeReferenceNo === oeArticleNo;
                                let sortValue = 0;
                                if (aOe && !bOe) {
                                    sortValue = -1;
                                }
                                if (!aOe && bOe) {
                                    sortValue = 1;
                                }
                                return sortValue;
                            })
                            .map((row, i) => <CrossNumberRow oeMatch={oeArticleNo} value={row} key={`${row.oeReferenceNo}-${i}`} />)}
                    </tbody>
                </table>
            </Modal>
            <Tooltip visible={visible} className={className} content={<TooltipContentWrapper>{tooltipText}</TooltipContentWrapper>}>
                <Button onClick={handleClick} icon={icon} buttonStyle={buttonStyle} buttonSize={buttonSize}>
                    {children}
                </Button>
            </Tooltip>
        </>
    );
};

export default CrossNumbers;
