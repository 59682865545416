// Sass $breakpoints - 1px
export enum MediaQuery {
    XS = '(max-width: 575.98px)',
    SM = '(max-width: 767.98px)',
    MD = '(max-width: 991.98px)',
    LG = '(max-width: 1199.98px)',
    XL = '(min-width: 1439px)',

    // Used for Google Tag Manager
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Mobile = '(max-width: 767.98px)',
    Tablet = '(min-width: 768px) and (max-width: 991.98px)',
    Desktop = '(min-width: 992px) and (max-width: 1199.98px)',
    LargeDesktop = '(min-width: 1200px) and (max-width: 1439.98px)',
    HugeDesktop = '(min-width: 1440px)',
}

// Why subtract .02px?
// Browsers don’t currently support range context queries, so we work around the limitations
// of min- and max- prefixes and viewports with fractional widths (which can occur under
// certain conditions on high-dpi devices, for instance) by using values with higher precision.
