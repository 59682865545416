import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './price.module.scss';
import Skeleton from '~/shared/skeleton';
import { IWithClassName } from '~/models/dev';

export interface IProps extends IWithClassName {
    textDecoration?: 'normal' | 'lineThrough';
    size?: 'small' | 'medium' | 'large';
    color?: 'lightGrey' | 'grey' | 'blue' | 'green' | 'black';
    fetching?: boolean;
    textAlign?: 'left' | 'center' | 'right';
    upperCase?: boolean;
    weight?: 'regular' | 'semiBold' | 'bold';
}

const Price: React.FC<IProps> = ({
    children,
    color = 'blue',
    textDecoration = 'normal',
    size = 'medium',
    textAlign,
    fetching,
    weight = 'regular',
    className,
    upperCase,
}) => {
    const skeletonHeight = useMemo(() => {
        switch (size) {
            case 'small':
                return '12px';
            case 'medium':
                return '13px';
            case 'large':
            default:
                return '18px';
        }
    }, [size]);

    if (fetching) {
        return (
            <Skeleton
                className={className}
                style={{
                    width: '90%',
                    maxWidth: '100px',
                    height: skeletonHeight,
                }}
            />
        );
    }

    return (
        <span
            className={clsx(
                className,
                styles.price,
                styles[color],
                styles[textDecoration],
                styles[size],
                styles[textAlign as string],
                {
                    [styles.upperCase]: upperCase,
                },
                styles[weight]
            )}
        >
            {children}
        </span>
    );
};

export default Price;
