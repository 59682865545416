import { Svg } from '~/shared/svg';
import styles from './styled.module.scss';

interface IProps {
    isOpen: boolean;
    hasChildren: boolean;
    onClick: () => void;
}

export default function CollapseButton({ hasChildren, isOpen, onClick }: IProps) {
    return (
        <div className={styles.wrapper}>
            {hasChildren ? (
                <button aria-label={isOpen ? 'Close' : 'Open'} type="button" className={styles.button} onClick={onClick}>
                    <Svg name="plus" className={isOpen ? styles.iconOpen : styles.icon} thick />
                </button>
            ) : (
                <span className={styles.placeholder} />
            )}
        </div>
    );
}
