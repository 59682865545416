import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Vehicles } from '~/models/vehicles.d';
import useUser from '~/libs/use-user';
import { useLayoutState } from '~/context/layout.context';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';

export function useVehicleModelTypes(modelId?: number | string) {
    const { expertMode } = useLayoutState();
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { vehicles, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: `${vehicles.getModelTypes}/${modelId}/types`,
        localeOption: LocaleOptions.path,
        query: {
            includeExpertVehicles: expertMode,
        },
    });

    return useQuery<Vehicles.ICarSeriesModelTypeList, Error>({
        queryKey: queryKeys.vehicle.vehicleModelTypes(locale, expertMode, modelId),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn && !!modelId,
    });
}
