import clsx from 'clsx';
import React from 'react';
import styles from '~/shared/notification-banner/components/styles.module.scss';
import { Svg } from '~/shared/svg';

type Color = 'white' | 'black';

export type NotificationBannerCloseButtonProps = {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    color?: Color;
};

export function NotificationBannerCloseButton({ onClick, color = 'white' }: NotificationBannerCloseButtonProps) {
    return (
        <button className={styles.closeBtn} type="button" onClick={onClick}>
            <Svg
                name="close"
                thick
                className={clsx({
                    [styles.whiteCloseBtnIcon]: color === 'white',
                    [styles.blackCloseBtnIcon]: color === 'black',
                })}
            />
        </button>
    );
}
