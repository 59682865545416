import { useRouter } from 'next/router';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import styles from './styled.module.scss';

type IHeaderProps = {
    id?: string | number;
    date?: string;
    status?: string;
};

export default function Header({ id, date, status }: IHeaderProps) {
    const { back } = useRouter();
    const translate = useTranslations();

    return (
        <header className={styles.header}>
            <div className={styles.navigation}>
                <button aria-label="Back" type="button" onClick={back} className={styles.backButton}>
                    <Svg name="chevron-left" thick className={styles.backIcon} />
                </button>
                <Heading tagName="h3" displayStyle="h4" className={styles.title}>
                    {translate('returns.caseNo', 'Sags nr.')} {id}
                </Heading>
            </div>
            <div className={styles.additionalInfo}>
                <ValueWithCaption caption={translate('returns.createdAt', 'Oprettet')}>
                    <Text textStyle="monoSmall">{date}</Text>
                </ValueWithCaption>
                <ValueWithCaption caption={translate('returns.status', 'Status')}>
                    <Text textStyle="bodySmall" fontWeight="semiBold">
                        {status}
                    </Text>
                </ValueWithCaption>
            </div>
        </header>
    );
}
