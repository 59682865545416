import { captureException } from '@sentry/nextjs';
import type { HTMLAttributes } from 'react';
import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'usehooks-ts';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import useSimpleVariant from '~/libs/queries/products/hooks/use-simple-variant';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useLabelOrderStorage } from '../use-label-order.hook';

type LabelOrderFormProps = HTMLAttributes<HTMLFormElement>;

type LabelOrderFormValues = {
    itemVariantId: string;
};

//Name Before: page-elements/labels/label-order-form/label-order-form.component.tsx
export default function LabelOrderForm(props: LabelOrderFormProps) {
    const translate = useTranslations();
    const addOrder = useLabelOrderStorage((state) => state.addOrder);
    const dispatch = useLayoutDispatch();
    const {
        clearErrors,
        register,
        setError,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<LabelOrderFormValues>({
        defaultValues: {
            itemVariantId: '',
        },
        mode: 'onBlur',
    });

    const variantId = watch('itemVariantId');
    const debouncedVariantId = useDebounce(variantId, 2000);

    const { refetch } = useSimpleVariant(variantId, {
        enabled: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    const addLabelOrder = useCallback(
        (identifier: string) => {
            addOrder(identifier);
            dispatch(
                addSuccessToast(
                    translate('label.productAddedHeadline', 'Produktet er blevet tilføjet'),
                    translate(
                        'label.productAddedDescription',
                        'Find labelbestillingslisten under Tjenester i venstre menu, og godkend din bestilling.',
                    ),
                ),
            );
        },
        [addOrder, dispatch, translate],
    );

    const addProduct = useCallback(
        async function addProduct() {
            try {
                const variantId = debouncedVariantId.trim();

                if (!variantId) return;

                clearErrors('itemVariantId');

                const { data } = await refetch({ cancelRefetch: true });
                if (!data) {
                    return setError('itemVariantId', {
                        message: translate('label.invalidVariantId', 'Ugyldigt varenr.'),
                    });
                }

                if (!data.ftzCode) {
                    throw new Error('Could not add variant without ftzCode');
                }

                addLabelOrder(data.ftzCode);

                reset();
            } catch (error) {
                captureException(error);
            }
        },
        [addLabelOrder, clearErrors, debouncedVariantId, refetch, reset, setError, translate],
    );

    useEffect(() => {
        if (!debouncedVariantId.length) {
            return;
        }

        addProduct();
    }, [addProduct, debouncedVariantId]);

    return (
        <form onSubmit={handleSubmit(() => '')}>
            <Input
                {...register('itemVariantId')}
                autoComplete="off"
                label={translate('label.addProduct', 'Tilføj nyt product')}
                iconType="quick-scan"
            />
            {errors?.itemVariantId && (
                <ValidationErrorMessage>
                    <>{errors?.itemVariantId.message}</>
                </ValidationErrorMessage>
            )}
        </form>
    );
}
