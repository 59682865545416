import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import useActiveUser from '~/libs/use-active-user';
import type { Products as ProductTypes } from '~/models/products';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { queryKeys } from '../../query-keys';
import useUser from '~/libs/use-user';

const useSimpleVariant = (variantId?: string, config?: UseQueryOptions<ProductTypes.IVariantLight, Error>) => {
    const { locale } = useRouter();
    const { activeProfile } = useActiveUser();
    const { products, createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: products.getProductVariantSimple,
        localeOption: LocaleOptions.path,
        query: {
            ftzCode: variantId,
        },
    });

    return useQuery({
        queryKey: queryKeys.products.simpleVariant(locale as string, variantId, activeProfile?.id),
        queryFn: ({ signal }) => apiClient.auth(user).get(url, { signal }),
        ...config,
    });
};

export default useSimpleVariant;
