import type { CSSProperties } from 'react';

type tagType = Pick<JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4'>;

export default function useResponsiveTypography() {
    const baseFontSize = 16;

    const pxToRem = (pxValue: number | string): string => {
        const value = parseFloat(pxValue.toString());
        return `${value / baseFontSize}rem`;
    };

    /**
     * Get the css clamp value usable for fluid font size
     *
     * @param viewportMinInPx – the viewport at which the minimum size will apply
     * @param viewportMaxInPx – the viewport at which the maximum size will apply
     * @param minSizeInPx – minimum size - Also used for calculating slope
     * @param maxSizeInPx – maximum size at the target viewport
     * @returns e.g. 'clamp(2rem, 1.25vw + 1.5rem, 4.5rem)'
     */
    const getClampValue = (viewportMinInPx: number, viewportMaxInPx: number, minSizeInPx: number, maxSizeInPx: number): string => {
        // Calculate a linear equation based on the 2 points,
        // given by [viewportMinInPx, minSizeInPx] and [viewportMaxInPx, maxSizeInPx].
        // y = m * x + b
        // Visualising tool: https://modern-fluid-typography.vercel.app/

        // Get the slope of the equation
        const m = (minSizeInPx - maxSizeInPx) / (viewportMinInPx - viewportMaxInPx);
        // Get the y-intercept
        const b = minSizeInPx - m * viewportMinInPx;

        const min = pxToRem(minSizeInPx); // used when preferred is less than this value
        const preferred = `${m * 100}vw + ${pxToRem(b)}`; // used when this value is between min and max
        const max = pxToRem(Math.round(m * viewportMaxInPx + b)); // used when preferred is greater than this value

        return `clamp(${min}, ${preferred}, ${max})`;
    };

    /**
     * Function to reduce the amount of arguments needed to send, and create consistent clamp values
     *
     * @param minValue – minimum value at screenwidth 575px
     * @param maxValue – maximum value at screenwidth 1440
     * @returns e.g. 'clamp(2rem, 1.25vw + 1.5rem, 4.5rem)'
     */
    const getClampFullRange = (minValue: number, maxValue: number): string => {
        return getClampValue(575, 1440, minValue, maxValue);
    };

    const getInlineStyles = (as: keyof tagType): CSSProperties => {
        switch (as) {
            case 'h2':
                return {
                    fontSize: getClampFullRange(parseInt('20px'), parseInt('30px')),
                };
            case 'h3':
                return {
                    fontSize: getClampFullRange(parseInt('18px'), parseInt('25px')),
                };
            case 'h4':
                return {
                    fontSize: getClampFullRange(parseInt('12px'), parseInt('18px')),
                };
            case 'h1':
            default:
                return {
                    fontSize: getClampFullRange(parseInt('30px'), parseInt('40px')),
                };
        }
    };

    return {
        getInlineStyles,
    };
}
