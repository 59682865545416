import Heading from '~/shared/heading/heading.component';
import PageTitle from '~/shared/page-title/page-title.component';
import { BasketSubmitManualOrderContainer } from './../../basket-content/components/basket-submit-manual-order/basket-submit-manual-order.container';
import { BasketSubmitContainer } from './../../basket-content/components/basket-submit/basket-submit.container';
import styles from './basket-header.module.scss';
import { BasketHeaderItemsCountContainer } from './components/basket-header-items-count/basket-header-items-count.container';
import { BasketHeaderNextDeliveryContainer } from './components/basket-header-next-delivery/basket-header-next-delivery.container';
import { BasketHeaderPrintBasketContainer } from './components/basket-header-print-basket/basket-header-print-basket.container';
import { BasketHeaderSavedBasketsLinkContainer } from './components/basket-header-saved-baskets-link/basket-header-saved-baskets-link.container';

export type BasketHeaderProps = {
    heading: string;
    showSavedBasketsLink: boolean;
};

export function BasketHeader({ heading, showSavedBasketsLink }: BasketHeaderProps) {
    return (
        <PageTitle className={styles.basketHeaderWrapper}>
            <div className={styles.basketHeaderTitleSection}>
                <div className={styles.basketHeaderTitle}>
                    <Heading tagName="h1" displayStyle="h3">
                        {heading}
                    </Heading>

                    <BasketHeaderItemsCountContainer />
                </div>

                {showSavedBasketsLink ? <BasketHeaderSavedBasketsLinkContainer /> : null}

                <BasketHeaderPrintBasketContainer className={styles.basketHeaderPrintButton} />
            </div>

            <BasketHeaderNextDeliveryContainer />

            <div className={styles.basketHeaderButtonsWrapper}>
                <BasketSubmitContainer />

                <BasketSubmitManualOrderContainer />
            </div>
        </PageTitle>
    );
}
