import { useEndpoints } from '~/services/service-endpoint';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import { Basket as BasketTypes } from '~/models/basket';
import useUser from '~/libs/use-user';
import { useCurrentBasket } from '~/libs/queries/basket';

export function useNextDeliveryForBasket() {
    const { baskets, createUrl } = useEndpoints();
    const { data: basket } = useCurrentBasket();
    const { selectedDepartment } = useLayoutState();
    const { isLoggedIn, user } = useUser();

    const endpoint = createUrl({
        endpoint: baskets.getNextDeliveryForBasket,
        query: {
            basketId: basket?.id,
            departmentId: selectedDepartment?.id,
        },
    });

    return useQuery<BasketTypes.INextDeliveryDto, Error>({
        queryKey: queryKeys.basket.nextDeliveryForBasket(basket?.id, selectedDepartment?.id.toString()),
        queryFn: () => apiClient.auth(user).get(endpoint),
        enabled: isLoggedIn && !!basket?.id,
    });
}
