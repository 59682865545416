import React, { FC } from 'react';
import styles from './vehicle-info.module.scss';
// import InfoCell from './info-cell.component'
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import Text from '~/shared/text/text.component';

interface IVehicleInfoProps {
    vehicle?: VehicleTypes.ICarModelTypeDetails;
}

const VehicleInfo: FC<IVehicleInfoProps> = ({ vehicle }) => {
    const translate = useTranslations();
    return (
        <div className={styles.details}>
            <ValueWithCaption caption={translate('vehicle.ftzTypeNo', 'FTZ Biltype nr.')}>
                <Text textStyle="monoMedium">{vehicle?.ftzTypeNo}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('vehicle.genericArticleId', 'Anvendt gruppe')}>
                <Text textStyle="monoMedium">{vehicle?.genericArticleId}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('vehicle.kwHp', 'KW (HK)')}>
                <Text textStyle="monoMedium">
                    {vehicle?.kw} ({vehicle?.bhp})
                </Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('vehicle.yearFromTo', 'FRA - TIL')}>
                <Text textStyle="monoMedium">
                    {vehicle?.yearFrom} - {vehicle?.yearTo}
                </Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('vehicle.engineCode', 'Motorkode')}>
                <Text textStyle="monoMedium">{vehicle?.engineCodes?.join(', ')}</Text>
            </ValueWithCaption>
            <ValueWithCaption caption={translate('vehicle.carsInDenmark', 'Biler i DK(vejl.)')}>
                <Text textStyle="monoMedium">{vehicle?.carsInDenmark}</Text>
            </ValueWithCaption>
        </div>
    );
};

export default VehicleInfo;
