import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Basket as BasketTypes } from '~/models/basket.d';
import { CmsData } from '~/models/cms-data.d';
import useUser from '~/libs/use-user';
import useActiveUser from '~/libs/use-active-user';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { useCurrentBasket } from '~/libs/queries/basket';

interface IResponse {
    [key: string]: BasketTypes.ILatestInternalSavedBasketsOverview;
}

const useLatestCustomersBaskets = (customerIds: number[]) => {
    const { data: basket } = useCurrentBasket();
    const { isLoggedIn, user, isInternalUser } = useUser();
    const { locale } = useRouter();
    const { activeProfile } = useActiveUser();
    const { baskets, createUrl } = useEndpoints();
    const { selectedDepartment } = useLayoutState();

    const endpoint = createUrl({
        endpoint: baskets.latestCustomersBaskets,
        localeOption: LocaleOptions.query,
        query: { departmentId: selectedDepartment?.id },
    });

    return useQuery<IResponse, CmsData.IError>({
        queryKey: queryKeys.bff.latestCustomers(locale, customerIds, activeProfile?.id, basket?.id),
        queryFn: async () => {
            return apiClient.auth(user).post(endpoint, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(customerIds),
            });
        },
        enabled: isLoggedIn && isInternalUser && !!customerIds.length,
    });
};

export default useLatestCustomersBaskets;
