import React, { FC } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import styles from './collapse-button.module.scss';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';

interface IProps extends IWithClassName {
    textOpen?: string;
    textCollapsed?: string;
    onClick: () => void;
    isOpen?: boolean;
}

const CollapseButton: FC<IProps> = ({ textOpen, textCollapsed, onClick, isOpen = false, className }) => {
    const translate = useTranslations();
    return (
        <div className={styles.buttonWrapper}>
            <button aria-label={isOpen ? 'Collapse' : 'Open'} className={clsx(className, styles.button)} type="button" onClick={onClick}>
                {isOpen ? textOpen || translate('common.showLess', 'Vis mindre') : textCollapsed || translate('common.showMore', 'Vis mere')}
                <Svg
                    name="plus"
                    thick
                    className={clsx(styles.buttonIcon, {
                        [styles.buttonIconCollapsed]: !isOpen,
                    })}
                />
            </button>
        </div>
    );
};

export default CollapseButton;
