import { useFormContext } from 'react-hook-form';
import { Basket as BasketTypes } from '~/models/basket.d';
import Radio from '~/shared/form-elements/radio/radio.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useBasketStates } from '../../../../../use-basket-states';
import styles from './basket-checkout-split-delivery.module.scss';

export function BasketCheckoutSplitDeliveryContainer() {
    const translate = useTranslations();
    const { splitDeliveryOption, setSplitDeliveryOption } = useBasketStates();

    const {
        formState: { errors },
        clearErrors,
        register,
    } = useFormContext();

    const handleChange = (value: BasketTypes.DeliveryType) => {
        clearErrors('splitDeliveryOption');
        setSplitDeliveryOption(value);
    };

    return (
        <div className={styles.basketCheckoutSplitDeliveryOptionsWrapper}>
            <Radio
                {...register('splitDeliveryOption', {
                    required: true,
                })}
                isError={Boolean(errors.splitDeliveryOption)}
                onChange={() => handleChange(BasketTypes.DeliveryType.AllAtOnce)}
                checked={splitDeliveryOption === BasketTypes.DeliveryType.AllAtOnce}
                value={BasketTypes.DeliveryType.AllAtOnce}
                name="split-order"
            >
                {translate('basket.totalDeliveryLabel', 'Levér varerne samlet')}
            </Radio>

            <Radio
                {...register('splitDeliveryOption', {
                    required: true,
                })}
                isError={Boolean(errors.splitDeliveryOption)}
                onChange={() => handleChange(BasketTypes.DeliveryType.SplitDelivery)}
                checked={splitDeliveryOption === BasketTypes.DeliveryType.SplitDelivery}
                value={BasketTypes.DeliveryType.SplitDelivery}
                name="split-order"
            >
                {translate('basket.splitDeliveryLabel', 'Levér varerne hurtigst muligt, opsplit min ordre')}
            </Radio>
        </div>
    );
}
