import React, { FC, useCallback, useState } from 'react';
import { CdnImage } from '~/shared/image/cdn-image';
import { Bff } from '~/models/bff.d';
import { Modal } from '~/features/primitives/modal';
import styles from './light-box-image.module.scss';

interface IProps {
    image?: Bff.IImage;
}

const LightBoxImage: FC<IProps> = ({ image }) => {
    const [lightBoxOpen, setLightBoxOpen] = useState(false);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setLightBoxOpen(false);
    }, []);

    return (
        <>
            <Modal position="center" open={lightBoxOpen} onOpenChange={handleOpenChange}>
                <CdnImage
                    layout="responsive"
                    objectFit="contain"
                    width={1500}
                    height={800}
                    src={image?.url as string}
                    alt={image?.altText as string}
                />
            </Modal>

            <button aria-label={`Image of ${image?.altText}`} type="button" className={styles.button} onClick={() => setLightBoxOpen(true)}>
                <CdnImage
                    // className={styles.image}
                    layout="responsive"
                    objectFit="contain"
                    width={1000}
                    height={300}
                    src={image?.url as string}
                    alt={image?.altText as string}
                />
            </button>
        </>
    );
};

export default LightBoxImage;
