import { Bff } from '~/models/bff';
import WithOverviewNavigation from '../overview-navigation/overview-navigation.hoc';

//Before saved-orders.component.tsx
function W111SavedOrders() {
    return <div>SavedOrders Component placeholder</div>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default WithOverviewNavigation(W111SavedOrders);
