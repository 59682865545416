import React, { FC, useState, useEffect, useRef } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Button from '../buttons/button/button.component';
import { getPercentage } from '~/helpers';
import Progress from '~/shared/progress/progress.component';
import styles from './load-more.module.scss';

export interface ILoadMoreProps {
    onClick: () => void;
    total?: number;
    loaded?: number;
    fetching: boolean | undefined;
}

const LoadMore: FC<ILoadMoreProps> = ({ onClick, total = 0, loaded = 0, fetching }) => {
    const buttonEl = useRef<HTMLButtonElement>(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => setOpacity(1), []);

    const allLoaded = total <= loaded;

    const translate = useTranslations();

    const handleButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        onClick();
        buttonEl?.current?.blur();
    };

    return (
        <div className={styles.loadMore}>
            <p className={styles.text} style={{ opacity }}>
                {translate('common.showLoadedOutOfTotal', 'Viser [loaded] ud af [total]').replace('[loaded]', loaded).replace('[total]', total)}
            </p>
            <div className={styles.progress}>
                <Progress percentage={getPercentage(loaded, total)} />
            </div>
            <Button
                disabled={allLoaded || fetching}
                style={{ opacity: allLoaded ? 0 : 1 }}
                onClick={handleButtonClick}
                buttonStyle="secondary"
                fetching={fetching}
                ref={buttonEl}
            >
                {translate('common.loadMore', 'Indlæs flere')}
            </Button>
        </div>
    );
};

export default LoadMore;
