/* ONLY UMBRACO MODELS */

export namespace Umbraco {
    export enum PageElementTypes {
        rte = 'rte',
        docType = 'docType',
        media = 'media',
    }

    export enum PagesTypes {
        StandardPage = 'standardPage',
        ContentPage = 'contentPage',
        RootPage = 'rootPage',
        ErrorPage = 'errorPage',
    }

    export enum WidgetTypes {
        unknownWidget = 'unknownWidget',
        basketWidget = 'basketWidget',
        vehicleListWidget = 'vehicleListWidget',
        vehicleTypesListWidget = 'vehicleTypesListWidget',
        sparePartCategoryListWidget = 'sparePartCategoryListWidget',
        universalPartCategoryListWidget = 'universalPartCategoryListWidget',
        universalPartProductListWidget = 'universalPartProductListWidget',
        sparePartProductListWidget = 'sparePartProductListWidget',
        sectionHeading = 'sectionHeading',
        contentPageRichText = 'contentPageRichText',
        contentPageImage = 'contentPageImage',
        contentPageLinkContainer = 'contentPageLinkContainer',
        contentPageSmallAuthor = 'contentPageSmallAuthor',
        contentPageDataTable = 'contentPageDataTable',
        contentPageEmployeesList = 'contentPageEmployeesList',
        contentPageSmallRichText = 'contentPageSmallRichText',
        universalPartVariantWidget = 'universalPartVariantWidget',
        sparePartVariantWidget = 'sparePartVariantWidget',
        carBannerWidget = 'carBannerWidget',
        contentPageLoginWidget = 'contentPageLogin',
        searchResultWidget = 'searchResultWidget',
        savedBasketsOverviewWidget = 'savedBasketsOverviewWidget',
        orderOverviewWidget = 'orderOverviewWidget',
        manualWebordersOverviewWidget = 'manualWebordersOverviewWidget',
        invoicesOverviewWidget = 'invoicesOverviewWidget',
        invoiceDetailOverviewWidget = 'invoiceDetailOverviewWidget',
        savedOrdersOverviewWidget = 'savedOrdersOverviewWidget',
        creditsOverviewWidget = 'creditsOverviewWidget',
        creditsDetailOverviewWidget = 'creditsDetailOverviewWidget',
        returnsOverviewWidget = 'returnsOverviewWidget',
        returnsDetailOverviewWidget = 'returnsDetailOverviewWidget',
        chemicalProductsWidget = 'chemicalProductsWidget',
        textAndMedia = 'contentPageTextAndMedia',
        titleIconRichText = 'contentPageTitleAndRichText',
        ticketButlerScriptWidget = 'ticketButlerScript',
        nFLoginWidget = 'nFLoginWidget',
        haynesLoginWidget = 'haynesLoginWidget',
        impersonateWidget = 'impersonateWidget',
        oeSparePartsRequestWidget = 'oeSparePartsRequestWidget',
        favoritesWidget = 'favoritesWidget',
        campaignSpot = 'campaignSpot',
        magazineSpot = 'magazineSpot',
        campaignSliderSpot = 'campaignSliderSpot',
        raptorCampaignSliderSpot = 'raptorCampaignProductSliderSpot',
        ipaper = 'iPaperWidget',
        salesPriceMarkupWidget = 'salesPriceMarkupWidget',
        userAdministrationWidget = 'userAdministrationWidget',
        internalUserInfoFrontpageWidget = 'internalUserInfoFrontpageWidget',
        internalDashboardCustomerSearchWidget = 'internalDashboardCustomerSearchWidget',
        internalLatestCustomersWidget = 'internalLatestCustomersWidget',
        internalLatestOrdersWidget = 'internalLatestOrdersWidget',
        internalDashboardManualOrdersLink = 'internalDashboardManualOrdersLink',
        videoPopupWidget = 'videoPopupWidget',
        chassisSupportRequestWidget = 'chassisSupportRequestWidget',
        replacementEngineRequestWidget = 'replacementEngineRequestWidget',
        vantageDashboardWidget = 'vantageDashboardWidget',
        nestedGrid = 'nestedGrid',
        campaignImageSpot = 'campaignImageSpot',
        campaignHeroSlider = 'campaignHeroSlider',
        labelOrderWidget = 'labelOrdreWidget',
        internalDashboardReturnDepositWidget = 'internalDashboardReturnDepositWidget',
    }

    export interface IUmbracoLink {
        name: string;
        queryString?: string;
        target?: string;
        trashed: boolean;
        udi?: string;
        url: string;
    }

    export interface IUmbracoColor {
        value: string;
    }
}
