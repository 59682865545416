import React, { FC, useCallback, useState } from 'react';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import { Modal } from '~/features/primitives/modal';
import useTranslations from '../hooks/use-translations.hook';
import Content from './content.component';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import AxleInfoComponent from './axle-info/axle-info.component';
import { GA4CarInfoAdditional } from '~/libs/ga4';

interface IProps {
    tooltipText?: boolean;
    carInfoData?: VehicleTypes.IVehicleDetails;
}

type InternalProps = {
    showCalculatorModal: boolean;
    showAxleModal: boolean;
    selectedAxle?: VehicleTypes.IWheelData;
};

const TireCalculator: FC<IProps> = ({ tooltipText, carInfoData }) => {
    const translate = useTranslations();
    const titleText = translate('tireCalculator.tireCalculator', 'Dækberegner');
    const [componentState, setComponentState] = useState<InternalProps>({
        showCalculatorModal: false,
        showAxleModal: false,
        selectedAxle: undefined,
    });

    const handleAxleModalOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setComponentState((prev) => ({ ...prev, showAxleModal: false }));
    }, []);

    const handleCalculatorOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setComponentState((prev) => ({ ...prev, showCalculatorModal: false }));
    }, []);

    return (
        <>
            <Modal position="right" title={titleText} open={componentState.showCalculatorModal} onOpenChange={handleCalculatorOpenChange}>
                <Content axle={componentState.selectedAxle} />
            </Modal>

            {carInfoData ? (
                <Modal
                    title="Vælg aksel:"
                    open={componentState.showAxleModal}
                    size="small"
                    onOpenChange={handleAxleModalOpenChange}
                    position="center"
                >
                    <AxleInfoComponent
                        onAxleSelect={(axleNumber) => {
                            setComponentState((prev) => ({
                                ...prev,
                                showAxleModal: false,
                                selectedAxle: carInfoData?.wheelsData?.find((x) => x.axleNumber === axleNumber),
                                showCalculatorModal: true,
                            }));
                        }}
                        infoList={
                            carInfoData?.wheelsData?.map((x) => ({
                                axleNumber: x.axleNumber,
                                tireWidth: x.tireWidth ?? 0,
                                tireProfile: x.tireProfile ?? 0,
                                rimDiameter: x.rimDiameter ?? 0,
                            })) ?? []
                        }
                    />
                </Modal>
            ) : null}
            <ButtonCircle
                {...(tooltipText ? { tooltipText: titleText } : null)}
                buttonStyle={tooltipText ? 'default' : 'light'}
                iconName="calculator"
                onClick={() => {
                    if (carInfoData?.wheelsData?.length && carInfoData?.wheelsData?.length > 1) {
                        setComponentState((prev) => ({ ...prev, showAxleModal: true }));
                    } else {
                        setComponentState((prev) => ({
                            ...prev,
                            showCalculatorModal: true,
                            selectedAxle: carInfoData?.wheelsData?.[0],
                        }));
                    }

                    GA4CarInfoAdditional('Dækberegner');
                }}
                {...(!tooltipText ? { text: titleText } : null)}
            />
        </>
    );
};

export default TireCalculator;
