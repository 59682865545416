import { FC } from 'react';

import { useMediaQueryDetect } from './media-query-detect.hook';
import { MediaQuery } from './media-query-detect.types';

interface IMediaQueryDetectProps {
    match: MediaQuery;
    children: (isMatch: boolean) => any;
}

export const MediaQueryDetect: FC<IMediaQueryDetectProps> = (props) => {
    const { match, children } = props;
    const isMatch = useMediaQueryDetect(match);

    return children(isMatch) || null;
};
