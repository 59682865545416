import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';
import styles from './styled.module.scss';

export interface IButtonProps {
    className?: string;
}

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<IButtonProps>>(({ children, className }, ref) => {
    return (
        <button ref={ref} type="button" className={clsx([styles.button, className])}>
            {children}
        </button>
    );
});

export default Button;
