import React, { useCallback, useMemo, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { GA4SelectItem } from '~/libs/ga4';
import { IPricesList, getVariantPriceRequest, useProductPrices } from '~/libs/queries/products/hooks/use-product-prices';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { useSimpleVariantsByFtz } from '~/libs/queries/users/hooks/use-simple-variants-by-ftz';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { Products as ProductTypes } from '~/models/products';
import { ProductCardSmall } from '~/page-elements/product-card/product-card-small/product-card-small.component';
import ErrorBox from '~/shared/error-box/error-box';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import { MediaQuery } from '~/shared/media-query-detect';
import PageTitle from '~/shared/page-title/page-title.component';
import { useUniversalProductList } from '~/shared/product-list/product-list.component';
import { SwiperCarousel } from '~/shared/swiper-carousel';
import SwiperCarouselButtons from '~/shared/swiper-carousel/components/buttons/swiper-buttons';
import Dots from '~/shared/swiper-carousel/components/dots/dots.widget';
import styles from './styled.module.scss';
import { slidesPerView, useHasEnoughItems } from './utils';
import { useCurrentBasket } from '~/libs/queries/basket';

//Name before: campaign-slider-spot.component.tsx
export default function M033ProductsCarousel({
    categoryId,
    headline,
    link,
    productList,
    type,
    id,
    campaignSpot,
}: Bff.IM033ProductsCarouselModuleViewModel) {
    if (categoryId) {
        // by category
        return <CampaignSliderSpotCategory categoryId={categoryId} link={link} headline={headline} type={type} id={id} campaignSpot={campaignSpot} />;
    }

    if (productList?.length) {
        // by list of product ids
        return (
            <CampaignSliderSpotProductList
                productList={productList}
                link={link}
                headline={headline}
                type={type}
                id={id}
                campaignSpot={campaignSpot}
            />
        );
    }

    // No products to show
    return null;
}

export type CampaignSliderSpotCategoryProps = {
    categoryId: string;
    link?: Bff.ILinkViewModel;
    headline?: string;
    type?: string;
    id?: string;
    campaignSpot?: string;
};

export function CampaignSliderSpotCategory({ id, type, categoryId, link, headline, campaignSpot }: CampaignSliderSpotCategoryProps) {
    const {
        query: { data: { pages: [{ items = [] } = {}] = [] } = {} },
    } = useUniversalProductList({
        type: 'tile',
        pageSize: 20,
        categoryId,
    });

    const { data: prices } = useProductPrices(
        (items as ProductTypes.IProductTile[]).map<ProductTypes.IArticle>((item) => ({
            articleId: item.itemNo,
            quantity: item.colli,
        })),
    );
    const itemList = items?.map((item) => item.itemNo as string);
    const { data: stockStatus } = useStockStatus(itemList);

    const handleClickTracking = useCampaignSliderSpotClickTracking({
        listName: headline,
        prices,
        componentId: id,
        componentName: type,
        campaignSpot,
    });

    return (
        <CampaignSliderSpotProducts count={(items as ProductTypes.IProductTile[]).length} link={link} headline={headline}>
            {(items as ProductTypes.IProductTile[])?.map((item) => {
                const { id, title, itemNo, brand, colli, colliLocked, images, unit, url = '#' } = item;

                return (
                    <div key={id} className={styles.item}>
                        <div className={styles.innerItem}>
                            <ProductCardSmall
                                item={item}
                                url={url}
                                ftzCode={itemNo}
                                brand={brand}
                                colli={colli}
                                colliLocked={colliLocked}
                                image={images?.[0]}
                                stockStatus={itemNo ? stockStatus?.[itemNo] : undefined}
                                price={itemNo ? prices?.[itemNo] : undefined}
                                unit={unit}
                                title={title}
                                listName={headline}
                                onClick={() => handleClickTracking(item)}
                            />
                        </div>
                    </div>
                );
            })}
        </CampaignSliderSpotProducts>
    );
}

export type CampaignSliderSpotProductListProps = {
    productList: string[];
    link?: Bff.ILinkViewModel;
    headline?: string;
    type?: string;
    id?: string;
    campaignSpot?: string;
};

export function CampaignSliderSpotProductList({ productList, link, headline, type, id, campaignSpot }: CampaignSliderSpotProductListProps) {
    const { data: variants = [] } = useSimpleVariantsByFtz(productList);
    const { data: prices } = useProductPrices(getVariantPriceRequest(variants));
    const { data: stockStatus } = useStockStatus(variants?.map(({ ftzCode }: any) => ftzCode));
    const handleClickTracking = useCampaignSliderSpotClickTracking({
        listName: headline,
        prices,
        componentId: id,
        componentName: type,
        campaignSpot,
    });

    return (
        <CampaignSliderSpotProducts count={variants?.length} link={link} headline={headline}>
            {variants?.map((item: ProductTypes.IVariantLight) => {
                const { id: itemId, title, ftzCode, brand, colli, colliLocked, image, unit, url = '#' } = item;

                return (
                    <div key={itemId} className={styles.item}>
                        <div className={styles.innerItem}>
                            <ProductCardSmall
                                item={item}
                                url={url}
                                ftzCode={ftzCode}
                                brand={brand}
                                colli={colli}
                                colliLocked={colliLocked}
                                image={image}
                                stockStatus={ftzCode ? stockStatus?.[ftzCode] : undefined}
                                price={ftzCode ? prices?.[ftzCode] : undefined}
                                unit={unit}
                                title={title}
                                listName={headline}
                                onClick={() => handleClickTracking(item)}
                                componentId={id}
                                componentName={type}
                                campaignSpot={campaignSpot}
                            />
                        </div>
                    </div>
                );
            })}
        </CampaignSliderSpotProducts>
    );
}

export type CampaignSliderSpotProductsProps = {
    children: React.ReactNode[];
    headline?: string;
    link?: Bff.ILinkViewModel;
    loading?: boolean;
    error?: boolean;
    count: number;
};

export function CampaignSliderSpotProducts({ children, link, headline, count }: CampaignSliderSpotProductsProps) {
    const [cursor, setCursor] = useState<number>(0);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const hasSufficientSlides = useHasEnoughItems(count);
    const onSlideChange = useCallback(
        (swiper: SwiperCore) => {
            setCursor(swiper.realIndex);
        },
        [setCursor],
    );

    const carouselOptions = useMemo(
        () => ({
            breakpoints: {
                320: { slidesPerView: slidesPerView[MediaQuery.Mobile] },
                768: { slidesPerView: slidesPerView[MediaQuery.Tablet] },
                992: { slidesPerView: slidesPerView[MediaQuery.Desktop] },
                1200: { slidesPerView: slidesPerView[MediaQuery.LargeDesktop] },
                1440: { slidesPerView: slidesPerView[MediaQuery.HugeDesktop] },
            },
            spaceBetween: 0,
            centerInsufficientSlides: !hasSufficientSlides,
            loop: hasSufficientSlides,
        }),
        [hasSufficientSlides],
    );

    if (!children.length) {
        return null;
    }

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'minmax(0, 100%)' }}>
            <div className={styles.container}>
                <PageTitle className={styles.header}>
                    {headline?.length ? <Heading tagName="h3">{headline}</Heading> : null}
                    {link && link.url && link.title && (
                        <Link href={link.url} target={link.target}>
                            {link.title}
                        </Link>
                    )}
                </PageTitle>

                <div className={styles.container}>
                    <SwiperCarousel
                        onSlideChange={onSlideChange}
                        prevRef={prevRef}
                        nextRef={nextRef}
                        items={children}
                        optionsOverride={carouselOptions}
                    />

                    <SwiperCarouselButtons prevRef={prevRef} nextRef={nextRef} />
                </div>

                <Dots count={count} cursor={cursor} />
            </div>
        </div>
    );
}

export type UseCampaignSliderSpotProps = {
    prices?: IPricesList;
    listName?: string;
    promotionId?: string;
    componentId?: string;
    componentName?: string;
    campaignSpot?: string;
};

export function useCampaignSliderSpotClickTracking({
    prices,
    listName,
    promotionId,
    componentId,
    componentName,
    campaignSpot,
}: UseCampaignSliderSpotProps) {
    const { data: basket } = useCurrentBasket();
    const { profile } = useUser();

    return (product: ProductTypes.IVariantLight) => {
        const price = product?.ftzCode ? prices?.[product.ftzCode]?.actualPrice?.customerPrice : undefined;

        GA4SelectItem(product, {
            basketId: basket?.id,
            currency: profile?.currency ?? '',
            quantity: 1,
            listName,
            price: price ?? 0,
            promotionId,
            componentId,
            componentName,
            campaignSpot,
        });
    };
}

export function CampaignSliderSpotError() {
    return <ErrorBox>{useTranslations()('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
}
