import React, { FC } from 'react';
import { motion, Variants } from 'framer-motion';
import { EasingFramer, TimingFramer } from '~/constants/animation';
import { IWithClassName } from '~/models/dev';

const defaultVariants: Variants = {
    open: {
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        transitionEnd: {
            overflow: 'visible',
        },
    },
    collapsed: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        transitionEnd: {
            overflow: 'hidden',
        },
    },
};

interface ICollapseProps extends IWithClassName {
    children: React.ReactNode;
    duration?: number;
    ease?: Array<number>;
    isOpen?: boolean;
    style?: React.CSSProperties;
    variants?: Variants;
}

export const Collapse: FC<ICollapseProps> = ({
    children,
    duration = TimingFramer.SLOW,
    ease = EasingFramer.BOUNCE_IN,
    isOpen,
    variants = defaultVariants,
    className,
}) => (
    <motion.div
        initial={isOpen ? 'open' : 'collapsed'}
        animate={isOpen ? 'open' : 'collapsed'}
        className={className}
        transition={{ duration, ease }}
        variants={variants}
    >
        {children}
    </motion.div>
);

export default Collapse;
