import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products.d';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

export function useProductDetails(
    productId?: string | number,
    pictogramInfo?: {
        pictogramId?: string;
        pictogramGroupAlias?: string;
        pictogramGroupType?: string;
    },
) {
    const { expertMode, vehicle } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: `${products.getProductDetails}/${productId}`,
        localeOption: LocaleOptions.path,
        query: {
            carModelTypeId: vehicle?.carId,
            licensePlate: vehicle?.licensePlate?.number,
            licensePlateCountry: vehicle?.licensePlate?.country,
            vin: vehicle?.vin,
            includeExpertProducts: !!expertMode,
            ...pictogramInfo,
        },
    });

    const { isLoggedIn } = useUser();

    return useQuery<ProductTypes.IProduct, Error>({
        queryKey: queryKeys.products.productDetails(
            vehicle?.carId,
            vehicle?.licensePlate?.number,
            vehicle?.licensePlate?.country,
            vehicle?.vin,
            !!expertMode,
            pictogramInfo,
            productId,
        ),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: !!productId && isLoggedIn,
    });
}
