import ErrorBox from '~/shared/error-box/error-box';
import styles from './basket-error.module.scss';

export type BasketErrorProps = {
    errorMessage: string;
};

export function BasketError({ errorMessage }: BasketErrorProps) {
    return (
        <div className={styles.basketErrorWrapper}>
            <ErrorBox>{errorMessage}</ErrorBox>
        </div>
    );
}
