import { useRouter } from 'next/router';
import { ROUTER_QUERY_FUNCTIONS } from '~/constants/query';
import { Bff } from '~/models/bff';
import { Umbraco } from '~/models/umbraco.d';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { ExternalLogin } from './external-login.component';

// Before: haynes-pro.component.tsx
export default function W042HaynesLogin({ pageTitle, labelForLoginName, labelForSaveButton, errorText, successText }: Bff.IHaynesLoginWidget) {
    const translate = useTranslations();

    const { query, back } = useRouter();

    const isDisplayingBackButton = query.fn === ROUTER_QUERY_FUNCTIONS.haynesProPrompt;

    return (
        <>
            <ExternalLogin
                type={Umbraco.WidgetTypes.haynesLoginWidget}
                title={pageTitle}
                userNameLabel={labelForLoginName}
                buttonText={labelForSaveButton}
                errorText={errorText}
                successText={successText}
            />

            {isDisplayingBackButton ? (
                <Button onClick={back} buttonStyle="secondary">
                    {translate('externalLogin.backToCar', 'Tilbage til bil')}
                </Button>
            ) : null}
        </>
    );
}
