import { GA4ExtendPurchaseCall } from '~/libs/ga4';
import { Basket as BasketTypes } from '~/models/basket.d';
import type { Users as UsersTypes } from '~/models/users';
import type { UserProfile } from '~/models/dev';
import { type ConverterItemWithOpts, convertGeneric } from '~/libs/ga4/converters/convert-generic/convert-generic';
import { getCatalogType } from '~/helpers/get-catalog-type';
import { Bff } from '~/models/bff';

/**
 * Translate BasketTypes.DeliveryType to human readable for tracking
 * @param type
 */
export const getDeliveryTypeString = (type: BasketTypes.DeliveryType) => {
    switch (type) {
        case BasketTypes.DeliveryType.AllAtOnce:
            return 'Samlet levering';
        case BasketTypes.DeliveryType.SplitDelivery:
            return 'Dellevering ønskes';

        default:
            return type;
    }
};

export const formatDeliveryDate = (date: Date) => {
    if (!date) {
        return '';
    }

    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}${mo}${da}`;
};

export const getTrackingProducts = (sortedGroups: any[], currency?: string, staticContent?: Bff.IStaticContentV2) => {
    const basketItems: Array<BasketTypes.IBasketItemDto> = sortedGroups.flatMap((g) => g.items);
    const trackingProducts: ConverterItemWithOpts[] = basketItems.map((item, index) => {
        const { count: quantity, price, stockStatus, productLink } = item;

        return {
            opts: {
                index,
                currency,
                price: price?.customerPrice,
                quantity,
                stockStatus: stockStatus?.statusCode,
                productType: getCatalogType(productLink?.url as string, staticContent?.urlMappings?.spareParts),
            },
            ...item,
        } as ConverterItemWithOpts;
    });

    return trackingProducts;
};

const getClientId = (cookie: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const [, , clientId] = /(\d+\.\d+\.)(\d+\.\d+)/.exec(cookie);
        return clientId;
    } catch (err) {
        console.log(`invalid ga cookie [${cookie}]`, err);
    }
    return null;
};

const getGaSessionInfo = (cookie: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const [, sessionId, sessionNumber] = /^GS1\.(\d)\.(\d+)\.\d+\.\d+\.\d+\.\d+\.\d+\.\d+$/.exec(cookie);
        return { sessionId, sessionNumber };
    } catch (err) {
        console.log(`invalid ga sessionid cookie [${cookie}]`, err);
    }

    return {};
};

export const trackPurchaseToExtendApi = async (
    gaCookie: string,
    gaTransactionCookie: string,
    currency: string,
    total: BasketTypes.IPriceModelDto,
    activeProfile: UsersTypes.IBasicUserInfo,
    deliveryDate: string,
    selectedDeliveryMethod: BasketTypes.IDeliveryOptionDto,
    splitDeliveryOption: BasketTypes.DeliveryType,
    profile: UserProfile,
    trackingProducts: ConverterItemWithOpts[],
    transactionId: string,
) => {
    const clientId = getClientId(gaCookie);
    const { sessionId, sessionNumber } = getGaSessionInfo(gaTransactionCookie);

    const customer = activeProfile.customer;

    await GA4ExtendPurchaseCall({
        client_id: clientId,
        user_id: profile.sub as string,
        timestamp_micros: String(Date.now() * 1000), // multiply milliseconds by 1000 to get microseconds
        environment: process.env.NEXT_PUBLIC_ENVIRONMENT as string,
        events: [
            {
                name: 'purchase',
                params: {
                    items: trackingProducts.map(({ opts, ...item }) => convertGeneric(item, opts)),
                    employeeID: profile.employeeId?.toString(),
                    employeeCustomerID: profile.customer_id?.toString() as string,
                    employeeCustomerName: profile.customer_name as string,
                    primaryDepartment: customer?.primaryDepartmentId.toString() as string,
                    shipping: 0,
                    session_id: sessionId,
                    session_number: sessionNumber && parseInt(sessionNumber, 10),
                    tax: total.vat,
                    transaction_id: transactionId,
                    value: total.customerPrice,
                    customerID: customer?.customerId.toString() as string,
                    customerName: customer?.name as string,
                    delivery_date: deliveryDate,
                    delivery_method: selectedDeliveryMethod.displayName as string,
                    delivery_type: splitDeliveryOption,
                    currency,
                },
            },
        ],
    });
};
