import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import { IFormElementCommonProps } from '../common/common';
import Label from '../common/label/label.component';
import styles from './text-area.module.scss';
import ErrorInputIcon from '~/shared/form-elements/common/error-input-icon/error-input-icon.component';
import InputValidation from '../common/input-validation/input-validation.component';

interface ITextareaProps extends InputHTMLAttributes<HTMLTextAreaElement>, IFormElementCommonProps {
    value?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, ITextareaProps>((props, ref): JSX.Element => {
    const {
        value,
        maxLength,
        label,
        name,
        required = false,
        className,
        wrapperClassName,
        isValid = true,
        errorMessage,
        disabled,
        readOnly,
        hideLabel,
        validMessage,
        ...rest
    } = props;

    const isError = !isValid || errorMessage;

    return (
        <div
            className={clsx(wrapperClassName, {
                [styles.readOnly]: readOnly,
            })}
        >
            {label && (
                <Label isHidden={hideLabel} disabled={disabled} name={`TextArea${name}`} required={required}>
                    {label}
                </Label>
            )}
            <div className={styles.inputWrapper}>
                <textarea
                    id={`TextArea${name}`}
                    readOnly={readOnly}
                    disabled={disabled}
                    ref={ref}
                    name={name}
                    className={clsx(className, styles.textArea, {
                        [styles.invalid]: isError,
                    })}
                    maxLength={maxLength}
                    value={value}
                    required={required}
                    {...rest}
                />
                {isError && <ErrorInputIcon />}
            </div>
            <InputValidation errorMessage={errorMessage} value={value?.toString()} maxLength={maxLength} validMessage={validMessage} />
        </div>
    );
});

export default TextArea;
