import { Orders as OrdersTypes } from '~/models/orders';
import OverviewErrorBox from '~/page-elements/overview/common/overview-error-box/overview-error-box.component';
import OverviewLoader from '~/page-elements/overview/common/overview-loader/overview-loader.component';
import OverviewNoResult from '~/page-elements/overview/common/overview-no-results/overview-no-results.component';
import TimeSlot from '../time-slot/time-slot.component';

type IProps = {
    orders: OrdersTypes.IOrdersOverviewByTime[];
    fetching: boolean;
    sortBy: string | null;
    error: Error | undefined;
};

export default function OrdersList({ orders = [], fetching, sortBy, error }: IProps) {
    if (error) return <OverviewErrorBox />;
    if (fetching) return <OverviewLoader />;
    if (!orders?.length) return <OverviewNoResult />;

    return (
        <>
            {orders?.map((timeSlot: OrdersTypes.IOrdersOverviewByTime, i: number) => (
                <TimeSlot timeSlot={timeSlot} key={`${timeSlot.displayDateTimeTitle}-${sortBy}-${i}`} />
            ))}
        </>
    );
}
