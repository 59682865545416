import { useEffect, useState } from 'react';
import { useLayoutState } from '~/context/layout.context';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import Filters from '~/page-elements/overview/common/filters/filters.component';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import Header from '../common/header/header.component';
import CreditDrafts from './credit-drafts/credit-drafts.component';
import CreditList from './credits-list/credits-list.component';

//Name before: credits.component.tsx
function W040CreditsOverview() {
    const { orders } = useEndpoints();
    const { selectedDepartment } = useLayoutState();
    const [endpoint, setEndpoint] = useState<string>(`${orders.credits}/all`);

    const {
        data: credits,
        isLoadingInitialData,
        isLoadingMore,
        total,
        loaded,
        loadMore,
        fromDate,
        toDate,
        setDate,
        setSearchVal,
        searchVal,
        error,
        mutate,
    } = useInfiniteLoading({
        pathName: endpoint,
        localeOption: LocaleOptions.path,
        key: 'credits',
        query: { departmentId: selectedDepartment?.id },
    });

    useEffect(() => {
        setEndpoint(searchVal ? `${orders.credits}/search` : `${orders.credits}/all`);
    }, [searchVal]);

    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();
    const showSavedCredits = shouldShowFeature(features?.credits?.multipleCredits);

    return (
        <>
            {showSavedCredits && <CreditDrafts mutateCredits={mutate} />}
            <Header>
                <Filters fromDate={fromDate} toDate={toDate} setDate={setDate} query={searchVal} setQuery={setSearchVal} hidePageSizeFilter />
            </Header>

            <CreditList searchQuery={searchVal} error={error as Error} credits={credits} fetching={isLoadingInitialData} />

            {credits?.length ? <OverviewLoadMore total={total} loaded={loaded} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </>
    );
}

export default WithOverviewNavigation(W040CreditsOverview);
