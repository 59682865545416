import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import styles from './basket-footer-print-order.module.scss';

export type BasketFooterPrintOrderProps = {
    label: string;
    isChecked: boolean;
    handleChange: () => void;
};

export function BasketFooterPrintOrder({ label, isChecked, handleChange }: BasketFooterPrintOrderProps) {
    return (
        <div className={styles.basketFooterPrintOrderWrapper}>
            <Checkbox checked={isChecked} onChange={handleChange}>
                {label}
            </Checkbox>
        </div>
    );
}
