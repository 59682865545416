import { create } from 'zustand';
import { Basket as BasketTypes } from '~/models/basket.d';

export type BasketStates = {
    isTracked: boolean;
    setIsTracked: (value: boolean) => void;

    isSubmitting: boolean;
    setIsSubmitting: (value: boolean) => void;

    isSaving: boolean;
    setIsSaving: (value: boolean) => void;

    isCheckoutFetching: boolean;
    setIsCheckoutFetching: (value: boolean) => void;

    splitDeliveryOption: BasketTypes.DeliveryType;
    setSplitDeliveryOption: (value: BasketTypes.DeliveryType) => void;

    selectedDeliveryMethod: BasketTypes.IDeliveryOptionDto | undefined;
    setSelectedDeliveryMethod: (value: BasketTypes.IDeliveryOptionDto | undefined) => void;

    isLoading: boolean;
};

export const useBasketStates = create<BasketStates>((set) => ({
    isTracked: false,
    setIsTracked: (value) =>
        set({
            isTracked: value,
        }),

    isSubmitting: false,
    setIsSubmitting: (value) =>
        set({
            isSubmitting: value,
        }),

    isSaving: false,
    setIsSaving: (value) =>
        set({
            isSaving: value,
        }),

    isCheckoutFetching: false,
    setIsCheckoutFetching: (value) =>
        set({
            isCheckoutFetching: value,
        }),

    splitDeliveryOption: BasketTypes.DeliveryType.UnSpecified,
    setSplitDeliveryOption: (value) =>
        set({
            splitDeliveryOption: value,
        }),

    selectedDeliveryMethod: undefined,
    setSelectedDeliveryMethod: (value) =>
        set({
            selectedDeliveryMethod: value,
        }),

    get isLoading() {
        return this.isSubmitting || this.isSaving || this.isCheckoutFetching;
    },
}));
