import { useMemo } from 'react';
import { replaceUrlTokens } from '~/helpers';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import ImageSpot from '~/shared/image-spot/image-spot.component';
import M170HeroSlider from '../M170HeroSlider/M170HeroSlider';
import styles from './styled.module.scss';

// Combination of old components: campaign-image-spot.component.tsx & hero-slider.component
export default function M190ImageSpotHeroSlider({ type, imageSpot, slides, discriminator, id }: Bff.IM190ImageSpotAndHeroModule) {
    const { profile } = useUser();

    const replacedLinks = useMemo(() => {
        if (!slides || !slides.length) {
            return [];
        }

        return slides.map((slide) => {
            if (!profile?.customer_id) {
                return slide;
            }

            return {
                ...slide,
                callToAction: {
                    ...slide.callToAction,
                    url: replaceUrlTokens(slide.callToAction?.url ?? '#', { '##customer_id##': profile?.customer_id }),
                },
            };
        });
    }, [slides, profile?.customer_id]);

    if (!imageSpot || !slides.length || !id) {
        return null;
    }

    return (
        <div className={styles.gridContainer}>
            <div className={styles.imageContainer}>
                <ImageSpot type={type} imageSpot={imageSpot} campaignId={id} />
            </div>

            <div className={styles.carouselContainer}>
                <M170HeroSlider type={type} id={id} slides={replacedLinks} discriminator={discriminator} />
            </div>
        </div>
    );
}
