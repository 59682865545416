import React, { FC, useCallback } from 'react';
import clsx from 'clsx';
import styles from './attribute-group.module.scss';
import { findVariant } from '../variant-picker.helper';
import { Link } from '~/shared/link';
import { Products, Products as ProductTypes } from '~/models/products';

interface IAttributeGroupProps {
    group: ProductTypes.IVariantAttribute;
    setSelectedAttribute: ({ key, value }: { key: string; value: string; variants: number[] }) => void;
    activeVariant: Products.IVariant | null;
    activePossibleVariants: Products.IVariant[] | null;
    product: ProductTypes.IProduct;
    activePrimaryAttribute: Products.IProductAttribute;
    activeFilteredPossibleVariants: ProductTypes.IVariant[];
}

const AttributeGroup: FC<IAttributeGroupProps> = ({
    group,
    setSelectedAttribute,
    activeVariant,
    activePossibleVariants,
    product,
    activePrimaryAttribute,
    activeFilteredPossibleVariants,
}) => {
    const isPrimaryAttribute = useCallback(
        (value: string, key: string) => activePrimaryAttribute && activePrimaryAttribute.value === value && activePrimaryAttribute.key === key,
        [activePrimaryAttribute]
    );

    const isSelected = useCallback(
        (value: string, key: string) =>
            activeVariant && activeVariant.attributes?.find((attribute) => attribute.value === value && attribute.key === key),
        [activeVariant]
    );

    const isPossible = useCallback(
        (value: string, key: string) =>
            activePossibleVariants &&
            activePossibleVariants
                .map((variant) => variant.attributes?.find((attribute) => attribute.value === value && attribute.key === key))
                .filter((element) => element !== undefined).length !== 0,
        [activePossibleVariants]
    );

    const getUrlForAttribute = useCallback(
        (attribute: { key: string; value: string; variants: number[] }) => {
            if (activeFilteredPossibleVariants && activePrimaryAttribute) {
                const { bestFilteredMatch } = findVariant(attribute, product, activePrimaryAttribute, activeFilteredPossibleVariants);

                return bestFilteredMatch.url;
            }
            return product.variants?.[0].url;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activePrimaryAttribute, activeFilteredPossibleVariants]
    );

    return (
        <dl className={styles.attributeGroup}>
            <dt className={styles.attributeGroupTitle}>{group.title}</dt>
            <dd>
                {group?.values?.map((attribute, i) => (
                    <Link
                        key={`${attribute.value}-${i}`}
                        className={clsx(styles.attribute, {
                            [styles.primaryAttribute]: isPrimaryAttribute(attribute.value as string, group.key as string),
                            [styles.possibleChoice]: isPossible(attribute.value as string, group.key as string),
                            [styles.selected]: isSelected(attribute.value as string, group.key as string),
                        })}
                        href={getUrlForAttribute({
                            key: group.key as string,
                            value: attribute.value as string,
                            variants: attribute.variants as number[],
                        })}
                        onClick={(event: any) => {
                            event.preventDefault();
                            setSelectedAttribute({
                                key: group.key as string,
                                value: attribute.value as string,
                                variants: attribute.variants as number[],
                            });
                        }}
                    >
                        {attribute.value}
                    </Link>
                ))}
            </dd>
        </dl>
    );
};

export default AttributeGroup;
