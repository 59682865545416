import useActiveUser from '~/libs/use-active-user';
import Loader from '~/shared/loader/loader.component';
import { TopmotiveRequestForm } from '~/shared/topmotive/components';

export function TopmotiveRequestFormContainer() {
    const { activeProfile } = useActiveUser();

    if (!activeProfile) {
        return <Loader />;
    }

    return <TopmotiveRequestForm profile={activeProfile} />;
}
