import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './error-report-button.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { IWithClassName } from '~/models/dev';
import { ErrorReportPayload, LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';

interface IErrorReportButtonProps extends IWithClassName {
    onCLick?: () => void;
    data?: ErrorReportPayload;
}

const ErrorReportButton: FC<IErrorReportButtonProps> = ({ className, data = {}, children }) => {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const handleClick = () => {
        dispatch({
            type: LayoutActionTypes.OpenErrorReport,
            payload: { ...data },
        });
    };

    return (
        <button type="button" className={clsx(styles.reportErrorBtn, className)} onClick={handleClick}>
            {translate('errorReport.reportError', 'Meld fejl')} {children}
        </button>
    );
};

export default ErrorReportButton;
