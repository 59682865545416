import cx from 'clsx';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';

import styles from './topmotive-product-details.module.scss';

export type TopmotiveProductDetailsProps = {
    className?: string;
    name: string;
    id: string;
};

export function TopmotiveProductDetails({ className, id, name }: TopmotiveProductDetailsProps) {
    const translate = useTranslations();

    return (
        <div className={cx(className, styles.topmotiveProductDetails)}>
            <span>
                <Svg className={styles.topmotiveProductDetailsIcon} width={24} height={24} name="badge-confirm" />
                {name}
            </span>
            <span>{translate('topmotive.request.productId', 'OE-reference: {{id}}').replace('{{id}}', id)}</span>
        </div>
    );
}
