import { motion } from 'framer-motion';
import { IWithClassName } from '~/models/dev';

export interface IProps extends IWithClassName {
    motionKey: string;
    duration?: number;
    children: React.ReactNode;
}

const Fader = ({ motionKey, duration = 0.2, children, className }: IProps) => (
    <motion.div
        className={className}
        key={motionKey}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration }}
    >
        {children}
    </motion.div>
);

export default Fader;
