import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './expert-toggle.module.scss';
import { useLayoutDispatch, useLayoutState, LayoutActionTypes } from '~/context/layout.context';
import Toggle, { ToggleType } from '~/shared/toggle/toggle.component';
import { IWithClassName } from '~/models/dev';
import Text from '~/shared/text/text.component';
import useTranslations from '../hooks/use-translations.hook';

interface IExpertToggleProps extends IWithClassName {
    textClassName?: string;
}

const ExpertToggle: FC<IExpertToggleProps> = ({ className, textClassName }) => {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const { expertMode } = useLayoutState();

    return (
        <div className={clsx(styles.wrapper, className)}>
            <Text tagName="span" textStyle="bodySmall" className={clsx(styles.text, textClassName)}>
                {translate('expert.expertMode', 'Expert Mode')}
            </Text>

            <Toggle
                toggleType={ToggleType.expertMode}
                isOn={!!expertMode}
                onClick={() =>
                    dispatch({
                        type: LayoutActionTypes.SetExpertMode,
                        payload: !expertMode,
                    })
                }
                onIcon="visibility-off"
                offIcon="visibility"
            />
        </div>
    );
};

export default ExpertToggle;
