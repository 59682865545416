import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { addErrorToast, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import useTranslations from '~/shared/hooks/use-translations.hook';

interface IProps {
    basketId: string;
    carId?: string | number;
    licensePlate?: VehicleTypes.ILicensePlate;
}

const useVantageDashboard = () => {
    const { selectedDepartment } = useLayoutState();
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();

    const { createUrl, baskets } = useEndpoints();

    const openVantageDashboard = ({ basketId, carId, licensePlate }: IProps) => {
        if (!basketId) {
            return dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        }

        const link = createUrl({
            endpoint: baskets?.vantageDashboard,
            localeOption: LocaleOptions.omit,
            query: {
                basketId,
                carId,
                licensePlateCountry: licensePlate?.country === 'D' ? null : licensePlate?.country,
                licensePlateNumber: licensePlate?.country === 'D' ? null : licensePlate?.number,
                departmentId: selectedDepartment?.id,
            },
        });

        window.open(link, '_blank');
    };

    return { openVantageDashboard };
};

export default useVantageDashboard;
