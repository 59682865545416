import { Orders as OrderTypes } from '~/models/orders';

export enum createStatus {
    initial,
    error,
    created,
    notCreated,
}

export interface ICreateCreditItem extends OrderTypes.IInvoiceItem {
    carInfo?: OrderTypes.ICarInfo;
}

export interface ICreateCreditProps {
    items: ICreateCreditItem[];
    invoiceDate: string;
    invoiceId: number | string;
    carInfo?: OrderTypes.ICarInfo;
}

export interface ICreditItem extends ICreateCreditItem {
    selectedQuantity: number;
    depositOnly: boolean;
    quantityWarn: boolean;
}
