import { captureException } from '@sentry/nextjs';
import type { HTMLAttributes } from 'react';
import { FC, useMemo } from 'react';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import Button from '~/shared/buttons/button/button.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { useLabelOrderMutation, useLabelOrderStorage } from './use-label-order.hook';
import LabelOrderForm from './label-order-form/label-order-form.component';
import LabelOrderList from './label-order-list/label-order-list.component';
import { Bff } from '~/models/bff';

//Name Before: label-order.component.tsx
export default function W062LabelOrder(props: Bff.IModuleViewModel) {
    const translate = useTranslations();
    const labelOrders = useLabelOrderStorage((state) => state.orders);
    const resetStorage = useLabelOrderStorage((state) => state.reset);
    const dispatch = useLayoutDispatch();

    const orderMutation = useLabelOrderMutation({ labelOrders });

    const hasLabelOrders = useMemo(() => !!labelOrders.length, [labelOrders]);

    const handleSubmit = async () => {
        try {
            await orderMutation.mutateAsync();

            dispatch(
                addSuccessToast(
                    translate('label.labelsOrderedHeadline', 'Dine labels er blevet bestilt'),
                    translate(
                        'label.labelsOrderedDescription',
                        'Labels printes og afsendes fra centrallageret i Odense, så forvent levering inden for 2 hverdage.',
                    ),
                ),
            );
            resetStorage();
        } catch (error) {
            captureException(error);
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        }
    };

    return (
        <>
            <Heading className={styles.headline} tagName="h1">
                {translate('label.headline', 'Labelbestilling')}
            </Heading>
            <div className={styles.container}>
                <div className={styles.header}>
                    <LabelOrderForm />
                    <Button disabled={!hasLabelOrders} onClick={handleSubmit} fetching={orderMutation.isLoading}>
                        {translate('common.order', 'Bestil')}
                    </Button>
                </div>
                <LabelOrderList className={styles.list} />
            </div>
        </>
    );
}
