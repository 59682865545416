import { useVehicleDetails } from '~/libs/queries/vehicles/hooks/use-vehicle-details';
import useActiveUser from '~/libs/use-active-user';
import { Bff } from '~/models/bff';
import { Vehicles } from '~/models/vehicles';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import PageTitle from '~/shared/page-title/page-title.component';
import ChassisForm from './chassis-form/chassis-form.component';

// Old component: car-chassis-support.component.tsx
export default function W010CarChassisSupport({ licensePlate, carId, vin }: Bff.IBaseCarDetailsWidget) {
    const translate = useTranslations();

    const { activeProfile, isLoading: isLoadingUser, error: userError } = useActiveUser();

    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicle,
        error: vehicleError,
    } = useVehicleDetails(licensePlate as Vehicles.ILicensePlate, carId as string, vin as string);

    const {
        email,
        userName,
        customer: { customerId = null, name: companyName = '', address = '', zipCodeAndCity = '', phoneNumber = '' } = {},
    } = activeProfile || {};

    const isLoading = isLoadingUser || isLoadingVehicle;
    const error = vehicleError || userError;

    return (
        <>
            <PageTitle>
                <Heading tagName="h1" displayStyle="h3">
                    {translate('chassisSupport.chassisSupport', 'Karrosseriteknisk Support')}
                </Heading>
            </PageTitle>
            {isLoading && <Loader padding="50px" />}
            {!isLoading && !error && (
                <ChassisForm
                    customerDetails={{
                        email,
                        customerId: customerId as number,
                        companyName,
                        phoneNumber,
                        userName,
                        address,
                        zipCodeAndCity,
                    }}
                    vehicleDetails={vehicleDetails as Vehicles.IVehicleDetails}
                />
            )}
        </>
    );
}
