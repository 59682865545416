import { useLayoutState } from '~/context/layout.context';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Products as ProductTypes } from '~/models/products.d';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';

interface IUseVariantVehicleInfoProps {
    carId: string;
    ftzCodes: string[];
    shouldFetch: boolean;
}

export function useVariantVehicleInfo(props: IUseVariantVehicleInfoProps) {
    const { carId, ftzCodes, shouldFetch } = props;
    const { user, isLoggedIn } = useUser();
    const { expertMode } = useLayoutState();
    const { products, createUrl } = useEndpoints();
    const { locale } = useRouter();
    const endpoint = createUrl({
        endpoint: products.getVariantVehicleInfo,
        localeOption: LocaleOptions.path,
        query: { includeExpertProducts: expertMode },
    });

    return useQuery<ProductTypes.IVariantLight[], Error>({
        queryKey: queryKeys.vehicle.variantVehicleInfo(locale, ftzCodes, carId, expertMode),
        queryFn: ({ signal }) => {
            return apiClient.auth(user).postJSON(
                endpoint,
                {
                    carModelTypeId: carId,
                    ftzCodes,
                },
                { signal },
            );
        },
        enabled: isLoggedIn && !!ftzCodes && shouldFetch && !!carId,
    });
}
