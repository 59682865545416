import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4NewsletterSignup } from '~/libs/ga4';
import useEmailSignUp from '~/libs/queries/bff/hooks/use-email-sign-up';
import { IWithClassName } from '~/models/dev';
import RawHtmlComponent from '~/page-elements/raw-html/raw-html.component';
import Button from '~/shared/buttons/button/button.component';
import Input from '~/shared/form-elements/input/input.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ErrorBox from '../error-box/error-box';
import Checkbox from '../form-elements/checkbox/checkbox.component';
import styles from './email-sign-up-form.module.scss';

type EmailSignUpFormProps = IWithClassName & {
    primaryText?: string;
    text?: string;
};

interface EmailSignUpFormFormFields {
    email: string;
    permission: boolean;
}

export default function EmailSignUpForm({ className, primaryText, text }: EmailSignUpFormProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const { register, handleSubmit, reset, watch, setError, control, formState } = useForm<EmailSignUpFormFormFields>({
        defaultValues: {
            email: '',
            permission: false,
        },
        mode: 'onBlur',
    });

    const isPermissionGiven = watch('permission');

    const { mutate, isLoading } = useEmailSignUp();

    const onSubmit = async (fields: EmailSignUpFormFormFields) => {
        if (isLoading || !fields.permission) {
            return;
        }

        mutate(
            { email: fields.email },
            {
                onSuccess: () => {
                    dispatch(addSuccessToast(translate('signUpForm.success', 'Tak for din tilmelding!')));
                    GA4NewsletterSignup();
                    reset();
                },
                onError: () => {
                    setError('email', { message: translate('common.somethingWentWrong', 'Der gik noget galt. Prøv igen.') });
                },
            },
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={clsx(styles.formWrapper, className)}>
            <Text color="primaryColor" textAlign="center" tagName="h3" fontWeight="bold" className={styles.primaryText}>
                {primaryText}
            </Text>
            <Text color="primaryColor" textAlign="center" fontWeight="regular" className={styles.text}>
                {text}
            </Text>

            <div className={styles.inputWrapper}>
                <Input
                    {...register('email')}
                    type="email"
                    name="email"
                    className={styles.inputPlaceholder}
                    aria-label={translate('signUpForm.placeholderEmail', 'E-mail')}
                    wrapperClassName={styles.input}
                    placeholder={translate('signUpForm.email', 'Din email')}
                />
                <Button
                    disabled={isLoading || !isPermissionGiven}
                    type="submit"
                    buttonStyle="tertiary"
                    buttonSize="default"
                    className={styles.button}
                >
                    {translate('signUpForm.submitButton', 'Tilmeld')}
                </Button>
            </div>

            <Checkbox
                background="white"
                {...register('permission', {
                    required: true,
                })}
            >
                <RawHtmlComponent
                    className={styles.checkboxText}
                    content={translate('signUpForm.emailConsent', 'Jeg giver samtykke til, at FTZ må sende mig emails med tilbud og nyheder.')}
                />
            </Checkbox>

            {/* TODO: Add translations*/}
            {formState.errors.permission && (
                <ErrorBox>{translate('signUpForm.errors.permission', 'Der gik noget galt. Giv venligst samtykke.')}</ErrorBox>
            )}
            {formState.errors.email && (
                <ErrorBox>{translate('signUpForm.errors.emai', 'Der gik noget galt. Tjek din mail udfylder kriterierne.')}</ErrorBox>
            )}
        </form>
    );
}
