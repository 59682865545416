import { useMutation } from '@tanstack/react-query';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { LABEL_ORDERS } from '~/constants/local.storage';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, endpoints, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';

interface LabelOrder {
    orders: string[];
    addOrder: (id: string) => void;
    removeOrder: (id: string) => void;
    reset: () => void;
}

//Name Before: use-label-order.hook.ts
export const useLabelOrderStorage = create<LabelOrder>()(
    persist(
        (set) => ({
            orders: [],
            addOrder: (id) => set((state) => ({ orders: state.orders.includes(id) ? state.orders : [...state.orders, id] })),
            removeOrder: (id) => set((state) => ({ orders: state.orders.filter((item) => item !== id) })),
            reset: () => set({ orders: [] }),
        }),
        {
            name: LABEL_ORDERS,
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export type UseLabelOrderProps = {
    labelOrders: string[];
};

export function useLabelOrderMutation({ labelOrders }: UseLabelOrderProps) {
    const { createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: endpoints.products.submitLabelOrder,
        localeOption: LocaleOptions.path,
    });

    return useMutation(() => {
        return apiClient.auth(user).postJSON(url, labelOrders);
    });
}
