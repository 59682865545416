import React, { FC } from 'react';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './brand-logo.module.scss';
import { Products as ProductTypes } from '~/models/products.d';

type ImgElementStyle = NonNullable<JSX.IntrinsicElements['img']['style']>;
interface IBrandLogo {
    brand?: ProductTypes.IBrand;
    height?: number;
    width?: number;
    objectPosition?: ImgElementStyle['objectPosition'];
}

const BrandLogo: FC<IBrandLogo> = ({ brand = null, height = 20, width = 64, objectPosition = 'left center' }) => {
    if (brand?.image?.url) {
        return (
            <div style={{ height, width }}>
                <CdnImage
                    layout="fixed"
                    height={height}
                    width={width}
                    src={brand.image?.url}
                    alt={brand.image?.altText}
                    objectFit="contain"
                    objectPosition={objectPosition}
                    draggable={false}
                />
            </div>
        );
    }

    if (brand?.name) {
        return <span className={styles.brandName}>{brand.name}</span>;
    }

    return null;
};

export default BrandLogo;
