import React from 'react';
import NoResults from '~/shared/no-results/no-results.component';
import useTranslations from '~/shared/hooks/use-translations.hook';

const OverviewNoResults: React.FC = () => {
    const translate = useTranslations();

    return <NoResults>{translate('common.noResultsForSelectedPeriod', 'Der er ingen resultater inden for de valgte periode')}</NoResults>;
};

export default OverviewNoResults;
