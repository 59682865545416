import { IWithClassName } from '~/models/dev.d';
import { Link } from '~/shared/link';
import styles from './basket-header-saved-baskets-link.module.scss';

export type BasketHeaderSavedBasketsLinkProps = IWithClassName & {
    url: string | undefined;
    label: string;
};

export function BasketHeaderSavedBasketsLink({ url, label }: BasketHeaderSavedBasketsLinkProps) {
    return (
        <Link href={url} className={styles.basketHeaderSavedBasketsLink}>
            {label}
        </Link>
    );
}
