import React, { useMemo } from 'react';
import SwiperCore, { A11y, Autoplay, Keyboard, Mousewheel, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { useSwiperCarousel } from '../..';
import styles from './swiper-carousel.module.scss';

SwiperCore.use([A11y, Navigation, Mousewheel, Pagination, Keyboard, Autoplay]);

export interface CarouselProps {
    /**
     * A list of items to be shown in a slide
     */
    items: React.ReactNode[];
    /**
     * Add reference to prev button
     */
    prevRef?: React.RefObject<HTMLButtonElement>;
    /**
     * Add reference to next button
     */
    nextRef?: React.RefObject<HTMLButtonElement>;
    /**
     * Override default swiper options
     */
    optionsOverride?: SwiperOptions;
    /**
     * Get Swiper instance
     */
    onSwiper?: Swiper['onSwiper'];
    /**
     * Event fired when slides changes
     */
    onSlideChange?: (swiper: SwiperCore) => void;
}

export const SwiperCarousel = ({
    items,
    prevRef: initialPrevRef,
    nextRef: initialNextRef,
    optionsOverride,
    onSwiper,
    onSlideChange,
}: CarouselProps) => {
    const { element: prevEl } = useSwiperCarousel({ ref: initialPrevRef });
    const { element: nextEl } = useSwiperCarousel({ ref: initialNextRef });

    const config = useMemo<SwiperOptions>(
        () => ({
            slidesPerView: 'auto',
            spaceBetween: 25,
            watchSlidesProgress: true,
            watchOverflow: true,
            speed: 200,
            mousewheel: {
                forceToAxis: true,
                thresholdDelta: 6,
            },
            a11y: {
                slideRole: 'listitem',
                prevSlideMessage: 'Previous carousel item',
                nextSlideMessage: 'Next carousel item',
            },
            navigation: {
                prevEl,
                nextEl,
            },
            pagination: false,
            onSlideChange,
            ...optionsOverride,
        }),
        [prevEl, nextEl, optionsOverride],
    );

    return (
        <div className={styles.swiperCarousel}>
            <Swiper role="list" onSwiper={onSwiper} {...config}>
                {items.map((item, index) => (
                    <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
