import React, { FC } from 'react';
import clsx from 'clsx';
import { Products as ProductTypes } from '~/models/products.d';
import { Svg } from '~/shared/svg';
import styles from './tire-properties.module.scss';
import Text from '~/shared/text/text.component';

interface ICategoryIconProps {
    category: ProductTypes.TireCategoryIcon | undefined;
}

const CategoryIcon: FC<ICategoryIconProps> = ({ category }) => {
    switch (category) {
        case ProductTypes.TireCategoryIcon.Summer:
            return <Svg name="summer" className={styles.categoryIconSummer} />;
        case ProductTypes.TireCategoryIcon.Winter:
            return <Svg name="winter" className={styles.categoryIconWinter} />;
        case ProductTypes.TireCategoryIcon.AllYear:
            return <Svg name="allYear" className={styles.svgIcon} />;

        default:
            return <></>;
    }
};

interface IProps {
    properties: ProductTypes.ITireTileProperties;
}

const TireProperties: FC<IProps> = ({ properties }) => {
    const mapNoiseLabel = (noiseIndicator: string) => {
        switch (noiseIndicator) {
            case '1':
            case 'A':
                return 'A';
            case '2':
            case 'B':
                return 'B';
            case '3':
            case 'C':
                return 'C';
            default:
                return '-';
        }
    };

    const noiseLabel = mapNoiseLabel(properties?.noiceIndicator as string);

    return (
        <div className={styles.container}>
            <div className={styles.additionalProperties}>
                <div className={styles.categoryIconWrapper}>
                    <CategoryIcon category={properties?.categoryIcon} />
                </div>
                {properties?.hasOE && (
                    <div className={styles.additionalProperty}>
                        <Svg name="confirm" className={styles.svgIcon} />
                        <Text textStyle="monoMedium" tagName="span" className={styles.additionalPropertyText}>
                            OE
                        </Text>
                    </div>
                )}
                {properties?.runFlat && (
                    <div className={styles.additionalProperty}>
                        <Svg name="run-flat" className={styles.svgIcon} />
                        <Text textStyle="monoMedium" tagName="span" className={styles.additionalPropertyText}>
                            RFT
                        </Text>
                    </div>
                )}
                {properties?.extraLoad && (
                    <div className={styles.additionalProperty}>
                        <Svg name="weight" className={styles.svgIcon} />
                        <Text textStyle="monoMedium" tagName="span" className={styles.additionalPropertyText}>
                            XL
                        </Text>
                    </div>
                )}
                {properties?.electricVehicle && (
                    <div className={styles.additionalProperty}>
                        <Svg name="electric-vehicle" className={styles.svgIcon} />
                        <Text textStyle="monoMedium" tagName="span" className={styles.additionalPropertyText}>
                            EV
                        </Text>
                    </div>
                )}
            </div>
            <div className={styles.propertyCell}>
                <Svg name="fuel" className={styles.labelIcon} />
                <span className={clsx(styles.labelBadge, styles[`labelFuel${properties?.fuel}`])}>{properties?.fuel}</span>
            </div>
            <div className={styles.propertyCell}>
                <Svg name="loudness" className={styles.labelIcon} />
                <span className={clsx(styles.labelBadge, styles[`labelNoise${noiseLabel}`])}>{noiseLabel}</span>
                {properties.noiceDb && <Text textStyle="monoMedium">{properties.noiceDb} DB</Text>}
            </div>
            <div className={styles.propertyCell}>
                <Svg name="weather" className={styles.labelIcon} />
                <span className={clsx(styles.labelBadge, styles[`labelGrip${properties?.grip}`])}>{properties?.grip}</span>
            </div>
            <div className={styles.propertyCell}>
                {properties?.winterGrip && <Svg name="winter-grip" className={styles.gripIcon} />}
                {properties?.iceGrip && <Svg name="ice-grip" className={styles.gripIcon} />}
            </div>
            <div />
        </div>
    );
};

export default TireProperties;
