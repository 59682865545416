import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import { getFormData } from '~/helpers';
import useUser from '~/libs/use-user';
import { CarChassisRequest } from '~/services/product/car-chassis-request.service';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Label from '~/shared/form-elements/common/label/label.component';
import ValidationErrorMessage from '~/shared/form-elements/common/validation-error-message/validation-error-message.component';
import FileInput from '~/shared/form-elements/file-input/file-input.component';
import Input from '~/shared/form-elements/input/input.component';
import TextArea from '~/shared/form-elements/text-area/text-area.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import { IChassisFormDataType, IChassisProps } from '../types';
import styles from './styled.module.scss';

export default function ChassisForm({ vehicleDetails, customerDetails = {} }: IChassisProps) {
    const acceptedFiles = ['.jpg', '.png', '.bmp', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.gif', '.zip', '.rar'];
    const translate = useTranslations();
    const [fetching, setFetching] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const { locale } = useRouter();
    const { user } = useUser();
    const dispatch = useLayoutDispatch();
    const maxFilesSizeForEmail = files.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);
    const emailSizeWarning = maxFilesSizeForEmail >= 25 * 1024 * 1024; /* 25mb limit*/
    const disableSubmit = emailSizeWarning;
    // const urgentRadioButtonError = translate(
    //   'chassisSupport.urgentRadioButtonError',
    //   'Vælg venligst typen'
    // )
    const {
        clearErrors,
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();

    const { email, userName, customerId, companyName, phoneNumber, address, zipCodeAndCity } = customerDetails;

    const { licensePlate, manufacturerName, modelName, modelTypeId, modelTypeName, typeYearTo, typeYearFrom } = vehicleDetails;

    const onSubmit = async (postData: IChassisFormDataType) => {
        if (disableSubmit) return;
        reset({
            MustHaveAnswersToday: false,
            IsVeryUrgent: false,
        });

        if (!postData.RegNr) {
            setError('carDataError', {
                type: 'manual',
                message: translate('chassisSupport.carInfoValidation', 'Udfyld Reg. Nr'),
            });
            return;
        }
        setFetching(true);

        const formData = getFormData(postData);
        files.forEach((file) => formData.append('Files', file));

        try {
            const { errorMessage } = await CarChassisRequest(formData, locale as string, user);
            if (!errorMessage) {
                dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                setFetching(false);
                window.setTimeout(() => {
                    window.close();
                }, 3000);
            } else {
                dispatch(addErrorToast(errorMessage));
            }
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        } finally {
            setFetching(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formGrid}>
                <fieldset className={styles.uploadCol}>
                    <legend>{translate('sharedFormTranslations.uploadFiles', 'Upload filer')}</legend>
                    <Text textStyle="bodySmall">
                        {translate(
                            'sharedFormTranslations.uploadFileLimitation',
                            'Max 3 stk. i format .jpg, .png, .bmp, .pdf, .xls, .xlsx, .doc, .docx eller .gif. Må ikke fylde mere end 25 MB hver.',
                        )}
                    </Text>
                    <FileInput
                        errorMessage={
                            emailSizeWarning
                                ? translate(
                                      'sharedFormTranslations.emailSizeWaring',
                                      'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                                  )
                                : null
                        }
                        accept={acceptedFiles.join(', ')}
                        acceptedFileExtensions={acceptedFiles}
                        maxFileSize={25 * 1024 * 1024 /* 25mb limit per file*/}
                        onFilesChange={setFiles}
                    />
                </fieldset>
            </div>
            <fieldset>
                <legend>{translate('chassisSupport.fillOutInputFields', 'Udfyld felterne herunder')}</legend>
                <div className={styles.formGrid}>
                    <div className={styles.infoCol}>
                        <Input
                            {...register('CustomerNumber')}
                            wrapperClassName={styles.textInput}
                            label={translate('customer.customerId', 'Kunde nr')}
                            defaultValue={customerId}
                        />
                        <Label required>{translate('customer.email', 'E-mail')}</Label>
                        <Input
                            required
                            aria-label="email"
                            {...register('Email')}
                            type="email"
                            wrapperClassName={styles.textInput}
                            defaultValue={email}
                        />
                        <Input
                            {...register('Contact')}
                            wrapperClassName={styles.textInput}
                            defaultValue={userName}
                            label={translate('customer.contactPerson', 'Kontaktperson')}
                        />
                    </div>
                    <div className={styles.infoCol}>
                        <Input
                            {...register('CustomerName')}
                            wrapperClassName={styles.textInput}
                            defaultValue={companyName}
                            label={translate('customer.companyName', 'Firmanavn')}
                        />
                        <Input
                            {...register('PhoneNumber')}
                            wrapperClassName={styles.textInput}
                            defaultValue={phoneNumber}
                            label={translate('customer.phone', 'Telefon')}
                        />
                    </div>
                    <div className={styles.infoCol}>
                        <Input
                            {...register('Address')}
                            label={translate('customer.address', 'Adresse')}
                            defaultValue={address}
                            wrapperClassName={styles.textInput}
                        />
                        <Input
                            {...register('ZipCodeAndCity')}
                            wrapperClassName={styles.textInput}
                            defaultValue={zipCodeAndCity}
                            label={translate('customer.postNumberAndCity', 'Post.nr&by')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>BilInformation</legend>
                <div className={styles.formGrid}>
                    <div className={styles.infoCol}>
                        <fieldset>
                            <Input
                                {...register('RegNr')}
                                wrapperClassName={styles.textInput}
                                defaultValue={licensePlate?.number}
                                label={translate('vehicle.regNr', 'Reg nr.')}
                                onChange={() => clearErrors('carDataError')}
                            />
                            {errors?.carDataError && (
                                <ValidationErrorMessage>
                                    <>{errors?.carDataError.message}</>
                                </ValidationErrorMessage>
                            )}
                            <Input
                                {...register('ModelTypeId')}
                                wrapperClassName={styles.textInput}
                                defaultValue={modelTypeId}
                                label={translate('vehicle.modelTypeId', 'FTZ Biltype nr')}
                            />
                        </fieldset>
                    </div>
                    <div className={styles.infoCol}>
                        <Input
                            {...register('ManufacturerName')}
                            wrapperClassName={styles.textInput}
                            defaultValue={manufacturerName}
                            label={translate('vehicle.manufacturerName', 'Mærke')}
                        />
                        <Input
                            {...register('ModelName')}
                            wrapperClassName={styles.textInput}
                            defaultValue={modelName}
                            label={translate('vehicle.modelName', 'Model')}
                        />
                    </div>
                    <div className={styles.infoCol}>
                        <div className={styles.dateRangeGrid}>
                            <Input
                                {...register('TypeYearFrom')}
                                defaultValue={typeYearFrom}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearFrom', 'Fra årgang')}
                            />
                            <Input
                                {...register('TypeYearTo')}
                                defaultValue={typeYearTo}
                                wrapperClassName={styles.textInput}
                                label={translate('vehicle.yearTo', 'Til årgang')}
                            />
                        </div>
                        <Input
                            {...register('ModelTypeName')}
                            wrapperClassName={styles.textInput}
                            defaultValue={modelTypeName}
                            label={translate('vehicle.type', 'Type')}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div className={styles.formGrid}>
                    <div className={styles.descriptionWrapper}>
                        <TextArea
                            className={styles.description}
                            label={translate('common.description', 'beskrivelse')}
                            {...register('Description', {
                                maxLength: {
                                    value: 500,
                                    message: translate('sharedFormTranslations.maxCharacters', 'Max 500'),
                                },
                            })}
                        />
                        {errors?.Description?.message && (
                            <ValidationErrorMessage>
                                <>{errors?.Description?.message}</>
                            </ValidationErrorMessage>
                        )}
                    </div>
                </div>
            </fieldset>
            <fieldset className={styles.infoCol}>
                <legend>
                    {translate('chassisSupport.howUrgent', 'Hvor meget haster det')}
                    {/* <span className={styles.requiredStar}>*</span> */}
                </legend>
                <div className={styles.radioWrapper}>
                    <Checkbox {...register('IsVeryUrgent', {})}>{translate('chassisSupport.veryUrgent', 'Haster meget')}</Checkbox>
                    <Checkbox {...register('MustHaveAnswersToday', {})}>
                        {translate('chassisSupport.needAnswerToday', 'Skal have svar idag')}
                    </Checkbox>
                    {errors?.Subject && (
                        <ValidationErrorMessage>
                            <>{errors.Subject.message}</>
                        </ValidationErrorMessage>
                    )}
                </div>
            </fieldset>
            <div className={styles.infoCol}>
                <Checkbox {...register('IncludeUserInCc')}>
                    {translate(
                        'sharedFormTranslations.includeUserInCcCheckBox',
                        'Jeg ønsker selv at modtage en kopi af mailen (Mailen bliver sendt til den mail som du har udfyldt i "E-MAIL" filtet)',
                    )}
                </Checkbox>
            </div>
            {emailSizeWarning && (
                <ValidationErrorMessage>
                    {translate(
                        'sharedFormTranslations.emailSizeWaring',
                        'Max samlede filstørrelse for upload er 25 mb. Vælg mindre filstørrelse og prøv igen.',
                    )}
                </ValidationErrorMessage>
            )}
            {errors?.carDataError && (
                <ValidationErrorMessage>
                    <>{errors?.carDataError.message}</>
                </ValidationErrorMessage>
            )}
            <Button type="submit" className={styles.submitButton} fetching={fetching} disabled={disableSubmit}>
                {translate('chassisSupport.sendInquiry', 'Send')}
            </Button>
        </form>
    );
}
