export const ROUTER_QUERY_FUNCTIONS = {
    internalLogin: 'internal-login',
    externalCredit: 'external-credit',
    haynesProPrompt: 'haynesProPrompt',
};

export enum CLIENT_QUERY_OPTIONS {
    fromDate = 'fromDate',
    toDate = 'toDate',
    page = 'page',
    inPageSearch = 'inPageSearch',
    sortBy = 'sortBy',
}
