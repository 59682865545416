import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import { FC, useCallback, useState } from 'react';
import { Bff } from '~/models/bff.d';
import { CdnImage } from '~/shared/image/cdn-image';
import styles from './gallery.module.scss';
import { Svg } from '~/shared/svg';
import useEventListener from '../hooks/use-event-listener.hook';
import LightBox from '../light-box/light-box.component';
import Thumbnails from './thumbnails/thumbnails.component';

const variants = {
    enter: (direction: number) => ({
        x: direction > 0 ? 500 : -500,
        opacity: 1,
    }),
    center: {
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => ({
        x: direction < 0 ? 500 : -500,
        opacity: 0,
        zIndex: 0,
    }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

interface IGallery {
    slides: Bff.IImage[] | undefined;
    withThumbnails?: boolean;
    canOpenInModalView?: boolean;
    isButtonsActive?: boolean;
}

const Gallery: FC<IGallery> = ({ slides, withThumbnails = true, isButtonsActive = false, canOpenInModalView = false }) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const [isOpen, setIsOpen] = useState(false);
    const imageIndex = wrap(0, slides?.length as number, page);
    const moreThanOneSlide = (slides?.length as number) > 1;

    const openLightBox = () => {
        if (canOpenInModalView) {
            setIsOpen(true);
        }
    };

    const closeLightBox = () => {
        if (canOpenInModalView) {
            setIsOpen(false);
        }
    };

    const nextImage = () => {
        if (moreThanOneSlide) {
            setPage([page + 1, 1]);
        }
    };

    const prevImage = () => {
        if (moreThanOneSlide) {
            setPage([page + -1, -1]);
        }
    };

    const onKeyPress = useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'ArrowLeft': {
                    return prevImage();
                }
                case 'ArrowRight': {
                    return nextImage();
                }
                default: {
                    break;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prevImage]
    );

    useEventListener('keydown', onKeyPress);

    if (!slides?.length) {
        return null;
    }

    return (
        <>
            <div className={styles.gallery}>
                {moreThanOneSlide && isButtonsActive ? (
                    <>
                        <button aria-label="Previous" type="button" className={styles.prevBtn} onClick={prevImage}>
                            <Svg name="chevron-left" />
                        </button>
                        <button aria-label="Next" type="button" className={styles.nextBtn} onClick={nextImage}>
                            <Svg name="chevron-right" />
                        </button>
                    </>
                ) : null}

                <div className={styles.slider}>
                    <div className={styles.sliderImageContainer}>
                        <AnimatePresence initial={false} custom={direction}>
                            <motion.div
                                onClick={openLightBox}
                                key={page}
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: { type: 'spring', stiffness: 200, damping: 30 },
                                    opacity: { duration: 0.5 },
                                }}
                                drag={moreThanOneSlide ? 'x' : false}
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={1}
                                onDragEnd={(e, { offset, velocity }) => {
                                    const swipe = swipePower(offset.x, velocity.x);

                                    if (swipe < -swipeConfidenceThreshold) {
                                        nextImage();
                                    } else if (swipe > swipeConfidenceThreshold) {
                                        prevImage();
                                    }
                                }}
                            >
                                <CdnImage
                                    loading="eager"
                                    src={slides[imageIndex]?.url as string}
                                    alt={`Image of ${slides[imageIndex]?.altText}`}
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
                <LightBox isOpen={isOpen} onClose={closeLightBox}>
                    <CdnImage
                        src={slides[imageIndex]?.url as string}
                        alt={slides[imageIndex]?.altText}
                        objectFit="contain"
                        width={1250}
                        height={1350}
                        layout="intrinsic"
                    />

                    {withThumbnails && slides.length > 1 && (
                        <Thumbnails images={slides} activeThumbnailIndex={imageIndex} setActiveThumbnail={setPage} />
                    )}
                </LightBox>
            </div>

            {withThumbnails && slides.length > 1 && <Thumbnails images={slides} activeThumbnailIndex={imageIndex} setActiveThumbnail={setPage} />}
        </>
    );
};

export default Gallery;
