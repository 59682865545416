import { FC } from 'react';
import { Products as ProductTypes } from '~/models/products';
import CrossNumbers from '~/shared/cross-numbers/cross-numbers.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import styles from './product-cross-numbers.module.scss';

interface IProductCrossNumbers {
    values?: ProductTypes.ICrossNumber[];
    oeReference?: string;
    visible?: boolean;
}

const ProductCrossNumbers: FC<IProductCrossNumbers> = ({ values, oeReference, visible }) => {
    const translate = useTranslations();

    const tooltipText = translate('product.showCrossNumbers', 'Se kryds nr');

    return (
        <div className={styles.productCrossNumbers}>
            <p>
                {values?.length} {translate('product.crossNumbers', 'Kryds-numre')}
            </p>
            <CrossNumbers
                oeArticleNo={oeReference}
                visible={visible}
                tooltipText={tooltipText}
                icon={<Svg name="chevron-right" />}
                buttonStyle="secondaryLight"
                values={values}
            >
                {tooltipText}
            </CrossNumbers>
        </div>
    );
};

export default ProductCrossNumbers;
