import { useSavedBasketsTotal } from '~/libs/queries/basket/hooks/use-saved-basket-total';
import { useFeatures, useFeatureToggle } from '~/libs/queries/bff';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { BasketEmpty } from './basket-empty';

export function BasketEmptyContainer() {
    const translate = useTranslations();
    const { data: { basket: basketFeatures } = {} } = useFeatures();
    const { shouldHideFeature } = useFeatureToggle();
    const { data: savedBasketsTotal } = useSavedBasketsTotal();

    const shouldShowSavedBaskets = !!(!shouldHideFeature(basketFeatures?.savedBaskets) && savedBasketsTotal && savedBasketsTotal.total > 0);
    const savedBasketsLabels = shouldShowSavedBaskets
        ? {
              heading: translate('basket.mySavedBaskets', 'Mine gemte kurve'),
              subHeading: `${savedBasketsTotal.total} ${translate('basket.baskets', 'kurve')}`,
          }
        : {};

    return (
        <BasketEmpty
            heading={translate('basket.basketIsEmpty', 'Kurven er tom')}
            showSavedBaskets={shouldShowSavedBaskets}
            savedBasketsHeading={savedBasketsLabels.heading}
            savedBasketsSubHeading={savedBasketsLabels.subHeading}
        />
    );
}
