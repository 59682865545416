import Link from 'next/link';
import cx from 'classnames';
import { Products as ProductTypes } from '~/models/products';
import styles from './sibling-category-item.module.scss';

export type SiblingCategoryItemProps = {
    item: ProductTypes.ICategoryNameWithUrl;
    onClick: (to: string) => void;
};

export function SiblingCategoryItem({ item, onClick }: SiblingCategoryItemProps) {
    if (!item.disabled) {
        return (
            <Link href={item.url as string} passHref>
                <a
                    className={cx(styles.siblingCategoryItem, {
                        [styles.selected]: item.selected,
                    })}
                    onClick={() => onClick?.(item.name as string)}
                >
                    {item.name}
                </a>
            </Link>
        );
    }

    return (
        <a aria-disabled="true" className={styles.siblingCategoryItem}>
            {item.name}
        </a>
    );
}
