import type { Bff } from '~/models/bff';
import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import Text from '~/shared/text/text.component';
import Router, { useRouter } from 'next/router';
import { useCallback } from 'react';
import { CATEGORY_LIST_TYPE } from '~/constants/local.storage';
import useLocalStorage from '~/shared/hooks/use-local-storage.hook';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import Toggle from '~/shared/toggle/toggle.component';
import { Products } from '~/models/products';
import ErrorBox from '~/shared/error-box/error-box';
import Fader from '~/shared/fader/fader.component';
import { useSparePartCategoryByVehicle } from '~/libs/queries/vehicles/hooks/use-spare-part-categories-by-vehicle';
import { TopmotiveFrame } from 'shared/topmotive';
import Heading from '~/shared/heading/heading.component';
import Button from '~/shared/buttons/button/button.component';
import useUser from '~/libs/use-user';
import { useStaticContent } from '~/libs/queries/bff';
import Links from '~/widgets/W120SparePartCategoryList/chunks/links';
import { CategoryListViewTypes } from '~/widgets/W120SparePartCategoryList/types';

const Pictogram = dynamic(() => import('~/widgets/W120SparePartCategoryList/pictogram/pictogram.component'));

function isCategoryList(data?: Products.ICategory | Products.ICategory[]): data is Products.ICategory[] {
    return Array.isArray(data);
}

export function SparepartCategoryList(props: Bff.ISparePartCategoryListWidgetViewModel) {
    if (
        // `2` is the identifier for the truck and trailer spareparts catalogue
        props.catalogueType === 2
    ) {
        return <TruckSparepartCategoryList topmotiveUrl={props.topMotiveUrl} />;
    }

    return <CarSparepartCategoryList {...props} />;
}

export function TopmotiveAccessError() {
    const translate = useTranslations();
    const router = useRouter();

    const { isInternalUser } = useUser();

    const { data: { urlMappings } = {} } = useStaticContent();

    const handleClick = useCallback(() => {
        if (!urlMappings?.internalHomepage && !urlMappings?.homepage) {
            return;
        }

        router.push(isInternalUser ? urlMappings.internalHomepage : urlMappings.homepage);
    }, [router, isInternalUser, urlMappings?.internalHomepage, urlMappings?.homepage]);

    return (
        <div className={styles.noAccessContainer}>
            <Heading className={styles.noAccessHeader} tagName="h2">
                {translate('topmotive.noAccessHeader', 'Vi kan se du har forsøgt at finde en lastbil')}
            </Heading>
            <Text tagName="p">
                {translate(
                    'topmotive.noAccessSubHeading',
                    'Vi kan se du har søgt efter en lastbil, men du har ikke rettighederne til at slå reservedele op på denne type af køretøj.',
                )}
            </Text>
            <Text tagName="p">
                {translate(
                    'topmotive.noAccessSecondaryText',
                    'For at få rettighed, eller hører nærmere om FTZs truck univers kontakt Truck and Trailer teamet på +65 65 41 90  eller truck.trailer@ftz.dk',
                )}
            </Text>
            <Button className={styles.backButton} onClick={handleClick}>
                {translate('topmotive.noAccessBackToFrontpage', 'Tilbage til forside')}
            </Button>
        </div>
    );
}

export type TruckSparepartCategoryListProps = {
    topmotiveUrl?: string;
};

export function TruckSparepartCategoryList({ topmotiveUrl }: TruckSparepartCategoryListProps) {
    if (!topmotiveUrl) {
        return <TopmotiveAccessError />;
    }

    return <TopmotiveFrame src={topmotiveUrl} />;
}

const CarSparepartCategoryList = (props: Bff.ISparePartCategoryListWidgetViewModel) => {
    const translate = useTranslations();
    const [viewType, setViewType] = useLocalStorage(CATEGORY_LIST_TYPE, getDefaultViewType(), true);
    const isMobile = useMediaQueryDetect(MediaQuery.SM);
    const { data: categoryData, isLoading, isError } = useSparePartCategoryByVehicle(props?.carId, props, props?.categoryId);

    const children = isCategoryList(categoryData) ? categoryData : categoryData?.children;

    // Default viewtype should be pictogram if available
    const isViewTypeList =
        (typeof viewType === 'boolean' && viewType) || viewType === CategoryListViewTypes.List || !props.pictogramImageUrl || isMobile;

    const handleViewModeToggle = () => {
        const currentViewType = isViewTypeList ? CategoryListViewTypes.Pictogram : CategoryListViewTypes.List;

        setViewType(currentViewType);
    };

    return (
        <>
            {props.pictogramImageUrl && !isMobile && !isLoading && (
                <header className={styles.header}>
                    <div className={styles.toggler}>
                        <div className={styles.label}>{translate('categoryList.chooseViewType', 'Vælg visning:')}</div>

                        <Toggle
                            isOn={!isViewTypeList}
                            onClick={handleViewModeToggle}
                            onIcon="list"
                            onText={translate('categoryList.togglerList', 'Liste')}
                            offIcon="car"
                            offText={translate('categoryList.togglerPictogram', 'Oversigt')}
                        />
                    </div>
                </header>
            )}

            <AnimatePresence initial={false} exitBeforeEnter>
                {isViewTypeList && (
                    <Fader motionKey="links">
                        <Links isLoading={isLoading} count={props?.count} categories={children} />

                        {isError && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt. Prøv igen senere')}</ErrorBox>}
                    </Fader>
                )}

                {!isViewTypeList && props.pictogramImageUrl && (
                    <Fader motionKey="pictogram">
                        <Pictogram title={props.title} id={props.pictogramId} src={props.pictogramImageUrl} vehicleData={props} />
                    </Fader>
                )}
            </AnimatePresence>
        </>
    );
};

const getDefaultViewType = () => {
    const hashTag = Router.asPath && Router.asPath.match(/#([a-z0-9]+)/gi);
    const isPictogramInUrl = hashTag && hashTag[0] === `#${CategoryListViewTypes.Pictogram}`;
    const isListInUrl = hashTag && hashTag[0] === `#${CategoryListViewTypes.List}`;

    return (isPictogramInUrl && CategoryListViewTypes.Pictogram) || (isListInUrl && CategoryListViewTypes.List) || false;
};

export default SparepartCategoryList;
