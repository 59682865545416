import React, { FC } from 'react';
import styles from './progress.module.scss';

interface IProgressProps {
    percentage: number;
    width?: string;
}

const Progress: FC<IProgressProps> = ({ percentage, width = '100%' }) => (
    <div className={styles.progressBar} style={{ width }}>
        <span style={{ width: `${percentage}%` }} className={styles.progress} />
    </div>
);

export default Progress;
