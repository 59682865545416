import { useLayoutState } from '~/context/layout.context';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import OverviewLoadMore from '~/page-elements/overview/common/overview-load-more/overview-load-more.component';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useInfiniteLoading from '~/shared/hooks/use-infinite-loading';
import WithOverviewNavigation from '~/widgets/overview/overview-navigation/overview-navigation.hoc';
import BasketsList from './baskets-list-component/baskets-list.component';

//Name before: saved-baskets.component.tsx
function W110SavedBasketsOverview() {
    const { baskets } = useEndpoints();
    const { data: features } = useFeatures();
    const { shouldHideFeature } = useFeatureToggle();
    const { selectedDepartment } = useLayoutState();

    const {
        isLoadingInitialData,
        data: savedBaskets,
        isLoadingMore,
        total,
        loaded,
        error,
        loadMore,
        mutate,
    } = useInfiniteLoading({
        pathName: baskets.getSavedBaskets,
        key: 'savedBaskets',
        localeOption: LocaleOptions.query,
        query: {
            departmentId: selectedDepartment?.id,
        },
    });

    if (shouldHideFeature(features?.basket?.savedBaskets)) {
        return null;
    }

    return (
        <>
            <BasketsList error={error} baskets={savedBaskets?.filter(Boolean)} fetching={isLoadingInitialData} reloadBaskets={mutate} />

            {savedBaskets?.length ? <OverviewLoadMore total={total} loaded={loaded} onClick={loadMore} fetching={isLoadingMore} /> : null}
        </>
    );
}

const SavedBasketsWithNav = WithOverviewNavigation(W110SavedBasketsOverview);

export { W110SavedBasketsOverview, SavedBasketsWithNav as default };
