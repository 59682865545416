import { createQueryKeys } from '@lukemorales/query-key-factory';
import { FavoritePlacement, FavoriteType } from '.';

export const favoriteKeys = createQueryKeys('favorite', {
    favoriteList: (locale: string, userId: string, type: FavoriteType) => ({ locale, userId, type }),
    statusList: (locale?: string, userId?: string, placement?: FavoritePlacement, itemNoList?: string[], pageIndex?: number) => ({
        locale,
        userId,
        placement,
        itemNoList,
        pageIndex,
    }),
});
