import React, { FC } from 'react';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import FacetGroup from './facet-group/facet-group.component';
import { Products as ProductTypes } from '~/models/products';
import FilterSkeleton from './filter-skeleton.component';
import { useAllFilters, useFilterActions } from './filter.hook';
import styles from './filter.module.scss';

interface IProps {
    facets?: ProductTypes.IFacet[];
    totalResults?: number;
    fetching: boolean;
    closeFilterModal: () => void;
}

const FacetsFilter: FC<IProps> = ({ facets, totalResults = 0, fetching, closeFilterModal }) => {
    const translate = useTranslations();

    const { clearQueryParams } = useFilterActions();
    const { totalActiveFilters } = useAllFilters();

    return (
        <div>
            {fetching ? <FilterSkeleton /> : facets?.map((facet, i: number) => <FacetGroup key={facet.key} index={i} facet={facet} />)}
            <div className={styles.buttonGroup}>
                <Button type="button" disabled={totalActiveFilters === 0} buttonStyle="secondaryLight" onClick={clearQueryParams}>
                    {translate('filter.clearFacets', 'Fjern alle filtrér')}
                </Button>
                <Button type="button" buttonStyle="primary" onClick={() => closeFilterModal()}>
                    {`${translate('filter.show', 'Vis')} ${totalResults ?? ''} ${translate('filter.results', 'resultater')}`}
                </Button>
            </div>
        </div>
    );
};

export default FacetsFilter;
