import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import styles from './ftz-prices-tooltip.module.scss';
import ColliPrices, { IColliPrice } from '~/shared/colli-prices/colli-prices.component';
import useTranslations from '../hooks/use-translations.hook';
import Skeleton from '~/shared/skeleton/skeleton.component';
import { IWithClassName } from '~/models/dev';
import Price from '~/shared/price/price.component';
import Tooltip, { TooltipContentWrapper } from '../tooltip/tooltip.component';

interface IProps extends IWithClassName {
    prices: IColliPrice;
    colli?: number;
    colliLocked?: boolean;
    isShort?: boolean;
    isLoading?: boolean;
    unit?: string;
}

const FtzPricesTooltip: FC<IProps> = ({ prices, colli, colliLocked, isShort, isLoading, className, unit }) => {
    const translate = useTranslations();
    const displayUnit = unit || translate('product.piece', 'stk');

    const ftzCode = useMemo(() => {
        if (!prices) return '-';
        if (!prices?.actualPrice?.ftzCode) return '-';
        if (isShort) return prices?.actualPrice?.ftzCode;
        if (colliLocked) {
            return `${colli} ${displayUnit} / ${prices?.actualPrice?.ftzCode || '-'}`;
        }
        const code = prices.allPrices.find((x) => x.quantity === 1)?.variant?.ftzCode;

        return `1 ${displayUnit} / ${code || '-'}`;
    }, [prices, isShort, colliLocked, colli, displayUnit]);

    if (isLoading) {
        return (
            <div className={className}>
                <Skeleton style={{ height: '15px', width: '70px', marginTop: '5px' }} />
            </div>
        );
    }

    return (
        <div className={clsx(styles.root, className)}>
            <Price color="grey" size="small" upperCase>
                {ftzCode}
            </Price>
            {prices?.allPrices?.length > 1 && (
                <Tooltip
                    content={
                        <TooltipContentWrapper>
                            <ColliPrices prices={prices.allPrices} unit={unit} />
                        </TooltipContentWrapper>
                    }
                />
            )}
        </div>
    );
};

export default FtzPricesTooltip;
